export var parishList = [
    "All parishes",
    "Acadia",
    "Allen",
    "Ascension",
    "Assumption",
    "Avoyelles",
    "Beauregard",
    "Bienville",
    "Bossier",
    "Caddo",
    "Calcasieu",
    "Caldwell",
    "Cameron",
    "Catahoula",
    "Claiborne",
    "Concordia",
    "DeSoto",
    "East Baton Rouge",
    "East Carroll",
    "East Feliciana",
    "Evangeline",
    "Franklin",
    "Grant",
    "Iberia",
    "Iberville",
    "Jackson",
    "Jefferson",
    "Jefferson Davis",
    "Lafayette",
    "Lafourche",
    "LaSalle",
    "Lincoln",
    "Livingston",
    "Madison",
    "Morehouse",
    "Natchitoches",
    "Orleans",
    "Ouachita",
    "Plaquemines",
    "Pointe Coupee",
    "Rapides",
    "Red River",
    "Richland",
    "Sabine",
    "St. Bernard",
    "St. Charles",
    "St. Helena",
    "St. James",
    "St. John the Baptist",
    "St. Landry",
    "St. Martin",
    "St. Mary",
    "St. Tammany",
    "Tangipahoa",
    "Tensas",
    "Terrebonne",
    "Union",
    "Vermilion",
    "Vernon",
    "Washington",
    "Webster",
    "West Baton Rouge",
    "West Carroll",
    "West Feliciana",
    "Winn"
];
  
   
   
export var parishList2 = [
    "All parishes",
    "Acadia",
    "Allen",
    "Ascension",
    "Assumption",
    "Avoyelles",
    "Beauregard",
    "Bienville",
    "Bossier",
    "Caddo",
    "Calcasieu",
    "Caldwell",
    "Cameron",
    "Catahoula",
    "Claiborne",
    "Concordia",
    "DeSoto",
    "East Baton Rouge",
    "East Carroll",
    "East Feliciana",
    "Evangeline",
    "Franklin",
    "Grant",
    "Iberia",
    "Iberville",
    "Jackson",
    "Jefferson",
    "Jefferson Davis",
    "Lafayette",
    "Lafourche",
    "LaSalle",
    "Lincoln",
    "Livingston",
    "Madison",
    "Morehouse",
    "Natchitoches",
    "Orleans",
    "Ouachita",
    "Plaquemines",
    "Pointe Coupee",
    "Rapides",
    "Red River",
    "Richland",
    "Sabine",
    "St. Bernard",
    "St. Charles",
    "St. Helena",
    "St. James",
    "St. John the Baptist",
    "St. Landry",
    "St. Martin",
    "St. Mary",
    "St. Tammany",
    "Tangipahoa",
    "Tensas",
    "Terrebonne",
    "Union",
    "Vermilion",
    "Vernon",
    "Washington",
    "Webster",
    "West Baton Rouge",
    "West Carroll",
    "West Feliciana",
    "Winn",
    "Multiple",
    "Out of State",
    "Out of Country",
    "Unknown",
];