import React, { Fragment } from "react";
import Markdown from "react-markdown";

import CTABlock from "./CTABlock";
import Button from "../Button/Button";

import LighthouseGraphic from "../../../Assets/Images/PublicSite/lighthouse-graphic.svg";

import "./LoginCTABlock.scss";

export default function LoginCTABlock(props) {
    const openButtonLink = () => {
        window.open(props.ctaButtonLink, "_blank");
    };

    const getDescription = () => {
        return (
            <Fragment>
                <div className="title">
                    {props.ctaTitle}
                </div>
                <div className="description">
                    {props.ctaBody}
                </div>
            </Fragment>
        );
    };

    const getContent = () => {
        return (
            <Fragment>
                {
                    !props.hideImage && props.ctaSmallText && (
                        <div>
                        {!props.hideImage && <div className="img-wrapper">
                            <img
                                alt="LighthouseGraphic"
                                src={LighthouseGraphic} />
                        </div>}

                        {props.ctaSmallText && <span>
                                <h3><Markdown>{props.ctaSmallText}</Markdown></h3>
                        </span>}
                        </div>
                    )
                }
                <Button className="public-site-btn btn-white" onClickFn={openButtonLink}>
                    <h3>{props.ctaButton}</h3>
                </Button>
            </Fragment>
        );
    };

    return (
        <div>
            <CTABlock
                className="login-cta-block cta-block-blue"
                description={getDescription()}
                content={getContent()}
            />
        </div>
    );
};