import React, { useState, useEffect, Fragment } from "react";
import Markdown from "react-markdown";

import Layout from "./Layout";
import Hero from "../../Components/PublicSite/Hero/Hero";
import MediaWrapper from "../../Components/PublicSite/MediaWrapper/MediaWrapper";
import IconWrapper from "../../Components/PublicSite/IconWrapper/IconWrapper";
import LoginCTABlock from "../../Components/PublicSite/CTABlock/LoginCTABlock";
import Highlight from "../../Components/PublicSite/Highlight/Highlight";

import useContentful from "../../Lib/Hooks/useContentful";
import { ENUMS } from "../../Lib/Enums";

export default function LandingPage() {
    const {
        getIconContent,
        getHighlightContent,
        getSiteContent
    } = useContentful();

    const [siteContent, setSiteContent] = useState({});
    const [listOfIcons, setListOfIcons] = useState([]);
    const [listOfHighlights, setListOfHighlights] = useState([]);

    const getSiteHeaderTitle = (header) => {
        if (!header) {
        return (<Fragment></Fragment>)
        }

        const headerElementStr = header
        .replace("\\", "<span class='site-header-title title highlight'>")
        .replace("\\", "</span>");
        return (
        <div
            className="site-header-title title"
            dangerouslySetInnerHTML={{ __html: headerElementStr }}
        >
        </div>
        );
    };

    useEffect(() => {
        (async () => {
          const response = await getSiteContent(ENUMS.ROUTES.LANDING_PAGE.CID);
          setSiteContent(response);
        })();
    }, [getSiteContent]);

    useEffect(() => {
        (async () => {
          const response = await getIconContent(ENUMS.ROUTES.LANDING_PAGE.CID, "intro");
          setListOfIcons(response);
        })();
    }, [getIconContent]);

    useEffect(() => {
        (async () => {
          const response = await getHighlightContent(ENUMS.ROUTES.LANDING_PAGE.CID, "highlight");
          setListOfHighlights(response);
        })();
    }, [getHighlightContent]);

    return (
        <Layout>
            <div className="landing">
                <Hero
                    title={getSiteHeaderTitle(siteContent.header)}
                    body={siteContent.headerBody}
                    button={siteContent.headerButton}
                    buttonLink={siteContent.headerButtonLink}
                />
                <MediaWrapper
                    url={siteContent.headerMedia?.url}
                    contentType={siteContent.headerMedia?.contentType}
                    title={siteContent.headerMedia?.title}
                />
                <div className="site-body">
                    <div className="site-section site-section-row">
                        <div className="site-header">
                            {getSiteHeaderTitle(siteContent.introTitle)}
                        </div>
                        {
                            <h3>
                            <Markdown>
                            {siteContent.introBodySingle}
                            </Markdown>
                            </h3>
                        }
                    </div>
                    <div className="icon-list-wrapper site-section-row">
                        {
                            listOfIcons.map((icon) => (
                                <IconWrapper
                                    iconName={icon.iconName}
                                    caption={icon.caption}
                                />
                            ))
                        }
                    </div>

                    <div className="site-section">
                        <LoginCTABlock
                            hideImage={true}
                            ctaTitle={siteContent.cta1title}
                            ctaBody={siteContent.cta1body}
                            ctaButton={siteContent.cta1button}
                            ctaButtonLink={siteContent.cta1buttonLink}
                        />
                    </div>

                    <div className="site-section">
                        {listOfHighlights.map((highlight) => (
                            <Highlight
                                sortOrder={highlight.sortOrder}
                                header={highlight.header}
                                body={highlight.body}
                                image={highlight.image}
                                button={highlight.button}
                                buttonLink={highlight.buttonLink}
                            />
                        ))}
                    </div>

                    <div className="site-section">
                        <LoginCTABlock
                            hideImage={false}
                            ctaTitle={siteContent.cta2title}
                            ctaBody={siteContent.cta2body}
                            ctaSmallText={siteContent.cta2smallText}
                            ctaButton={siteContent.cta2button}
                            ctaButtonLink={siteContent.cta2buttonLink}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}