import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";
import { Checkbox } from '../../../Components/Checkbox'

export const SingleBarGraph = (props) => {
  const wrapperRef = useRef();
  const svgRef = useRef();
  const [activeBarLegend, setActiveBarLegend] = useState(true)
  const [trendLine, setTrendLine] = useState(true);
  const [trendLineTwo, setTrendLineTwo] = useState(true);
  const { chartColorArray } = useAppContext()
  const colors = props.singleColor ? d3.scaleOrdinal([chartColorArray[0]]) : d3.scaleOrdinal(chartColorArray)

  const handleBarLegendClick = (e) => {
    setActiveBarLegend(!activeBarLegend)
  };

  const handleTrendLineClick = (e) => {
    // If chartLine is updated in this component, update props
    props.updateChartLineShowing(!trendLine)
    setTrendLine(!trendLine);
  };

  const handleTrendLine2Click = (e) => {
    // If chartLine is updated in this component, update props
    props.updateChartLineShowingTwo(!trendLineTwo)
    setTrendLineTwo(!trendLineTwo);
  };

  

  useEffect(() => {
    function endTooltip() {
      const boxes = document.querySelectorAll('.tooltip');

      boxes.forEach(box => {
        box.style.opacity = 0;
      });
    }
    window.addEventListener('scroll', endTooltip)
    //Setting the width and height
    var margin = { top: 20, right: props.yaxisRight ? 70 : 0, bottom: 90, left: 80 };
    var width;
    var height = 500;
    if (window.innerWidth > 1200 && window.innerWidth < 1440) {
      width = 900;
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
      width = 900;
    } else {
      width = 1200;
    }
    if (props.widthNeeded) {
      width = props.widthNeeded;
    } else if (props.report) {
      width = 700
    }

    // Formatting xaxis titles and bar colors
    let titles;
    if (props.keys) {
      titles = props.keys.map((item) => {
        if (item.quarter) {
          return `${item.name} ${item.quarter}`;
        } else if (props.xaxisLabelsKey) {
          return item[props.xaxisLabelsKey];
        } else {
          return item.name;
        }
      });
    } else {
      titles = props.data.map(d => d[props.xaxisVar])
    }

    //For reports
    var data;
    if (props.type === "cc") {
      data = props.data;
    } else {
      data = props.data.referrals;
    }

    if (data.length < 5) {
      width = 600;
    }

    //Calculating axis
    let max = d3.max(data, (d) => +(d.maxYaxis ?? d.value ?? d.total))
    if (max === 0) {
      max = 5
    }
    let max2 = props.yaxisRight ? d3.max(data, (d) => +(d.value ?? d.total)) : null
    if (max2 === 0) {
      max2 = 5
    }

    //Selecting the svg element and removing past svg's (from filter)
    let svg = d3.select(svgRef.current);
    svg.selectAll("svg").remove();

    //Creating SVG
    var barsvg = svg
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + 0 + "," + 0 + ")");

    // Creating the tooltip
    var tooltip = d3
      .select(wrapperRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("color", "black")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("position", "fixed")
      .style("z-index", "-1")
      .style("padding", "5px");
    tooltip.append("div").attr("class", "title");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "percent");

    // set the ranges
    var x = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([margin.left, width - margin.right])
      .padding(0.1);
    var y = d3
      .scaleLinear()
      .domain([0, max])
      .nice()
      .range([height - margin.bottom, margin.top]);
    var y2;
    if (props.yaxisRight) {
      y2 = d3
        .scaleLinear()
        .domain([0, max2])
        .nice()
        .range([height - margin.bottom, margin.top]);
    } 

    var xAxis = (g) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .tickFormat((i) => titles[i])
          .tickSizeOuter(0)
      );

    var formatxAxis = d3.format(".0f");
    let yAxis = (g) =>
        g
          .attr("transform", `translate(${max < 20 ? 60 : 80},0)`)
          .call(max < 20 ? d3.axisLeft(y).ticks(max).tickFormat(formatxAxis) : d3.axisLeft(y).ticks(null, data.y))
          .attr("class", "y-axis")
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y)
          );

    let yAxis2;
    if (props.yaxisRight) {
      yAxis2 = (g) =>
        g
          .attr("transform", `translate(${width - margin.right},0)`)
          .call(max2 < 20 ? d3.axisRight(y2).ticks(max2).tickFormat(formatxAxis) : d3.axisRight(y2).ticks(null, data.y))
          .attr("class", `y-axis ${!activeBarLegend ? 'transparent' : ''}`)
          .call((g) =>
            g
              .append("text")
              .attr("x", +margin.right)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y)
        )
          .attr('opacity', activeBarLegend ? 1 : 0);
    }

    // Adding the bars
    barsvg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove)
      .attr("x", (d, i) => x(i))
      .attr("y", (d) => {
        if (props.yaxisRight) {
          return y2(d.value ?? d.total);
        } else {
          return y(d.value ?? d.total);
        }
      })
      .attr("height", (d) => {
        if (props.yaxisRight) {
          return y2(0) - y2(d.value ?? d.total); // ??
        } else {
          return y(0) - y(d.value ?? d.total);
        }
      })
      // .attr("width", x.bandwidth() > 90 ? 60 : x.bandwidth())
      //.attr("width", x.bandwidth() > 60 ? 60 : x.bandwidth())
      .attr("width", x.bandwidth())
      .attr("fill", (d, i) => {
        if (activeBarLegend) {
          return colors(i);
        } else {
          return 'transparent'
        }
      });

    var labelKeys = props.keys ?? props.labelOnHover ?? [];
    var graphVariable = props.graphVariable ? props.graphVariable : "source";

    function onMouseOver(d) {
      tooltip.style("opacity", 1)
      tooltip.style("z-index", "9999");

      let title;
      if (props.barLegend) {
        title = props.barLegend[0].name
      } else if (props.labelOnHover && props.xaxisVar) {
        if (props.xaxisVar === 'age') {
          title = `Age: ${d[props.xaxisVar]}`
        } else {
          title = d[props.xaxisVar]
        }
      } else {
        for (let i = 0; i < labelKeys.length; i++) {
          if (d[graphVariable] === labelKeys[i].variable) {
            if (graphVariable === "age") {
              title = `Age: ${labelKeys[i].name}`;
            } else {
              title = labelKeys.filter(f => f.variable === d[graphVariable])[0].name;
            }
          }
        }
      }
      var total = d3.sum(
        data.map((d) => {
          return d.value ?? d.total;
        })
      );
      var percent =
        Math.round((1000 * (d.value ?? d.total)) / total) / 10;
      tooltip.select(".title").text(title);
      tooltip
        .select(".label")
        .text(
          "Total: " +
          (d.value ?? d.total)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
      if (!props.noPercent) {
        tooltip.select(".percent").text("Percent: " + percent + "%");
      }
      }

    function onMouseOut() {
      tooltip.style("opacity", 0)
        .style("z-index", "-1");
    }

    function onMouseMove(d) {
      tooltip
        .style("opacity", 1)
        .style("z-index", "9999")
        .style("top", d3.event.clientY - 100 + "px")
        .style("left", d3.event.clientX - 150 + "px");
    }

    // x axis labels
      barsvg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .selectAll(".tick text")
        .style("font-size", props.xaxisFontSize ? props.xaxisFontSize : '12px')
      .call(wrap, x.bandwidth()); 
    
    if (props.rotateXaxis) {
    barsvg
      .select(".x.axis")
      .call(xAxis)
      .selectAll(".tick text")
      .attr('transform', 'rotate(55 -8 30)')
    }
    

    barsvg.append("g").call(yAxis);

    if (props.yaxisRight) {
      barsvg.append("g").call(yAxis2);
    } 
    

    // text label for the x axis
    barsvg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "translate(" + width / 2 + " ," + (height - 5) + ")")
      .style("text-anchor", "middle")
      .text(props.xaxis);

    // text label for the y axis
    barsvg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(props.yaxis);
    
    if (props.yaxisRight) {
      barsvg
        .append("text")
        .attr("class", "axislabel")
        .attr("transform", "rotate(-90)")
        .attr("y", width - 20)
        .attr("x", -(height/2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(props.yaxisRight && activeBarLegend ? props.yaxisRight : '');
    }

    // wrap x axis labels function
    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/[ /]+/).reverse(), // /\s+/
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = 0.5, // parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width && line.length > 1) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    // ********************* Start of Trend line(s) *********************
    if (props.trendLine) {
      // eslint-disable-next-line array-callback-return
      let lineData;
      if (props.trendLineData1) {
        lineData = props.trendLineData1
      } else {
        lineData = data.filter(item => {
          item.value = item.value ?? "0";
          return item;
        });
      }

      //if (lineData.length > 1) {
      if (trendLine && lineData.length > 1) {

        //Adding line and circle hover
        const totalLine = d3
          .line()
          .x((d, i) => x(i) + x.bandwidth() / 2)
          .y((d) => y(d.value ?? d.total));

        barsvg
          .append("path")
          .datum(lineData)
          .attr("fill", "none")
          .attr("stroke", props.trendLineColor ? chartColorArray[props.trendLineColor] : "#212121")
          .attr("stroke-width", 2)
          .attr("class", `line trendLine ${props.trendLineColor ? `strokeColor${props.trendLineColor}` : ''}`)
          .attr("d", totalLine);

        let Tooltip = d3
          .select(wrapperRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          .style("z-index", "-1")
          .style("padding", "5px");
        Tooltip.append("div").attr("class", "label");
        Tooltip.append("div").attr("class", "percent");

        // Tberry added code here
        const mouseover = (d) => {
          Tooltip.style("opacity", 1);
          Tooltip.style("z-index", "9999");
          if (props.imbByYear) {
            var percent = 0;
            for (let i = 1; i < props.data.length; i++) {

              // console.log("name", props.data[i].name);
              if (props.data[i].name === d.name) {
                percent = (
                  props.data[i].value / props.data[i - 1].value
                ).toFixed(2);
              }
            }
            if (d.name !== "2016") {
              Tooltip.select(".percent").text("Percent Change: " + percent + "%");
            } else if (d.name === "2016") {
              Tooltip.select(".percent").text("");
            }
          }
        };
        const mousemove = (d) => {
          if (props.lineTitle) {
            Tooltip.select(".label").text(
              props.lineTitle +
              ": " +
              (d.value ?? d.total)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
            Tooltip.style("top", d3.event.clientY - 60 + "px") // - 75
              .style("left", d3.event.clientX - 150 + "px");
          } else {
            Tooltip.select(".label").text(
              props.trendLineLegend +
              ": " +
              (d.value ?? d.total)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
            Tooltip.style("top", d3.event.clientY - 60 + "px").style(
              "left",
              d3.event.clientX - 150 + "px"
            );
            if (props.imbByYear) {
              var percent = 0;
              for (let i = 1; i < props.data.length; i++) {
                if (props.data[i].name === d.name) {
                  percent = (
                    props.data[i].value / props.data[i - 1].value
                  ).toFixed(2);
                }
              }
              if (d.name !== "2016") {
                Tooltip.select(".percent").text(
                  "Percent Change: " + percent + "%"
                );
              } else if (d.name === "2016") {
                Tooltip.select(".percent").text("");
              }
            }
          }
        };
        const mouseleave = (d) => {
          Tooltip.style("opacity", 0);
          Tooltip.style("z-index", "-1");
        };

        barsvg
          .append("g")
          .selectAll("dot")
          .data(lineData)
          .enter()
          .append("circle")
          .attr("class", "myCircle")
          .attr("cx", function (d, i) {
            return x(i) + x.bandwidth() / 2;
          })
          .attr("cy", function (d) {
            return y(d.value ?? d.total);
          })
          .attr("r", 3)
          .attr("stroke", props.trendLineColor ? chartColorArray[props.trendLineColor] : "#000000")
          .attr("stroke-width", 3)
          .attr("fill", props.trendLineColor ? chartColorArray[props.trendLineColor] : "#000000")
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave);
        // else if (props.percentLine) {
        //   // eslint-disable-next-line array-callback-return
        //   let lineData = data.filter((item, key) => {
        //     if (item.value) {
        //       return item;
        //     }
        //   });
        //   console.log(lineData)
        //   lineData[0].percent = 0;
        //   let percentageArray = [lineData[0]];
        //   for (let i = 1; i < lineData.length; i++){
        //     let newValue = (lineData[i].value - lineData[i - 1].value)/lineData[i - 1].value;
        //     lineData[i].percent = newValue * 100;
        //     percentageArray.push(lineData[i])
        //   }
        //   console.log(percentageArray)

        //   let y2 = d3.scaleLinear().range([height - margin.bottom, margin.top]);

        //   let percentMax = d3.max(percentageArray, (d) => +d.percent);
        //   console.log(percentMax)

        //   y2.domain([0, percentMax]);

        //   let y2Axis = d3.axisRight(y2).tickFormat(d => d + "%")

        //   svg
        //     .append("g")
        //     .attr("class", "y axis")
        //     .attr("transform", `translate(${width}, 0 )`)
        //     .call(y2Axis);

        //   //Adding line and circle hover
        //   const percentLine = d3
        //     .line()
        //     .x((d, i) => x(i) + x.bandwidth() / 2)
        //     .y((d) => y2(d.percent));

        //   barsvg
        //     .append("path")
        //     .datum(percentageArray)
        //     .attr("fill", "none")
        //     .attr("stroke", "#212121")
        //     .attr("stroke-width", 2)
        //     .attr("class", "line trendLine")
        //     .attr("d", percentLine);

        //   let Tooltip = d3
        //     .select(wrapperRef.current)
        //     .append("div")
        //     .style("opacity", 0)
        //     .attr("class", "tooltip")
        //     .style("opacity", 0)
        //     .style("background-color", "white")
        //     .style("color", "black")
        //     .style("border", "solid")
        //     .style("border-width", "2px")
        //     .style("border-radius", "5px")
        //     .style("position", "fixed")
        //     .style("z-index", "-1")
        //     .style("padding", "5px");
        //   Tooltip.append("div").attr("class", "label");
        //   Tooltip.append("div").attr("class", "percent");

        //   // Tberry added code here
        //   const mouseover = (d) => {
        //     Tooltip.style("opacity", 1);
        //     Tooltip.style("z-index", "9999");
        //     if (props.imbByYear) {
        //       var percent = 0;
        //       for (let i = 1; i < props.data.length; i++) {
        //         console.log("d", d);

        //         console.log("name", props.data[i].name);
        //         if (props.data[i].name === d.name) {
        //           percent = (
        //             props.data[i].value / props.data[i - 1].value
        //           ).toFixed(2);
        //         }
        //       }
        //       if (d.name !== "2016") {
        //         Tooltip.select(".percent").text("Percent Change: " + percent + "%");
        //       } else if (d.name === "2016") {
        //         Tooltip.select(".percent").text("");
        //       }
        //     }
        //   };
        //   const mousemove = (d) => {
        //     if (props.lineTitle) {
        //       Tooltip.select(".label").text(
        //         props.lineTitle +
        //           ": " +
        //           (d.value ?? d.total)
        //             .toString()
        //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //       );
        //       Tooltip.style("top", d3.event.clientY - 60 + "px") // - 75
        //         .style("left", d3.event.clientX - 150 + "px");
        //     } else {
        //       Tooltip.select(".label").text(
        //         props.trendLineLegend +
        //           ": " +
        //           (d.value ?? d.total)
        //             .toString()
        //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //       );
        //       Tooltip.style("top", d3.event.clientY - 60 + "px").style(
        //         "left",
        //         d3.event.clientX - 150 + "px"
        //       );
        //       if (props.imbByYear) {
        //         var percent = 0;
        //         for (let i = 1; i < props.data.length; i++) {
        //           console.log("d", d);
        //           if (props.data[i].name === d.name) {
        //             console.log("data[i] name", props.data[i].name);
        //             console.log("d name", d.name);
        //             percent = (
        //               props.data[i].value / props.data[i - 1].value
        //             ).toFixed(2);
        //           }
        //         }
        //         if (d.name !== "2016") {
        //           Tooltip.select(".percent").text(
        //             "Percent Change: " + percent + "%"
        //           );
        //         } else if (d.name === "2016") {
        //           Tooltip.select(".percent").text("");
        //         }
        //       }
        //     }
        //   };
        //   const mouseleave = (d) => {
        //     Tooltip.style("opacity", 0);
        //     Tooltip.style("z-index", "-1");
        //   };

        //   barsvg
        //     .append("g")
        //     .selectAll("dot")
        //     .data(lineData)
        //     .enter()
        //     .append("circle")
        //     .attr("class", "myCircle")
        //     .attr("cx", function (d, i) {
        //       return x(i) + x.bandwidth() / 2;
        //     })
        //     .attr("cy", function (d) {
        //       return y(d.value ?? d.total);
        //     })
        //     .attr("r", 3)
        //     .attr("stroke", "#000000")
        //     .attr("stroke-width", 3)
        //     .attr("fill", "#000000")
        //     .on("mouseover", mouseover)
        //     .on("mousemove", mousemove)
        //     .on("mouseleave", mouseleave);
      } else {
        svg.selectAll(".trendLine").remove();
        svg.selectAll(".dot").remove();
        svg.selectAll(".myCircle").remove();
      }
    }


    if (props.trendLineTwo) {
      let lineData2 = props?.trendLineDataTwo

      //if (lineData.length > 1) {
      if (trendLineTwo && lineData2?.length > 1) {

        //Adding line and circle hover
        const totalLine2 = d3
          .line()
          .x((d, i) => x(i) + x.bandwidth() / 2)
          .y((d) => y(d.value ?? d.total));

        barsvg
          .append("path")
          .datum(lineData2)
          .attr("fill", "none")
          .attr("stroke", props.trendLineTwoColor ? chartColorArray[props.trendLineTwoColor] : "#949494")
          .attr("stroke-width", 2)
          .attr("class", `line trendLineTwo ${props.trendLineTwoColor ? `strokeColor${props.trendLineTwoColor}` : 'colorGrey'}`)
          .attr("d", totalLine2);

        let Tooltip2 = d3
          .select(wrapperRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          .style("z-index", "-1")
          .style("padding", "5px");
        Tooltip2.append("div").attr("class", "label");
        Tooltip2.append("div").attr("class", "percent");

        const mouseover = (d) => {
          Tooltip2.style("opacity", 1);
          Tooltip2.style("z-index", "9999");
        };

        const mousemove = (d) => {
          Tooltip2.select(".label").text(
            props.trendLineLegendTwo +
            ": " +
            (d.value ?? d.total)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
          Tooltip2.style("top", d3.event.clientY - 60 + "px").style(
            "left",
            d3.event.clientX - 150 + "px"
          );
        };
        const mouseleave = (d) => {
          Tooltip2.style("opacity", 0);
          Tooltip2.style("z-index", "-1");
        };

        barsvg
          .append("g")
          .selectAll("dot2")
          .data(lineData2)
          .enter()
          .append("circle")
          .attr("class", "myCircle2")
          .attr("cx", function (d, i) {
            return x(i) + x.bandwidth() / 2;
          })
          .attr("cy", function (d) {
            return y(d.value ?? d.total);
          })
          .attr("r", 3)
          .attr("stroke", props.trendLineTwoColor ? chartColorArray[props.trendLineTwoColor] : "#949494")
          .attr("stroke-width", 3)
          .attr("fill", props.trendLineTwoColor ? chartColorArray[props.trendLineTwoColor] : "#949494")
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave);
      } else {
        svg.selectAll(".trendLine2").remove();
        svg.selectAll(".dot2").remove();
        svg.selectAll(".myCircle2").remove();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.data,
    props.keys,
    props.yaxisMaxValue,
    props.trendLineLegend,
    props.report,
      props.widthNeeded,
      activeBarLegend,
    props.trendLine,
    props.trendLineTwo,
    trendLine,
    trendLineTwo,
    props.xaxis,
      props.yaxis,
      props.yaxisRight,
    props.percentLine,
    props.percentLegend,
    props.line,
    props.lineTitle,
    props.graphVariable,
    props.type,
  ]);

  return (
    <div id={props.id} className={props.smalltext ? "graph" : "graph1"}>
      <div ref={wrapperRef} style={{ position: "relative" }}>
        <div ref={svgRef} />
      </div>

      <div className='fields' style={{ display: "flex", flexWrap: "wrap" }}>
        {props.barLegend?.map((l, i) => (
          <Checkbox
            key={l.name}
              text={l.name}
              checked={activeBarLegend}
              onClickFunc={handleBarLegendClick}
              onClickFuncItem={l.var}
            color={colors(i)}
            report={props.report}
            />
        ))}

        {props.trendLine && (
          <Checkbox
              text={props.trendLineLegend}
              checked={trendLine}
              onClickFunc={handleTrendLineClick}
            color={props.trendLineColor ? chartColorArray[props.trendLineColor] : 'black'}
            report={props.report}
            />
        )}

        {props.trendLineTwo && (
          <Checkbox
              text={props.trendLineLegendTwo}
              checked={trendLineTwo}
              onClickFunc={handleTrendLine2Click}
            color={props.trendLineTwoColor ? chartColorArray[props.trendLineTwoColor] : '#949494'}
            report={props.report}
            />
        )}
      </div>
    </div>
  );
};
