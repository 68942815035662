import React, { useState, useEffect, Fragment } from "react";
// import { useAppContext } from "../../../../Lib/UserContext";
import "./Demographics.css";
import { makeStyles } from "@material-ui/core/styles";

import SingleCard from "../../../../Components/Card";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";
import PieGraph from "../../GraphComponents/PieGraph"

import AgeTable from "./Tables/AgeTable";
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'
// import TableForYearGraphs from '../../../../Components/ReusableTables/TableForYearGraphs'


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    cardContainer: {
        // width: "calc(100% - 200px)",
        // padding: "20px 10px",
        // marginTop: "80px",
    },
    openIcon: {
        color: "#28ADE3",
    },
    countyList: {
        padding: 0,
    },
    toolbar: {
        boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    content: {
        marginTop: "64px",
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: 0,
    },
}));

export default function Screenings(props) {
    const classes = useStyles();
    // const { chartColorArray } = useAppContext()
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        if (props.data) {
            setGraphData(props.data)
            console.log('props.data', props.data)
        }
    }, [props.data])

    const renderAllCharts = () => {
        return (
            <div className={classes.root}>
                <div className={classes.cardContainer}>
                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        title="Screenings Information  >  Screenings by Location"
                        id="Screenings Information Screenings by Location"
                        graph={
                            <HorizontalBarChart
                                yLabelsWidth={105}
                                percentOfTotal={graphData.screeningsByLocation}
                                data={graphData.screeningsByLocation?.slice(0, 10) ?? []} // get top 10 counties for the chart
                                value={'county'}
                                xaxis="Number of Screenings"
                                yaxis="Location"
                            />
                        }
                        table={
                            <TableWithTotal
                                id="Screenings Information Screenings by Location"
                                name='Screenings by Location'
                                data={graphData.screeningsByLocation ?? []}
                                horizontalSort
                                headers={['Location', 'Number of Screenings']}
                                columnKeys={['county', 'total']}
                                totalKey='total'
                            />
                        }
                        definitions={[["", "This chart shows the top ten locations for screenings in descending order, based on selected filters. The locations reflect the youth's residence at the time of the screening, although trafficking may have occurred at a different location. The percent is the number of screenings by location out of all screenings. The table shows the screenings by all locations. All filters apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        title="Screenings Information  >  Screenings by Location and Population"
                        id="Screenings Information Screenings by Location and Population"
                        graph={
                            <HorizontalBarChart
                                percentOfTotal={graphData.screeningsByLocationPop}
                                data={graphData.screeningsByLocationPop?.slice(0, 10) ?? []} // get top 10 counties for the chart
                                value="city"
                                xaxis="Number of Screenings per 100,000"
                                yaxis="Location"
                            />
                        }
                        table={
                            <TableWithTotal
                                noTotalRow
                                id="Screenings Information Screenings by Location and Population"
                                name='Screenings by Location and Population'
                                data={graphData.screeningsByLocationPop ?? []}
                                horizontalSort
                                toFixed={2}
                                headers={['Location', 'Number of Screenings by Population']}
                                columnKeys={['city', 'total']}
                                totalKey='total'
                            />
                        }
                        definitions={[["", "This chart shows the top ten locations for screenings per 100,000 population per location in descending order, based on selected filters. The locations reflect the youth's residence at the time of the screening, although trafficking may have occurred at a different location. The percent is the number of screenings by location per 100,000 population out of all screenings per 100,000 population. The table shows screenings for all locations per 100,000 population. All filters apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        title="Screenings Information  >  Screenings by Victim Status"
                        id="Screenings Information Screenings by Victim Status"
                        graph={
                            <PieGraph
                                keepOrder
                                data={graphData.byVictimStatus ?? []}
                                sourceNameVar='status'
                            />
                        }
                        table={
                            <TableWithTotal
                                data={graphData.byVictimStatus ?? []}
                                headers={['Victim Status', 'Number of Screenings']}
                                columnKeys={['status', 'value']}
                                totalKey='value'
                            />
                        }
                        definitions={[["", "This chart shows screenings by trafficking victim status based on the selected filters. Victim status represents the screener's assessment of the likelihood that the individual is a victim of human trafficking at the conclusion of the screening. All filters are applicable for this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        id="Demographics Age"
                        title="Demographics  >  Age"
                        type="Screenings"
                        graph={
                            <SingleBarGraph
                                data={graphData.ageData ?? []}
                                xaxis="Age"
                                labelOnHover
                                xaxisVar="age"
                                yaxis="Number of Screenings"
                                type="cc"
                                smalltext={true}
                            />
                        }
                        table={<AgeTable data={graphData.ageData ?? []} />}
                        definitions={[["", "This chart displays the age of individuals at the time of screening, based on selected filters. Values of five or less do not appear in the chart or table. The Ages filters does not apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        id="Demographics Gender"
                        title="Demographics  >  Gender"
                        type="Screenings"
                        graph={
                            <SingleBarGraph
                                data={graphData.genderData ?? []}
                                xaxis="Gender"
                                labelOnHover
                                xaxisVar="gender"
                                yaxis="Number of Screenings"
                                type="cc"
                                smalltext={true}
                            />
                        }
                        table={
                            <TableWithTotal
                                data={graphData.genderData ?? []}
                                headers={['Gender', 'Number of Screenings']}
                                columnKeys={['gender', 'value']}
                                totalKey='value'
                            />
                        }
                        definitions={[["", "This chart displays the gender of individuals at birth, based on selected filters. Values of five or less do not appear in the chart or table. All filters apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        id="Demographics Race"
                        title="Demographics  >  Race"
                        type="Screenings"
                        graph={
                            <SingleBarGraph
                                data={graphData.raceData ?? []}
                                xaxis="Race"
                                labelOnHover
                                xaxisVar="race"
                                yaxis="Number of Screenings"
                                type="cc"
                                smalltext={true}
                            />
                        }
                        table={<TableWithTotal
                            data={graphData.raceData ?? []}
                            headers={['Race', 'Number of Screenings']}
                            columnKeys={['race', 'value']}
                            totalKey='value'
                        />}
                        definitions={[["", "This chart displays the race of individuals, based on selected filters. Values of five or less do not appear in the chart or table. All filters apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        id="Demographics Ethnicity"
                        title="Demographics  >  Ethnicity"
                        type="Screenings"
                        graph={
                            <SingleBarGraph
                                data={graphData?.ethnicityData ?? []}
                                xaxis="Ethnicity"
                                labelOnHover
                                xaxisVar="ethnicity"
                                yaxis="Number of Screenings"
                                type="cc"
                                smalltext={true}
                            />
                        }
                        table={
                            <TableWithTotal
                                data={graphData?.ethnicityData ?? []}
                                headers={['Ethnicity', 'Number of Screenings']}
                                columnKeys={['ethnicity', 'value']}
                                totalKey='value'
                            />
                        }
                        definitions={[["", "This chart displays the ethnicity of individuals, based on selected filters. Values of five or less do not appear in the chart or table. All filters apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        id="Demographics Sexual Orientation/Gender Identity"
                        title="Demographics  >  Sexual Orientation/Gender Identity"
                        type="Screenings"
                        graph={
                            <SingleBarGraph
                                data={graphData?.orientationIdentity ?? []}
                                xaxis="Sexual Orientation/Gender Identity"
                                labelOnHover
                                xaxisVar="identity"
                                yaxis="Number of Screenings"
                                type="cc"
                                smalltext={true}
                            />
                        }
                        table={
                            <TableWithTotal
                                data={graphData?.orientationIdentity ?? []}
                                headers={['Sexual Orientation/Gender Identity', 'Number of Screenings']}
                                columnKeys={['identity', 'value']}
                                totalKey='value'
                            />
                        }
                        definitions={[["", "This chart displays the number of screenings attached to individuals who identify as LGBTQ or not, based on selected filters. This chart only displays screenings completed by DCF. Values of five or less do not appear in the chart or table. The Organizations filter does not apply to this chart. All other filters apply."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        title="Indicators  >  Indicator Prevalence"
                        id="Indicators Indicator Prevalence"
                        graph={
                            <HorizontalBarChart
                                percentOfTotal={graphData.indicatorsTotal}
                                data={graphData.indicators ?? []}
                                value="xaxis"
                                xaxis="Number of Screenings"
                                labelOnHover='hoverLabel'
                                yLabelsWidth={220}
                            />
                        }
                        table={
                            <TableWithTotal
                                noTotalRow                      
                                descending
                                data={graphData.indicators ?? []}
                                headers={['Indicators', 'Number of Screenings']}
                                columnKeys={['xaxis', 'total']}
                                totalKey='total'
                            />
                        }
                        definitions={[["", 'This chart displays the prevalence of each "Evidence of" indicator scored as a "Yes," based on selected filters. All filters apply to this chart.']]}
                        addToReports={props.addToReportPage}
                    />
                </div>
            </div>
        )
    }


    const renderSingleChart = () => {
        const charts = renderAllCharts();
        const singleCards = charts.props.children.props.children;

        const index = singleCards.findIndex((card) => card.props.id === props.chartId);
        if (index <= -1) {
            return <Fragment></Fragment>;
        }

        const elementToRender = singleCards[index];
        return <SingleCard
            {...elementToRender.props}
            title={props.chartTitle || props.title}
            description={props.chartInsight || ""}
            shouldUseNewStyles={true}
        />;
    }

    return (
        props.chartId
            ? renderSingleChart()
            : renderAllCharts()
    );
}