import React, { useEffect, useState } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";
import { Checkbox } from '../../../Components/Checkbox'

export default function HorizontalBarChart(props) {
  const anchor = React.createRef();
  const { chartColorArray } = useAppContext()
  //Only for graphs with legends
  const [keys, setKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(props.legendCategories ? props.legendCategories : []);
  
  let colors = {}

  if (props.data) {
    if (props.legendCategories) {
      props.legendCategories.forEach((lc, i) => colors[lc] = chartColorArray[i])
    } else {
      let reverseColorCount = chartColorArray.slice(0, props.data?.length).reverse()
      colors = d3.scaleOrdinal(reverseColorCount)
    }
  }
  
  const handleLegendClick = (e, key) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(selectedKeys.filter((_key) => _key !== key));
    } else {
      var newArray = Array.from(new Set([...selectedKeys, key]));
      setSelectedKeys(newArray);
    }
  };


  useEffect(() => {
    var margin = { top: 0, right: 40, bottom: 90, left: !props.yaxis ? 0 : 40, yLabelsWidth: props.yLabelsWidth || 160 };
    
    var width = 900;
    var height = 750;
    

    var max = 0;
    if (props.data) {
      var data = props.data;
      
      //Clickable Legend
      if (props.legendCategories) {
        setKeys(props.legendCategories)
        data = data.filter(item => selectedKeys.includes(item.name))
      }
      data.sort((a, b) => {
        if (a.total === b.total) {
          return (props.reverseAlphabetSort ? a : b)[props.value].toLowerCase() > (props.reverseAlphabetSort ? b : a)[props.value].toLowerCase() ? 1 : -1;
        } else {
          return a.total - b.total;
        }
      })

      // if (data.length > 40) {
      //   height = 2250;
      // } else if (data.length > 25) {
      //   height = 1400;
      // } else if (data.length > 11) {
      //   height = 750;
      // } else {
      //   height = 500;
      // }
      if (data.length === 1) {
        height = 170
      } else if (data.length <= 3) {
        height = ((data.length * 60) * 2)
      } else if (data.length <= 6) {
        height = (data.length * 60) + (data.length * 30) // bar heights + spacing inbetween bars
      } else {
        height = (data.length * 30) + (data.length * 20) // bar heights + spacing inbetween bars
      }

      var totalValue;
      // Some charts show top 10 but the percent needs to be based off full total not just top 10 total
      if (props.percentOfTotal) {
        totalValue = d3.sum(props.percentOfTotal.map((d) => {
          return d.total;
        }))
      } else {
        totalValue = d3.sum(data.map((d) => {
          return d.total;
        }))
      }

      var variableName = '';
      if (props.value) {
        variableName = props.value
      }
      max = d3.max(data, (d) => +(d.total ?? 0));

      if (max === 0) {
        max = 5
      }

      let rotateXaxis = max >= 10000 ? true : false
      height = rotateXaxis ? height += 20 : height
      margin.bottom = rotateXaxis ? margin.bottom += 20 : margin.bottom

      const svg = d3.select(anchor.current);

      svg.selectAll("svg").remove();
      svg.selectAll(".tooltip").remove();

      var barsvg = svg
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${margin.yLabelsWidth}, 20)`);

      var tooltip = d3
        .select(anchor.current)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("top", 0)
        .style("left", 0)
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        // .style("z-index", "-1")
        .style("padding", "5px")
        .style("width", "200px");
      tooltip.append("div").attr("class", "label");
      tooltip.append("div").attr("class", "count");
      tooltip.append("div").attr("class", "percent");

      var x = d3
        .scaleLinear()
        .domain([0, max])
        .range([margin.left, width - margin.right - margin.yLabelsWidth])
      //.nice();

      var y = d3
        .scaleBand()
        .domain(d3.range(data.length))
        .range([height - margin.bottom, margin.top])
        .padding(0.1);

      var xAxisFormat;
      if (max < 20) {
        xAxisFormat = d3.axisBottom(x).ticks(max);
      } else {
        xAxisFormat = d3.axisBottom(x).tickSizeOuter(0);
      }

      var xAxis = (g) =>
        g
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .call(xAxisFormat)
          .selectAll(".tick text")
          .attr('transform', rotateXaxis ? 'rotate(55 -8 30)' : 'none');

      var yAxis = (g) =>
        g.attr("transform", `translate(${margin.left - 10},0)`).call(
          d3
            .axisLeft(y)
            .tickSize(0)
            .tickFormat((i) => data[i][props.value].length > 0 ? data[i][props.value] : 'Unknown')
        ).selectAll(".tick text")
          .call(wrap, margin.yLabelsWidth - 35);

      barsvg
        .append("g")
        .selectAll("rect")
        .data(data)
        .join("rect")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("x", (d) => x(0))
        .attr("y", (d, i) => y(i))
        .attr("height", data.length <= 6 ? 60 : 30)
        .attr("width", (d) => x(d.total) - x(0))
        .attr("fill", (d, i) => {
          if (props.legendCategories) {
            return colors[d.name]
          } else {
            return colors(i);
          }
        });

      barsvg.append("g").call(xAxis);

      barsvg.append("g").call(yAxis);

      let noPercent = props.noPercent;

      function onMouseOver(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")
        if (props.labelOnHover) {
          tooltip.select(".label").text(d[props.labelOnHover]);
        } else {
          tooltip.select(".label").text(d[variableName].length > 0 ? d[variableName] : 'Unknown');
        }
        tooltip.select(".count").text("Total: " + d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        var percent = Math.round((d.total / totalValue) * 100);
        if (props.percentFromData) {
          percent = d.percent
        }
        if (noPercent) {
          tooltip.select(".percent").text("");
        } else {
          tooltip.select(".percent").text("Percent: " + percent + "%");
        }
      }

      function onMouseOut() {
        tooltip
          .style("opacity", 0)
          .style("top", 0)
          .style("left", 0)
        // tooltip.style("z-index", "-1")

      }

      function onMouseMove(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")
        tooltip
          .style("top", (d3.event.clientY - 110) + "px")
          .style("left", (d3.event.clientX - 100) + "px");
      }

      // text label for the x axis
      var xaxisLocation = height - 30

      barsvg
        .append("text")
        .attr("class", "axislabel")
        .attr(
          "transform",
          `translate(${(width - margin.right - margin.yLabelsWidth) / 2}, ${xaxisLocation})`
        )
        .style("text-anchor", "middle")
        .text(props.xaxis);

      // text label for the y axis
      barsvg
        .append("text")
        .attr("class", "axislabel")
        .attr("transform", "rotate(-90)")
        .attr("y", -margin.yLabelsWidth + 20)
        .attr("x", 0 - height / 3)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(props.yaxis);

      function wrap(text, width) {
        text.each(function () {
          var text = d3.select(this),
            words = text.text().replace('/', '/ ').split(/\s+/).reverse(),  // .split(/\s+/)
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.2, // ems
            y = text.attr("y"),
            dy = 0.01, // parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));
            if (tspan.node().getComputedTextLength() > width && line.length > 1) {
              line.pop();
              tspan.text(line.join(' '));
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", ++lineNumber * lineHeight + dy + "em")
                .text(word);
            }
          }
        });
      }
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedKeys])


  return (
    <div id={props.id}>
      <div
        ref={anchor}
        className={props.smalltext ? 'horizontal-graph' : 'graph1'}
        style={{ position: "relative", width: '900px' }}
      ></div>
      {props.legend ? (
        <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
          {keys.map((key, index) => {
            return (
              <Checkbox
                key={key[0].toUpperCase() + key.slice(1)}
              text={key[0].toUpperCase() + key.slice(1)}
              checked={selectedKeys.includes(key)}
              onClickFunc={handleLegendClick}
              onClickFuncItem={key}
                color={colors[key]}
                report={props.report}
            />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
