import React from "react";
import { useAppContext } from "../../../../Lib/UserContext";

import TXLAScreenings from './TXLAScreenings'
import FLScreenings from './FLScreenings'

export default function Screenings(props) {
    const { stateLocation } = useAppContext();

    return (
        <div>
            {stateLocation === 'TX' || stateLocation === 'LA' ? (
                <div>
                    <TXLAScreenings data={props.data} addToReportPage={props.addToReportPage} id={props.chart} />
                </div>)
                :
                (<div>
                    <FLScreenings data={props.data} addToReportPage={props.addToReportPage} id={props.chart} />
                </div>)
            }
        </div>
    )
}