import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import {
  select,
  scaleBand,
  event,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  line,
  //mouse,
  format,
} from "d3";
import { Checkbox } from '../../../Components/Checkbox'


export const StackedBarVariableXAxis = (props) => {
  let defaultKeys = props.keys?.map((item) => {
    return item.variable;
  })
  const [allKeys, setAllKeys] = useState(defaultKeys ?? []);
  const [activeKeys, setActiveKeys] = useState(defaultKeys ?? []);
  
  const { chartColorArray } = useAppContext()
  let colors = {};
  for (let i = 0; i < props.keys?.length; i++) {
    colors[props.keys[i].variable] = chartColorArray[i];
  }

  //If a trendline, adding values to state
  const [trendLine, setTrendLine] = useState(true);
  const [trendLineLegend, setTrendLineLegend] = useState(true);
  // For charts that want 2 trend lines:
  const [trendLineTwo, setTrendLineTwo] = useState(true);
  const [trendLineLegendTwo, setTrendLineLegendTwo] = useState(true);

  const svgRef = useRef();
  const wrapperRef = useRef();

  const handleLegendClick = (e, key) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((_key) => _key !== key));
    } else {
      var newArray = Array.from(new Set([...activeKeys, key]));
      var sortedArray = [];
      for (let i = 0; i < allKeys.length; i++) {
        for (let j = 0; j < newArray.length; j++) {
          if (allKeys[i] === newArray[j]) {
            sortedArray.push(newArray[j]);
          }
        }
      }
      setActiveKeys(sortedArray);
    }
  };

  // Click handler for trendline(s) in legend
  const handleLegendLineClick = (e) => {
    // If chartLine is set to false in this component, update props
    props.updateChartLineShowing(!trendLine)
    setTrendLineLegend(!trendLineLegend);
    setTrendLine(!trendLine);
  };
  // For charts that have 2 trend lines:
  const handleLegendLineClickTwo = (e) => {
    props.updateChartLineShowingTwo(!trendLineTwo)
    setTrendLineLegendTwo(!trendLineLegendTwo);
    setTrendLineTwo(!trendLineTwo);
  }

  var data = props.data ?? []

  useEffect(() => {
    setAllKeys(defaultKeys)
    setActiveKeys(props.activeKeys ?? defaultKeys)
    // eslint-disable-next-line
  }, [props.keys])


  useEffect(() => {
    if (props.data?.length > 0) {
      function endTooltip() {
        const boxes = document.querySelectorAll('.tooltip');

        boxes.forEach(box => {
          box.style.opacity = 0;
        });
      }
      window.addEventListener('scroll', endTooltip)
      //Selecting the container that will hold the D3 graph
      const svg = select(svgRef.current);

      //Removing the previous lines and data, in order to re-draw them when the filters are changed
      svg.selectAll(".line").remove();
      svg.selectAll(".myCircle").remove();
      svg.selectAll(".axis").remove();
      svg.selectAll(".text").remove();
      svg.selectAll(".quarterText").remove();
      svg.selectAll(".axislabel").remove();

      // Setting the height and width of the graph (responsive to viewport size)
      var margin = { top: 30, right: 0, bottom: props.smallXaxis ? 105 : 90, left: props.smallXaxis && props.fontSize ? 70 : 80 };
      var width;
      var height;
      if (window.innerWidth > 1200 && window.innerWidth < 1440) {
        width = 900;
        height = 500;
      } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
        width = 900;
        height = 500;
      } else {
        width = 1200;
        height = 500;
      }
      if (props.report) {
        if (props.width) {
          width = props.width
        } else {
          width = 750;
        }
      }

      //Organizing the data to created the stacked bars
      const stackGenerator = stack().keys(activeKeys);
      const layers = stackGenerator(data);

      //Creating the hover tooltip
      var tooltip = select(wrapperRef.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px");
      tooltip.append("div").attr("class", "title");
      tooltip.append("div").attr("class", "label");
      tooltip.append("div").attr("class", "percent");

      // Creating all of the axis calculations
      let yMaxValue = 1
      if (props.differentYaxisMaxVars) {
        let updatedTotals = []
        data.forEach(dataObj => {
          props.differentYaxisMaxVars.forEach(v => {
            return updatedTotals.push(dataObj[v])
          })
        })
        yMaxValue = updatedTotals.sort((a, b) => b - a)[0]
      } else {
        yMaxValue = max(data, (d) => Number(d.total ?? 0));
      }
    
      if (yMaxValue === 0) { // dots will display on zero line
        yMaxValue = 5
      }

      const yScale = scaleLinear()
        .domain([0, yMaxValue])
        .range([height - margin.bottom, margin.top]);

      const x0Scale = scaleBand()
        .domain(data.map((d) => d.xaxis))
        .range([margin.left, width - margin.right])
        .padding(0.2);

      const xAix = axisBottom(x0Scale);

      var ticks = yScale.ticks(),
        lastTick = ticks[ticks.length - 1],
        newLastTick = lastTick + (ticks[1] - ticks[0]);
      ticks.push(newLastTick);

      yScale.domain([0, newLastTick]);

      //Making sure that there are no decimal places in the y-axis
      var formatxAxis = format(".0f");
      var yAix;
      if (yMaxValue < 20) {
        yAix = axisLeft(yScale).ticks(yMaxValue).tickFormat(formatxAxis);
      } else {
        yAix = axisLeft(yScale).tickValues(ticks);
      }

      //Adding the x and y axis to the graph
      if (props.wrapText) {
        svg
          .select(".x-axis")
          .attr("transform", `translate(0, ${height - 75})`)
          .call(xAix)
          .selectAll(".tick text")
          .call(wrap, props.wrapTextWidth ?? 40);

        // wrap x axis labels function
        function wrap(text, width) {
          text.each(function () {
            var text = select(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              lineNumber = 0,
              lineHeight = 1.1, // ems
              y = text.attr("y"),
              dy = 0.5, // parseFloat(text.attr("dy")),
              tspan = text
                .text(null)
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", dy + "em");
            while ((word = words.pop())) {
              line.push(word);
              tspan.text(line.join(" "));
              if (tspan.node().getComputedTextLength() > width && line.length > 1) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text
                  .append("tspan")
                  .attr("x", 0)
                  .attr("y", y)
                  .attr("dy", ++lineNumber * lineHeight + dy + "em")
                  .text(word);
              }
            }
          });
        }
      } else if (props.smallXaxis) {
        svg
          .select(".x-axis")
          .attr("transform", `translate(0, ${height - 90})`)
          .call(xAix)
          .selectAll(".tick text")
          .attr('transform', 'rotate(55 -8 30)');
      } else {
        svg
          .select(".x-axis")
          .attr("transform", `translate(0, ${height - 75})`)
          .call(xAix)
          .selectAll(".tick text")
      }

      svg.select(".y-axis").attr("transform", `translate(${margin.left}, 0 )`).call(yAix);

      //This section creates the bars for the graph
      // eslint-disable-next-line
      svg
        .selectAll(".layer")
        .data(layers)
        .join("g")
        .attr("class", "layer")
        .attr("fill", (layer) => colors[layer.key])
        .selectAll("rect")
        .data((layer) => layer)
        .join("rect")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("x", (sequence) => x0Scale(sequence.data.xaxis))
        .attr("width", x0Scale.bandwidth())
        //.attr("width", x0Scale.bandwidth() > 60 ? 60 : x0Scale.bandwidth())
        .attr("y", (sequence) => yScale(sequence[1]))
        .attr("height", (sequence) => yScale(sequence[0]) - yScale(sequence[1]));

      //This section adds the quarter labels to graphs that display quarter data
      svg
        .selectAll(".text")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "quarterText")
        .attr("x", (d) => x0Scale(d.xaxis))
        .attr("y", height - 80)
        .style("font-size", ".8em")
        .text((d) => d.quarter);

      //Hover for bar graph
      function onMouseOver(d) {
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999");

        var subgroupName = select(this.parentNode).datum().key;
        var label;
        for (let i = 0; i < props.keys?.length; i++) {
          if (subgroupName === props.keys[i].variable) {
            label = props.keys[i].name;
          }
        }
        var tooltipData = select(this).data()[0];
        // console.log('tooltipData', tooltipData)
        var total = tooltipData[1] - tooltipData[0];
        tooltip
          .select(".label")
          .text(
            "Total: " + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        var percent = Math.round((total / tooltipData.data.total) * 100);
        // if (percent !== 100) {
        tooltip.select(".percent").text("Percent: " + percent + "%");
        //}
        tooltip.select(".title").text(label);
      }

      function onMouseOut() {
        tooltip.style("opacity", 0);
        tooltip.style("z-index", "-1");
      }

      function onMouseMove(d) {
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999");

        tooltip
          .style("top", event.clientY - 150 + "px")
          .style("left", event.clientX - 200 + "px");
      }

      // ********************* Start of Trend line(s) *********************
      if (props.trendLine) {
      
        var lineData = (props.line ?? data).filter((item, key) => {
          return item;
        });

        if (trendLine && lineData.length > 1) {
          //Adding line and circle hover
          const totalLine = line()
            .x((d) => x0Scale(d.xaxis) + x0Scale.bandwidth() / 2)
            .y((d) => yScale(d.total));

          svg
            .append("path")
            .datum(lineData)
            .attr("fill", "none")
            .attr("stroke", "#212121")
            .attr("stroke-width", 2)
            .attr("class", "line trendLine")
            .attr("d", totalLine);

          var TooltipTrend = select(wrapperRef.current)
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("color", "black")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("position", "fixed")
            .style("z-index", "-1")
            .style("padding", "5px");

          // Tberry added code here
          let mouseovertrend = function (d) {
            TooltipTrend.style("opacity", 0);
            TooltipTrend.style("opacity", 1);
            TooltipTrend.style("z-index", "9999");
          };
          let mousemovetrend = function (d) {
            if (props.lineTitle) {
              TooltipTrend.html(
                props.lineTitle +
                ": " +
                d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              )
                .style("top", event.clientY - 75 + "px")
                .style("left", event.clientX - 150 + "px");
            } else {
              TooltipTrend.html(
                props.trendLineLegend +
                ": " +
                d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              )
                .style("top", event.clientY - 75 + "px")
                .style("left", event.clientX - 150 + "px");
            }
          };
          var mouseleavetrend = function (d) {
            TooltipTrend.style("opacity", 0);
            TooltipTrend.style("z-index", "-1");
          };
          // var mouseover = function (d) {
          //   Tooltip.style("opacity", 1);
          // };
          // var mousemove = function (d) {
          //   Tooltip.html(
          //     props.trendLineLegend +
          //       ": " +
          //       d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          //   )
          //     .style("top", event.clientY - 100 + "px")
          //     .style("left", event.clientX - 150 + "px");
          // };
          // var mouseleave = function (d) {
          //   Tooltip.style("opacity", 0);
          // };

          svg
            .append("g")
            .selectAll("dot")
            .data(lineData)
            .enter()
            .append("circle")
            .attr("class", "myCircle")
            .attr("cx", function (d) {
              return x0Scale(d.xaxis) + x0Scale.bandwidth() / 2;
            })
            .attr("cy", function (d) {
              return yScale(d.total);
            })
            .attr("r", 3)
            .attr("stroke", "#000000")
            .attr("stroke-width", 3)
            .attr("fill", "#000000")
            .on("mouseover", mouseovertrend)
            .on("mousemove", mousemovetrend)
            .on("mouseleave", mouseleavetrend);
        } else {
          svg.selectAll(".trendLine").remove();
          svg.selectAll(".dot").remove();
        }
      }

      // For charts that want 2 trend lines: 
      if (props.trendLineTwo) {
        let lineDataTwo;
        if (props.lineTwo) { // use lineTwo for custom line data
          lineDataTwo = props.lineTwo
        } else {
          lineDataTwo = data
        }

        if (trendLineTwo && lineDataTwo.length > 1) {
          //Adding line and circle hover
          const totalLineTwo = line()
            .x((d) => x0Scale(d.xaxis) + x0Scale.bandwidth() / 2)
            .y((d) => yScale(d.total));

          svg
            .append("path")
            .datum(lineDataTwo)
            .attr("fill", "none")
            .attr("stroke", "#949494")
            .attr("stroke-width", 2)
            .attr("class", "line trendLineTwo colorGrey")
            .attr("d", totalLineTwo);

          var TooltipTrendTwo = select(wrapperRef.current)
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltipTwo")
            .style("background-color", "white")
            .style("color", "black")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("position", "fixed")
            .style("z-index", "-1")
            .style("padding", "5px");

          // Tberry added code here
          let mouseovertrend = function (d) {
            TooltipTrendTwo.style("opacity", 0);
            TooltipTrendTwo.style("opacity", 1);
            TooltipTrendTwo.style("z-index", "9999");
          };
          let mousemovetrend = function (d) {
            if (props.lineTitleTwo) {
              TooltipTrendTwo.html(
                props.lineTitleTwo +
                ": " +
                d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              )
                .style("top", event.clientY - 75 + "px")
                .style("left", event.clientX - 150 + "px");
            } else {
              TooltipTrendTwo.html(
                props.trendLineLegendTwo +
                ": " +
                d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              )
                .style("top", event.clientY - 75 + "px")
                .style("left", event.clientX - 150 + "px");
            }
          };
          var mouseleavetrendTwo = function (d) {
            TooltipTrendTwo.style("opacity", 0);
            TooltipTrendTwo.style("z-index", "-1");
          };
          // var mouseover = function (d) {
          //   Tooltip.style("opacity", 1);
          // };
          // var mousemove = function (d) {
          //   Tooltip.html(
          //     props.trendLineLegendTwo +
          //       ": " +
          //       d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          //   )
          //     .style("top", event.clientY - 100 + "px")
          //     .style("left", event.clientX - 150 + "px");
          // };
          // var mouseleave = function (d) {
          //   TooltipTwo.style("opacity", 0);
          // };

          svg
            .append("g")
            .selectAll("dot")
            .data(lineDataTwo)
            .enter()
            .append("circle")
            .attr("class", "myCircle")
            .attr("cx", function (d) {
              return x0Scale(d.xaxis) + x0Scale.bandwidth() / 2;
            })
            .attr("cy", function (d) {
              return yScale(d.total);
            })
            .attr("r", 3)
            .attr("stroke", "#949494")
            .attr("stroke-width", 3)
            .attr("fill", "#949494")
            .on("mouseover", mouseovertrend)
            .on("mousemove", mousemovetrend)
            .on("mouseleave", mouseleavetrendTwo);
        } else {
          svg.selectAll(".trendLineTwo").remove();
          svg.selectAll(".dot").remove();
        }
      }



      // text label for the x axis
      svg
        .append("text")
        .attr("class", "axislabel")
        .attr(
          "transform",
          "translate(" + (width + 60) / 2 + " ," + (height - 20) + ")"
        )
        .style("text-anchor", "middle")
        .text(props.xaxis);

      // text label for the y axis
      svg
        .append("text")
        .attr("class", "axislabel")
        .attr("transform", "rotate(-90)")
        .attr("y", 0)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(props.yaxis);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.data,
    colors,
    props.keys,
      activeKeys,
    props.xaxis,
    props.yaxis,
    props.trendLine,
    props.trendLineLegend,
    trendLine,
    props.report,
  ]);

  return (
    <div
      className={`${props.smalltext ? "graph-stacked" : "graph"} ${props.smallXaxis && 'smallXaxis'}`}
      id={props.id}
      style={{ position: "relative" }}
    >
      <div ref={wrapperRef} style={{ position: "relative", height: 500 }} className={props.fontSize ? "fontSize" : "noFontSize"}>
        <svg ref={svgRef} style={{ width: props.report ? props.width ? props.width : 750 : window.innerWidth < 1200 || window.innerWidth > 1920 ? 1200 : 900, height: 500 }}>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </div>
      {props.noLegend ? null : (
        <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
          {allKeys?.map((key) => {
            var labelId;
            for (let i = 0; i < props.keys?.length; i++) {
              if (key === props.keys[i].variable) {
                labelId = props.keys[i].name;
              }
            }
            return (
              <Checkbox
                key={labelId}
                text={labelId}
                checked={activeKeys.includes(key)}
                onClickFunc={handleLegendClick}
                onClickFuncItem={key}
                color={colors[key]}
                report={props.report}
              />
            );
          })}
          
          {data.length > 1 && props.trendLine ? (
            <Checkbox
                text={props.trendLineLegend}
                checked={trendLineLegend}
                onClickFunc={handleLegendLineClick}
              color={'black'}
              report={props.report}
              />
            
          ) : null}

          {data.length > 1 && props.trendLineTwo ? (
            <Checkbox
                text={props.trendLineLegendTwo}
                checked={trendLineLegendTwo}
                onClickFunc={handleLegendLineClickTwo}
              color={'#949494'}
              report={props.report}
              />
          ) : null}
        </div>
      )}
    </div>
  );
};
