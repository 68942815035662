// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

export function StatePolicyLayer(map, filters, data, stateLocation, mapsIndex) {
    // console.log(data)

    let mapDataIndex = mapsIndex['State Policy']['Data'];

    if (data[mapDataIndex.dataVar] &&
        data[mapDataIndex.dataVar].length > 0) {
        // #271
        let removeFromMapJson = { ...mapDataIndex.mapJson }
        removeFromMapJson.features = removeFromMapJson.features.filter(f => {
            if (f.properties[mapDataIndex.mapLocVar] !== 'Puerto Rico' && f.properties[mapDataIndex.mapLocVar] !== 'Guam' && f.properties[mapDataIndex.mapLocVar] !== 'Northern Mariana Islands' && f.properties[mapDataIndex.mapLocVar] !== 'Virgin Islands' && f.properties[mapDataIndex.mapLocVar] !== 'District of Columbia') {
                return f
            }
            return null
        })

        let mapJson = JSON.parse(JSON.stringify(removeFromMapJson))
        
        mapJson.features?.forEach(f => {
            f.properties.SCREENINGS = 0

            data[mapDataIndex.dataVar].forEach(d => {
                if (f.properties[mapDataIndex.mapLocVar].toLowerCase() === d[mapDataIndex.sourceVar].toLowerCase()) {
                    let newObject = {};
                    // Iterate through the object keys
                    for (let key in d) {
                        if (d.hasOwnProperty(key)) {
                            if (d[key] === "1") {
                                newObject[key] = d[key]
                            }
                        }
                    }
                    // Getting the number of state policies
                    f.properties.SCREENINGS = Object.keys(newObject).length ?? 0
                    // Generate list of policies
                    let policyLabels = [];
                    Object.keys(newObject).forEach(policy => {
                        mapDataIndex.mapInfoBox.info.forEach(l => {
                            if (policy === l.sourceVar) {
                                policyLabels.push(l.label)
                            }
                        })
                    });
                    f.properties.POLICIES = policyLabels
                }
            })
        })

        let calculatedData = mapJson;
        map.on("load", function () {
            map.addSource("county", {
                type: "geojson",
                data: calculatedData,
            });
            map.addLayer({
                id: "county",
                type: "line",
                source: "county",
                layout: {
                    "line-join": "round",
                    "line-cap": "round",

                },
                paint: {
                    "line-color": "#28ADE3",
                    "line-width": 1,
                },
            });

            map.addLayer({
                id: "county-fills",
                type: "fill",
                source: "county",
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "fill-color": {
                        property: "SCREENINGS",
                        stops: mapDataIndex.mapFillColors
                    },
                    "fill-outline-color": "#28ADE3",
                    "fill-opacity": mapDataIndex.mapFillOpacity,
                },
            });
        });

        let hoveredStateId = null;
        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        });

        map.on("mousemove", "county-fills", function (e) {
            let uniqueMapIdProp = e.features[0].properties.GEO_ID
            if (hoveredStateId !== uniqueMapIdProp) {
                if (e.features.length > 0) {
                    if (hoveredStateId) {
                        map.setFeatureState(
                            { source: "county", id: hoveredStateId },
                            { hover: false }
                        );
                    }
                    hoveredStateId = uniqueMapIdProp;
                    map.setFeatureState(
                        { source: "county", id: hoveredStateId },
                        { hover: true }
                    );

                    let hasPolicies = e.features?.[0]?.properties?.POLICIES ?? null
                    let parsedString = hasPolicies ? JSON.parse(hasPolicies)?.sort((a,b) => a < b ? -1 : 1) : [];
                    let list = parsedString?.map(policy => `<li><p>${policy}</p></li>`)?.join("")
                    let hoverText =
                        `<div>
                            <h3><strong>${e.features[0].properties[mapDataIndex.hoverTitleVar]}</strong></h3>
                            ${parsedString?.length > 0 ?
                            `<p>Policies Passed: ${parsedString.length}</p>
                            <ul style="margin:0px; padding-left: 30px;">${list}</ul>`
                            :
                            `<p>No Policies Enacted</p>`
                            }
                        </div>`;

                    let description = hoverText
                    popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
                }
            }
        });

        map.on("mouseleave", "county-fills", function () {
            if (hoveredStateId) {
                map.setFeatureState(
                    { source: "county", id: hoveredStateId },
                    { hover: false }
                );
            }
            hoveredStateId = null;
            popup.remove();
        });
    }
}
