import React from "react";
import CardWithDrawer from '../../../../Components/CardWithDrawer'
// import SingleCard from "../../../../Components/Card";

import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'


export default function FLServices(props) {

    if (props.data) {
        // console.log(props.data)
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardWithDrawer
                        width="35vw"
                        id="Organizations Total Organizations"
                        title="Organizations  >  Total Organizations"
                        category="Advocacy"
                        value={[
                            {
                                data: props.data.totalAreasServed?.length ?? 0,
                                text: "Total Areas Served",
                            },
                            {
                                data: props.data.totalUniqueOrgs?.length ?? 0,
                                text: "Total Unique Organizations",
                            },
                        ]}
                        definitions={[["Total Organizations", "All organizations that offer or may offer services to confirmed or potential victims of human trafficking, reported by TiP Research Lab's BRIGHT Team. Multiple counties or regions may be listed if the organization provides services in more than one location."], ['','This chart can only be filtered by Provider Location and Organization; other filters are not applicable.']]}
                        table={
                            <TableWithTotal
                                id="Total Organizations"
                                title="Service Provider Organizations"
                                noTotalRow
                                evenColumns
                                data={props.data.orgList ?? []}
                                headers={['Organization Name', 'Service Category', 'County', 'Region']}
                                columnKeys={['organization', 'org_type', 'county', 'region']}
                            />
                        }
                        addToReports={props.addToReports}
                    />
                </div>
            </div>
        )
    }
}