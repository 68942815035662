import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
//import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        // display: 'flex',
        padding: '20px',
        fontWeight: '300'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer',
      fontWeight: "700 !important",
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function TimeFilter(props) {
  const classes = useStyles();
  // const [time, setTime] = React.useState(true);
  const [yearSelections, setYearSelections] = React.useState(["All years"]);

  const handleYearCheckboxChange = (e, item) => {
    if (yearSelections.includes(item)){
      let newArray = yearSelections.filter(x => x !== item); 
      setYearSelections(newArray);
    } else {
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      filteredArray.push(item);
      setYearSelections(filteredArray);
    }
  }

  const handleAllYearCheckboxChange = (e) => {
    if (e.target.checked){
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "2018" && x !== "2019" && x !== "2020" && x !== "2021" && x !== "2022" && x !== "2023");
      filteredArray.push("All years");
      setYearSelections(["All years"]);
    } else {
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      setYearSelections(filteredArray);
    }
  }

  const handleSubmit = () => {
    var timeCategory = "Fiscal Year";
    props.filterCallback({
      name: 'Time ',
      filter: 'Services',
      stateLocation: 'TX',
      selection: timeCategory,
      value: yearSelections,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      //Sort filters to get time filters
      if (props.selectedFilters[i].name === 'Time ' && props.selectedFilters[i].filter === 'Services'){
        setYearSelections(props.selectedFilters[i].value)
      }
    }
  }, [props.selectedFilters])

    return (
        <div className={classes.container}>
              <div className={classes.checkboxes}>
                  <FormControlLabel className="checkboxLabel"
                      control={ 
                        <BlackCheckbox 
                          checked={yearSelections.includes("All years")} 
                          onChange={e => handleAllYearCheckboxChange(e, "All years")}
                          name="checkedA" 
                          />
                        }
                      label="All years"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={yearSelections.includes("2018")} 
                          onChange={e => handleYearCheckboxChange(e, "2018")}
                          name="checkedA" 
                          />
                        }
                      label="FY18 (Oct 2017 - Sep 2018)"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={yearSelections.includes("2019")} 
                          onChange={e => handleYearCheckboxChange(e, "2019")}
                          name="checkedA" 
                          />
                        }
                      label="FY19 (Oct 2018 - Sep 2019)"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={yearSelections.includes("2020")} 
                          onChange={e => handleYearCheckboxChange(e, "2020")} 
                          name="checkedA" 
                          />
                        }
                      label="FY20 (Oct 2019 - Sep 2020)"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={yearSelections.includes("2021")} 
                          onChange={e => handleYearCheckboxChange(e, "2021")}
                          name="checkedA" 
                          />
                        }
                      label="FY21 (Oct 2020 - Sep 2021)"
                    />
                     <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={yearSelections.includes("2022")} 
                          onChange={e => handleYearCheckboxChange(e, "2022")}
                          name="checkedA" 
                          />
                        }
                      label="FY22 (Oct 2021 - Sep 2022)"
          />
          <FormControlLabel className="checkboxLabel"
            control={
              <BlackCheckbox
                checked={yearSelections.includes("2023")}
                onChange={e => handleYearCheckboxChange(e, "2023")}
                name="checkedA"
              />
            }
            label="FY23 (Oct 2022 - Sep 2023)"
          />
                </div>
                
                <div className={classes.saveButton}>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
        </div>
    )
}