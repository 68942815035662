import React, { useEffect, useState } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";
// import { Checkbox } from '../../../Components/Checkbox'

export default function ConnectedScatterplot(props) {
    const anchor = React.createRef();
    const { chartColorArray } = useAppContext()
    //Only for graphs with legends
    const [keys, setKeys] = useState([]);
    const [activeKeys, setActiveKeys] = useState(props.legendKey ? [...new Set([...props.data]?.map(d => d[props.legendKey]))] : []);

    let colors = {}

    let legendItems = props.legendKey ? [...new Set([...props.data]?.map(d => d[props.legendKey]))] : []
    legendItems?.forEach((li, i) => {
        colors[li] = chartColorArray[i]
    })

    const handleLegendClick = (e, key) => {
        if (activeKeys.includes(key)) {
            setActiveKeys(activeKeys.filter((_key) => _key !== key));
        } else {
            var newArray = Array.from(new Set([...activeKeys, key]));
            setActiveKeys(newArray);
        }
    };


    useEffect(() => {
        var margin = { top: 20, right: 80, bottom: 90, left: 80 };
        let padding = 15
        var width = 900;
        var height = 750;

        if (props.report) {
            width = 1000;
        }


        // var xmax = 0;
        var ymax = 0;
        if (props.data) {
            var data = props.data;

            //Clickable Legend
            if (props.legendKey) {
                setKeys([...new Set([...data].map(d => d[props.legendKey]))])
                data = data.filter(item => activeKeys.includes(item[props.legendKey]))
            }

            ymax = d3.max(data, (d) => +d[props.yaxisKey]);
            if (ymax <= 5) {
                ymax = 5
                height = 550;
            }
            //Check if any objects have the same values (for hovers)
            // const countMap = {};
            // let sorting = data.map(obj => {
            //     const key = `${obj[props.xaxisKey]}-${obj[props.yaxisKey]}`;
            //     countMap[key] = (countMap[key] || 0) + 1;
            //     return obj
            // });
            // eslint-disable-next-line array-callback-return
            // const result = sorting.map(obj => {
            //     const key = `${obj[props.xaxisKey]}-${obj[props.yaxisKey]}`;
            //     if (countMap[key] > 1) {
            //         obj.size = 'larger'
            //         return obj
            //     } else {
            //         return obj
            //     }
            // });
            // console.log(result);

            // Select the graph
            const svg = d3.select(anchor.current);

            svg.selectAll("svg").remove();
            svg.selectAll(".tooltip").remove();

            var barsvg = svg
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", `translate(0, 0)`);

            var tooltip = d3
                .select(anchor.current)
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("background-color", "white")
                .style("color", "black")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("position", "fixed")
                // .style("z-index", "-1")
                .style("padding", "5px")
                .style("width", "200px");
            tooltip.append("div").append("strong").attr("class", "title");
            tooltip.append("div").attr("class", "total");
            tooltip.append("div").attr("class", "percent");

            var x = d3
                .scaleBand()
                .domain(data.map((d) => d[props.xaxisKey]))
                .range([margin.left, width - margin.right])
                .padding(0.2);

            var y = d3
                .scaleLinear()
                //.domain(d3.range(data.length))
                .domain([0, ymax])
                .range([height - margin.bottom, margin.top])

            // var xAxisFormat;
            // if (xmax < 20) {
            //     xAxisFormat = d3.axisBottom(x).ticks(xmax);
            // } else {
            let xAxisFormat = d3.axisBottom(x) //.tickSizeOuter(0);
            // }

            var xAxis = (g) =>
                g
                    .attr("transform", `translate(0,${(height - margin.bottom) + padding})`)
                    .call(xAxisFormat);

            //var yAxis = d3.axisLeft(y).ticks(10);
            var yAxis = (g) =>
                g
                    .attr("transform", `translate(${80},0)`)
                    .call(d3.axisLeft(y).ticks(null, data.y))
                    .attr("class", "y-axis")
                    .call((g) =>
                        g
                            .append("text")
                            .attr("x", -margin.left)
                            .attr("y", 10)
                            .attr("text-anchor", "start")
                            .text(data.y)
                    );

            // Adding connecting lines
            activeKeys.forEach(key => {
                const addLines = d3
                    .line()
                    .x((d) => x(d[props.xaxisKey]) + (x.bandwidth() / 2))
                    .y((d) => y(d[props.yaxisKey]));
                barsvg
                    .append("path")
                    .datum(data.filter(d => d[props.legendKey] === key))
                    .attr("fill", "none")
                    .attr("stroke", colors[key])
                    .attr("stroke-width", 4)
                    .attr("class", "connectedLine")
                    .attr("d", addLines);
            })

            // Adding dots
            barsvg
                .append("g")
                .selectAll("circle")
                .data(data)
                .join("circle")
                .on("mouseover", onMouseOver)
                .on("mouseout", onMouseOut)
                .on("mousemove", onMouseMove)
                .attr("cx", (d) => x(d[props.xaxisKey]) + (x.bandwidth() / 2))
                .attr("cy", (d) => y(d[props.yaxisKey]))
                // .attr("x", (d) => x(d[props.xaxisKey]))
                // .attr("y", (d) => y(d[props.yaxisKey]))
                .attr('r', (d) => 6)
                // .attr("height", (d) => d.size ? 30: 10)
                // .attr("width", (d) => d.size ? 30: 10)
                // .style("border-radius", "100%")
                .attr("fill", (d, i) => colors[d[props.legendKey]])

            barsvg.append("g").call(xAxis);

            barsvg.append("g").call(yAxis);

            //let noPercent = props.noPercent;

            function onMouseOver(d) {
                console.log('d', d)
                tooltip.style("opacity", 1);
                tooltip.style("z-index", "9999")
                // if (d.size) {
                //     let otherItems = data.filter(obj => obj[props.xaxisKey] === d[props.xaxisKey] && obj[props.yaxisKey] === d[props.yaxisKey])
                //     let labelValue = '';
                //     if (otherItems.length > 2) {
                //         otherItems.forEach((a, i) => (
                //             labelValue += `${a.name}${i === otherItems.length - 1 ? `` : `, `}`
                //         ))
                //     } else {
                //         labelValue += `${otherItems[0].name} & ${otherItems[1].name}`
                //     }
                //     tooltip.select(`.label`).text(labelValue).style("font-weight", "900");
                // } else {

                tooltip.select(".title").text(d[props.legendKey]);
                // }
                tooltip.select(".total").text(`${props.hoverItems[0]} ${d[props.hoverItems[1]].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }`);
                // let firstXaxisLabel = data.map((d) => d[props.xaxisKey]).sort((a, b) => a - b)[0]
                // if (props.removeFirstPercent && d[props.xaxisKey] === firstXaxisLabel) {
                //     tooltip.select(".percent").text(``);
                // } else {
                let percentNum = d[props.hoverItems[3]]?.split('.00')[0]
                if (percentNum) {
                    tooltip.select(".percent").text(`${props.hoverItems[2]} ${percentNum?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`);
                }
                // }

            }

            function onMouseOut() {
                tooltip.style("opacity", 0);
                tooltip.style("z-index", "-1")
                tooltip.select(".title").text("");
                tooltip.select(".total").text("");
                tooltip.select(".percent").text("");
            }

            function onMouseMove(d) {
                tooltip
                    .style("opacity", 1)
                    .style("z-index", "9999")
                    .style("top", ((d3.event.clientY - tooltip.node().getBoundingClientRect().height) - 30) + "px")
                    .style("left", (d3.event.clientX - 100) + "px");
            }


            // text label for the x axis
            var xaxisLocation = (height - 30) + padding / 2

            barsvg
                .append("text")
                .attr("class", "axislabel")
                .attr(
                    "transform",
                    "translate(" + ((width - margin.right) / 2) + " ," + xaxisLocation + ")"
                )
                .style("text-anchor", "middle")
                .text(props.xaxis);

            // text label for the y axis
            barsvg
                .append("text")
                .attr("class", "axislabel")
                .attr("transform", "rotate(-90)")
                .attr("y", 20)
                .attr("x", 0 - height / 3)
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(props.yaxis);
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, activeKeys])


    return (
        <div id={props.id}>
            <div
                ref={anchor}
                className={`customPathStrokes ${props.smalltext ? 'horizontal-graph' : 'graph1'}`}
                style={{ position: "relative", width: '900px' }}
            ></div>
            {props.legend ? (
                <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
                    {keys.map((key, i) => (
                        <div style={{ position: 'relative' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '20px' }}>
                                <div style={{ cursor: props.report ? 'default' : 'pointer', width: '30px', marginRight: '4px' }} onClick={(e) => props.report ? console.log('') : handleLegendClick(e, key)}>
                                    {activeKeys.includes(key) ?
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ width: '100%', height: '4px', backgroundColor: colors[key], borderRadius: '4px' }} />
                                            <div style={{ width: '11px', height: '11px', backgroundColor: colors[key], borderRadius: '100%', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto'}}/>
                                        </div>
                                        :
                                        <div style={{ width: '17px', height: '17px', borderRadius: '4px', border: '1px solid #0000008a', margin: 'auto' }}></div>
                                    }
                                </div>
                                <span style={{ fontSize: '12px', fontWeight: 600 }}>{key}</span>
                            </div>
                        </div>

                        // <Checkbox
                        //     key={key}
                        //     text={key}
                        //     checked={activeKeys.includes(key)}
                        //     onClickFunc={handleLegendClick}
                        //     onClickFuncItem={key}
                        //     color={colors[key]}
                        //     report={props.report}
                        // />
                    ))}
                </div>
            ) : null}
        </div>
    );
}
