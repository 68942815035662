import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "./Lib/UserContext";
import { isPublicSiteRoute } from "./Lib/Helpers/Utils";
import "./App.css";
import Routes from "./Routes";
import ReactGA from "react-ga4";
ReactGA.initialize('G-MTRFJT3Q2N');

function App() {
  const [role, setRole] = useState("Standard");
  const [email, setEmail] = useState("");
  const [stateLocation, setStateLocation] = useState("");
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const chartColorArray = ['#1957CD', '#0F347B', '#46ADE3', '#1895A7', '#A5E3ED', '#44CEA5', '#297C63', '#AEE0C6', '#532C95', '#9E9BF0', '#6F0C4E', '#B2127C', '#E1AFEF', '#EC3240', '#262F35', '#A8ACAE', '#1957CD', '#0F347B', '#46ADE3', '#1895A7', '#A5E3ED', '#44CEA5', '#297C63', '#AEE0C6', '#532C95', '#9E9BF0', '#6F0C4E', '#B2127C', '#E1AFEF', '#EC3240', '#262F35', '#A8ACAE', '#1957CD', '#0F347B', '#46ADE3', '#1895A7', '#A5E3ED', '#44CEA5', '#297C63', '#AEE0C6', '#532C95', '#9E9BF0', '#6F0C4E', '#B2127C', '#E1AFEF', '#EC3240', '#262F35', '#A8ACAE', '#1957CD', '#0F347B', '#46ADE3', '#1895A7', '#A5E3ED', '#44CEA5', '#297C63', '#AEE0C6', '#532C95', '#9E9BF0', '#6F0C4E', '#B2127C', '#E1AFEF', '#EC3240', '#262F35', '#A8ACAE', '#1957CD', '#0F347B', '#46ADE3', '#1895A7', '#A5E3ED', '#44CEA5', '#297C63', '#AEE0C6', '#532C95', '#9E9BF0', '#6F0C4E', '#B2127C', '#E1AFEF', '#EC3240', '#262F35', '#A8ACAE'] // color array (x5) for high data count charts

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoad() {
    if (isPublicSiteRoute(pathname)) {
      return;
    }

    try {
      await Auth.currentSession().then((response) => {
        setEmail(response.idToken.payload.email)
      })
      userHasAuthenticated(true);
      if (localStorage.getItem("role") !== null && localStorage.getItem("stateLocation") !== null) {
        let storedRole = localStorage.getItem("role");
        setRole(storedRole);
        let storedLocation = localStorage.getItem("stateLocation");
        setStateLocation(storedLocation);
        history.push("/dashboard");
        // console.log('history', history)
      } else {
        await Auth.currentUserInfo().then((response) => {
          setEmail(response.attributes.email);
          API.post("lighthouse-data-initiative", "getuserrole", {
            body: {
              email: response.attributes.email,
            },
          }).then((response) => {
            setRole(response[0].role);
            setStateLocation(response[0].state_location)
            localStorage.setItem("role", response[0].role);
            localStorage.setItem("stateLocation", response[0].state_location);
            history.push("/dashboard");
            localStorage.setItem("stateLocation", response[0].state_location);
            history.push("/dashboard");
          });
        });
      }
    } catch (e) {
      console.log("No current user.");
    }
  }

  return (
    <div>
      <AppContext.Provider
        value={{
          isAuthenticated,
          userHasAuthenticated,
          role,
          setRole,
          stateLocation,
          setStateLocation,
          email,
          setEmail,
          shouldShowBanner,
          setShouldShowBanner,
          chartColorArray
        }}
      >
        <Routes />
      </AppContext.Provider>
    </div>
  );
}
export default App;