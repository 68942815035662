import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
// import Autocomplete from "@material-ui/lab/Autocomplete";
import { laRegionList } from "../../../../Data/laRegionList";
// import TextField from "@material-ui/core/TextField";
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
var newRegionList = [...laRegionList]
newRegionList.splice(0, 1);

const BlackCheckbox = withStyles({
    root: {
        color: 'black',
        '&$checked': {
            color: 'black',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: '20px',
        // width: '400px',
        fontWeight: '300',
        height: '300px',
        overflowY: 'scroll',
    },
    filterWrapper: {
        display: 'flex'
    },
    active: {
        cursor: 'pointer',
        fontWeight: "700 !important",
    },
    inactive: {
        fontWeight: '400 !important',
        cursor: 'pointer'
    },
    checkboxLabel: {
        padding: '0px'
    },
    checkboxes: {
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column'
    },
    saveButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '20px',
        paddingBottom: '10px'
    }
}));

export default function LocationLAFilter(props) {
    const classes = useStyles();
    const [location, setLocation] = useState("Region")
    const [regionSelections, setRegionSelections] = useState([]);

    const handleRegionChange = (event) => {
        // console.log(event.target.value)
        var newRegionList = [...regionSelections];
        const index = newRegionList.indexOf('All regions');
        if (index > -1) {
            newRegionList.splice(index, 1); // 2nd parameter means remove one item only
        }
        if (newRegionList.includes(event.target.value)) {
            const index = newRegionList.indexOf(event.target.value);
            if (index > -1) {
                newRegionList.splice(index, 1); // 2nd parameter means remove one item only
            }
            setRegionSelections(newRegionList);
        } else {
            newRegionList.push(event.target.value)
            setRegionSelections(newRegionList);
        }
    };

    const handleAllRegionChange = (e) => {
        if (e.target.checked) {
            setRegionSelections(["All regions"]);
        } else {
            setRegionSelections([]);
        }
    }

    const handleSubmit = () => {
        props.filterCallback({
            name: 'Location',
            filter: 'Care Coordination',
            selection: location,
            value: regionSelections,
        })
        props.handleClose()
    }

    useEffect(() => {
        for (let i = 0; i < props.selectedFilters.length; i++) {
            if (props.selectedFilters[i].name === 'Location' && props.selectedFilters[i].filter === 'Care Coordination') {
                if (props.selectedFilters[i].selection === 'Region') {
                    setLocation("Region")
                    setRegionSelections(props.selectedFilters[i].value)
                } else {
                    setLocation("Region")
                    setRegionSelections(["All regions"])
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.container}>
            <div className={classes.filterWrapper}>
                <div>
                    <p className={classes.active}>Region</p>
                </div>
                <div>
                    <div className={classes.checkboxes}>
                        <FormControlLabel className="checkboxLabel"
                            control={
                                <BlackCheckbox
                                    checked={regionSelections.includes("All regions")}
                                    onChange={handleAllRegionChange}
                                    name="checkedA" />
                            }
                            label="All regions"
                        />
                        {newRegionList.map(region => {
                            return (
                                <FormControlLabel key={region} className="checkboxLabel"
                                    control={
                                        <BlackCheckbox
                                            checked={regionSelections.includes(region)}
                                            onChange={e => handleRegionChange(e, region)}
                                            name="checkedA"
                                            value={region} />
                                    }
                                    label={region}
                                />
                            )
                        })}
                    </div>
                    <div className={classes.saveButton}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}