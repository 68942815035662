import React, { Fragment, useState } from "react";

import Snackbar from "@mui/material/Snackbar";

import NewsletterSubscribeModal from "../NewsletterSubscribeModal/NewsletterSubscribeModal";
import Button from "../Button/Button";
import CTABlock from "./CTABlock.js";

import "./NewsletterCTABlock.scss";

export default function NewsletterCTABlock() {
    const [shouldShowSnackbar, setShouldShowSnackbar] = useState(false);
    const [shouldShowSubscribeModal, setShouldShowSubscribeModal] = useState(false);

    const openSubscribeModal = () => {
        setShouldShowSubscribeModal(true);
    };

    const closeSubscriptionModal = () => {
        setShouldShowSubscribeModal(false);
    }

    const getDescription = () => {
        return (
            <Fragment>
                <div className="title">
                    Add impact to your inbox
                </div>
                <div className="description-wrapper">
                    <h3 className='margin0'>Receive email updates to stay informed about our latest blog posts,
                    design futures, and company updates.</h3>
                    {/* <a href='https://share.hsforms.com/1m6-mR4fySxu2vvQzKP8zKA2gbug' target='_blank' rel="noopener noreferrer"> */}
                        <Button className="public-site-btn btn-white marginLeft32" onClickFn={openSubscribeModal}>
                            Subscribe
                        </Button>
                    {/* </a> */}
                </div>
            </Fragment>
        );
    };

    const handleSnackbarClose = () => {
        setShouldShowSnackbar(false);
    };

    return (
        <div>
            <CTABlock
                className="newsletter-cta-block"
                description={getDescription()}
            />
            <NewsletterSubscribeModal
                open={shouldShowSubscribeModal}
                handleClose={closeSubscriptionModal}
                showSnackbar={() => setShouldShowSnackbar(true)}
            />
            <Snackbar
                open={shouldShowSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                message="Thank you for signing up!"
            />
        </div>
    );
};