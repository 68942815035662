// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

export const ServicesLayer = (stateLocation, map, filters, data, legend, report, mapsIndex, chartColorArray) => {

  let mapDataIndex = mapsIndex['Services']

  let providerLocation = filters.filter(f => f.name === 'Provider Location ' && f.filter === 'Services')?.[0]?.value?.[0]
  let plFilterDataIndex = mapsIndex['Services']['Provider Location '][providerLocation]
  // console.log(providerLocation)

  let hoveredStateId = null

  map.on("load", function () {
    map.addSource('loc1', {
      type: "geojson",
      data: plFilterDataIndex.mapJson,
    });
    map.addLayer({
      id: "loc1",
      type: "line",
      source: "loc1",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#28ADE3",
        "line-width": 1,
      },
    });

    map.addLayer({
      id: "loc1-fills",
      type: "fill",
      source: "loc1",
      layout: {},
      paint: {
        "fill-color": "#28ADE3",
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.5,
          0,
        ],
      },
    });

    // Multiple pins should display(anchor) at once if organization name, lat, and long are the exact same
    let pinAnchors = ['center', 'top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right']
    const groupStackedPins = data?.[plFilterDataIndex?.dataVar] && Object?.groupBy(data?.[plFilterDataIndex?.dataVar], ({ organization, lat, long }) => `${organization}-${lat}`);

    data?.[plFilterDataIndex?.dataVar]?.forEach((item) => {
      mapDataIndex.orgList?.forEach(type => {
        if (item.org_type === type.name && legend.filter(l => l.name === type.name)?.[0]?.checked === true) {
          let indexForStackedPin = [...groupStackedPins[`${item.organization}-${item.lat}`]]?.map(o => o.org_type).indexOf(item.org_type)

          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<p>${item.organization}</p>
                <p>${plFilterDataIndex.popUpLocName}: <span style="font-weight: 300 !important">${item[plFilterDataIndex.locSourceVar]} </span></p>
                <p>${plFilterDataIndex.popUpOrgTypeName}: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
          );
          return new mapboxgl.Marker({ anchor: pinAnchors[indexForStackedPin], color: chartColorArray[type.color], scale: report ? 0.2 : 0.5 })
            .setLngLat([item.long, item.lat])
            .setPopup(popup)
            .addTo(map);
        }
      })
    });

    var popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on("mousemove", "loc1-fills", function (e) {
      if (hoveredStateId !== e.features[0].id) {
        if (e.features.length > 0) {
          if (hoveredStateId) {
            map.setFeatureState(
              { source: "loc1", id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = e.features[0].id;
          map.setFeatureState(
            { source: "loc1", id: hoveredStateId },
            { hover: true }
          );
        }

        var description = e.features[0].properties[plFilterDataIndex.featureLocProp];
        popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
      }
    });

    map.on("mouseleave", "loc1-fills", function () {
      if (hoveredStateId) {
        map.setFeatureState(
          { source: "loc1", id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
      popup.remove();
    });
  });
}