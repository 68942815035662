import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
// import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        // display: 'flex',
        padding: '20px',
        fontWeight: '300'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer',
      fontWeight: "700 !important",
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function HotlineTimeFilter(props) {
  const classes = useStyles();
  //const [filter, setFilter] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = React.useState(true);
  const [qFSelections, setQFSelections] = React.useState(["All years"]);
  const [yearSelections, setYearSelections] = React.useState(["All years"]);

  // const quartersToUseArray = ['Q1: Jan 1 - March 31', 'Q2: Apr 1 - Jun 31', 'Q3: Jul 1 - Sep 30', 'Q4: Oct 1 - Dec 31']
  const yearsToUseArray = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']

  // const handleQFCheckboxChange = (e, item) => {
  //   if (qFSelections.includes(item)){
  //     let newArray = qFSelections.filter(x => x !== item); 
  //     setQFSelections(newArray);
  //   } else {
  //     let newArray = [...qFSelections]
  //     let filteredArray = newArray.filter(x => x !== "All quarters");
  //     filteredArray.push(item);
  //     setQFSelections(filteredArray);
  //   }
  // }

  const handleQFYearCheckboxChange = (e, item) => {
    let newArray = [...qFSelections]
    if (e.target.checked){
      newArray = newArray.filter(x => x !== "All years");
      newArray.push(item);
    } else {
      newArray = newArray.filter(x => x !== item); 
    }
    setQFSelections(newArray);
  }

  // const handleQFAllQuarterCheckboxChange = (e) => {
  //   if (e.target.checked){
  //     let newArray = [...qFSelections]
  //     let filteredArray = newArray.filter(x => x !== "Q1: Jan 1 - Mar 31" && x !== "Q2: Apr 1 - Jun 30" && x !== "Q3: Jul 1 - Sep 30"  && x !== "Q4: Oct 1 - Dec 31");
  //     filteredArray.push("All quarters");
  //     setQFSelections(filteredArray);
  //   } else {
  //     let newArray = [...qFSelections]
  //     let filteredArray = newArray.filter(x => x !== "All quarters");
  //     setQFSelections(filteredArray);
  //   }
  // }

  const handleQFAllYearCheckboxChange = (e) => {
    let newArray = [...qFSelections]
    if (e.target.checked) {
      for (let i = 0; i < yearsToUseArray.length; i++) {
        if (newArray.includes(yearsToUseArray[i])) {
          newArray = newArray.filter(x => x !== yearsToUseArray[i]);
        }
      }
      newArray.push('All years')
    } else {
      newArray = newArray.filter(x => x !== "All years");
    }
    setQFSelections(newArray);
  }
  

  // eslint-disable-next-line no-unused-vars
  const handleYearCheckboxChange = (e, item) => {
    if (yearSelections.includes(item)){
      let newArray = yearSelections.filter(x => x !== item); 
      setYearSelections(newArray);
    } else {
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      filteredArray.push(item);
      setYearSelections(filteredArray);
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleAllYearCheckboxChange = (e) => {
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      setYearSelections(filteredArray);
  }

  const handleTimeChange = () => {
    // setTime(!time)
  }

  const handleSubmit = () => {
    var timeCategory = "Quarter & Fiscal Year";
    props.filterCallback({
      name: 'Time',
      filter: 'Hotline',
      selection: timeCategory,
      value: qFSelections,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      //Sort filters to get time filters
      if (props.selectedFilters[i].name === 'Time' && props.selectedFilters[i].filter === 'Hotline'){
        //Filter based on Quarter/Fiscal Year and Fiscal Year
        if (props.selectedFilters[i].selection === 'Quarter & Fiscal Year') {
          setTime(true)
          setQFSelections(props.selectedFilters[i].value)
        } else {
          setTime(false)
          setYearSelections(props.selectedFilters[i].value)
        }
      }
    }
  }, [props.selectedFilters])

  return (
    <div className={classes.container}>
      {/* {time ? ( */}
          <div className={classes.filterWrapper}>
            <div>
          <p className={classes.active} onClick={handleTimeChange}>{`Calendar Year`}</p> 
          {/* Quarter & */}
            </div>
            {/* <div className={classes.checkboxes}>
              <FormControlLabel className="checkboxLabel"
                control={ 
                  <BlackCheckbox 
                    checked={qFSelections.includes("All quarters")} 
                    onChange={handleQFAllQuarterCheckboxChange} name="checkedA" 
                  />
                }
                label="All quarters"
            />
            {quartersToUseArray.map((quarter, index) => {
              return (
                <FormControlLabel
                  key={quarter}
                  className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={qFSelections.includes(quarter)}
                      onChange={e => handleQFCheckboxChange(e, quarter)} name="checkedA"
                    />
                  }
                  label={quarter}
                />
              )
            })}
            </div>
            <Divider orientation="vertical" flexItem /> */}
          <div className={classes.checkboxes}>
            <FormControlLabel className="checkboxLabel"
              control={
                <BlackCheckbox
                  checked={qFSelections.includes("All years")}
                  onChange={e => handleQFAllYearCheckboxChange(e, "All years")}
                  name="checkedA"
                />
              }
              label="All years"
            />
            {yearsToUseArray.map((year, index) => {
              return (
                <FormControlLabel
                  key={year}
                  className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={qFSelections.includes(year)}
                      onChange={e => handleQFYearCheckboxChange(e, year)}
                      name="checkedA"
                    />
                  }
                  label={year}
                />
              )
            })}
            </div> 
          </div> 
      
      {/* ) : (
        <div>
        <div className={classes.filterWrapper}>
          <div>
            <p className={time ? classes.active : classes.inactive} onClick={handleTimeChange}>{`Quarter & Calendar Year`}</p>
          </div>                
          <div className={classes.checkboxes}>
            <FormControlLabel className="checkboxLabel"
              control={ 
                 <BlackCheckbox 
                    checked={yearSelections.includes("All years")} 
                    onChange={handleAllYearCheckboxChange} name="checkedA" 
                  />
                }
                label="All years"
            />
            {yearsToUseArray.map((year, index) => {
              return (
                <FormControlLabel
                  key={year}
                  className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={yearSelections.includes(year)}
                      onChange={e => handleYearCheckboxChange(e, year)}
                      name="checkedA"
                    />
                  }
                  label={year}
                />
              )
            })}
          </div>
        </div>
        </div>
      )} */}
      <div className={classes.saveButton}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </div> 
  )
}                  