import React, { createRef, useEffect, useState } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";
import { Checkbox } from '../../../Components/Checkbox'

const PieGraph = (props) => {
  const { chartColorArray } = useAppContext()
  const chartRef = createRef();
  // const [ready, setReady] = useState(false)
  const [data, setData] = useState([])
  const [colors, setColors] = useState({})
  // let defaultKeys = props.keys ? props.keys.map(k => k.name) : props.data?.map(d => d.label ?? d[props?.sourceNameVar])
  // const [ogLegendKeys, setOgLegendKeys] = useState(defaultKeys)
  // const [activeLegendKeys, setActiveLegendKeys] = useState(defaultKeys)



  // useEffect(() => {
  //   setReady(true)
  // }, []);


  // const handleLegendClick = (keyName) => {
  //   let updateKeys = [...activeLegendKeys]
  //   if (activeLegendKeys.includes(keyName)) {
  //     updateKeys = updateKeys.filter(f => f !== keyName)
  //   } else {
  //     updateKeys.push(keyName)
  //   }
  //   setActiveLegendKeys(updateKeys)
  // }



  useEffect(() => {
    if (props.data) {
      var width = 320;
      var height = 350;
      if (props.height) {
        height = props.height
      }

      let dataset = props.data;

      if (props.keys) {
        dataset = props.data.sort((a, b) =>
          props.keys.indexOf(props.keys.filter(k => k.variable === a[props.sourceNameVar ?? 'label'])[0]) -
          props.keys.indexOf(props.keys.filter(k => k.variable === b[props.sourceNameVar ?? 'label'])[0])
        )
      } else if (!props.keepOrder) {
        dataset = props.data.sort((a,b) => b.value - a.value);
      }
      setData(dataset)

      let colors = {}

      if (props.keys) {
        dataset.forEach(d => {
          colors[d[props.sourceNameVar ?? 'label']] = props.keys.filter(k => k.name === d[props.sourceNameVar ?? 'label'])?.[0]?.color
        })
      } else {
          dataset.forEach((d, i) => {
            colors[d[props.sourceNameVar ?? 'label']] = chartColorArray[i]
          })
          // d3.scaleOrdinal(chartColorArray);
      }
      setColors(colors)

      

      // TODO(WISHLIST): Not working for sub pies
      // let legendUpdateDataset = []
      // activeLegendKeys?.forEach(k => {
      //   dataset?.forEach(d => {
      //     console.log(d)
      //     let name = d.label ?? d[props?.sourceNameVar]
      //     if (name === k) {
      //       legendUpdateDataset.push(d)
      //     }
      //   })
      // })

      // dataset = legendUpdateDataset

      var radius = width / 2;

      // var legendRectSize = 25;
      // var legendSpacing = 6;

      let svg = d3.select(chartRef.current);
      svg.selectAll("svg").remove();

      var piesvg = svg
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${radius - 20}, 250)`);

      var arc = d3.arc().innerRadius(0).outerRadius(radius);

      var pie = d3
        .pie()
        .value(function (d) {
          return d.value;
        })
        .sort(null);

      // define tooltip
      var tooltip = d3
        .select(chartRef.current)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px");

      tooltip.append("div").attr("class", "label");

      tooltip.append("div").attr("class", "count");

      tooltip.append("div").attr("class", "percent");

      dataset.forEach(function (d) {
        d.value = +d.value;
        d.enabled = true;
      });

      // creating the chart
      var path = piesvg
        .selectAll("path")
        .data(pie(dataset))
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("transform", "translate(20,-70)")
        .attr("fill", function (d) {
          return colors[d.data[props.sourceNameVar ?? 'label']];
        })
      // .each((d) => this._current = d);

      // mouse event handlers are attached to path so they need to come after its definition
      path.on("mouseover", function (d) {
        var total = d3.sum(
          dataset.map(function (d) {
            return d.enabled ? d.value : 0;
          })
        );
        var percent = Math.round((1000 * d.data.value) / total) / 10;
        tooltip.select(".label").html(d.data[props.sourceNameVar ?? 'label']);
        tooltip.select(".count").html("Total: " + d.data.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        tooltip.select(".percent").html("Percent: " + percent + "%");
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999")

        if (props.sliceHoverFunc) {
          props.sliceHoverFunc(d.data[props.sourceNameVar ?? 'label'])
        }

      });

      path.on("mouseout", function () {
        tooltip.style("opacity", 0);
        tooltip.style("z-index", "-1")

      });

      path.on("mousemove", function (d) {
        tooltip
          .style("top", (d3.event.clientY - 100) + "px")
          .style("left", (d3.event.clientX - 265) + "px");
      });

      // var legend = piesvg
      //   .selectAll(".legend")
      //   .data(color.domain())
      //   .enter()
      //   .append("g")
      //   .attr("class", "legend")
      //   .attr("transform", function (d, i) {
      //     var height = legendRectSize + legendSpacing;
      //     var vert = i * height - 150;
      //     return "translate(" + 250 + "," + vert + ")";
      //   });

      // // adding colored squares to legend
      // legend
      //   .append("rect") // append rectangle squares to legend
      //   .attr("width", legendRectSize) // width of rect size is defined above
      //   .attr("height", legendRectSize) // height of rect size is defined above
      //   .style("fill", color) // each fill is passed a color
      //   .style("stroke", color) // each stroke is passed a color
      //   .on("click", function (label) {
      //     var rect = d3.select(this); // this refers to the colored squared just clicked
      //     var enabled = true; // set enabled true to default
      //     var totalEnabled = d3.sum(
      //       dataset.map(function (d) {
      //         // can't disable all options
      //         return d.enabled ? 1 : 0; // return 1 for each enabled entry. and summing it up
      //       })
      //     );

      //     if (rect.attr("class") === "disabled") {
      //       // if class is disabled
      //       rect.attr("class", ""); // remove class disabled
      //     } else {
      //       // else
      //       if (totalEnabled < 2) return; // if less than two labels are flagged, exit
      //       rect.attr("class", "disabled"); // otherwise flag the square disabled
      //       enabled = false; // set enabled to false
      //     }

      //     pie.value(function (d) {
      //       if (d.label === label) d.enabled = enabled; // if entry label matches legend label
      //       return d.enabled ? d.value : 0; // update enabled property and return count or 0 based on the entry's status
      //     });

      //     path = path.data(pie(dataset)); // update pie with new data

      //     path
      //       .transition() // transition of redrawn pie
      //       .duration(750) //
      //       .attrTween("d", function (d) {
      //         // 'd' specifies the d attribute that we'll be animating
      //         var interpolate = d3.interpolate(0, d); // this = current path element
      //         // interpolate(0, d); // interpolate between current value and the new value of 'd'
      //         return function (t) {
      //           return arc(interpolate(t));
      //         };
      //       });
      //   });

      // // adding text to legend
      // legend
      //   .append("text")
      //   .attr('class', 'piechartLegend')
      //   .attr("x", legendRectSize + legendSpacing)
      //   .attr("y", legendRectSize - legendSpacing)
      //   .text(function (d) {
      //     return d;
      //   }); // return label
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <div id={props.id} style={{ width: props.report ? '60vw' : '100%' }}>
      <div className='chart pieGraph'  ref={chartRef}></div>
      <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
        {data.map(d => {
          let name = d.label ?? d[props.sourceNameVar]
          return (
            <Checkbox
              key={name}
              text={name}
              checked={false}
              colorWhenFalse
              color={colors[name]}
              cursorDefault
              report={props.report}
            />
          )
        }
        )}
      </div>
    </div>
  );
}
export default PieGraph;
