// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

export function StatePolicyCriminalLayer(map, filters, data, stateLocation, mapsIndex) {
   // console.log(filters)

    // eslint-disable-next-line array-callback-return
    let filterName = filters.filter(f => {
        if (f.name === 'Sentencing Requirement' && f.filter === 'State Policy') {
          return f.value
        }
      })
    //console.log(filterName)
    
    
    let mapDataIndex = mapsIndex['State Policy']['CriminalData'];

    if (data[mapDataIndex.dataVar] &&
        data[mapDataIndex.dataVar].length > 0) {
        // #271
        let removeFromMapJson = { ...mapDataIndex.mapJson }
        removeFromMapJson.features = removeFromMapJson.features.filter(f => {
            if (f.properties[mapDataIndex.mapLocVar] !== 'Puerto Rico' && f.properties[mapDataIndex.mapLocVar] !== 'Guam' && f.properties[mapDataIndex.mapLocVar] !== 'Northern Mariana Islands' && f.properties[mapDataIndex.mapLocVar] !== 'Virgin Islands' && f.properties[mapDataIndex.mapLocVar] !== 'District of Columbia') {
                return f
            }
            return null
        })

        let mapJson = JSON.parse(JSON.stringify(removeFromMapJson))
        
        mapJson.features?.forEach(f => {
            f.properties.SCREENINGS = 0

            data[mapDataIndex.dataVar].forEach(d => {
                if (f.properties[mapDataIndex.mapLocVar].toLowerCase() === d[mapDataIndex.sourceVar].toLowerCase()) {
                    f.properties.SCREENINGS = d.year === 'life' ? 100 : Number(d.year)
                }
            })
        })

        let calculatedData = mapJson;
        map.on("load", function () {
            map.addSource("county", {
                type: "geojson",
                data: calculatedData,
            });
            map.addLayer({
                id: "county",
                type: "line",
                source: "county",
                layout: {
                    "line-join": "round",
                    "line-cap": "round",

                },
                paint: {
                    "line-color": "#28ADE3",
                    "line-width": 1,
                },
            });

            map.addLayer({
                id: "county-fills",
                type: "fill",
                source: "county",
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "fill-color": {
                        property: "SCREENINGS",
                        stops: mapDataIndex.mapFillColors
                    },
                    "fill-outline-color": "#28ADE3",
                    "fill-opacity": mapDataIndex.mapFillOpacity,
                },
            });
        });

        let hoveredStateId = null;
        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        });

        map.on("mousemove", "county-fills", function (e) {
            let uniqueMapIdProp = e.features[0].properties.GEO_ID
            if (hoveredStateId !== uniqueMapIdProp) {
                if (e.features.length > 0) {
                    if (hoveredStateId) {
                        map.setFeatureState(
                            { source: "county", id: hoveredStateId },
                            { hover: false }
                        );
                    }
                    hoveredStateId = uniqueMapIdProp;
                    map.setFeatureState(
                        { source: "county", id: hoveredStateId },
                        { hover: true }
                    );

                   
                    let hoverText =
                        `<div>
                            <h3><strong>${e.features[0].properties[mapDataIndex.hoverTitleVar]}</strong></h3>
                            <p style="margin:0px; padding: 0px;">${filterName[0].value[0]}</p>
                            <p style="margin:0px; padding: 0px;">${e.features[0].properties.SCREENINGS === 100 ? 'Life' : e.features[0].properties.SCREENINGS + " years" }</p>
                        </div>`;

                    let description = hoverText
                    popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
                }
            }
        });

        map.on("mouseleave", "county-fills", function () {
            if (hoveredStateId) {
                map.setFeatureState(
                    { source: "county", id: hoveredStateId },
                    { hover: false }
                );
            }
            hoveredStateId = null;
            popup.remove();
        });
    }
}
