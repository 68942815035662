import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";

import Button from "../Button/Button";
import MediaWrapper from "../MediaWrapper/MediaWrapper";

import "./Highlight.scss";

export default function Highlight(props) {
    const [className, setClassName] = useState("");

    useEffect(() => {
        const isEven = (props.sortOrder + 1) % 2 === 0;
        setClassName(
            isEven
            ? "highlight highlight-reverse"
            : "highlight"
        );
    }, [props.sortOrder]);

    return (
        <div className={className}>
            <div className="highlight-column">
                <div className="highlight-header title">
                    {props.header}
                </div>
                <div className="highlight-body">
                    <h3>
                    <Markdown>
                        {props.body}
                        </Markdown>
                    </h3>
                </div>
                <Button onClickFn={() => {
                    window.open(props.buttonLink, "_blank");
                }} className="btn-black">
                    <h3>{props.button}</h3>
                </Button>
            </div>
            <MediaWrapper
                url={props.image?.url}
                contentType={props.image?.contentType}
            />
        </div>
    );
}