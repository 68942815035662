/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { useAppContext } from "../../Lib/UserContext";
import { data } from "../Dashboard/Charts/Screenings/DemographicData";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { CSVLink } from "react-csv";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import AgeBarGraphV2 from "../Dashboard/Charts/Screenings/Graphs/AgeBarGraphV2";
// import AgeAllBarGraphV2 from "../Dashboard/Charts/Screenings/Graphs/AgeAllBarGraphV2";
import AgeTable from "../Dashboard/Charts/Screenings/Tables/AgeTable";
import AgeAllTable from "../Dashboard/Charts/Screenings/Tables/AgeAllTable";
// import GenderBarGraph from "../Dashboard/Charts/Screenings/Graphs/GenderBarGraph";
import TableWithTotal from "../../Components/ReusableTables/TableWithTotal";
import HorizontalTableData from "../../Components/ReusableTables/HorizontalTableData";
import TableForYearGraphs from '../../Components/ReusableTables/TableForYearGraphs'

import AgeGenderComp from "../Dashboard/Charts/Screenings/Graphs/AgeGenderComp";
import AgeAllGenderComp from "../Dashboard/Charts/Screenings/Graphs/AgeAllGenderComp";
import AgeGenderTable from "../Dashboard/Charts/Screenings/Tables/AgeGenderTable";
import AgeAllGenderTable from "../Dashboard/Charts/Screenings/Tables/AgeAllGenderTable";
import RaceGenderComp from "../Dashboard/Charts/Screenings/Graphs/RaceGenderComp";
import RaceGenderTable from "../Dashboard/Charts/Screenings/Tables/RaceGenderTable";
import RaceAgePie from "../Dashboard/Charts/Screenings/Graphs/RaceAgePie";
import RaceAgeAllPie from "../Dashboard/Charts/Screenings/Graphs/RaceAgeAllPie";
import RaceAgeTable from "../Dashboard/Charts/Screenings/Tables/RaceAgeTable";
import RaceAgeAllTable from "../Dashboard/Charts/Screenings/Tables/RaceAgeAllTable";
import TableHoverDonutChart from "../Dashboard/Charts/Screenings/Graphs/TableHoverDonutChart";
import HorizontalAndDonutChart from '../Dashboard/Charts/Screenings/Graphs/HorizontalAndDonutChart'
import HoverDonutChartTable from "../Dashboard/Charts/Screenings/Tables/HoverDonutChartTable";
import { BarChart } from "../Dashboard/Charts/Screenings/Graphs/BarChart";
import DendrogramV2 from "../Dashboard/Charts/Screenings/Graphs/DendrogramV2";
import InteractivePieChart from "../Dashboard/Charts/Screenings/Graphs/InteractivePieChart";
import TotalIndicatorsTable from "../Dashboard/Charts/Screenings/Tables/TotalIndicatorsTable";
import TotalIndicatorsOrderedTable from "../Dashboard/Charts/Screenings/Tables/TotalIndicatorsOrderedTable";
// import ScreeningByConcernPie from "../Dashboard/Charts/Screenings/Graphs/ScreeningByConcernPie";
import ScreeningConcernTable from "../Dashboard/Charts/Screenings/Tables/ScreeningConcernTable";

import { GroupedBarGraph } from "../Dashboard/GraphComponents/GroupedBarGraph";
import { SingleBarGraph } from "../Dashboard/GraphComponents/SingleBarGraph";
import { SingleBarDottedGraph } from '../Dashboard/GraphComponents/SingleBarDottedGraph'
import { StackedBarGraph } from "../Dashboard/GraphComponents/StackedBarGraph";
import { VerticalMirrorBarChart } from '../Dashboard/GraphComponents/VerticalMirrorBarChart'
import PieGraph from "../Dashboard/GraphComponents/PieGraph";
import OrgList from "../Dashboard/Charts/Services/Tables/OrgList";

import CustomMeasuresTable from "../Dashboard/Charts/Services/Tables/CustomMeasuresTable";
import AvStayProgramTable from '../Dashboard/Charts/Services/Tables/AvStayProgramTable';
import { ccgraphdata } from "../Dashboard/GraphComponents/GraphVariables/CCData";
import { LACCKeys } from "../Dashboard/GraphComponents/GraphVariables/LACCKeys";
import { servicesdata } from "../Dashboard/GraphComponents/GraphVariables/ServicesData";
import { tdcjdata } from "../Dashboard/GraphComponents/GraphVariables/TDCJ";
import { servicesmockdata } from "../Dashboard/Charts/Services/ServicesData";
import { tdcjmockdata } from "../Dashboard/Charts/TDCJ/TDCJData";
import { newsmockdata } from "../Dashboard/Charts/News/NewsData";

//import { casesData } from "../Dashboard/Charts/Hotline/HotlineCasesData";
import HotlineCasesTable from "../Dashboard/Charts/Hotline/Tables/HotlineCasesTable";
import { hotlineVariables } from "../Dashboard/GraphComponents/GraphVariables/HotlineVariables.js";

import { publicsafetydata } from "../Dashboard/GraphComponents/GraphVariables/PublicSafety";
import { publicsafetymockdata } from "../Dashboard/Charts/PublicSafety/PublicSafetyData";
import { demanddata } from "../Dashboard/GraphComponents/GraphVariables/DemandData";
import { demandmockdata } from "../Dashboard/Charts/Demand/DemandData";
import { federalcasesmockdata } from "../Dashboard/Charts/FederalCases/FederalCasesData";


//import IndicatorImpactTable from '../Dashboard/Charts/Screenings/Tables/IndicatorImpactTable';

import CCTotalsTable from "../Dashboard/Charts/CareCoordination/Tables/Referrals/CCTotalsTable";
import CCReferralTotalsTable from "../Dashboard/Charts/CareCoordination/Tables/Referrals/CCReferralTotalsTable";
import YouthDfpsTable from "../Dashboard/Charts/CareCoordination/Tables/Referrals/YouthDfpsTable";
import CCReferralTable from "../Dashboard/Charts/CareCoordination/Tables/Referrals/CCReferralTable";
import CCDaysPlacementTable from "../Dashboard/Charts/CareCoordination/Tables/Identified/CCDaysPlacementTable";
import CCHoursPlacementTable from "../Dashboard/Charts/CareCoordination/Tables/Identified/CCHoursPlacementTable";
import MeetingsTypeTable from "../Dashboard/Charts/CareCoordination/Tables/Meetings/MeetingsTypeTable";
import MeetingsHeldTable from "../Dashboard/Charts/CareCoordination/Tables/Meetings/MeetingsHeldTable";
import CasesClosedTable from "../Dashboard/Charts/CareCoordination/Tables/Meetings/CasesClosedTable";
import FBSSTable from "../Dashboard/Charts/CareCoordination/Tables/Meetings/FBSSTable";
import ForensicIntCstTable from "../Dashboard/Charts/CareCoordination/Tables/Meetings/ForensicIntCstTable";
import GuardianTypeTable from "../Dashboard/Charts/CareCoordination/Tables/Outcomes/GuardianTypeTable";
import { PaidUnpaidChart } from "../Dashboard/Charts/CareCoordination/Graphs/LocationDays/PaidUnpaidDays";
import PaidUnpaidTable from "../Dashboard/Charts/CareCoordination/Tables/LocationDays/PaidUnpaidTable";
import LocationDaysTable from "../Dashboard/Charts/CareCoordination/Tables/LocationDays/LocationDaysTable";
import IndividualsInCCTable from "../Dashboard/Charts/CareCoordination/Tables/Identified/IndividualsInCCTable";
import IdentifiedRecoveredTable from "../Dashboard/Charts/CareCoordination/Tables/Identified/IdentifiedRecoveredTable";
import TotalPlacements72Table from "../Dashboard/Charts/CareCoordination/Tables/Identified/TotalPlacements72Table";
import TotalPlacements15Table from "../Dashboard/Charts/CareCoordination/Tables/Identified/TotalPlacements15Table";
import TotalDaysTable from "../Dashboard/Charts/CareCoordination/Tables/LocationDays/TotalDaysTable";
import TotalInCareTable from "../Dashboard/Charts/CareCoordination/Tables/Identified/TotalInCareTable";
import PlacementsTable from "../Dashboard/Charts/CareCoordination/Tables/Outcomes/PlacementsTable";
import { makeStyles } from "@material-ui/core/styles";

import { StackedBarVariableXAxis } from "../Dashboard/GraphComponents/StackedBarVariableXAxis";
import { GroupedBarVariableXGraph } from "../Dashboard/GraphComponents/GroupedBarVariableXAxis";
import ScatterplotChart from "../Dashboard/GraphComponents/Scatterplot";
import ConnectedScatterplot from "../Dashboard/GraphComponents/ConnectedScatterplot";
import GridChart from "../Dashboard/GraphComponents/GridChart";
import { WhiskerPlot } from '../Dashboard/GraphComponents/WhiskerPlot'

import HorizontalBarChart from "../Dashboard/GraphComponents/HorizontalBarChart";
import PublicSafetyTable from "../Dashboard/Charts/PublicSafety/Tables/PublicSafetyTable";
import PublicSafetyHorizontalTable from "../Dashboard/Charts/PublicSafety/Tables/PublicSafetyHorizontalTable";
import TDCJTable from "../Dashboard/Charts/TDCJ/Tables/TDCJTable";
// import DemandTable from "../Dashboard/Charts/Demand/Tables/DemandTable";
import DemandHorizontalTable from "../Dashboard/Charts/Demand/Tables/DemandHorizontalTable";
import { GroupedBarVariableLegend } from "../Dashboard/GraphComponents/GroupedBarVariableLegend";

import { federalcasesdata } from "../Dashboard/GraphComponents/GraphVariables/FederalCasesData";
import { statepolicydata } from "../Dashboard/GraphComponents/GraphVariables/StatePolicyData";
// import FederalCasesTable from "../Dashboard/Charts/FederalCases/Tables/FederalCasesTable";
import PeriodOfExploitationTable from "../Dashboard/Charts/FederalCases/Tables/PeriodOfExploitationTable";
import CasesByYearTable from "../Dashboard/Charts/FederalCases/Tables/CasesByYearTable";
import FederalCasesHorizontalTable from "../Dashboard/Charts/FederalCases/Tables/FederalCasesHorizontalTable";
import NewsHorizontalTable from "../Dashboard/Charts/News/Tables/NewsHorizontalTable";

// import OVCTable from '../Dashboard/Charts/Services/Tables/OVCTable'

//Louisiana
import HorizontalTable from "../Dashboard/Charts/Services/Louisiana/Tables/HorizontalTable";
import TypeGenderTable from "../Dashboard/Charts/Services/Louisiana/Tables/TypeGenderTable";
import TypeRaceTable from "../Dashboard/Charts/Services/Louisiana/Tables/TypeRaceTable";
import { laservicesdata } from "../Dashboard/GraphComponents/GraphVariables/LAServicesData";
import { screeningsgraphdata } from "../Dashboard/GraphComponents/GraphVariables/ScreeningsData";
import TypeGenderComp from "../Dashboard/Charts/Services/Louisiana/TypeGenderGraph";
import TypeRaceGraph from "../Dashboard/Charts/Services/Louisiana/TypeRaceGraph";
import { latraflocationdata } from "../Dashboard/GraphComponents/GraphVariables/LATrafLocationData";

import ImbTable from "../Dashboard/Charts/IMB/Tables/ImbTable";
import ImbTotalsTable from "../Dashboard/Charts/IMB/Tables/ImbTotalsTable";
import ImbHorizontalTable from "../Dashboard/Charts/IMB/Tables/ImbHorizontalTable";
import { imbdata } from "../Dashboard/GraphComponents/GraphVariables/BusinessesOfInterestData";
import { imbmockdata } from "../Dashboard/Charts/IMB/ImbData";

import Map from "../Dashboard/Map";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { set } from "react-ga";

const useStyles = makeStyles((props) => ({
  data: {
    paddingRight: "20px",
    paddingLeft: "20px",
  },
}));

export default function ReportCard(props) {
  const [reports, setReports] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [isHtmlCaptureReady, setIsHtmlCaptureReady] = React.useState(false)
  const [addToDownload, setAddToDownload] = React.useState(false)
  const classes = useStyles();
  const { stateLocation } = useAppContext();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [title, setTitle] = React.useState("Report");
  const [editTitle, setEditTitle] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [arrayIndicators, setArrayIndicators] = React.useState([]);
  const [arrayMaleIndicators, setMaleArrayIndicators] = React.useState([]);
  const [arrayFemaleIndicators, setFemaleArrayIndicators] = React.useState([]);
  const [raceAgeDataCsvData, setRaceAgeDataCsvData] = React.useState([]);
  const [raceAllAgeDataCsvData, setRaceAllAgeDataCsvData] = React.useState([]);
  const [indicatorsByCategoryCsvData, setIndicatorsByCategoryCsvData] = React.useState([]);
  const [paidUnpaidCsvData, setPaidUnpaidCsvData] = React.useState([]);
  const [raceTypeCsvData, setRaceTypeCsvData] = React.useState([]);
  const [ltImpactAssessmentCsvData, setLtImpactAssessmentCsvData] = React.useState([]);
  const [emAvStayProgCsvData, setEmAvStayProgCsvData] = React.useState([]);
  const [ltAvStayProgCsvData, setLtAvStayProgCsvData] = React.useState([]);
  const [emAdmissionsReferralCsvData, setEmAdmissionsReferralCsvData] = React.useState([]);
  const [laCCRaceCsvData, setLaCCRaceCsvData] = useState([]);
  const [laCCGenderCsvData, setLaCCGenderCsvData] = useState([])
  const [laCCEthnicityCsvData, setLaCCEthnicityCsvData] = useState([])
  const [laCCAgeCsvData, setLaCCAgeCsvData] = useState([])
  const [servicesByTypeDisplay, setServicesByTypeDisplay] = useState('')

  const handleTitleChange = (event) => {
    event.stopPropagation();
    setTitle(event.target.value);
  };

  // AccordionDetails must be open in order for the pdf to get the html content
  const handleCheckBoxChange = async (e) => {
    if (e.target.checked === true) {
      setExpanded("panel1")
      setAddToDownload(true)
      setIsHtmlCaptureReady(true)
    } else {
      setAddToDownload(false)
      setIsHtmlCaptureReady(true)
    }
  }
  useEffect(() => {
    if (isHtmlCaptureReady) {
      props.handleChange(addToDownload, props.data, title)
      setIsHtmlCaptureReady(false)
    }
    // console.log(props.data)
    if (props.data.type === 'Care Coordination' && stateLocation === "LA" && props.data.name !== 'Care Coordination Map' && 'emotional_support_relatives' in props.data?.value?.referralServicesByType?.[0]) {
      setServicesByTypeDisplay('relatives')
    } else {
      setServicesByTypeDisplay('default')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHtmlCaptureReady, props, addToDownload, props.data, title])


  const handleEditTitle = (event) => {
    event.stopPropagation();
    setEditTitle(true);
  };
  // console.log(servicesByTypeDisplay)

  const handleTitleUpdate = (event) => {
    event.stopPropagation();
    setEditTitle(false);
    props.handleSaveReport(reports, title);
    setSaved(true);
  };

  const handleSaveReport = () => {
    if (saved) {
      props.handleUnsaveReport(reports, title);
      setSaved(false);
    } else {
      props.handleSaveReport(reports, title);
    }
  };

  useEffect(() => {
    setReports(props.data);
    if (props.data.title) {
      setTitle(props.data.title);
    } else if (props.data.config.title) {
      setTitle(props.data.config.title);
    } else {
      setTitle("Report");
    }
    setSaved(props.data.saved);


    if (props.data.name === 'Location Days  >  Paid/Unpaid Days') {
      let order = { 'Paid': 1, 'Paid total': 2, 'Unpaid': 3, 'Unpaid total': 4 }
      let getPaidUnpaidCsvData = []
      let getPaidTotal = 0
      let getUnpaidTotal = 0
      props.data.value.paidunpaid?.forEach((obj) => {
        obj.children?.forEach((childrenObj) => {
          if (obj.id === 'Paid') {
            getPaidTotal += childrenObj.value
          } else {
            getUnpaidTotal += childrenObj.value
          }
          return (getPaidUnpaidCsvData.push({
            id: obj.id,
            category: obj.id,
            locationType: childrenObj.id,
            value: childrenObj.value.toLocaleString(),
          })
          )
        })
        getPaidUnpaidCsvData.push({
          id: `${obj.id} total`,
          category: 'Total',
          locationType: '',
          value: obj.id === 'Paid' ? getPaidTotal.toLocaleString() : getUnpaidTotal.toLocaleString(),
        })
      })
      getPaidUnpaidCsvData.sort((a, b) => (order[a.id] || Number.MAX_VALUE) - (order[b.id] || Number.MAX_VALUE));
      setPaidUnpaidCsvData(getPaidUnpaidCsvData)
    }

    if (props.data.name === 'Indicators  >  Indicators By Category') {
      let getIndicatorsByCategoryCsvData = []
      props.data.value.indicators?.children?.forEach((obj) => {
        getIndicatorsByCategoryCsvData.push({
          name: obj.id,
          value: '', // leave blank
        })
        obj.children?.forEach((childrenObj) => {
          return (getIndicatorsByCategoryCsvData.push({
            name: childrenObj.id,
            value: childrenObj.value.toLocaleString(),
          })
          )
        })
      })
      setIndicatorsByCategoryCsvData(getIndicatorsByCategoryCsvData)
    }
    if (
      (props.data.name === "Indicators  >  All Indicators" ||
      props.data.name === "Indicators  >  Indicator Prevalence" ||
        props.data.name === "Indicators  >  Indicator Relationship") &&
      stateLocation !== 'FL'
    ) {
      var arrayIndicators = [];
      for (let i = 0; i < props.data.value.indicators?.children.length; i++) {
        for (
          let j = 0;
          j < props.data.value.indicators?.children[i].children.length;
          j++
        ) {
          arrayIndicators.push({
            id: props.data.value.indicators.children[i].children[j].id,
            value: props.data.value.indicators.children[i].children[j].value,
          });
        }
      }
      setArrayIndicators(arrayIndicators);
    }
    if (
      props.data.name ===
      "Indicators  >  Indicator Relationship to CSE-IT Score (Clear Concern) - Male"
    ) {
      var maleArrayIndicators = [];
      for (
        let i = 0;
        i < props.data.value.indicatorImpactMale.children.length;
        i++
      ) {
        for (
          let j = 0;
          j < props.data.value.indicatorImpactMale.children[i].children.length;
          j++
        ) {
          maleArrayIndicators.push({
            id: props.data.value.indicatorImpactMale.children[i].children[j].id,
            value:
              props.data.value.indicatorImpactMale.children[i].children[j]
                .value,
          });
        }
      }
      maleArrayIndicators.sort((a, b) => b.value - a.value);
      setMaleArrayIndicators(maleArrayIndicators);
    }
    if (
      props.data.name ===
      "Indicators  >  Indicator Relationship to CSE-IT Score (Clear Concern) - Female"
    ) {
      var femaleArrayIndicators = [];
      for (
        let i = 0;
        i < props.data.value.indicatorImpactFemale.children.length;
        i++
      ) {
        for (
          let j = 0;
          j <
          props.data.value.indicatorImpactFemale.children[i].children.length;
          j++
        ) {
          femaleArrayIndicators.push({
            id: props.data.value.indicatorImpactFemale.children[i].children[j]
              .id,
            value:
              props.data.value.indicatorImpactFemale.children[i].children[j]
                .value,
          });
        }
      }
      femaleArrayIndicators.sort((a, b) => b.value - a.value);
      setFemaleArrayIndicators(femaleArrayIndicators);
    }

    if (props.data.name === "Demographics  >  Age + Race" && props.data.value.raceAgeData) {
      // let order = {
      // 'White or Caucasian': 1, 'African American or Black': 2, 'Asian': 3, 'Middle Eastern or North African': 4, 'American Indian or Alaska Native': 5, 'Native Hawaiian or Other Pacific Islander': 6, 'Other': 7, 'Unknown': 8, 'Multiracial': 9 };
      let getRaceAgeDataCsvData = []
      props.data.value.raceAgeData.forEach((obj) => {
        obj.children.forEach((childrenObj) => {
          return (getRaceAgeDataCsvData.push({
            race: obj.id,
            age: childrenObj.id,
            value: childrenObj.value.toLocaleString(),
          })
          )
        })
      })
      getRaceAgeDataCsvData.sort((a, b) => a.race - b.race);
      setRaceAgeDataCsvData(getRaceAgeDataCsvData)
    }

    if (props.data.name === "Demographics  >  Ages 25+ and Race" && props.data.value.raceAllAgeData) {
      let order = {
        'White or Caucasian': 1, 'African American or Black': 2, 'Asian': 3, 'Middle Eastern or North African': 4, 'American Indian or Alaska Native': 5, 'Native Hawaiian or Other Pacific Islander': 6, 'Other': 7, 'Unknown': 8, 'Multiracial': 9
      };
      let getRaceAllAgeDataCsvData = []
      props.data.value.raceAllAgeData.forEach((obj) => {
        obj.children.forEach((childrenObj) => {
          return (getRaceAllAgeDataCsvData.push({
            race: obj.id,
            age: childrenObj.id,
            value: childrenObj.value.toLocaleString(),
          })
          )
        })
      })
      getRaceAllAgeDataCsvData.sort((a, b) => (order[a.race] || Number.MAX_VALUE) - (order[b.race] || Number.MAX_VALUE));
      setRaceAllAgeDataCsvData(getRaceAllAgeDataCsvData)
    }

    if (props.data.name === "Demographics  >  Type of Trafficking by Race" && stateLocation === "LA") {
      let getRaceTypeCsvData = []
      props.data.value.raceType.forEach((obj) => {
        obj.children.forEach((childrenObj) => {
          return (getRaceTypeCsvData.push({
            race: obj.id,
            type: childrenObj.id,
            value: childrenObj.value.toLocaleString(),
          })
          )
        })
      })
      setRaceTypeCsvData(getRaceTypeCsvData)
    }

    const getRowsAndTotalRowCsvData = (data, keys) => {
      let getCsvData = keys.map((item) => {
        let total = 0;
        for (let i = 0; i < data.length; i++) {
          total = d3.sum(
            data.map((d) => {
              if (item.variable === d.source) {
                return d.value;
              }
            })
          );
        }
        item.total = total;
        return item;
      })
      let allTotal = d3.sum(
        getCsvData.map((d) => {
          return d.total;
        })
      );
      getCsvData.push({
        name: 'Total',
        total: allTotal,
      })
      return getCsvData
    }

    if (props.data.name === "Long Term Residential  >  Impact Assessment") {
      let csvData = getRowsAndTotalRowCsvData(props.data.value.customMeasures.ltImpactAssessmentPie, servicesdata.ltImpactAssessment)
      // console.log('csvData', csvData)
      setLtImpactAssessmentCsvData(csvData)
    }

    if (props.data.name === "Emergency Shelter  >  Admissions by Referral Source") {
      let csvData = getRowsAndTotalRowCsvData(props.data.value.customMeasures.emergencyAdmissions, servicesdata.emergencyAdmissions)
      setEmAdmissionsReferralCsvData(csvData)
    }

    const getAvStayProgCsvData = (data, keys) => {
      const propertyArrays = {};
      for (const obj of data) {
        for (const [key, val] of Object.entries(obj)) {
          // Remove these 4 props from the object
          if (key !== 'name' && key !== 'quarter' && key !== 'total' && key !== 'type') {
            // Join matching properties and turn their values into 1 array to get the d3.mean
            propertyArrays[key] = Array.of(propertyArrays[key], val).flat(1)
          }
        }
      }
      let csvData = []

      for (const prop in propertyArrays) {
        propertyArrays[prop] = propertyArrays[prop].filter((item) => {
          if (item !== 'undefined' && item !== 0) {
            return item
          }
        })
        let programName = keys.filter((obj) => obj.variable === prop)[0].name
        // console.log(programName)
        csvData.push({
          program: programName,
          average: propertyArrays[prop].length > 0 ? d3.mean(propertyArrays[prop]).toFixed(1) : 0,
        })
      }
      return csvData
    }

    if (props.data.name === "Emergency Shelter  >  Average Length of Stay by Program") {
      let getEmAvStayProgCsvData = getAvStayProgCsvData(props.data.value.customMeasures.emergencyAvStayProg, servicesdata.emergencyAvStayProg)
      setEmAvStayProgCsvData(getEmAvStayProgCsvData)
    }

    if (props.data.name === "Long Term Residential  >  Average Length of Stay by Program") {
      let getLtAvLengthProgCsvData = getAvStayProgCsvData(props.data.value.customMeasures.ltAvLengthStayProg, servicesdata.ltAvLengthStayProg)
      setLtAvStayProgCsvData(getLtAvLengthProgCsvData)
    }

    const getSingleBarDisplayNames = (dataArray, keys) => {
      let csvData = [...dataArray].map(obj => {
        obj.name = keys.filter(keyObj => keyObj.variable === obj.source)[0].name
        return obj
      })
      return csvData
    }

    if (props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Race' && props.data.value.demographicsRace) {
      let csvData = getSingleBarDisplayNames(props.data.value.demographicsRace, LACCKeys.demographicsRace)
      setLaCCRaceCsvData(csvData)
    }

    if (props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Ethnicity' && props.data.value.demographicsEthnicity) {
      let csvData = getSingleBarDisplayNames(props.data.value.demographicsEthnicity, LACCKeys.demographicsEthnicity)
      setLaCCEthnicityCsvData(csvData)
    }

    if (props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Gender Identity' && props.data.value.demographicsGender) {
      let csvData = getSingleBarDisplayNames(props.data.value.demographicsGender, LACCKeys.demographicsGender)
      setLaCCGenderCsvData(csvData)
    }

    if (props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Age' && props.data.value.demographicsAge) {
      let csvData = getSingleBarDisplayNames(props.data.value.demographicsAge, LACCKeys.demographicsAge)
      setLaCCAgeCsvData(csvData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);


  const csvForYearCharts = (keys) => {
    let csvArray = [{ label: 'Time', key: 'name' }, { label: 'Quarter', key: 'type' }].concat(keys.map((key, i) => {
      return {
        label: key.name,
        key: key.variable,

      }
    }))
    return csvArray
  }

  const handleRemoveReport = (item) => {
    props.removeReports(item);
  };
  
  return (
    <div>
      {props.data.value ? (
        <Accordion
          square
          style={{ width: "80vw", marginBottom: "10px", overflowY: 'scroll', scrollbarColor: '#fff #fff' }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="report-accordion"
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{minWidth: '900px'}}
            expandIcon={
              expanded === "panel1" ? (
                <Tooltip title="Close Preview">
                  <ExpandMoreIcon style={{ marginTop: "50px" }} />
                </Tooltip>
              ) : (
                  <Tooltip title="Open Preview">
                    <ExpandMoreIcon style={{ marginTop: "-50px" }} />
                  </Tooltip>
                )
            }
          >
            <div
              style={{
                margin: "10px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onFocus={(event) => event.stopPropagation()}
                    onClick={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        disabled={props.loading}
                        onChange={(e) => handleCheckBoxChange(e)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                  />
                  {editTitle ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <FormControl
                        fullWidth
                        onFocus={(event) => event.stopPropagation()}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Report Title"
                          name="title"
                          value={title}
                          onChange={handleTitleChange}
                        />
                      </FormControl>
                      <CheckIcon onClick={handleTitleUpdate} />
                    </div>
                  ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <h3
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          {title}
                        </h3>
                        <EditIcon onClick={handleEditTitle} />
                      </div>
                    )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    //  alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {saved ? (
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onFocus={(event) => event.stopPropagation()}
                      onClick={(event) => event.stopPropagation()}
                      control={
                        <Tooltip title="Save Report">
                          <SaveOutlinedIcon
                            style={{ color: "blue" }}
                            onClick={handleSaveReport}
                          />
                        </Tooltip>
                      }
                    />
                  ) : (
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onFocus={(event) => event.stopPropagation()}
                        onClick={(event) => event.stopPropagation()}
                        control={
                          <Tooltip title="Save Report">
                            <SaveOutlinedIcon onClick={handleSaveReport} />
                          </Tooltip>
                        }
                      />
                    )}

                  <FormControlLabel
                    aria-label="Acknowledge"
                    onFocus={(event) => event.stopPropagation()}
                    onClick={(event) => event.stopPropagation()}
                    control={
                      <Tooltip title="Delete Report">
                        <DeleteOutlineIcon
                          onClick={(e) => handleRemoveReport(props.data)}
                        />
                      </Tooltip>
                    }
                  />
                </div>
              </div>

              <div>
                <div
                  className="report-filter-titles"
                  style={{
                    marginLeft: "48px",
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ minWidth: '40%', display: "flex", alignItems: "center" }}>
                    <h3>
                      {props.data.type} &gt; {props.data.name}:
                    </h3>
                  </div>
                  {props.data.name === "News Map" ? (
                    <div>
                      <p>All News</p>
                    </div>
                  ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {props.data.config.map((item, key) => {
                          //  console.log('item:', item)
                          if (
                            item.filter === props.data.type &&
                            item.value &&
                            (item.stateLocation === stateLocation ||
                              !item.stateLocation)
                          ) {
                            return (
                              <p key={key}>
                                {item.name.trim()}: {item.value.join(", ")}
                              </p>
                            );
                          }
                        })}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div style={{ width: '100%' }}> {/* "60vw" */}
              {/* Map */}
              {props.data.name?.split(' ')[props.data.name?.split(' ')?.length - 1] === 'Map' &&
                <div id={props.data.id} style={{width: '60vw'}}>
                  <div>
                    <Map
                      data={props.data.value}
                      report={true}
                    charts={props.data.name.replace(' Map', '')}
                      filters={props.data.config}
                      id={props.data.id}
                    />
                  </div>
                </div>
              }


              {/* CSEY Advocacy */}
              {props.data.name === "CSEY Advocacy  >  Total Organizations" ? (
                <div id={props.data.id}>
                  <div>
                    <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                    <p>
                      {
                        props.data.value.orgList.filter(
                          (item) => item.org_type === "CSEY Advocacy"
                        ).length
                      }
                    </p>
                    <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                    <p>
                      {[
                        ...new Set(
                          props.data.value.orgList.map((item) => {
                            if (item.org_type === "CSEY Advocacy") {
                              return item.organization;
                            }
                          })
                        ),
                      ].length - 1}
                    </p>
                    <OrgList
                      data={props.data.value.orgList}
                      title="CSEY Advocacy"
                      type="CSEY Advocacy  >  Total Organizations"
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.orgList.filter(
                        (item) => item.org_type === "CSEY Advocacy"
                      )}
                      headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: 'County', key: 'county' }, { label: 'Region', key: 'region' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                </div>
              ) : null}
              {props.data.name ===
                "CSEY Advocacy  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "CSEY Advocacy") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {props.data.name === "CSEY Advocacy  >  Individuals Served" ? (
                <div>
                  <GroupedBarGraph
                    keys={servicesdata.advocacyTotalServed}
                    data={
                      props.data.value.customMeasures.advocacyTotalServed
                        ? props.data.value.customMeasures.advocacyTotalServed
                        : servicesmockdata.advocacyTotalServed
                    }
                    xaxis="Time (Q and FY)"
                    yaxis="Number of Individuals"
                    percentLine={props.data.chartLine}
                    percentLegend="Percentage of New Individuals"
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.customMeasures.advocacyTotalServed}
                    name={props.data.name}
                    keys={servicesdata.advocacyTotalServed}
                    id={`${props.data.id}-table`}
                  />
                  <CSVLink
                    data={props.data.value.customMeasures.advocacyTotalServed}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "CSEY Advocacy  >  New Individuals Served (by Age)" ? (
                  <div>
                    <GroupedBarGraph
                      keys={servicesdata.advocacyAgeServed}
                      data={
                        props.data.value.customMeasures.advocacyAgeServed
                          ? props.data.value.customMeasures.advocacyAgeServed
                          : servicesmockdata.advocacyAgeServed
                      }
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      yaxisMaxValue={true}
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.advocacyAgeServed}
                      name={props.data.name}
                      keys={servicesdata.advocacyAgeServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.advocacyAgeServed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "CSEY Advocacy  >  Referral Sources" ? (
                <div>
                  <SingleBarGraph
                    xaxis="Referral Source"
                    yaxis="Number of Referrals"
                    type="cc"
                    keys={servicesdata.advocacyReferrals}
                    data={
                      props.data.value.customMeasures.advocacyReferrals
                        ? props.data.value.customMeasures.advocacyReferrals
                        : servicesmockdata.advocacyReferrals
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.customMeasures.advocacyReferrals}
                    name={props.data.name}
                    keys={servicesdata.advocacyReferrals}
                    id={`${props.data.id}-table`}
                    single={true}
                  />
                  <CSVLink
                    data={props.data.value.customMeasures.advocacyReferrals}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "CSEY Advocacy  >  Referrals Over Time" ? (
                <div>
                  <StackedBarGraph
                    xaxis="Time (Q and FY)"
                    yaxis="Number of Referrals"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Referrals"
                    keys={servicesdata.advocacyReferralSources}
                    data={
                      props.data.value.customMeasures.advocacyReferralSources
                        ? props.data.value.customMeasures
                          .advocacyReferralSources
                        : servicesmockdata.advocacyReferralSources
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={
                      props.data.value.customMeasures.advocacyReferralSources
                    }
                    name={props.data.name}
                    keys={servicesdata.advocacyReferralSources}
                    id={`${props.data.id}-table`}
                  />
                  <CSVLink
                    data={
                      props.data.value.customMeasures.advocacyReferralSources
                    }
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "CSEY Advocacy  >  Exiting Services by Reason" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Individuals"
                      keys={servicesdata.advocacyEngagement}
                      data={
                        props.data.value.customMeasures.advocacyEngagement
                          ? props.data.value.customMeasures.advocacyEngagement
                          : servicesmockdata.advocacyEngagement
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.advocacyEngagement}
                      name={props.data.name}
                      keys={servicesdata.advocacyEngagement}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.advocacyEngagement}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "CSEY Advocacy  >  Responses to Crisis Recoveries" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Responses"
                      keys={servicesdata.advocacyCrisis}
                      data={
                        props.data.value.customMeasures.advocacyCrisis
                          ? props.data.value.customMeasures.advocacyCrisis
                          : servicesmockdata.advocacyCrisis
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.advocacyCrisis}
                      name={props.data.name}
                      keys={servicesdata.advocacyCrisis}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.advocacyCrisis}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* Bringing Justice */}
              {props.data.name ===
                "Bringing Justice  >  Total Organizations" ? (
                  <div id={props.data.id}>
                    <div>
                      <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                      <p>
                        {
                          props.data.value.orgList.filter(
                            (item) => item.org_type === "Bringing Justice"
                          ).length
                        }
                      </p>
                      <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                      <p>
                        {[
                          ...new Set(
                            props.data.value.orgList.map((item) => {
                              if (item.org_type === "Bringing Justice") {
                                return item.organization;
                              }
                            })
                          ),
                        ].length - 1}
                      </p>

                      <OrgList
                        data={props.data.value.orgList}
                        title="Bringing Justice"
                        type="Bringing Justice  >  Total Organizations"
                        id={`${props.data.id}-table`}
                      />
                      <CSVLink
                        data={props.data.value.orgList.filter(
                          (item) => item.org_type === "Bringing Justice"
                        )}
                        headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: 'County', key: 'county' }, { label: 'Region', key: 'region' }]}
                        target="_blank"
                        filename={`${props.data.name}-data.csv`}
                        style={{ marginTop: ".5em", color: "gray" }}
                      ><button>Download Table Data</button></CSVLink>
                    </div>
                  </div>
                ) : null}
              {props.data.name ===
                "Bringing Justice  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "Bringing Justice") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {/* DropIn Center */}
              {props.data.name === "Drop-In Center  >  Total Organizations" ? (
                <div id={props.data.id}>
                  <div>
                    <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                    <p>
                      {
                        props.data.value.orgList.filter(
                          (item) => item.org_type === "Drop-In Center"
                        ).length
                      }
                    </p>
                    <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                    <p>
                      {[
                        ...new Set(
                          props.data.value.orgList.map((item) => {
                            if (item.org_type === "Drop-In Center") {
                              return item.organization;
                            }
                          })
                        ),
                      ].length - 1}
                    </p>

                    <OrgList
                      data={props.data.value.orgList}
                      title="Drop-In Center"
                      type="Drop-In Center  >  Total Organizations"
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.orgList.filter(
                        (item) => item.org_type === "Drop-In Center"
                      )}
                      headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: 'County', key: 'county' }, { label: 'Region', key: 'region' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    ><button>Download Table Data</button></CSVLink>
                  </div>
                </div>
              ) : null}
              {props.data.name ===
                "Drop-In Center  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "Drop-In Center") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {props.data.name === "Drop-In Center  >  Individuals Served" ? (
                <div>
                  <GroupedBarGraph
                    keys={servicesdata.dropinTotalServed}
                    data={
                      props.data.value.customMeasures.dropinTotalServed
                        ? props.data.value.customMeasures.dropinTotalServed
                        : servicesmockdata.dropinTotalServed
                    }
                    xaxis="Time (Q and FY)"
                    yaxis="Number of Individuals"
                    percentLine={props.data.chartLine}
                    percentLegend="Percentage of New Individuals"
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.customMeasures.dropinTotalServed}
                    name={props.data.name}
                    keys={servicesdata.dropinTotalServed}
                    id={`${props.data.id}-table`}
                  />
                  <CSVLink
                    data={props.data.value.customMeasures.dropinTotalServed}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Drop-In Center  >  New Individuals Served (by Age)" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      yaxisMaxValue={true}
                      keys={servicesdata.dropinAgeServed}
                      data={
                        props.data.value.customMeasures.dropinAgeServed
                          ? props.data.value.customMeasures.dropinAgeServed
                          : servicesmockdata.dropinAgeServed
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.dropinAgeServed}
                      name={props.data.name}
                      keys={servicesdata.dropinAgeServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.dropinAgeServed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Drop-In Center  >  Referral Sources" ? (
                <div>
                  <SingleBarGraph
                    xaxis="Referral Source"
                    yaxis="Number of Referrals"
                    type="cc"
                    keys={servicesdata.dropinReferrals}
                    data={
                      props.data.value.customMeasures.dropinReferrals
                        ? props.data.value.customMeasures.dropinReferrals
                        : servicesmockdata.dropinReferrals
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.customMeasures.dropinReferrals}
                    name={props.data.name}
                    keys={servicesdata.dropinReferrals}
                    id={`${props.data.id}-table`}
                    single={true}
                  />
                  <CSVLink
                    data={props.data.value.customMeasures.dropinReferrals}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Drop-In Center  >  Referrals Over Time" ? (
                <div>
                  <StackedBarGraph
                    xaxis="Time (Q and FY)"
                    yaxis="Number of Referrals"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Referrals"
                    keys={servicesdata.dropinReferralsArray}
                    data={
                      props.data.value.customMeasures.dropinReferralsArray
                        ? props.data.value.customMeasures.dropinReferralsArray
                        : servicesmockdata.dropinReferralsArray
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.customMeasures.dropinReferralsArray}
                    name={props.data.name}
                    keys={servicesdata.dropinReferralsArray}
                    id={`${props.data.id}-table`}
                  />
                  <CSVLink
                    data={props.data.value.customMeasures.dropinReferralsArray}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Drop-In Center  >  Connecting with Individuals" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Individuals"
                      keys={servicesdata.dropinConnecting}
                      data={
                        props.data.value.customMeasures.dropinConnecting
                          ? props.data.value.customMeasures.dropinConnecting
                          : servicesmockdata.dropinConnecting
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.dropinConnecting}
                      name={props.data.name}
                      keys={servicesdata.dropinConnecting}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.dropinConnecting}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Drop-In Center  >  Connecting and Total Served" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      percentLine={props.data.chartLine}
                      percentLegend="Percent Connecting"
                      keys={servicesdata.dropinConnectingServed}
                      data={
                        props.data.value.customMeasures.dropinConnectingServed
                          ? props.data.value.customMeasures.dropinConnectingServed
                          : servicesmockdata.dropinConnectingServed
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={
                        props.data.value.customMeasures.dropinConnectingServed
                      }
                      name={props.data.name}
                      keys={servicesdata.dropinConnectingServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={
                        props.data.value.customMeasures.dropinConnectingServed
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Drop-In Center  >  Extended Safety" ? (
                <div>
                  <GroupedBarGraph
                    xaxis="Time (Q and FY)"
                    yaxis="Number of Individuals"
                    keys={servicesdata.dropinPlacement}
                    data={
                      props.data.value.customMeasures.dropinPlacement
                        ? props.data.value.customMeasures.dropinPlacement
                        : servicesmockdata.dropinPlacement
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.customMeasures.dropinPlacement}
                    name={props.data.name}
                    keys={servicesdata.dropinPlacement}
                    id={`${props.data.id}-table`}
                  />
                  <CSVLink
                    data={props.data.value.customMeasures.dropinPlacement}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Drop-In Center  >  Extended Safety and Total Served" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      keys={servicesdata.dropinReferredServed}
                      percentLine={props.data.chartLine}
                      percentLegend="Percentage Reaching Extended Safety"
                      data={
                        props.data.value.customMeasures.dropinReferredServed
                          ? props.data.value.customMeasures.dropinReferredServed
                          : servicesmockdata.dropinReferredServed
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.dropinReferredServed}
                      name={props.data.name}
                      keys={servicesdata.dropinReferredServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.dropinReferredServed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* Emergency Center */}
              {props.data.name ===
                "Emergency Shelter  >  Total Organizations" ? (
                  <div id={props.data.id}>
                    <div>
                      <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                      <p>
                        {
                          props.data.value.orgList.filter(
                            (item) => item.org_type === "Emergency Shelter"
                          ).length
                        }
                      </p>
                      <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                      <p>
                        {[
                          ...new Set(
                            props.data.value.orgList.map((item) => {
                              if (item.org_type === "Emergency Shelter") {
                                return item.organization;
                              }
                            })
                          ),
                        ].length - 1}
                      </p>

                      <OrgList
                        data={props.data.value.orgList}
                        title="Emergency Shelter"
                        type="Emergency Shelter  >  Total Organizations"
                        id={`${props.data.id}-table`}
                      />

                      <CSVLink
                        data={props.data.value.orgList.filter(
                          (item) => item.org_type === "Emergency Shelter"
                        )}
                        headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: 'County', key: 'county' }, { label: 'Region', key: 'region' }]}
                        target="_blank"
                        filename={`${props.data.name}-data.csv`}
                        style={{ marginTop: ".5em", color: "gray" }}
                      ><button>Download Table Data</button></CSVLink>
                    </div>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "Emergency Shelter") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Individuals Served" ? (
                  <div>
                    <GroupedBarGraph
                      keys={servicesdata.emergencyIndividualServed}
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      percentLine={props.data.chartLine}
                      percentLegend="Percentage of New Individuals"
                      data={
                        props.data.value.customMeasures.emergencyIndividualServed
                          ? props.data.value.customMeasures
                            .emergencyIndividualServed
                          : servicesmockdata.emergencyIndividualServed
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={
                        props.data.value.customMeasures.emergencyIndividualServed
                      }
                      name={props.data.name}
                      keys={servicesdata.emergencyIndividualServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={
                        props.data.value.customMeasures.emergencyIndividualServed
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  New Individuals Served (by Age)" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      yaxisMaxValue={true}
                      data={
                        props.data.value.customMeasures.emergencyAgeServed
                          ? props.data.value.customMeasures.emergencyAgeServed
                          : servicesmockdata.emergencyAgeServed
                      }
                      keys={servicesdata.emergencyAgeServed}
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.emergencyAgeServed}
                      name={props.data.name}
                      keys={servicesdata.emergencyAgeServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.emergencyAgeServed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Emergency Shelter  >  Referral Sources" ? (
                <div>
                  <SingleBarGraph
                    xaxis="Referral Source"
                    yaxis="Number of Referrals"
                    keys={servicesdata.emergencyReferrals}
                    type="cc"
                    data={
                      props.data.value.customMeasures.emergencyReferrals
                        ? props.data.value.customMeasures.emergencyReferrals
                        : servicesmockdata.emergencyReferrals
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.customMeasures.emergencyReferrals}
                    name={props.data.name}
                    keys={servicesdata.emergencyReferrals}
                    id={`${props.data.id}-table`}
                    single={true}
                  />
                  <CSVLink
                    data={props.data.value.customMeasures.emergencyReferrals}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Referrals Over Time" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Referrals"
                      keys={servicesdata.emergencyReferralsArray}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Referrals"
                      data={
                        props.data.value.customMeasures.emergencyReferralsArray
                          ? props.data.value.customMeasures
                            .emergencyReferralsArray
                          : servicesmockdata.emergencyReferralsArray
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={
                        props.data.value.customMeasures.emergencyReferralsArray
                      }
                      name={props.data.name}
                      keys={servicesdata.emergencyReferralsArray}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={
                        props.data.value.customMeasures.emergencyReferralsArray
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Admissions by Referral Source" ? (
                  <div>
                    <SingleBarGraph
                      xaxis="Referral Source"
                      yaxis="Number of Admissions"
                      keys={servicesdata.emergencyAdmissions}
                      type="cc"
                      data={
                        props.data.value.customMeasures.emergencyAdmissions
                          ? props.data.value.customMeasures.emergencyAdmissions
                          : servicesmockdata.emergencyAdmissions
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.emergencyAdmissions}
                      name={props.data.name}
                      keys={servicesdata.emergencyAdmissions}
                      id={`${props.data.id}-table`}
                      single={true}
                    />
                    <CSVLink
                      data={emAdmissionsReferralCsvData && emAdmissionsReferralCsvData}
                      headers={[{ label: `${props.data.name}`, key: 'name' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Admissions by Referral Source (over time)" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Admissions"
                      keys={servicesdata.emergencyAdmissionsArray}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Admissions"
                      data={
                        props.data.value.customMeasures.emergencyAdmissionsArray
                          ? props.data.value.customMeasures
                            .emergencyAdmissionsArray
                          : servicesmockdata.emergencyAdmissionsArray
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={
                        props.data.value.customMeasures.emergencyAdmissionsArray
                      }
                      name={props.data.name}
                      keys={servicesdata.emergencyAdmissionsArray}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={
                        props.data.value.customMeasures.emergencyAdmissionsArray
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Total Admissions and Referrals" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Total Admissions and Referrals"
                      keys={servicesdata.emergencyTotalAdRef}
                      percentLine={props.data.chartLine}
                      percentLegend="Percentage Admitted"
                      data={
                        props.data.value.customMeasures.emergencyTotalAdRef
                          ? props.data.value.customMeasures.emergencyTotalAdRef
                          : servicesmockdata.emergencyTotalAdRef
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.emergencyTotalAdRef}
                      name={props.data.name}
                      keys={servicesdata.emergencyTotalAdRef}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.emergencyTotalAdRef}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Average Length of Stay" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Days"
                      keys={servicesdata.emergencyAvStay}
                      data={
                        props.data.value.customMeasures.emergencyAvStay
                          ? props.data.value.customMeasures.emergencyAvStay
                          : servicesmockdata.emergencyAvStay
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      getAverageTotal
                      data={props.data.value.customMeasures.emergencyAvStay}
                      name={props.data.name} // props.data.name
                      keys={servicesdata.emergencyAvStay}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={[{
                        name: 'Average',
                        total: d3.mean(props.data.value.customMeasures.emergencyAvStay.map((d) => {
                          if (d.total !== 0) { return Number(d.total) }
                        })).toFixed(1)
                      }]}
                      headers={[{ label: `${props.data.name}`, key: 'name' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Average Length of Stay by Program" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Average Length of Stay"
                      keys={servicesdata.emergencyAvStayProg}
                      yaxisMaxValue={true}
                      data={
                        props.data.value.customMeasures.emergencyAvStayProg
                          ? props.data.value.customMeasures.emergencyAvStayProg
                          : servicesmockdata.emergencyAvStayProg
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <AvStayProgramTable
                      data={props.data.value.customMeasures.emergencyAvStayProg}
                      name={props.data.name}
                      keys={servicesdata.emergencyAvStayProg}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={emAvStayProgCsvData && emAvStayProgCsvData}
                      headers={[{ label: 'Program', key: 'program' }, { label: 'Average', key: 'average' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Emergency Shelter  >  Planned/Unplanned Discharges by Type" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of individuals at time of discharge"
                      keys={servicesdata.emergencyDischarge}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Individuals"
                      data={
                        props.data.value.customMeasures.emergencyDischarge
                          ? props.data.value.customMeasures.emergencyDischarge
                          : servicesmockdata.emergencyDischarge
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.emergencyDischarge}
                      name={props.data.name}
                      keys={servicesdata.emergencyDischarge}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.emergencyDischarge}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* Innovative Services */}
              {props.data.name ===
                "Innovative Services  >  Total Organizations" ? (
                  <div id={props.data.id}>
                    <div>
                      <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                      <p>
                        {
                          props.data.value.orgList.filter(
                            (item) => item.org_type === "Innovative Services"
                          ).length
                        }
                      </p>
                      <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                      <p>
                        {[
                          ...new Set(
                            props.data.value.orgList.map((item) => {
                              if (item.org_type === "Innovative Services") {
                                return item.organization;
                              }
                            })
                          ),
                        ].length - 1}
                      </p>

                      <OrgList
                        data={props.data.value.orgList}
                        title="Innovative Services"
                        type="Innovative Services  >  Total Organizations"
                        id={`${props.data.id}-table`}
                      />
                      <CSVLink
                        data={props.data.value.orgList.filter(
                          (item) => item.org_type === "Innovative Services"
                        )}
                        headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: stateLocation === 'TX' ? 'County' : 'Parish', key: 'county' }, { label: 'Region', key: 'region' }]}
                        target="_blank"
                        filename={`${props.data.name}-data.csv`}
                        style={{ marginTop: ".5em", color: "gray" }}
                      ><button>Download Table Data</button></CSVLink>
                    </div>
                  </div>
                ) : null}
              {props.data.name ===
                "Innovative Services  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "Innovative Services") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {/* Longterm Measures */}
              {props.data.name ===
                "Long Term Residential  >  Total Organizations" ? (
                  <div id={props.data.id}>
                    <div>
                      <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                      <p>
                        {
                          props.data.value.orgList.filter(
                            (item) => item.org_type === "Long Term Residential"
                          ).length
                        }
                      </p>
                      <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                      <p>
                        {[
                          ...new Set(
                            props.data.value.orgList.map((item) => {
                              if (item.org_type === "Long Term Residential") {
                                return item.organization;
                              }
                            })
                          ),
                        ].length - 1}
                      </p>

                      <OrgList
                        data={props.data.value.orgList}
                        title="Long Term Residential"
                        type="Long Term Residential  >  Total Organizations"
                        id={`${props.data.id}-table`}
                      />
                      <CSVLink
                        data={props.data.value.orgList.filter(
                          (item) => item.org_type === "Long Term Residential"
                        )}
                        headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: 'County', key: 'county' }, { label: 'Region', key: 'region' }]}
                        target="_blank"
                        filename={`${props.data.name}-data.csv`}
                        style={{ marginTop: ".5em", color: "gray" }}
                      ><button>Download Table Data</button></CSVLink>
                    </div>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "Long Term Residential") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Individuals Served" ? (
                  <div>
                    <GroupedBarGraph
                      keys={servicesdata.ltIndividualsServed}
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      percentLine={props.data.chartLine}
                      percentLegend="Percentage of New Individuals"
                      data={
                        props.data.value.customMeasures.ltIndividualsServed
                          ? props.data.value.customMeasures.ltIndividualsServed
                          : servicesmockdata.ltIndividualsServed
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltIndividualsServed}
                      name={props.data.name}
                      keys={servicesdata.ltIndividualsServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltIndividualsServed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  New Individuals Served (by Age)" ? (
                  <div>
                    <GroupedBarGraph
                      keys={servicesdata.ltAgeServed}
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      yaxisMaxValue={true}
                      data={
                        props.data.value.customMeasures.ltAgeServed
                          ? props.data.value.customMeasures.ltAgeServed
                          : servicesmockdata.ltAgeServed
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltAgeServed}
                      name={props.data.name}
                      keys={servicesdata.ltAgeServed}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltAgeServed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Referral Sources" ? (
                  <div>
                    <SingleBarGraph
                      xaxis="Referral Source"
                      yaxis="Number of Referrals"
                      keys={servicesdata.ltReferrals}
                      type="cc"
                      data={
                        props.data.value.customMeasures.ltReferrals
                          ? props.data.value.customMeasures.ltReferrals
                          : servicesmockdata.ltReferrals
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltReferrals}
                      name={props.data.name}
                      keys={servicesdata.ltReferrals}
                      id={`${props.data.id}-table`}
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltReferrals}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Referrals Over Time" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Referrals"
                      keys={servicesdata.ltReferralsArray}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Referrals"
                      data={
                        props.data.value.customMeasures.ltReferralsArray
                          ? props.data.value.customMeasures.ltReferralsArray
                          : servicesmockdata.ltReferralsArray
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltReferralsArray}
                      name={props.data.name}
                      keys={servicesdata.ltReferralsArray}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltReferralsArray}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Admissions by Referral Source" ? (
                  <div>
                    <SingleBarGraph
                      xaxis="Referral Source"
                      yaxis="Number of Admissions"
                      keys={servicesdata.ltAdmissions}
                      type="cc"
                      data={
                        props.data.value.customMeasures.ltAdmissions
                          ? props.data.value.customMeasures.ltAdmissions
                          : servicesmockdata.ltAdmissions
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltAdmissions}
                      name={props.data.name}
                      keys={servicesdata.ltAdmissions}
                      id={`${props.data.id}-table`}
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltAdmissions}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Admissions by Referral Source (over time)" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Admissions"
                      keys={servicesdata.ltAdmissionsArray}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Admissions"
                      data={
                        props.data.value.customMeasures.ltAdmissionsArray
                          ? props.data.value.customMeasures.ltAdmissionsArray
                          : servicesmockdata.ltAdmissionsArray
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltAdmissionsArray}
                      name={props.data.name}
                      keys={servicesdata.ltAdmissionsArray}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltAdmissionsArray}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Total Admissions and Referrals" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Total Admissions and Referrals"
                      keys={servicesdata.ltTotalAdmissionsReferral}
                      percentLine={props.data.chartLine}
                      percentLegend="Percentage Admitted"
                      data={
                        props.data.value.customMeasures.ltTotalAdmissionsReferral
                          ? props.data.value.customMeasures
                            .ltTotalAdmissionsReferral
                          : servicesmockdata.ltTotalAdmissionsReferral
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={
                        props.data.value.customMeasures.ltTotalAdmissionsReferral
                      }
                      name={props.data.name}
                      keys={servicesdata.ltTotalAdmissionsReferral}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={
                        props.data.value.customMeasures.ltTotalAdmissionsReferral
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Average Length of Stay" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Days"
                      keys={servicesdata.ltAvLengthStay}
                      type="cc"
                      data={
                        props.data.value.customMeasures.ltAvLengthStay
                          ? props.data.value.customMeasures.ltAvLengthStay
                          : servicesmockdata.ltAvLengthStay
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      getAverageTotal
                      data={props.data.value.customMeasures.ltAvLengthStay}
                      name={props.data.name}
                      keys={servicesdata.ltAvLengthStay}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={[{
                        name: 'Average',
                        total: d3.mean(props.data.value.customMeasures.ltAvLengthStay.map((d) => {
                          if (d.total !== 0) { return Number(d.total) }
                        })).toFixed(1)
                      }]} // props.data.value.customMeasures.ltAvLengthStay
                      headers={[{ label: `${props.data.name}`, key: 'name' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Average Length of Stay by Program" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Average Length of Stay"
                      yaxisMaxValue={true}
                      keys={servicesdata.ltAvLengthStayProg}
                      data={
                        props.data.value.customMeasures.ltAvLengthStayProg
                          ? props.data.value.customMeasures.ltAvLengthStayProg
                          : servicesmockdata.ltAvLengthStayProg
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <AvStayProgramTable
                      data={props.data.value.customMeasures.ltAvLengthStayProg}
                      name={props.data.name}
                      keys={servicesdata.ltAvLengthStayProg}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={ltAvStayProgCsvData && ltAvStayProgCsvData}
                      headers={[{ label: 'Program', key: 'program' }, { label: 'Average', key: 'average' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Planned/Unplanned Discharges by Type" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of individuals at time of discharge"
                      keys={servicesdata.ltDischarge}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Individuals"
                      data={
                        props.data.value.customMeasures.ltDischarge
                          ? props.data.value.customMeasures.ltDischarge
                          : servicesmockdata.ltDischarge
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltDischarge}
                      name={props.data.name}
                      keys={servicesdata.ltDischarge}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltDischarge}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Impact Assessment" ? (
                  <div>
                    <PieGraph
                      // keys={servicesdata.ltImpactAssessment}
                      data={props.data.value.customMeasures.ltImpactAssessmentPie ?? []}
                      sourceNameVar='source'
                      value="Long Term Residential"
                      id={props.data.id}
                      report
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltImpactAssessmentPie}
                      name={props.data.name}
                      keys={servicesdata.ltImpactAssessment}
                      id={`${props.data.id}-table`}
                      single
                    />
                    <CSVLink
                      data={ltImpactAssessmentCsvData && ltImpactAssessmentCsvData}
                      headers={[{ label: `${props.data.name}`, key: 'name' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Long Term Residential  >  Impact Assessment (over time)" ? (
                  <div>
                    <StackedBarGraph
                      data={props.data.value.customMeasures.ltImpactAssessment}
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Individuals"
                      keys={servicesdata.ltImpactAssessment}
                      trendLine={props.data.chartLine}
                      report={true}
                      trendLineLegend="Total Individuals"
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.customMeasures.ltImpactAssessment}
                      name={props.data.name}
                      keys={servicesdata.ltImpactAssessment}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.customMeasures.ltImpactAssessment}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* Other Measures */}
              {props.data.name === "Other  >  Total Organizations" ? (
                  <div>
                  <div id={props.data.id}>
                    <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                    <p>
                      {
                        props.data.value.orgList.filter(
                          (item) => item.org_type === "Other"
                        ).length
                      }
                    </p>
                    <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                    <p>
                      {[
                        ...new Set(
                          props.data.value.orgList.map((item) => {
                            if (item.org_type === "Other") {
                              return item.organization;
                            }
                          })
                        ),
                      ].length - 1}
                    </p>
                  </div>
                    <OrgList
                      data={props.data.value.orgList}
                      title="Other"
                      type="Other  >  Total Organizations"
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.orgList.filter(
                        (item) => item.org_type === "Other"
                      )}
                      headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: stateLocation === 'TX' ? 'County' : 'Parish', key: 'county' }, { label: 'Region', key: 'region' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    ><button>Download Table Data</button></CSVLink>
                </div>
              ) : null}
              {props.data.name === "Other  >  Governor's Office Funding" ? (
                <div id={props.data.id}>
                  <div className={classes.data}>
                    <h3 style={{ margin: "0" }}>Total Budget</h3>
                    <p>
                      $
                      {Math.round(
                      d3.sum(
                        props.data.value.allGrantees.map((item) => {
                          if (item.org_type === "Other") {
                            return Number(
                              item.budget.replace(/[^0-9.-]+/g, "")
                            );
                          }
                        })
                      )
                    )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              ) : null}
              {/* Prevention Measures */}
              {props.data.name === "Prevention  >  Total Organizations" ? (
                  <div>
                  <div id={props.data.id}>
                    <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                    <p>
                      {
                        props.data.value.orgList.filter(
                          (item) => item.org_type === "Prevention"
                        ).length
                      }
                    </p>
                    <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                    <p>
                      {[
                        ...new Set(
                          props.data.value.orgList.map((item) => {
                            if (item.org_type === "Prevention") {
                              return item.organization;
                            }
                          })
                        ),
                      ].length - 1}
                    </p>
                  </div>
                    <OrgList
                      data={props.data.value.orgList}
                      title="Prevention"
                      type="Prevention  >  Total Organizations"
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.orgList.filter(
                        (item) => item.org_type === "Prevention"
                      )}
                      headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: stateLocation === 'TX' ? 'County' : 'Parish', key: 'county' }, { label: 'Region', key: 'region' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    ><button>Download Table Data</button></CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Prevention  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "Prevention") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {/* Therapy/Counseling Measures */}
              {props.data.name ===
                "Therapy/Counseling  >  Total Organizations" ? (
                  <div>
                    <div id={props.data.id}>
                      <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                      <p>
                        {
                          props.data.value.orgList.filter(
                            (item) => item.org_type === "Therapy/Counseling"
                          ).length
                        }
                      </p>
                      <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                      <p>
                        {[
                          ...new Set(
                            props.data.value.orgList.map((item) => {
                              if (item.org_type === "Therapy/Counseling") {
                                return item.organization;
                              }
                            })
                          ),
                        ].length - 1}
                      </p>
                    </div>
                      <OrgList
                        data={props.data.value.orgList}
                        title="Therapy/Counseling"
                        type="Therapy/Counseling  >  Total Organizations"
                        id={`${props.data.id}-table`}
                      />
                      <CSVLink
                        data={props.data.value.orgList.filter(
                          (item) => item.org_type === "Therapy/Counseling"
                        )}
                        headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Grantee Fiscal Year', key: 'grantee_fiscal_year' }, { label: stateLocation === 'TX' ? 'County' : 'Parish', key: 'county' }, { label: 'Region', key: 'region' }]}
                        target="_blank"
                        filename={`${props.data.name}-data.csv`}
                        style={{ marginTop: ".5em", color: "gray" }}
                      ><button>Download Table Data</button></CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Therapy/Counseling  >  Governor's Office Funding" ? (
                  <div id={props.data.id}>
                    <div className={classes.data}>
                      <h3 style={{ margin: "0" }}>Total Budget</h3>
                      <p>
                        $
                      {Math.round(
                        d3.sum(
                          props.data.value.allGrantees.map((item) => {
                            if (item.org_type === "Therapy/Counseling") {
                              return Number(
                                item.budget.replace(/[^0-9.-]+/g, "")
                              );
                            }
                          })
                        )
                      )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                ) : null}
              {/* {props.data.name === "OVC/OTIP Grantees  >  Organizations" ? (
              <div id={props.data.id}>
                <div className={classes.data}>
                <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                <p>{props.data.value.orgList.length}</p>
                <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                <p>{[...new Set(props.data.value.orgList.map((item) => {
                   return item.organization;
                  }))].length - 1}</p>
                <OVCTable 
                  data={props.data.value.ovcList}
                  title="Organizations"
                  type="OVC/OTIP Grantees  >  Organizations" id={`${props.data.id}-table`}/>
                </div>
              </div>
            ) : null} */}
              {/* National Referral Directory */}
              {props.data.name === "National Referral Directory  >  Organizations" ? (
                <div>
                  <div id={props.data.id}>
                    <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                    <p>{props.data.value.nrdAreasServed.length}</p>
                    <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                    <p>{props.data.value.nrdTotalOrgs.length}
                    </p>
                  </div>
                    <OrgList
                      data={props.data.value.nrdAreasServed}
                      columnsToShow={['provider type', 'city']}
                      title="National Referral Directory"
                      type="National Referral Directory  >  Total Organizations"
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.nrdAreasServed}
                      headers={[{ label: 'Organization Name', key: 'organization' }, { label: 'Provider type', key: 'provider_type' }, { label: 'City', key: 'city' }, { label: stateLocation === 'TX' ? 'County' : 'Parish', key: 'county' }, { label: 'Region', key: 'region' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    ><button>Download Table Data</button></CSVLink>
                  
                </div>
              ) : null}
              {/* Services / Louisiana Measures */}
              {props.data.name === "Organizations  >  Total Organizations" ? ( // LA/FL
                <div>
                  <div id={props.data.id}>
                    <h3 style={{ margin: "0" }}>Total Areas Served</h3>
                    <p>{stateLocation === 'LA' ? props.data.value?.orgList?.length : props.data.value?.totalAreasServed?.length ?? 0}</p>
                    <h3 style={{ margin: "0" }}>Total Unique Organizations</h3>
                    <p>
                      {stateLocation === 'LA' ? [...new Set(props.data.value?.orgList?.map((item) => item.organization))]?.length
                        : 
                        props.data.value?.totalUniqueOrgs?.length ?? 0
                      }
                    </p>
                  </div>
                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      title='Service Provider Organizations'
                      data={props.data.value.orgList ?? []}
                      noTotalRow
                      evenColumns
                      headers={['Organization Name',
                        stateLocation === 'LA' ? 'Organization Type' : 'Service Category',
                        stateLocation === 'LA' ? 'Parish' : 'County',
                        'Region']}
                          columnKeys={['organization', 'org_type', 'county', 'region']}
                      />
                    <CSVLink
                      data={props.data.value.orgList}
                      headers={[{ label: 'Organization Name', key: 'organization' }, { label: stateLocation === 'LA' ? 'Organization Type' : 'Service Category', key: 'org_type' }, { label: stateLocation === 'LA' ? 'Parish' : 'County', key: 'county' }, { label: 'Region', key: 'region' }]
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    ><button>Download Table Data</button></CSVLink>
                </div>
              ) : null}

              {props.data.name ===
                "Client Information  >  Individuals Served" ? (
                  <div>
                    <GroupedBarVariableXGraph
                      data={props.data.value.individualsServed}
                      keys={laservicesdata.individualsServed}
                      xaxis="Year"
                      yaxis="Number of Individuals"
                      percentLine={props.data.chartLine}
                      percentLegend="Percentage of New Individuals"
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.individualsServed}
                      type="Client Information  >  Individuals Served"
                      name={props.data.name}
                      id={`${props.data.id}-table`}
                      keys={laservicesdata.individualsServed}
                      totalIndividuals={true}
                    />
                    <CSVLink
                      data={props.data.value.individualsServed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Client Information  >  Victim Status" ? (
                <div>
                  <StackedBarVariableXAxis
                    data={props.data.value.victimStatus}
                    xaxis="Year"
                    yaxis="Number of Individuals"
                    keys={laservicesdata.victimStatus}
                    report={true}
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Individuals"
                    id={props.data.id}
                  />
                  <CustomMeasuresTable
                    data={props.data.value.victimStatus}
                    name={props.data.name}
                    keys={laservicesdata.victimStatus}
                    id={`${props.data.id}-table`}
                  />
                  <CSVLink
                    data={props.data.value.victimStatus}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.name ===
                "Client Information  >  Type of Trafficking" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={props.data.value.typeOfTrafficking}
                      xaxis="Year"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.typeOfTrafficking}
                      trendLine={true}
                      report={true}
                      trendLineLegend="Total Individuals"
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      data={props.data.value.typeOfTrafficking}
                      name={props.data.name}
                      keys={laservicesdata.typeOfTrafficking}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.typeOfTrafficking}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name === "Demographics  >  Age" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.age}
                      xaxis="Age"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.age}
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <CustomMeasuresTable
                      single
                      data={props.data.value.age}
                      name={props.data.name}
                      keys={laservicesdata.age}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.age}
                      headers={[{ label: 'Age', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name === "Demographics  >  Type of Trafficking by Age" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <StackedBarVariableXAxis
                      report
                      width={750}
                      id={props.data.id}
                      data={props.data.value.typeTraffAge}
                      xaxis="Age"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.typeTraffAge}
                      trendLine={true}
                      trendLineLegend="Total Individuals"
                    />
                    <HorizontalTableData
                      data={props.data.value.typeAgeTableData ?? []}
                      name={props.data.name}
                      id={`${props.data.id}-table`}
                      headers={[{ label: 'Type of Trafficking', key: 'source' }, { label: 'Ages 0-4', key: 'agegroup1' }, { label: 'Ages 5-9', key: 'agegroup2' }, { label: 'Ages 10-14', key: 'agegroup3' }, { label: 'Ages 15-17', key: 'agegroup4' }, { label: 'Ages 18-20', key: 'agegroup5' }, { label: 'Ages 21-24', key: 'agegroup6' }, { label: 'Ages 25-29', key: 'agegroup7' }, { label: 'Ages 30-39', key: 'agegroup8' }, { label: 'Ages 40-49', key: 'agegroup9' }, { label: 'Ages 50-59', key: 'agegroup10' }, { label: 'Ages 60-69', key: 'agegroup11' }, { label: 'Ages 70+', key: 'agegroup12' }, { label: 'Age Unknown', key: 'unknown' }]}
                    />
                    <CSVLink
                      data={props.data.value.typeAgeTableData}
                      headers={[{ label: 'Type of Trafficking', key: 'source' }, { label: 'Ages 0-4', key: 'agegroup1' }, { label: 'Ages 5-9', key: 'agegroup2' }, { label: 'Ages 10-14', key: 'agegroup3' }, { label: 'Ages 15-17', key: 'agegroup4' }, { label: 'Ages 18-20', key: 'agegroup5' }, { label: 'Ages 21-24', key: 'agegroup6' }, { label: 'Ages 25-29', key: 'agegroup7' }, { label: 'Ages 30-39', key: 'agegroup8' }, { label: 'Ages 40-49', key: 'agegroup9' }, { label: 'Ages 50-59', key: 'agegroup10' }, { label: 'Ages 60-69', key: 'agegroup11' }, { label: 'Ages 70+', key: 'agegroup12' }, { label: 'Age Unknown', key: 'unknown' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name === "Demographics  >  Gender" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      id={props.data.id}
                      data={props.data.value.gender}
                      keys={laservicesdata.gender}
                      xaxis="Gender"
                      graphVariable="b_gender"
                      report={true}
                      type="cc"
                      smalltext={true}
                      yaxis="Number of Individuals"
                    />
                    <CustomMeasuresTable
                      data={props.data.value.gender}
                      name={props.data.name}
                      keys={laservicesdata.gender}
                      id={`${props.data.id}-table`}
                      columnValue="Number of Individuals"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.gender ? props.data.value.gender : []}
                      headers={[{ label: 'Gender', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics  >  Race" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.race}
                      xaxis="Race"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.race}
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <CustomMeasuresTable
                      single
                      data={props.data.value.race}
                      name={props.data.name}
                      keys={laservicesdata.race}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.race}
                      headers={[{ label: 'Race', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics  >  Ethnicity" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.ethnicity}
                      xaxis="Ethnicity"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.ethnicity}
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <CustomMeasuresTable
                      single
                      data={props.data.value.ethnicity}
                      name={props.data.name}
                      keys={laservicesdata.ethnicity}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.ethnicity}
                      headers={[{ label: 'Ethnicity', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name === "Demographics  >  Sexual Orientation" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.sexOrientation}
                      xaxis="Sexual Orientation"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.sexOrientation}
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <CustomMeasuresTable
                      single
                      data={props.data.value.sexOrientation}
                      name={props.data.name}
                      keys={laservicesdata.sexOrientation}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.sexOrientation}
                      headers={[{ label: 'Sexual Orientation', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics  >  Education Level" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.educationLevel}
                      xaxis="Education Level"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.educationLevel}
                      report={true}
                      widthNeeded={850}
                      xaxisLabelsKey='xaxis'
                      xaxisFontSize='.8em'
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <CustomMeasuresTable
                      single
                      data={props.data.value.educationLevel}
                      name={props.data.name}
                      keys={laservicesdata.educationLevel}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.educationLevel}
                      headers={[{ label: 'Education Level', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics  >  Disability" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.disability}
                      xaxis="Disability"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.disability}
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <CustomMeasuresTable
                      single
                      data={props.data.value.disability}
                      name={props.data.name}
                      keys={laservicesdata.disability}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.disability}
                      headers={[{ label: 'Disability', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics  >  Immigration Status" &&
                stateLocation === "LA" &&
                props.data.type === "Services" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.immigrationStatus}
                      xaxis="Immigration Status"
                      yaxis="Number of Individuals"
                      keys={laservicesdata.immigrationStatus}
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <CustomMeasuresTable
                      single
                      data={props.data.value.immigrationStatus}
                      name={props.data.name}
                      keys={laservicesdata.immigrationStatus}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.immigrationStatus}
                      headers={[{ label: 'Immigration Status', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demographics  >  Type of Trafficking by Gender" &&
                stateLocation === "LA" ? (
                  <div>
                    <TypeGenderComp
                      data={props.data.value.typeGender}
                      xaxis="Number of Individuals"
                      yaxis="Type of Trafficking"
                      keys={laservicesdata.typeGender}
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <TypeGenderTable
                      data={props.data.value.typeGender}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.typeGender}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demographics  >  Type of Trafficking by Race" &&
                stateLocation === "LA" ? (
                  <div>
                    <TypeRaceGraph
                      data={props.data.value.raceType}
                      keys={laservicesdata.raceType}
                      report={true}
                      id={props.data.id}
                    />
                    <TypeRaceTable
                      data={props.data.value.raceType}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      single={true}
                    />
                    <CSVLink
                      data={raceTypeCsvData && raceTypeCsvData}
                      headers={[{ label: 'Race', key: 'race' }, { label: 'Type of Trafficking', key: 'type' }, { label: 'Total Individuals', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Services Provided  >   Type of Service" ? (
                <div>
                  <HorizontalBarChart
                    data={props.data.value.typeOfServices}
                    value="type"
                    xaxis="Number of Individuals"
                    yaxis="Services"
                    report={true}
                    id={props.data.id}
                  />
                  <HorizontalTable
                    data={props.data.value.typeOfServices}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    reverseOrder={true}
                  />
                  <CSVLink
                    // LD-LA: V1 bugs deferred to V2#5
                    data={props.data.value.typeOfServices.sort((a, b) => {
                      return b.total - a.total;
                    })}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Services Provided  >  Length of Service" ? (
                <div>
                  <HorizontalBarChart
                    data={props.data.value.lengthService}
                    value="type"
                    xaxis="Number of Individuals"
                    yaxis="Length of Service"
                    report={true}
                    id={props.data.id}
                  />
                  <HorizontalTable
                    data={props.data.value.lengthService}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    reverseOrder={true}
                  />

                  <CSVLink
                    // LD-LA: V1 bugs deferred to V2#5
                    data={props.data.value.lengthService.sort((a, b) => {
                      return b.total - a.total;
                    })}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Services Provided  >  Reason for Exit" ? (
                <div>
                  <HorizontalBarChart
                    data={props.data.value.exitReason}
                    value="type"
                    xaxis="Number of Individuals"
                    yaxis="Reason for Exit"
                    report={true}
                    id={props.data.id}
                  />
                  <HorizontalTable
                    data={props.data.value.exitReason}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    reverseOrder={true}
                  />

                  <CSVLink
                    // LD-LA: V1 bugs deferred to V2#5
                    data={props.data.value.exitReason.sort((a, b) => {
                      return b.total - a.total;
                    })}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {/* Trafficking Locations */}
              {props.data.name ===
                "Trafficking Locations  >  Parish of Trafficking" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.parishTrafficking}
                      value="b_location_of_trafficking"
                      xaxis="Number of Locations"
                      yaxis="Location"
                      report={true}
                      id={props.data.id}
                    />
                    <HorizontalTable
                      data={props.data.value.parishTraffickingTableData}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      // LD-LA: V1 bugs deferred to V2#5
                      data={props.data.value.parishTrafficking.sort((a, b) => {
                        return b.total - a.total;
                      })}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Trafficking Locations  >  Parish of Origin" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.parishOrigin}
                      value="b_location_of_origin"
                      xaxis="Number of Individuals"
                      yaxis="Reason for Exit"
                      report={true}
                      id={props.data.id}
                    />
                    <HorizontalTable
                      data={props.data.value.parishOriginTableData}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      // LD-LA: V1 bugs deferred to V2#5
                      data={props.data.value.parishOrigin.sort((a, b) => {
                        return b.total - a.total;
                      })}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Trafficking Locations  >  Parish of Trafficking Over Time (Top Ten)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={props.data.value.parishTraffickingTime}
                      xaxis="Year"
                      yaxis="Number of Parishes"
                      keys={latraflocationdata.parishTraffickingTime}
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      showTopTenOnly
                      name={props.data.name}
                      ordered
                      data={props.data.value.parishTraffickingTime}
                      keys={latraflocationdata.parishTraffickingTime}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.parishTraffickingTime}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Trafficking Locations  >  Parish of Origin Over Time (Top Ten)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={props.data.value.parishOriginTime}
                      xaxis="Year"
                      yaxis="Number of Parishes"
                      keys={latraflocationdata.parishOriginTime}
                      report={true}
                      id={props.data.id}
                    />
                    <CustomMeasuresTable
                      showTopTenOnly
                      name={props.data.name}
                      ordered
                      data={props.data.value.parishOriginTime}
                      keys={latraflocationdata.parishOriginTime}
                      id={`${props.data.id}-table`}
                    />
                    <CSVLink
                      data={props.data.value.parishOriginTime}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {/* Screenings */}
              {props.data.name ===
                "Screenings Information  >  All Screenings" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <StackedBarVariableXAxis
                      id={props.data.id}
                      data={props.data.value.allScreenings}
                      xaxis="Year"
                      yaxis="Number of Screenings"
                      keys={screeningsgraphdata.allScreenings[stateLocation]}
                      trendLine={true}
                      trendLineLegend="Total Screenings"
                      report={true}
                    />
                    <TableForYearGraphs
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      data={props.data.value.allScreenings}
                      keys={screeningsgraphdata.allScreenings[stateLocation]}
                      columnValue='Number of Screenings'
                    />
                    <CSVLink
                      data={props.data.value.allScreenings}
                      headers={stateLocation === 'TX' ?
                        [{ label: 'Year', key: 'xaxis' }, { label: 'WCCC', key: 'wcc' }, { label: 'TJJD', key: 'tjjd' }, { label: 'DFPS', key: 'dfps' }, { label: 'Lighthouse', key: 'lighthouse' }, { label: 'Total', key: 'total' }]
                        :
                        [{ label: 'Year', key: 'xaxis' }, { label: 'WCCC', key: 'wcc' }, { label: 'Lighthouse', key: 'lighthouse' }, { label: 'Total', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Screenings Information  >  Screenings and Individuals Screened" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <GroupedBarGraph
                      id={props.data.id}
                      keys={screeningsgraphdata.screeningsIndividuals}
                      data={props.data.value.screeningsIndividuals}
                      xaxis="Time (calendar year and quarter)"
                      yaxis="Number"
                      percentLine={true}
                      percentLegend="Percent (Individuals Screened/Screenings)"
                      report
                    />
                    <TableForYearGraphs
                      id={`${props.data.id}-table`}
                      name='Screenings and Individuals Screened'
                      data={props.data.value.screeningsIndividuals}
                      keys={screeningsgraphdata.screeningsIndividuals}
                      columnValue='Number'
                      noTotalRow
                    />
                    <CSVLink
                      data={props.data.value.screeningsIndividuals}
                      headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'quarter' }, { label: 'Individuals screened', key: 'distinct' }, { label: 'Screenings', key: 'screenings' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Screenings Information  >  Screenings by Location" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <HorizontalBarChart
                      id={props.data.id}
                      rotateXaxis
                      yLabelsWidth={stateLocation === 'FL' ? 105 : false}
                      percentOfTotal={props.data.value.screeningsByLocation}
                      data={props.data.value.screeningsByLocation.slice(0, 10)} // get top 10 counties for the chart
                      value={'county'}
                      xaxis="Number of Screenings"
                      yaxis="Location"
                      report
                    />
                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      name='Screenings by Location'
                      data={props.data.value.screeningsByLocation}
                      horizontalSort
                      nameKey='country'
                      headers={[stateLocation === 'FL' ? 'Location' : 'Screenings by Location', stateLocation === 'FL' ? 'Number of Screenings' : 'Value']}
                      columnKeys={['county', 'total']}
                      totalKey='total'
                    />
                    <CSVLink
                      data={props.data.value.screeningsByLocation.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.country < b.country ? -1 : 1;
                        }
                        return b.total - a.total;
                      })}
                      headers={[{ label: stateLocation === 'FL' ? 'Location' : 'Screenings by Location', key: 'county' }, { label: stateLocation === 'FL' ? 'Number of Screenings' : 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Screenings Information  >  Screenings by Location and Population" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <HorizontalBarChart
                      id={props.data.id}
                      percentOfTotal={props.data.value.screeningsByLocationPop}
                      data={props.data.value.screeningsByLocationPop.slice(0, 10)} // get top 10 counties for the chart
                      value="city"
                      xaxis="Number of Screenings per 100,000"
                      yaxis="Location"
                      report
                    />
                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      noTotalRow
                      name='Screenings by Location and Population'
                      data={props.data.value.screeningsByLocationPop}
                      horizontalSort
                      nameKey='city'
                      toFixed={2}
                      headers={[stateLocation === 'FL' ? 'Location' : 'Screenings by Location and Population', stateLocation === 'FL' ? 'Number of Screenings by Population' : 'Value']}
                      columnKeys={['city', 'total']}
                      totalKey='total'
                    />
                    <CSVLink
                      data={props.data.value.screeningsByLocationPop.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city < b.city ? -1 : 1;
                        }
                        return b.total - a.total;
                      })}
                      headers={[{ label: stateLocation === 'FL' ? 'Location' : 'Screenings by Location and Population', key: 'city' }, { label: stateLocation === 'FL' ? 'Number of Screenings by Population' : 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name === "Screenings Information  >  Screenings by Victim Status" &&
                props.data.type === "Screenings" && (
                <div>
                  <PieGraph
                    report
                    id={props.data.id}
                    keepOrder
                    data={props.data.value.byVictimStatus ?? []}
                    sourceNameVar='status'
                  />
                  <TableWithTotal
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    data={props.data.value.byVictimStatus ?? []}
                    headers={['Victim Status', 'Number of Screenings']}
                    columnKeys={['status', 'value']}
                    totalKey='value'
                  />
                  <CSVLink
                    data={props.data.value.byVictimStatus}
                    headers={[{ label: 'Victim Status', key: 'status' }, { label: 'Number of Screenings', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                  </div>
                )}

              {props.data.name ===
                "Screenings Information  >  Screenings by Concern Level" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <PieGraph
                      report
                      id={props.data.id}
                      data={props.data.value.screeningconcern ?? data.screeningconcern}
                      keys={screeningsgraphdata.concernLevel}
                      sourceNameVar='calc_cseitscore'
                    />
                    <ScreeningConcernTable
                      data={props.data.value.screeningconcern ?? data.screeningconcern}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.screeningconcern}
                      headers={[{ label: 'Concern Level', key: 'calc_cseitscore' }, { label: 'Number of Screenings', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name === "Demographics  >  Age" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <SingleBarGraph
                      report={true}
                      id={props.data.id}
                      data={props.data.value.ageData ?? []}
                      keys={stateLocation !== 'FL' ? screeningsgraphdata.ageData : false}
                      xaxis="Age"
                      labelOnHover={stateLocation === 'FL' ? true : false}
                      graphVariable={stateLocation !== 'FL' ? "age" : false}
                      xaxisVar={stateLocation === 'FL' ? 'age' : false}
                      yaxis="Number of Screenings"
                      type="cc"
                      smalltext={true}
                    />
                    <AgeTable
                      data={props.data.value.ageData ?? []}
                      type="Demographics  >  Age"
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.ageData}
                      headers={[{label: 'Age', key: 'age'}, {label: 'Number of Screenings', key: 'value'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics  >  Ages 25+" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <SingleBarGraph
                      report={true}
                      id={props.data.id}
                      data={
                        props.data.value.ageGroupData
                          ? props.data.value.ageGroupData
                          : data.ageGroupData
                      }
                      keys={screeningsgraphdata.ageGroupData}
                      xaxis="Age Groups"
                      graphVariable="age"
                      yaxis="Number of Screenings"
                      type="cc"
                      smalltext={true}
                    />
                    <AgeAllTable
                      data={
                        props.data.value.ageGroupData
                          ? props.data.value.ageGroupData
                          : data.ageData
                      }
                      type="Ages 25+"
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.ageGroupData}
                      headers={[{ label: 'Age', key: 'age' }, { label: 'Number of Screenings', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics  >  Gender" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <SingleBarGraph
                      data={props.data.value.genderData ?? []}
                      keys={stateLocation !== 'FL' ? screeningsgraphdata.genderData : false}
                      xaxis="Gender"
                      graphVariable={stateLocation !== 'FL' ? "b_gender" : false}
                      labelOnHover={stateLocation === 'FL' ? true : false}
                      xaxisVar={stateLocation === 'FL' ? "gender" : false}
                      yaxis="Number of Screenings"
                      report={true}
                      id={props.data.id}
                      type="cc"
                      smalltext={true}
                    />
                    <TableWithTotal
                      // single
                      data={props.data.value.genderData}
                      headers={['Gender', 'Number of Screenings']}
                      keys={stateLocation !== 'FL' ? screeningsgraphdata.genderData : false}
                      columnKeys={stateLocation !== 'FL' ? ['b_gender', 'value'] : ['gender', 'value']}
                      totalKey='value'
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.genderData ?? []}
                      headers={[{ label: 'Gender', key: stateLocation !== 'FL' ? 'b_gender' : 'gender'}, { label: 'Number of Screenings', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* {props.data.name === "Demographics  >  Gender Pie Graph" ? (
              <div>
                <GenderPieGraph data={props.data.value.genderData ? props.data.value.genderData : data.genderData} />
                <TableWithTotal data={props.data.value.genderData ? props.data.value.genderData : data.genderData} id={props.data.id}/>
              </div>
            ) : null} */}
              {props.data.name === "Demographics  >  Race" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <SingleBarGraph
                      report
                      id={props.data.id}
                      data={props.data.value.raceData ?? []}
                      keys={stateLocation === 'FL' ? false : screeningsgraphdata.raceData}
                      xaxis="Race"
                      graphVariable={stateLocation === 'FL' ? false : "b_race"}
                      labelOnHover={stateLocation === 'FL' ? true : false}
                      xaxisVar={stateLocation === 'FL' ? "race" : false}
                      yaxis="Number of Screenings"
                      type="cc"
                      smalltext={true}
                    />

                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      data={props.data.value.raceData ?? []}
                      headers={['Race', 'Number of Screenings']}
                      columnKeys={[stateLocation === 'FL' ? 'race' : 'b_race', 'value']}
                      totalKey='value'
                    />
                    <CSVLink
                      data={props.data.value.raceData}
                      headers={[{ label: 'Race', key: stateLocation === 'FL' ? 'race' : 'b_race' }, { label: 'Number of Screenings', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name === "Demographics  >  Ethnicity" &&
                props.data.type === "Screenings" ? (
                  <div>
                    <SingleBarGraph
                      id={props.data.id}
                      title='Ethnicity'
                      data={props.data.value?.ethnicityData ?? []}
                      keys={screeningsgraphdata.ethnicityData}
                      xaxis="Ethnicity"
                      graphVariable={stateLocation === 'FL' ? false : "ethnicity"}
                      labelOnHover={stateLocation === 'FL' ? true : false}
                      xaxisVar={stateLocation === 'FL' ? 'ethnicity' : false}
                      yaxis="Number of Screenings"
                      type="cc"
                      smalltext={true}
                      report={true}
                    />
                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      data={props.data.value?.ethnicityData ?? []}
                      headers={['Ethnicity', 'Number of Screenings']}
                      columnKeys={['ethnicity', 'value']}
                      totalKey='value'
                    />
                    <CSVLink
                      data={props.data.value.ethnicityData}
                      headers={[{ label: 'Ethnicity', key: 'ethnicity' }, { label: 'Number of Screenings', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name === "Demographics  >  Sexual Orientation/Gender Identity" &&
                props.data.type === "Screenings" && (
                <div>
                  <SingleBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.orientationIdentity ?? []}
                    xaxis="Sexual Orientation/Gender Identity"
                    labelOnHover
                    xaxisVar="identity"
                    yaxis="Number of Screenings"
                    type="cc"
                    smalltext={true}
                  />
                  <TableWithTotal
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    data={props.data.value?.orientationIdentity ?? []}
                    headers={['Sexual Orientation/Gender Identity', 'Number of Screenings']}
                    columnKeys={['identity', 'value']}
                    totalKey='value'
                  />
                  <CSVLink
                    data={props.data.value.orientationIdentity}
                    headers={[{ label: 'Sexual Orientation/Gender Identity', key: 'identity' }, { label: 'Number of Screenings', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                  </div>
                )}

              {props.data.name === "Demographics  >  Age + Gender" ? (
                <div>
                  <AgeGenderComp
                    data={
                      props.data.value.ageGenderData
                        ? props.data.value.ageGenderData
                        : data.ageGenderData
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <AgeGenderTable
                    data={
                      props.data.value.ageGenderData
                        ? props.data.value.ageGenderData
                        : data.ageGenderData
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={props.data.value.ageGenderData}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Demographics  >  Ages 25+ + Gender" ? (
                <div>
                  <AgeAllGenderComp
                    data={
                      props.data.value.ageGenderGroupData
                        ? props.data.value.ageGenderGroupData
                        : data.ageGenderGroupData
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <AgeAllGenderTable
                    data={
                      props.data.value.ageGenderGroupData
                        ? props.data.value.ageGenderGroupData
                        : data.ageGenderGroupData
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={props.data.value.ageGenderGroupData}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Demographics  >  Gender + Race" ? (
                  <div>
                    <RaceGenderComp
                      data={
                        props.data.value.raceGenderData
                          ? props.data.value.raceGenderData
                          : data.raceGenderData
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <RaceGenderTable
                      data={
                        props.data.value.raceGenderData
                          ? props.data.value.raceGenderData
                          : data.raceGenderData
                      }
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.raceGenderData}
                      headers={[{ label: 'Race', key: 'b_race' }, { label: 'Number of Females', key: 'female' }, { label: 'Number of Males', key: 'male' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name === "Demographics  >  Age + Race" ? (
                <div>

                  <RaceAgePie
                    data={
                      props.data.value.raceAgeData
                        ? props.data.value.raceAgeData
                        : data.raceAgeData
                    }
                    report={true}
                    id={props.data.id}
                  />
                  <RaceAgeTable
                    data={
                      props.data.value.raceAgeData
                        ? props.data.value.raceAgeData
                        : data.raceAgeData
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={raceAgeDataCsvData && raceAgeDataCsvData}
                    headers={[{ label: "Race", key: "race" }, { label: "Age", key: "age" }, { label: "Numbers Screened", key: "value" }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Demographics  >  Ages 25+ and Race" ? (
                  <div>
                    <RaceAgeAllPie
                      data={
                        props.data.value.raceAllAgeData
                          ? props.data.value.raceAllAgeData
                          : data.raceAllAgeData
                      }
                      report={true}
                      id={props.data.id}
                    />
                    <RaceAgeAllTable
                      data={
                        props.data.value.raceAllAgeData
                          ? props.data.value.raceAllAgeData
                          : data.raceAllAgeData
                      }
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={raceAllAgeDataCsvData && raceAllAgeDataCsvData}
                      headers={[{ label: "Race", key: "race" }, { label: "Age Group", key: "age" }, { label: "Numbers Screened", key: "value" }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Demographics  >  Sexual Orientation + Concern Level" ? (
                  <div>
                    <TableHoverDonutChart
                      id={props.data.id}
                      report
                      data={props.data.value.orientationConcern}
                      donutColorIndex={screeningsgraphdata.concernLevel}
                      percentOf='Screenings'
                      activeCategory={props.data.activeCategory}
                      style={{ overflow: "scroll" }}
                    />
                    <HoverDonutChartTable
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      data={props.data.value.orientationConcern}
                      columnHeaders={['Sexual Orientation', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
                    />
                    <CSVLink
                      data={props.data.value.orientationConcern}
                      headers={[{ label: 'Sexual Orientation', key: 'id' }, { label: 'Number of Screenings', key: 'total' }, { label: 'Number of Clear Concern', key: 'children[0].value' }, { label: 'Number of Possible Concern', key: 'children[1].value' }, { label: 'Number of No Concern', key: 'children[2].value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name ===
                "Demographics  >  Gender Identity + Concern Level" ? (
                  <div>
                    <TableHoverDonutChart
                      id={props.data.id}
                      report
                      data={props.data.value.genderConcern}
                      donutColorIndex={screeningsgraphdata.concernLevel}
                      percentOf='Screenings'
                      activeCategory={props.data.activeCategory}
                      style={{ overflow: "scroll" }}
                    />
                    <HoverDonutChartTable
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      data={props.data.value.genderConcern}
                      columnHeaders={['Gender Identity', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
                    />
                    <CSVLink
                      data={props.data.value.genderConcern}
                      headers={[{ label: 'Gender Identity', key: 'id' }, { label: 'Number of Screenings', key: 'total' }, { label: 'Number of Clear Concern', key: 'children[0].value' }, { label: 'Number of Possible Concern', key: 'children[1].value' }, { label: 'Number of No Concern', key: 'children[2].value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              

              {props.data.name ===
                "Demographics  >  Disability + Concern Level" ? (
                  <div>
                    <TableHoverDonutChart
                      id={props.data.id}
                      report
                      data={props.data.value.disabilityConcern}
                      donutColorIndex={screeningsgraphdata.concernLevel}
                      percentOf='Screenings'
                      activeCategory={props.data.activeCategory}
                      style={{ overflow: "scroll" }}
                    />
                    <HoverDonutChartTable
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      data={props.data.value.disabilityConcern}
                      columnHeaders={['Disability', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
                    />
                    <CSVLink
                      data={props.data.value.disabilityConcern}
                      headers={[{ label: 'Disability', key: 'id' }, { label: 'Number of Screenings', key: 'total' }, { label: 'Number of Clear Concern', key: 'children[0].value' }, { label: 'Number of Possible Concern', key: 'children[1].value' }, { label: 'Number of No Concern', key: 'children[2].value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Demographics  >  Education Level + Concern Level" ? (
                  <div>
                    <HorizontalAndDonutChart
                      id={props.data.id}
                      report
                      data={props.data.value.educationConcern}
                      reverse
                      yaxis='Education Level'
                      xaxis='Number of Screenings'
                      keys={screeningsgraphdata.educationConcern}
                      donutColorIndex={screeningsgraphdata.concernLevel}
                      percentOf='Screenings'
                      activeCategory={props.data.activeCategory}
                      style={{ overflow: "scroll" }}
                    />
                    <HoverDonutChartTable
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      data={props.data.value.educationConcern}
                      reverse
                      columnHeaders={['Education Level', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
                    />
                    <CSVLink
                      data={props.data.value.educationConcern}
                      headers={[{ label: 'Education Level', key: 'id' }, { label: 'Number of Screenings', key: 'total' }, { label: 'Number of Clear Concern', key: 'children[0].value' }, { label: 'Number of Possible Concern', key: 'children[1].value' }, { label: 'Number of No Concern', key: 'children[2].value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}


              {/* Indicators */}
              {props.data.name === "Indicators  >  Indicators By Category" ? (
                <div>
                  <InteractivePieChart
                    width={1350}
                    style={{ overflow: "scroll" }}
                    data={
                      props.data.value.indicators
                        ? props.data.value.indicators
                        : data.indicators
                    }
                    id={props.data.id}
                    report={true}
                  />
                  <TotalIndicatorsTable
                    data={
                      props.data.value.indicators
                        ? props.data.value.indicators
                        : data.indicators
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={indicatorsByCategoryCsvData && indicatorsByCategoryCsvData}
                    headers={[{ label: '', key: 'name' }, { label: '', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Indicators  >  All Indicators" ? (
                <div>
                  <DendrogramV2
                    width={1110}
                    height={"2000"}
                    style={{ overflow: "scroll" }}
                    data={
                      props.data.value.indicators
                        ? props.data.value.indicators
                        : data.indicators
                    }
                    noLines={true}
                    id={props.data.id}
                  />
                  <TotalIndicatorsTable
                    data={
                      props.data.value.indicators
                        ? props.data.value.indicators
                        : data.indicators
                    }
                    type="All Indicators"
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={arrayIndicators}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.name === "Indicators  >  Indicator Prevalence" &&
                props.data.type === "Screenings" &&
                stateLocation === 'FL' && (
                  <div>
                  <HorizontalBarChart
                    id={props.data.id}
                    report
                    percentOfTotal={props.data.value.indicatorsTotal}
                    data={props.data.value.indicators ?? []}
                    value="xaxis"
                    labelOnHover='hoverLabel'
                    xaxis="Number of Screenings"
                    yLabelsWidth={220}
                  />
                  <TableWithTotal
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    noTotalRow
                    descending
                    data={props.data.value.indicators ?? []}
                    headers={['Indicators', 'Number of Screenings']}
                    columnKeys={['xaxis', 'total']}
                    totalKey='total'
                  />
                  <CSVLink
                    data={props.data.value.indicators}
                    headers={[{ label: 'Indicators', key: 'xaxis' }, { label: 'Number of Screenings', key: 'total'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                  </div>
                )
              }

              {props.data.name === "Indicators  >  Indicator Prevalence" && stateLocation !== 'FL' ? (
                <div>
                  <BarChart
                    data={
                      props.data.value.indicators
                        ? props.data.value.indicators
                        : data.indicators
                    }
                    style={{ overflow: "scroll" }}
                    id={props.data.id}
                    report={true}
                  />
                  <TotalIndicatorsOrderedTable
                    data={
                      props.data.value.indicators
                        ? props.data.value.indicators
                        : data.indicators
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={arrayIndicators.sort((a, b) => b.value - a.value)}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Indicators  >  Indicator Relationship to CSE-IT Score (Clear Concern) - Male" ? (
                  <div>
                    <BarChart
                      data={
                        props.data.value.indicatorImpactMale
                          ? props.data.value.indicatorImpactMale
                          : data.indicators
                      }
                      style={{ overflow: "scroll" }}
                      percent={true}
                      id={props.data.id}
                      report={true}
                    />
                    <TotalIndicatorsOrderedTable
                      data={
                        props.data.value.indicatorImpactMale
                          ? props.data.value.indicatorImpactMale
                          : data.indicators
                      }
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={arrayMaleIndicators}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Indicators  >  Indicator Relationship to CSE-IT Score (Clear Concern) - Female" ? (
                  <div>
                    <BarChart
                      data={
                        props.data.value.indicatorImpactFemale
                          ? props.data.value.indicatorImpactFemale
                          : data.indicators
                      }
                      style={{ overflow: "scroll" }}
                      percent={true}
                      id={props.data.id}
                      report={true}
                    />
                    <TotalIndicatorsOrderedTable
                      data={
                        props.data.value.indicatorImpactFemale
                          ? props.data.value.indicatorImpactFemale
                          : data.indicators
                      }
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={arrayFemaleIndicators}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {/****** TX Care Coordination *******/}
              {/****** Referrals *******/}
              {props.data.name === "Referrals  >  Total Referrals" ? (
                <div>
                  <StackedBarGraph
                    data={props.data.value.totalReferralArray}
                    keys={ccgraphdata.totalReferralArray}
                    xaxis="Time"
                    yaxis="Number of Referrals"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Referrals"
                    report={true}
                    id={props.data.id}
                  />
                  <CCReferralTotalsTable
                    data={props.data.value.totalReferralArray}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                  />
                  <CSVLink
                    data={props.data.value.totalReferralArray}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Referrals  >  Individuals with DFPS Cases" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Referrals"
                      yaxisMaxValue={true}
                      data={props.data.value.totalDfpsCases}
                      keys={ccgraphdata.totalDfpsCases}
                      report={true}
                      id={props.data.id}
                    />
                    <YouthDfpsTable
                      data={props.data.value.totalDfpsCases}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.totalDfpsCases}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Referrals  >  Referral Sources" ? (
                <div>
                  <SingleBarGraph
                    xaxis="Referrals by Source"
                    yaxis="Number of Individuals"
                    type="cc"
                    data={props.data.value.referrals}
                    keys={ccgraphdata.referrals}
                    report={true}
                    id={props.data.id}
                  />
                  <CCReferralTable
                    data={props.data.value.referrals}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={props.data.value.referrals}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Referrals  >  Referral Sources Over Time" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time"
                      yaxis="Number of Referrals"
                      data={props.data.value.referralSources}
                      keys={ccgraphdata.referralSources}
                      report={true}
                      id={props.data.id}
                    />
                    <CCReferralTable
                      data={props.data.value.referrals}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.referralSources}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Referrals  >  Referrals By Type Over Time" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time"
                      yaxis="Number of Referrals"
                      data={props.data.value.referralsByType}
                      keys={ccgraphdata.referralsByType}
                      report={true}
                      id={props.data.id}
                    />
                    <CCTotalsTable
                      data={props.data.value.totalYouth}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.referralsByType}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Total in Care Coordination (all time)" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      data={props.data.value.totalInCC}
                      keys={ccgraphdata.totalInCC}
                      report={true}
                      id={props.data.id}
                    />
                    <TotalInCareTable
                      data={props.data.value.totalInCC}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.totalInCC}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Individuals in Care Coordination" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      yaxisMaxValue={true}
                      data={props.data.value.newAndActiveYouth}
                      keys={ccgraphdata.newAndActiveYouth}
                      report={true}
                      id={props.data.id}
                    />
                    <IndividualsInCCTable
                      data={props.data.value.newAndActiveYouth}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.newAndActiveYouth}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Identified and Recovered Victims" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      yaxisMaxValue={true}
                      data={props.data.value.identifiedAndRecovered}
                      keys={ccgraphdata.identifiedAndRecovered}
                      report={true}
                      id={props.data.id}
                    />
                    <IdentifiedRecoveredTable
                      data={props.data.value.identifiedAndRecovered}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.identifiedAndRecovered}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Recovered/Re-recovered individuals returned or placed within 72 hours" ? (
                  <div>
                    <SingleBarGraph
                      xaxis="Placement Locations"
                      yaxis="Number of Individuals"
                      type="cc"
                      data={props.data.value.identified72}
                      keys={ccgraphdata.identified72}
                      report={true}
                      id={props.data.id}
                    />
                    <CCHoursPlacementTable
                      data={props.data.value.identified72}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.identified72}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Recovered/Re-recovered individuals returned or placed within 72 hours (over time)" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      data={props.data.value.identified72Array}
                      keys={ccgraphdata.identified72Array}
                      report={true}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Individuals"
                      id={props.data.id}
                    />
                    <CCHoursPlacementTable
                      data={props.data.value.identified72}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.identified72Array}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Total Placements (72 hours)" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      data={props.data.value.totalPlacedNotPlaced72}
                      keys={ccgraphdata.totalPlacedNotPlaced72}
                      report={true}
                      id={props.data.id}
                    />
                    <TotalPlacements72Table
                      data={props.data.value.totalPlacedNotPlaced72}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.totalPlacedNotPlaced72}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Recovered/Re-recovered individuals returned or placed during the first 15 days" ? (
                  <div>
                    <SingleBarGraph
                      xaxis="Placement Locations"
                      yaxis="Number of Individuals"
                      type="cc"
                      data={props.data.value.identified15}
                      keys={ccgraphdata.identified15}
                      report={true}
                      id={props.data.id}
                    />
                    <CCDaysPlacementTable
                      data={props.data.value.identified15}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.identified15}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Recovered/Re-recovered individuals returned or placed during the first 15 days (over time)" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      data={props.data.value.identified15Array}
                      keys={ccgraphdata.identified15Array}
                      report={true}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Individuals"
                      id={props.data.id}
                    />
                    <CCDaysPlacementTable
                      data={props.data.value.identified15}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.identified15Array}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Identified, Recovered, Placements  >  Total Placements (15 days)" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      data={props.data.value.totalPlacedNotPlaced15}
                      keys={ccgraphdata.totalPlacedNotPlaced15}
                      report={true}
                      id={props.data.id}
                    />
                    <TotalPlacements15Table
                      data={props.data.value.totalPlacedNotPlaced15}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.totalPlacedNotPlaced15}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Meetings Held" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Meetings"
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Meetings"
                      data={props.data.value.meetingsHeld}
                      keys={ccgraphdata.meetingsHeld}
                      report={true}
                      id={props.data.id}
                    />
                    <MeetingsHeldTable
                      data={props.data.value.meetingsHeld}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.meetingsHeld}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Cases Closed" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Cases"
                      //trendLine={props.data.chartLine}
                      //trendLineLegend="Total Meetings"
                      data={props.data.value.casesClosed}
                      keys={ccgraphdata.casesClosed}
                      report={true}
                      id={props.data.id}
                    />
                    <CasesClosedTable
                      data={props.data.value.casesClosed}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.casesClosed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Individual Entered DFPS Conservatorship or FBSS" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      //trendLine={props.data.chartLine}
                      //trendLineLegend="Total Meetings"
                      data={props.data.value.dfps}
                      keys={ccgraphdata.dfps}
                      report={true}
                      id={props.data.id}
                    />
                    <FBSSTable
                      data={props.data.value.dfps}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.dfps}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Cases Closed" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Cases"
                      //trendLine={props.data.chartLine}
                      //trendLineLegend="Total Meetings"
                      data={props.data.value.casesClosed}
                      keys={ccgraphdata.casesClosed}
                      report={true}
                      id={props.data.id}
                    />
                    <CasesClosedTable
                      data={props.data.value.casesClosed}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.casesClosed}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Individual Entered DFPS Conservatorship or FBSS" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      //trendLine={props.data.chartLine}
                      //trendLineLegend="Total Meetings"
                      data={props.data.value.dfps}
                      keys={ccgraphdata.dfps}
                      report={true}
                      id={props.data.id}
                    />
                    <FBSSTable
                      data={props.data.value.dfps}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.dfps}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Meetings By Type" ? (
                  <div>
                    <SingleBarGraph
                      xaxis="Type of Meetings"
                      yaxis="Number of Meetings"
                      type="cc"
                      data={props.data.value.meetingTypes}
                      keys={ccgraphdata.meetingTypes}
                      report={true}
                      id={props.data.id}
                    />
                    <MeetingsTypeTable
                      data={props.data.value.meetingTypes}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.meetingTypes}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Meetings By Type (over time)" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time"
                      yaxis="Number of Meetings"
                      data={props.data.value.meetingTypesOverTime}
                      keys={ccgraphdata.meetingTypesOverTime}
                      report={true}
                      id={props.data.id}
                    />
                    <MeetingsTypeTable
                      data={props.data.value.meetingTypesOverTime}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.meetingTypesOverTime}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Meetings & Service Coordination  >  Forensic Interviews and CST Case Reviews" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number"
                      trendLine={props.data.chartLine}
                      line={props.data.value.newAndActiveYouth}
                      lineTitle="Total in Active CC"
                      yaxisMaxValue={true}
                      data={props.data.value.forensicInterviews}
                      keys={ccgraphdata.forensicInterviews}
                      report={true}
                      id={props.data.id}
                    />
                    <ForensicIntCstTable
                      data={props.data.value.forensicInterviews}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.forensicInterviews}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Location Days  >  Total Days In Care Coordination" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Days"
                      data={props.data.value.totalLocationDays}
                      keys={ccgraphdata.totalLocationDays}
                      report={true}
                      id={props.data.id}
                    />
                    <TotalDaysTable
                      data={props.data.value.totalLocationDays}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.totalLocationDays}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Location Days  >  Location Days by Type" ? (
                <div>
                  <SingleBarGraph
                    xaxis="Days by Type"
                    yaxis="Number of Days"
                    type="cc"
                    data={props.data.value.locationsDaysType}
                    keys={ccgraphdata.locationsDaysType}
                    report={true}
                    id={props.data.id}
                  />
                  <LocationDaysTable
                    data={props.data.value.locationsDaysType}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                  />
                  <CSVLink
                    data={props.data.value.locationsDaysType}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Location Days  >  Location Days by Type (over time)" ? (
                  <div>
                    <StackedBarGraph
                      xaxis="Time"
                      yaxis="Number of Days"
                      trendLineLegend="Total Location Days"
                      trendLine={props.data.chartLine}
                      data={props.data.value.locationDaysByType}
                      keys={ccgraphdata.locationDaysByType}
                      report={true}
                      id={props.data.id}
                    />
                    <LocationDaysTable
                      data={props.data.value.locationsDaysType}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.locationDaysByType}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Location Days  >  Paid/Unpaid Days" ? (
                <div>
                  <PaidUnpaidChart
                    data={props.data.value.paidunpaid}
                    report={true}
                    id={props.data.id}
                  />
                  <PaidUnpaidTable
                    data={props.data.value.paidunpaid}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                  />
                  <CSVLink
                    data={paidUnpaidCsvData && paidUnpaidCsvData}
                    headers={[{ label: 'Category', key: 'category' }, { label: 'Location Type', key: 'locationType' }, { label: 'Value', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Outcome Measures  >  Placements within 72 Hours" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time"
                      yaxis="Number of Individuals"
                      percentLine={props.data.chartLine}
                      percentLegend="Percentage who remain safe for 72 hours"
                      data={props.data.value.placements}
                      keys={ccgraphdata.placements}
                      report={true}
                      id={props.data.id}
                    />
                    <PlacementsTable
                      data={props.data.value.placements}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.placements}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Outcome Measures  >  Guardians following CC recommendations" ? (
                  <div>
                    <GroupedBarGraph
                      xaxis="Time (Q and FY)"
                      yaxis="Number of Guardians"
                      yaxisMaxValue={true}
                      data={props.data.value.guardianTypes}
                      keys={ccgraphdata.guardianTypes}
                      report={true}
                      id={props.data.id}
                    />
                    <GuardianTypeTable
                      data={props.data.value.guardianTypes}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.guardianTypes}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {/****** LA Care Coordination *******/}
              {props.data.type === 'Care Coordination' && props.data.name === "Overview  >  Total Individuals Served by Region" &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.totalServedRegion}
                    keys={LACCKeys.totalServedRegion}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Individuals"
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.totalServedRegion}
                    keys={LACCKeys.totalServedRegion}
                    name='Region'
                    columnValue='Value'
                  />

                  <CSVLink
                    data={props.data.value.totalServedRegion}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Alexandria', key: 'Alexandria' }, { label: 'Baton Rouge', key: 'Baton Rouge' }, { label: 'Covington I', key: 'Covington 1: CAS' }, { label: 'Covington II', key: 'Covington 2: Hope House' }, { label: 'Lafayette', key: 'Lafayette' }, { label: 'Lake Charles', key: 'Lake Charles' }, { label: 'Monroe', key: 'Monroe' }, { label: 'Orleans', key: 'Orleans' }, { label: 'Shreveport', key: 'Shreveport' }, { label: 'Thibodaux', key: 'Thibodaux' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Overview  >  Total and New Individuals Served' &&
                <div>
                  <GroupedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.totalAndNew}
                    keys={LACCKeys.totalAndNew}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    yaxisMaxValue={true}
                  />
                  <TableForYearGraphs
                    noTotalRow
                    id={`${props.data.id}-table`}
                    data={props.data.value.totalAndNew}
                    keys={LACCKeys.totalAndNew}
                    name='Individuals Served'
                    columnValue='Value'
                  />
                  <CSVLink
                    data={props.data.value.totalAndNew}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'New individuals served', key: 'totalNew' }, { label: 'Total individuals served', key: 'totalServed' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Overview  >  Total Individuals Served by Victim Type' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.totalVictimTypeServed}
                    keys={LACCKeys.totalVictimTypeServed}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    name='Individuals Served'
                    data={props.data.value.totalVictimTypeServed}
                    keys={LACCKeys.totalVictimTypeServed}
                    columnValue='Value'
                  />
                  <CSVLink
                    data={props.data.value.totalVictimTypeServed}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Confirmed', key: 'tp_total_confirmed' }, { label: 'Suspected', key: 'tp_total_suspected' }, { label: 'High risk', key: 'tp_total_high_risk' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Overview  >  New Individuals Served by Victim Type' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.totalNewVictimTypeServed}
                    keys={LACCKeys.totalNewVictimTypeServed}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    name='Individuals Served'
                    data={props.data.value.totalNewVictimTypeServed}
                    keys={LACCKeys.totalNewVictimTypeServed}
                    columnValue='Value'
                  />
                  <CSVLink
                    data={props.data.value.totalNewVictimTypeServed}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Confirmed', key: 'totalConfirmed' }, { label: 'Suspected', key: 'totalSuspected' }, { label: 'High risk', key: 'totalRisk' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Overview  >  Referrals and Contacts' &&
                <div>
                  <GroupedBarGraph
                    report
                    id={props.data.id}
                    keys={LACCKeys.referralsAndContacts}
                    data={props.data.value.referralsAndContacts}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    yaxisMaxValue={false}
                  />
                  <TableForYearGraphs
                    noTotalRow
                    id={`${props.data.id}-table`}
                    name='Referrals and Contacts'
                    data={props.data.value.referralsAndContacts}
                    keys={LACCKeys.referralsAndContacts}
                    columnValue='Value'
                  />

                  <CSVLink
                    data={props.data.value.referralsAndContacts}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Care Coordination Referrals', key: 'tp_total_cc_referrals' }, { label: 'Anonymous Contacts', key: 'tp_total_anon_contacts' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }


              {/* Overview  >  Location of Trafficking */}
              {props.data.type === 'Care Coordination' && props.data.name === 'Overview  >  Location of Trafficking' &&
                <div>
                  <HorizontalBarChart
                      report
                      id={props.data.id}
                      data={props.data.value.locationTrafficking.slice(0, 10)}
                      keys={LACCKeys.locationOfTraff}
                      value="location"
                  xaxis="Number of Selections"
                      yaxis="Location"
                  />
                  <TableWithTotal
                     noTotalRow
                  id={`${props.data.id}-table`}
                  name={props.data.name}
                  horizontalSort
                  nameKey='location'
                    data={props.data.value.locationTrafficking.sort((a, b) => b.total - a.total)}
                    // descending
                    headers={['Location', 'Number of Selections']}
                    columnKeys={['location', 'total']}
                    totalKey='total'
                  />
                  <CSVLink
                  data={props.data.value.locationTrafficking.sort((a, b) => {
                    if (a.total === b.total) {
                      return a.location < b.location ? -1 : 1;
                    }
                    return b.total - a.total;
                  })}
                    headers={[{ label: 'Location', key: 'location' }, { label: 'Number of Selections', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }


              {props.data.type === 'Care Coordination' && props.data.name === 'Overview  >  Screenings' &&
                <div>
                  <GroupedBarGraph
                    report
                    id={props.data.id}
                    keys={LACCKeys.screenings}
                    data={props.data.value.screenings}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                  />
                  <TableForYearGraphs
                    noTotalRow
                    id={`${props.data.id}-table`}
                    name='Screenings'
                    data={props.data.value.screenings}
                    keys={LACCKeys.screenings}
                    columnValue='Value'
                  />

                  <CSVLink
                    data={props.data.value.screenings}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'CSE-IT', key: 'tp_cse_it_screenings' }, { label: 'Labor Trafficking', key: 'tp_labor_screenings' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Race' &&
                <div>
                  <SingleBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.demographicsRace.sort((a, b) =>
                      LACCKeys.demographicsRace.indexOf(LACCKeys.demographicsRace.filter(obj => obj.variable === a.source)[0])
                      -
                      LACCKeys.demographicsRace.indexOf(LACCKeys.demographicsRace.filter(obj => obj.variable === b.source)[0]))}
                    keys={LACCKeys.demographicsRace}
                    xaxis="Race"
                    yaxis="Number of Individuals"
                    type="cc"
                    smalltext={true}
                  />
                  <TableWithTotal
                  id={`${props.data.id}-table`}
                  name={props.data.name}
                    data={props.data.value.demographicsRace.sort((a, b) =>
                      LACCKeys.demographicsRace.indexOf(LACCKeys.demographicsRace.filter(obj => obj.variable === a.source)[0])
                      -
                      LACCKeys.demographicsRace.indexOf(LACCKeys.demographicsRace.filter(obj => obj.variable === b.source)[0]))}
                    keys={LACCKeys.demographicsRace}
                    headers={['Race', 'Number of Individuals']}
                    columnKeys={['source', 'value']}
                    totalKey='value'
                  />
                  <CSVLink
                    data={laCCRaceCsvData}
                    headers={[{ label: 'Race', key: 'name' }, { label: 'Number of Individuals', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Ethnicity' &&
                <div>
                  <SingleBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.demographicsEthnicity}
                    keys={LACCKeys.demographicsEthnicity}
                    xaxis="Ethnicity"
                    yaxis="Number of Individuals"
                    type="cc"
                    smalltext={true}
                  />
                  <TableWithTotal
                  id={`${props.data.id}-table`}
                  name={props.data.name}
                    data={props.data.value.demographicsEthnicity}
                    keys={LACCKeys.demographicsEthnicity}
                    headers={['Ethnicity', 'Number of Individuals']}
                    columnKeys={['source', 'value']}
                    totalKey='value'
                  />
                  <CSVLink
                    data={laCCEthnicityCsvData}
                    headers={[{ label: 'Ethnicity', key: 'name' }, { label: 'Number of Individuals', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Gender Identity' &&
                <div>
                  <SingleBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.demographicsGender.sort((a, b) =>
                      LACCKeys.demographicsGender.indexOf(LACCKeys.demographicsGender.filter(obj => obj.variable === a.source)[0])
                      -
                      LACCKeys.demographicsGender.indexOf(LACCKeys.demographicsGender.filter(obj => obj.variable === b.source)[0]))}
                    keys={LACCKeys.demographicsGender}
                    xaxis="Gender Identity"
                    yaxis="Number of Individuals"
                    type="cc"
                    smalltext={true}
                  />
                  <TableWithTotal
                  id={`${props.data.id}-table`}
                  name={props.data.name}
                    data={props.data.value.demographicsGender.sort((a, b) =>
                      LACCKeys.demographicsGender.indexOf(LACCKeys.demographicsGender.filter(obj => obj.variable === a.source)[0])
                      -
                      LACCKeys.demographicsGender.indexOf(LACCKeys.demographicsGender.filter(obj => obj.variable === b.source)[0]))}
                    keys={LACCKeys.demographicsGender}
                    headers={['Gender Identity', 'Number of Individuals']}
                    columnKeys={['source', 'value']}
                    totalKey='value'
                  />
                  <CSVLink
                    data={laCCGenderCsvData}
                    headers={[{ label: 'Gender Identity', key: 'name' }, { label: 'Number of Individuals', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Age' &&
                <div>
                  <SingleBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.demographicsAge}
                    keys={LACCKeys.demographicsAge}
                    xaxis="Age"
                    yaxis="Number of Individuals"
                    type="cc"
                    smalltext={true}
                  />
                  <TableWithTotal
                  id={`${props.data.id}-table`}
                  name={props.data.name}
                    data={props.data.value.demographicsAge}
                    keys={LACCKeys.demographicsAge}
                    headers={['Age', 'Number of Individuals']}
                    columnKeys={['source', 'value']}
                    totalKey='value'
                  />
                  <CSVLink
                    data={laCCAgeCsvData}
                    headers={[{ label: 'Age', key: 'name' }, { label: 'Number of Individuals', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Demographics  >  Citizenship' &&
                <div>
                  <HorizontalBarChart
                    report
                    id={props.data.id}
                    data={props.data.value.demographicsCitizen.sort((a, b) => b.total - a.total).slice(0, 5)}
                    value="country"
                    xaxis="Number of Individuals"
                    yaxis="Country of Origin"
                  />
                  <TableWithTotal
                  id={`${props.data.id}-table`}
                  name={props.data.name}
                    data={props.data.value.demographicsCitizen.sort((a, b) => b.total - a.total).slice(0, 5)}
                  horizontalSort
                  nameKey='country'
                    headers={['Country of Origin', 'Number of Individuals']}
                    columnKeys={['country', 'total']}
                    totalKey='total'
                  />
                  <CSVLink
                  data={props.data.value.demographicsCitizen.sort((a, b) => {
                    if (a.total === b.total) {
                      return a.country < b.country ? -1 : 1;
                    }
                    return b.total - a.total;
                  }).slice(0, 5)}
                    headers={[{ label: 'Country of Origin', key: 'country' }, { label: 'Number of Individuals', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Victimization  >  Trafficking Type' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.traffickingType}
                    keys={LACCKeys.traffickingType}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    name='Trafficking Type'
                    data={props.data.value.traffickingType}
                    keys={LACCKeys.traffickingType}
                    columnValue='Number of Individuals'
                  />
                  <CSVLink
                    data={props.data.value.traffickingType}
                  headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Sex Trafficking', key: 'victimization_sex_traff' }, { label: 'Labor Trafficking', key: 'victimization_labor_traff' }, { label: 'Both Sex and Labor Trafficking', key: 'victimization_sex_labor_traff' }, { label: 'Unknown', key: 'victimization_unknown_traff'}, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Victimization  >  Co-Occurring Victimization Type' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.coOccurringVictimType}
                    keys={LACCKeys.coOccurringVictimType}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.coOccurringVictimType}
                    keys={LACCKeys.coOccurringVictimType}
                    name='Victimization Type'
                    columnValue='Number of Individuals'
                  />
                  <CSVLink
                    data={props.data.value.coOccurringVictimType}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Adult Physical Assault', key: 'adult_physical_assault' }, { label: 'Adult Sexual Assault', key: 'adult_sexual_assault' }, { label: 'Adults sexually abused as children', key: 'adult_sexually_abused_as_child' }, { label: 'Arson', key: 'arson' }, { label: 'Bullying', key: 'bullying' }, { label: 'Burglary', key: 'burglary' }, { label: 'Child physical abuse or neglect', key: 'child_physical_abuse' }, { label: 'Child pornography', key: 'child_pornography' }, { label: 'Child sexual abuse/assault', key: 'child_sexual_abuse' }, { label: 'Cyber crimes', key: 'cyber_crimes' }, { label: 'Domestic and/or family violence', key: 'domestic_violence' }, { label: 'DUI/DWI incidents', key: 'dui_incidents' }, { label: 'Elder abuse or neglect', key: 'elder_abuse' }, { label: 'Gang violence', key: 'gang_violence' }, { label: 'Hate crime', key: 'hate_crime' }, { label: 'Identity theft/fraud/financial crime', key: 'identity_theft' }, { label: 'Kidnapping (noncustodial)', key: 'kidnapping_non_custodial' }, { label: 'Kidnapping (custodial)', key: 'kidnapping_custodial' }, { label: 'Mass violence', key: 'mass_violence' }, { label: 'Other vehicular victimization', key: 'other_vehicular_victimization' }, { label: 'Robbery', key: 'robbery' }, { label: 'Stalking/harassment', key: 'stalking' }, { label: 'Survivors of homicide victims', key: 'survivors_of_homicide_victims' }, { label: 'Teen dating victimization', key: 'teen_dating' }, { label: 'Terrorism', key: 'terrorism' }, { label: 'Other victimization', key: 'other_victimization' }, { label: 'Multiple types', key: 'more_than_one_victimization' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Victimization  >  Special Classification Category' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.specialClassification}
                    keys={LACCKeys.specialClassification}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.specialClassification}
                    keys={LACCKeys.specialClassification}
                    name='Category'
                    columnValue='Number of Individuals'
                  />
                  <CSVLink
                    data={props.data.value.specialClassification}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Deaf/hard of hearing', key: 'hard_of_hearing' }, { label: 'Homeless', key: 'homeless' }, { label: 'Immigrants/refugees/asylum seekers', key: 'immigrants' }, { label: 'LGBTQ/two-spirit persons', key: 'lgbtq' }, { label: 'Veterans', key: 'veterans' }, { label: 'Victims with disabilities: cognitive/physical/mental', key: 'disabilities' }, { label: 'Victims with limited English proficiency', key: 'limited_english' }, { label: 'Other special classification', key: 'other_special_classification' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Victim Services  >  Services by Type' && servicesByTypeDisplay === 'relatives' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value.referralServicesByType}
                    keys={LACCKeys.servicesByTypeRelatives}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.referralServicesByType}
                    keys={LACCKeys.servicesByTypeRelatives}
                  name={props.data.name}
                    columnValue='Number of Individuals'
                  />
                  <CSVLink
                    data={props.data.value.referralServicesByType}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Information and Referrals', key: 'referral_services_relatives' }, { label: 'Personal Advocacy and Accompaniment', key: 'personal_advocacy_relatives' }, { label: 'Emotional Support, Safety, and Health', key: 'emotional_support_relatives' }, { label: 'Shelter and Housing', key: 'housing_services_relatives' }, { label: 'Criminal or Civil Justice System Assistance', key: 'criminal_justice_assist_relatives' }, { label: 'Education, Employment, and Life Skills', key: 'life_skills_relatives' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }
              {props.data.type === 'Care Coordination' && props.data.name === 'Victim Services  >  Services by Type' && servicesByTypeDisplay === 'default' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.referralServicesByType}
                    keys={LACCKeys.referralServicesByType}
                    xaxis="Time"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value?.referralServicesByType}
                    keys={LACCKeys.referralServicesByType}
                  name={props.data.name}
                    columnValue='Number of Individuals'
                  />
                  <CSVLink
                    data={props.data.value.referralServicesByType}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Information and Referrals', key: 'referral_services_suspected' }, { label: 'Personal Advocacy and Accompaniment', key: 'personal_advocacy_suspected_victims' }, { label: 'Emotional Support, Safety, and Health', key: 'emotional_support_suspected_victims' }, { label: 'Shelter and Housing', key: 'housing_services_suspected_victims' }, { label: 'Criminal or Civil Justice System Assistance', key: 'criminal_justice_assist_suspected' }, { label: 'Education, Employment, and Life Skills', key: 'life_skills_suspected_victims' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Information and Referrals" && servicesByTypeDisplay === 'default' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.informationReferrals}
                    keys={LACCKeys.informationReferrals}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.informationReferrals}
                    keys={LACCKeys.informationReferrals}
                    name='Information and Referrals'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.informationReferrals}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Criminal justice process information', key: 'criminal_justice_victim_information' }, { label: 'Victim rights information', key: 'rights_victim_information' }, { label: 'Victim service program referrals', key: 'other_program_victim_referrals' }, { label: 'Other referrals', key: 'other_resource_victim_referrals' }, { label: 'Substance use treatment information', key: 'substance_victim_information' }, { label: 'Substance use treatment referrals', key: 'substance_victim_referral' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

            {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Information and Referrals" && servicesByTypeDisplay === 'relatives' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.informationReferrals}
                    keys={LACCKeys.informationReferralsRelatives}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.informationReferrals}
                    keys={LACCKeys.informationReferralsRelatives}
                    name='Information and Referrals'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.informationReferrals}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Criminal justice process information', key: 'criminal_justice_victim_information' }, { label: 'Victim rights information', key: 'rights_victim_information' }, { label: 'Victim service program referrals', key: 'other_program_victim_referrals' }, { label: 'Other referrals', key: 'other_resource_victim_referrals' }, { label: 'Substance use treatment information', key: 'substance_victim_information' }, { label: 'Substance use treatment referrals', key: 'substance_victim_referral' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Personal Advocacy and Accompaniment" && servicesByTypeDisplay === 'default' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.personalAdvocacy}
                    keys={LACCKeys.personalAdvocacy}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.personalAdvocacy}
                    keys={LACCKeys.personalAdvocacy}
                    name='Personal Advocacy and Accompaniment'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.personalAdvocacy}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Medical care advocacy', key: 'advocacy_medical_care_victims' }, { label: 'Forensic exam advocacy', key: 'advocacy_forensic_exam_victims' }, { label: 'Law enforcement advocacy', key: 'law_interview_advocacy_victims' }, { label: 'Individual advocacy', key: 'individual_advocacy_victims' }, { label: 'Forensic exam', key: '' }, { label: 'Immigration assistance advocacy', key: 'immigration_advocacy_victims' }, { label: 'Intervention with employer, etc.', key: 'intervention_advocacy_victims' }, { label: 'Child or dependent care assistance', key: 'dependent_care_victims' }, { label: 'Transportation assistance', key: 'transportation_victims' }, { label: 'Interpreter services', key: 'interpreter_advocacy_victims' }, { label: 'Family reunification', key: '' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

          {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Personal Advocacy and Accompaniment" && servicesByTypeDisplay === 'relatives' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.personalAdvocacy}
                    keys={LACCKeys.personalAdvocacyRelatives}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.personalAdvocacy}
                    keys={LACCKeys.personalAdvocacyRelatives}
                    name='Personal Advocacy and Accompaniment'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.personalAdvocacy}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Medical care advocacy', key: 'advocacy_medical_care_victims' }, { label: 'Forensic exam advocacy', key: 'advocacy_forensic_exam_victims' }, { label: 'Law enforcement advocacy', key: 'law_interview_advocacy_victims' }, { label: 'Individual advocacy', key: 'individual_advocacy_victims' }, { label: 'Forensic exam', key: '' }, { label: 'Immigration assistance advocacy', key: 'immigration_advocacy_victims' }, { label: 'Intervention with employer, etc.', key: 'intervention_advocacy_victims' }, { label: 'Child or dependent care assistance', key: 'dependent_care_victims' }, { label: 'Transportation assistance', key: 'transportation_victims' }, { label: 'Interpreter services', key: 'interpreter_advocacy_victims' }, { label: 'Family reunification', key: '' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }


              {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Emotional Support, Safety, and Health" && servicesByTypeDisplay === 'default' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.emotionalSupport}
                    keys={LACCKeys.emotionalSupport}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.emotionalSupport}
                    keys={LACCKeys.emotionalSupport}
                    name='Emotional Support, Safety, and Health'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.emotionalSupport}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Crisis intervention', key: 'crisis_intervention_victims' }, { label: 'Hotline counseling', key: 'hotline_service_victims' }, { label: 'On-scene crisis response', key: 'crisis_response_victims' }, { label: 'Individual mental health', key: 'therapy_services_victims' }, { label: 'Support groups', key: 'support_group_victims' }, { label: 'Other therapy', key: 'other_therapy_victims' }, { label: 'Emergency financial assistance', key: 'financial_assistance_victims' }, { label: 'Emergency medical care', key: 'emergency_medical_care_victims' }, { label: 'Long-term medical care', key: 'long_term_medical_care_victims' }, { label: 'Substance abuse services', key: 'substance_abuse_victims' }, { label: 'Safety planning', key: 'safety_planning_victims' }, { label: 'Case management', key: 'case_management_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }
             

          {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Emotional Support, Safety, and Health" && servicesByTypeDisplay === 'relatives' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.emotionalSupport}
                    keys={LACCKeys.emotionalSupportRelatives}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.emotionalSupport}
                    keys={LACCKeys.emotionalSupportRelatives}
                    name='Emotional Support, Safety, and Health'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.emotionalSupport}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Crisis intervention', key: 'crisis_intervention_victims' }, { label: 'Hotline counseling', key: 'hotline_service_victims' }, { label: 'On-scene crisis response', key: 'crisis_response_victims' }, { label: 'Individual mental health', key: 'therapy_services_victims' }, { label: 'Support groups', key: 'support_group_victims' }, { label: 'Other therapy', key: 'other_therapy_victims' }, { label: 'Emergency financial assistance', key: 'financial_assistance_victims' }, { label: 'Emergency medical care', key: 'emergency_medical_care_victims' }, { label: 'Long-term medical care', key: 'long_term_medical_care_victims' }, { label: 'Substance abuse services', key: 'substance_abuse_victims' }, { label: 'Safety planning', key: 'safety_planning_victims' }, { label: 'Case management', key: 'case_management_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Shelter and Housing" && servicesByTypeDisplay === 'default' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.shelterHousing}
                    keys={LACCKeys.shelterHousing}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.shelterHousing}
                    keys={LACCKeys.shelterHousing}
                    name='Shelter and Housing'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.shelterHousing}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Emergency shelter or placement', key: 'emergency_shelter_victims' }, { label: 'Transitional housing', key: 'transitional_housing_victims' }, { label: 'Relocation assistance', key: 'relocation_assistance_victims' }, { label: 'Rapid rehousing', key: 'rapid_rehousing_victims' }, { label: 'Rental assistance', key: 'rental_assistance_victims' }, { label: 'Host homes', key: 'host_homes_victims' }, { label: 'Other', key: 'other_housing_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

          {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Shelter and Housing" && servicesByTypeDisplay === 'relatives' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.shelterHousing}
                    keys={LACCKeys.shelterHousingRelatives}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.shelterHousing}
                    keys={LACCKeys.shelterHousingRelatives}
                    name='Shelter and Housing'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.shelterHousing}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Emergency shelter or placement', key: 'emergency_shelter_victims' }, { label: 'Transitional housing', key: 'transitional_housing_victims' }, { label: 'Relocation assistance', key: 'relocation_assistance_victims' }, { label: 'Rapid rehousing', key: 'rapid_rehousing_victims' }, { label: 'Rental assistance', key: 'rental_assistance_victims' }, { label: 'Host homes', key: 'host_homes_victims' }, { label: 'Other', key: 'other_housing_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Criminal or Civil Justice System Assistance" && servicesByTypeDisplay === 'default' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.criminalCivilJustice}
                    keys={LACCKeys.criminalCivilJustice}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.criminalCivilJustice}
                    keys={LACCKeys.criminalCivilJustice}
                    name='Criminal or Civil Justice System Assistance'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.criminalCivilJustice}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Event notification', key: 'criminal_justice_notification_victims' }, { label: 'Impact statement', key: 'impact_statement_assistance_victims' }, { label: 'Restitution assistance', key: 'restitution_assistance_victims' }, { label: 'Protection or restraining order', key: '' }, { label: 'Family law issues', key: 'family_law_assistance_victims' }, { label: 'Other emergency assistance', key: 'other_emergency_justice_victims' }, { label: 'Immigration assistance', key: 'immigration_assistance_victims' }, { label: 'Prosecution advocacy', key: 'prosecution_interview_victims' }, { label: 'Law enforcement advocacy', key: '' }, { label: 'Criminal justice advocacy', key: 'criminal_justice_services_victims' }, { label: 'Other legal advice', key: 'other_legal_advice_victims' }, { label: 'Repatriation', key: 'repatriation_services_victims' }, { label: 'Public benefits law', key: 'public_benefits_law_victims' }, { label: 'Criminal record relief', key: 'criminal_record_relief_victims' }, { label: "Victim's rights representation", key: 'victim_rights_services_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

          {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Criminal or Civil Justice System Assistance" && servicesByTypeDisplay === 'relatives' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.criminalCivilJustice}
                    keys={LACCKeys.criminalCivilJusticeRelatives}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.criminalCivilJustice}
                    keys={LACCKeys.criminalCivilJusticeRelatives}
                    name='Criminal or Civil Justice System Assistance'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.criminalCivilJustice}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Event notification', key: 'criminal_justice_notification_victims' }, { label: 'Impact statement', key: 'impact_statement_assistance_victims' }, { label: 'Restitution assistance', key: 'restitution_assistance_victims' }, { label: 'Protection or restraining order', key: '' }, { label: 'Family law issues', key: 'family_law_assistance_victims' }, { label: 'Other emergency assistance', key: 'other_emergency_justice_victims' }, { label: 'Immigration assistance', key: 'immigration_assistance_victims' }, { label: 'Prosecution advocacy', key: 'prosecution_interview_victims' }, { label: 'Law enforcement advocacy', key: '' }, { label: 'Criminal justice advocacy', key: 'criminal_justice_services_victims' }, { label: 'Other legal advice', key: 'other_legal_advice_victims' }, { label: 'Repatriation', key: 'repatriation_services_victims' }, { label: 'Public benefits law', key: 'public_benefits_law_victims' }, { label: 'Criminal record relief', key: 'criminal_record_relief_victims' }, { label: "Victim's rights representation", key: 'victim_rights_services_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Education, Employment, and Life Skills"  && servicesByTypeDisplay === 'default' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.educationEmployment}
                    keys={LACCKeys.educationEmployment}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.educationEmployment}
                    keys={LACCKeys.educationEmployment}
                    name='Education, Employment, and Life Skills'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.educationEmployment}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Education', key: 'education_services_victims' }, { label: 'Job/vocational training', key: 'job_training_services_victims' }, { label: 'Job readiness/employment', key: 'job_readiness_services_victims' }, { label: 'Life skills', key: 'life_skill_services_victims' }, { label: 'Supported employment', key: 'employment_services_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

          {props.data.type === 'Care Coordination' && props.data.name === "Victim Services  >  Education, Employment, and Life Skills"  && servicesByTypeDisplay === 'relatives' &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.educationEmployment}
                    keys={LACCKeys.educationEmploymentRelatives}
                    xaxis="Time"
                    yaxis="Number of Services"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Services"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.educationEmployment}
                    keys={LACCKeys.educationEmploymentRelatives}
                    name='Education, Employment, and Life Skills'
                    columnValue='Number of Services'
                  />

                  <CSVLink
                    data={props.data.value.educationEmployment}
                    headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Education', key: 'education_services_victims' }, { label: 'Job/vocational training', key: 'job_training_services_victims' }, { label: 'Job readiness/employment', key: 'job_readiness_services_victims' }, { label: 'Life skills', key: 'life_skill_services_victims' }, { label: 'Supported employment', key: 'employment_services_victims' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }   

              {props.data.type === 'Care Coordination' && props.data.name === "Partnerships  >  Partners Involved" &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.partnerships}
                    keys={LACCKeys.partnerships}
                    xaxis="Time"
                    yaxis="Number of Partners"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Partners"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.partnerships}
                    keys={LACCKeys.partnerships}
                    name='Partner Type'
                    columnValue='Number'
                  />

                  <CSVLink
                    data={props.data.value.partnerships}
                  headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'State law enforcement agency', key: 'state_law_total_partners' }, { label: 'Local law enforcement agency', key: 'local_law_total_partners' }, { label: 'Tribal law enforcement agency', key: 'tribal_law_total_partners' }, { label: 'Federal Bureau of Investigation (FBI)', key: 'fbi_total_partners' }, { label: 'Homeland Security Investigations (HSI)',key: 'hsi_total_partners'},{ label: 'Victim services provider/agency',key: 'victim_services_total_partners'},
                    { label: "United States Attorney's Office (USAO)", key: 'usao_total_partners'},
                    { label: 'State/Local prosecutor/Prosecutors office', key: 'prosecutor_office_total_partners' }, { label: 'State agency', key: 'state_agency_total_partners' }, { label: 'Tribal agency', key: 'tribal_agency_total_partners' }, { label: 'Local agency', key: 'local_agency_total_partners' }, { label: 'Immigrant/Refugee services agency', key: 'immigrant_agency_total_partners' }, { label: 'Interpreters/Language assistance services', key: 'interpreter_services_total_partners' }, { label: 'Legal services provider', key: 'legal_provider_total_partners' }, { label: 'Courts', key: 'courts_total_partnerts' }, { label: 'Corrections/Community corrections', key: 'corrections_total_partners' }, { label: 'Medical professionals/healthcare providers', key: 'healthcare_total_partners' }, { label: 'Mental health care providers', key: 'mental_health_total_partners' }, { label: 'Substance abuse treatment providers', key: 'substance_abuse_total_providers' }, { label: 'Child advocacy centers', key: 'child_advocacy_total_partners' }, { label: 'Community-based service providers', key: 'community_total_partners' }, { label: 'Faith-based organizations', key: 'faith_based_total_partners' }, { label: 'Foundations/Philanthropic organizations', key: 'foundations_total_partners' }, { label: 'Businesses', key: 'businesses_total_partners' }, { label: 'Local community group or community member', key: 'local_community_total_partners' }, { label: 'Other partners',key: 'other_partner_total'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === "Partnerships  >  Partnership Building" &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.partnershipBuilding}
                    keys={LACCKeys.partnershipBuilding}
                    xaxis="Time"
                    yaxis="Number of Activities"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.partnershipBuilding}
                    keys={LACCKeys.partnershipBuilding}
                    name='Partnership Activities'
                    columnValue='Number of Partners'
                  />

                  <CSVLink
                    data={props.data.value.partnershipBuilding}
                  headers={[{ label: 'Year', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Increase grantee capacity to identity/serve victims', key: 'grantee_capacity_increase_activities' }, { label: 'Increase client access to a range of services', key: 'service_access_increase_activities' }, { label: 'Increase awareness of the issue of trafficking', key: 'traff_awareness_increase_activities' }, { label: 'Increase sharing of professional expertise', key: 'expertise_sharing_increase_activities' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

            {props.data.type === 'Care Coordination' && props.data.name === "Training and Meetings  >  Training Types" &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.trainingsAndMeetings}
                    keys={LACCKeys.trainingsAndMeetings}
                    xaxis="Time"
                    yaxis="Number of Trainings"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Trainings"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.trainingsAndMeetings}
                    keys={LACCKeys.trainingsAndMeetings}
                    name='Training Types'
                    columnValue='Number of Trainings'
                  />

                  <CSVLink
                    data={props.data.value.trainingsAndMeetings}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

            {props.data.type === 'Care Coordination' && props.data.name === "Training and Meetings  >  Participant Types" &&
                <div>
                <VerticalMirrorBarChart
                  report
                  id={props.data.id}
                  data={props.data.value?.participantTypes}
                  keys={LACCKeys.participantTypes}
                  sourceVar='label'
                  leftKeys={{ label: 'Internal Trainings', var: 'internal' }}
                  rightKeys={{ label: 'External Trainings', var: 'external' }}
                  xaxis='Number of Selections'
                  yaxis='Participant Type'
                />
                <TableWithTotal
                  alphabeticalSort
                  id={`${props.data.id}-table`}
                  name={props.data.name}
                  data={props.data.value?.participantTypes}
                  noTotalRow
                  nameKey='label'
                  //keys={LACCKeys.participantTypes}
                  headers={['Participant Type', 'Number of Selections - Internal Training', 'Number of Selections - External Training']}
                  columnKeys={['label', 'internal', 'external']}
                  
                />
                  <CSVLink
                  data={props.data.value?.participantTypes}
                  headers={[{ label: 'Participant Type', key: 'label' }, { label: 'Internal Training', key: 'internal' }, { label: 'External Training', key: 'external' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === "Training and Meetings  >  Care Coordination Meetings" &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.careCoordinationMeetings}
                    keys={LACCKeys.careCoordinationMeetings}
                    xaxis="Time"
                    yaxis="Number"
                    trendLine={props.data.chartLine}
                    trendLineLegend="Total Meetings"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                  data={props.data.value.careCoordinationMeetings}
                  keys={LACCKeys.careCoordinationMeetings}
                  name='Care Coordination Meetings'
                    columnValue='total'
                  />

                  <CSVLink
                  data={props.data.value.careCoordinationMeetings}
                  headers={[ { label: 'Year', key: 'name' }, { label: 'Emergency Response Staffings', key: 'emergency_staffings' }, { label: 'Care Coordination Meetings', key: 'cc_meetings' }, { label: 'New Cases Reviewed', key: 'new_cases_reviewed' }, { label: 'Advisory Team Meetings', key: 'advisory_meetings' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

              {props.data.type === 'Care Coordination' && props.data.name === 'Training and Meetings  >  Funding' &&
                <div>
                  <GroupedBarGraph
                    report
                    id={props.data.id}
                  data={props.data.value?.funding}
                  keys={LACCKeys.funding}
                  xaxis="Time"
                  yaxis="Number"
                  yaxisMaxValue={true}
                  />
                 
                <TableForYearGraphs
                  noTotalRow
                  id={`${props.data.id}-table`}
                  name='Funded Items'
                  data={props.data.value?.funding}
                  keys={LACCKeys.funding}
                  columnValue='Value'
                />

                  <CSVLink
                    data={props.data.value.funding}
                  headers={[{ label: 'Time', key: 'name' }, { label: 'Quarter', key: 'type' }, { label: 'Grant-Funded Participants', key: 'totalServed' }, { label: 'Grant-Funded Trainings', key: 'totalNew' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

            {props.data.type === 'Care Coordination' && props.data.name === "Activities  >  Community Outreach" &&
                <div>
                <StackedBarGraph
                  report
                  id={props.data.id}
                  noPercent
                  data={props.data.value?.outreachTypes}
                  keys={LACCKeys.outreachTypes}
                  xaxis="Time"
                  yaxis="Number of Selections"
                trendLine={true}
                trendLineLegend="Total Individuals"
                />
                <TableForYearGraphs
                  noTotalRow
                  id={`${props.data.id}-table`}
                  name='Community Activities'
                  data={props.data.value?.outreachTypes}
                  keys={LACCKeys.outreachTypes}
                  columnValue='Number of Selections'
                />
                  <CSVLink
                  data={props.data.value?.outreachTypes}
                  headers={csvForYearCharts(LACCKeys.outreachTypes)}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }

               {props.data.type === 'Care Coordination' && props.data.name === "Activities  >  Law Enforcement" &&
                <div>
                  <StackedBarGraph
                    report
                    id={props.data.id}
                    data={props.data.value?.lawEnforcement}
                    keys={LACCKeys.lawEnforcement}
                    xaxis="Time"
                    yaxis="Number of Activities"
                    trendLine={true}
                    trendLineLegend="Law Enforcement"
                    noPercent={true}
                  />
                  <TableForYearGraphs
                    id={`${props.data.id}-table`}
                    data={props.data.value.lawEnforcement}
                    keys={LACCKeys.lawEnforcement}
                    name='Law Enforcement Activities'
                    columnValue='Value'
                  />

                  <CSVLink
                    data={props.data.value.lawEnforcement}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              }


              {/* Law Enforcement */}
              {props.data.name ===
                "Case and Agency Information  >  Cases by Year of Arrest (by Agency)" ? (
                  <div>
                    <GroupedBarVariableXGraph
                      xaxis="Year of Arrest"
                      yaxis="Number of Cases"
                      data={
                        props.data.value.casesYearAgency
                          ? props.data.value.casesYearAgency
                          : publicsafetymockdata.casesYearAgency
                      }
                      keys={publicsafetydata.casesYearAgency}
                      report={true}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Cases"
                      id={props.data.id}
                    />
                    <PublicSafetyTable
                      data={
                        props.data.value.casesYearAgency
                          ? props.data.value.casesYearAgency
                          : publicsafetymockdata.casesYearAgency
                      }
                      keys={publicsafetydata.casesYearAgency}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={
                        props.data.value.casesYearAgency
                          ? props.data.value.casesYearAgency
                          : ""
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Case and Agency Information  >  Cases by Year of Arrest" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.casesYearPenal
                          ? props.data.value.casesYearPenal
                          : publicsafetymockdata.casesYearPenal
                      }
                      keys={publicsafetydata.casesYearPenal}
                      xaxis="Year of Arrest"
                      yaxis="Number of Cases"
                      report={true}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Cases"
                      id={props.data.id}
                    />
                    <PublicSafetyTable
                      data={props.data.value.casesYearPenal}
                      keys={publicsafetydata.casesYearPenal}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={
                        props.data.value.casesYearPenal
                          ? props.data.value.casesYearPenal
                          : ""
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

                
              {props.data.name ===
                "Case and Agency Information  >  Cases by Year of Arrest (Top Ten Offenses)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.casesYearOffense
                          ? props.data.value.casesYearOffense
                          : publicsafetymockdata.casesYearOffense
                      }
                      keys={stateLocation === 'TX' ? publicsafetydata.casesYearOffenseTX : publicsafetydata.casesYearOffenseLA}
                      xaxis="Year of Arrest"
                      yaxis="Number of Cases"
                      report={true}
                      id={props.data.id}
                    />
                    <PublicSafetyTable
                      data={props.data.value.casesYearOffense}
                      keys={stateLocation === 'TX' ? publicsafetydata.casesYearOffenseTX : publicsafetydata.casesYearOffenseLA}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={
                        props.data.value.casesYearOffense
                          ? props.data.value.casesYearOffense
                          : ""
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Case and Agency Information  >  Cases by Year of Arrest (by Offense Group)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.casesYearOffense
                      }
                      keys={stateLocation === 'TX' ? publicsafetydata.casesYearOffenseTX : stateLocation === 'LA' ? publicsafetydata.casesYearOffenseLA : publicsafetydata.casesYearOffenseFL}
                      xaxis="Years"
                       yaxis="Number of Cases"
                      report={true}
                      id={props.data.id}
                    />
                     <TableWithTotal
                        id={`${props.data.id}-table`}
                        name={props.data.name}
                        data={props.data.value.casesYearOffense}
                        ascending
                        toFixed={0}
                      headers={props.stateLocation === 'TX' ? ['Year', 'Indecency with a Child - Sexual Contact/Exposure of Genitals with Intent to Gratify', 'Aggravated Sexual Assault Child', 'Prostitution (before 2015)', 'Sexual Assault of a Child', 'Prostitution 1+ Convictions (before 2015)', 'Sexual Assault', 'Prostitution - Seller', 'Possession of Child Pornography with 1+ Previous Conviction', 'Continuous Sex Abuse of a Child', 'Prostitution 1+ Convictions - Seller', 'Total'] : props.stateLocation === 'LA' ? ['Year', 'Trafficking of Persons', 'Internet Crimes Against Children', 'Sexual Assault Against Minors', 'Prostitution Offenses', 'Total'] : ['Year', 'Human Trafficking', 'Prostitution', 'Unknown']}
                      columnKeys={props.stateLocation === 'TX' ? ['xaxis', 'offense1', 'offense2', 'offense3', 'offense4', 'offense5', 'offense6', 'offense7', 'offense8', 'offense9', 'offense10', 'total'] : props.stateLocation === 'LA' ? ['xaxis', 'offense1', 'offense2', 'offense3', 'offense4', 'total'] : ['xaxis', 'offense1', 'offense2', 'offense3']}
                        totalKey='total'
                        totalEachColumn
                    />
                    <CSVLink
                      data={
                        props.data.value.casesYearOffense
                          ? props.data.value.casesYearOffense
                          : ""
                      }
                      headers={props.stateLocation === 'TX' ? [{ label: 'Year', key: 'xaxis' }, { label: 'Indecency with a Child - Sexual Contact/Exposure of Genitals with Intent to Gratify', key: 'offense1' }, { label: 'Aggravated Sexual Assault Child', key: 'offense2' }, { label: 'Prostitution (before 2015)', key: 'offense3' }, { label: 'Sexual Assault of a Child', key: 'offense4' }, { label: 'Prostitution 1+ Convictions (before 2015)', key: 'offense5' }, { label: 'Sexual Assault', key: 'offense6' }, { label: 'Prostitution - Seller', key: 'offense7' }, { label: 'Possession of Child Pornography with 1+ Previous Conviction', key: 'offense8' }, { label: 'Continuous Sex Abuse of a Child', key: 'offense9' }, { label: 'Prostitution 1+ Convictions - Seller', key: 'offense10' }, { label: 'Total', key: 'total' }] : props.stateLocation === 'LA' ? [{ label: 'Year', key: 'xaxis' }, { label: 'Trafficking of Persons', key: 'offense1' }, { label: 'Internet Crimes Against Children', key: 'offense2' }, { label: 'Sexual Assault Against Minors', key: 'offense3' }, { label: 'Prostitution Offenses', key: 'offense4' }, { label: 'Total', key: 'total' }] : [{ label: 'Year', key: 'xaxis' }, { label: 'Human Trafficking', key: 'offense1' }, { label: 'Prostitution', key: 'offense2' }, { label: 'Unknown', key: 'offense3' }, { label: 'Total', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Case and Agency Information  >  Arrest Cases by Location and Population" ? (
                  <div>
                    <HorizontalBarChart
                        percentOfTotal={props.data.value.arrestCasesByLocationPop}
                        data={props.data.value.arrestCasesByLocationPop.slice(0, 10)} // get top 10 counties for the chart
                        value="city"
                        xaxis="Number of Cases per 100,000"
                        yaxis="Locations"
                      />
                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      data={props.data.value.arrestCasesByLocationPop}
                      descending
                      toFixed={2}
                      headers={['Location', 'Number of Cases by Location and Population']}
                      columnKeys={['city', 'total']}
                      totalKey='total'
                    />
                    <CSVLink
                      data={props.data.value.arrestCasesByLocPopReport}
                      headers={[{ label: 'Location', key: 'city' }, { label: 'Number of Cases by Location and Population', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Case and Agency Information  >  Cases by Arresting Agency" ? (
                  <div>
                    <HorizontalBarChart
                      id={props.data.id}
                      report
                      data={props.data.value.agencyOria}
                      value="agency_oria_agency"
                      xaxis="Number of Cases"
                      yaxis="Top Ten Agencies"
                      smalltext
                      yLabelsWidth={175}
                    />
                    <PublicSafetyHorizontalTable
                      data={props.data.value.agencyOria}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.agencyOria}
                      headers={[{ label: 'Cases by Arresting Agency', key: 'agency_oria_agency' }, {label: 'Value', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Case and Agency Information  >  Cases by Agency (Prosecuting Agency (ORIP))" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.agencyOrip}
                      value="agency_orip_agency"
                      xaxis="Number of Cases"
                      yaxis="Top Ten Agencies"
                      report={true}
                      id={props.data.id}
                    />
                    <PublicSafetyHorizontalTable
                      data={props.data.value.agencyOrip}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.agencyOrip}
                      headers={[{ label: 'Cases by Agency (Prosecuting Agency (ORIP))', key: 'agency_orip_agency'}, {label: 'Value', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Case and Agency Information  >  Cases by Court Agency" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.agencyOric}
                      value="agency_oric_agency"
                      xaxis="Number of Cases"
                      yaxis="Top Ten Agencies"
                      report={true}
                      id={props.data.id}
                    />
                    <PublicSafetyHorizontalTable
                      data={props.data.value.agencyOric}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.agencyOric}
                      headers={[{
                        label: 'Cases by Court Agency', key: 'agency_oric_agency'}, {label: 'Value', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demographics  >  Age (by Penal Code Chapters)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.agePenalCode
                          ? props.data.value.agePenalCode
                          : publicsafetymockdata.agePenalCode
                      }
                      keys={publicsafetydata.agePenalCode}
                      xaxis="Age based on age at offense"
                      yaxis="County of Offense age"
                      report={true}
                      id={props.data.id}
                    />
                    <PublicSafetyTable
                      data={props.data.value.agePenalCode}
                      keys={publicsafetydata.agePenalCode}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      penalCode={true}
                    />
                    <CSVLink
                      data={props.data.value.agePenalCode}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Offender Demographics  >  Age" ? (
                <div>
                  <SingleBarGraph
                    data={
                      props.data.value.ageOffense
                        ? props.data.value.ageOffense
                        : publicsafetymockdata.ageOffense
                    }
                    keys={publicsafetydata.ageOffenseCode}
                    xaxis="Age based on age at offense"
                    yaxis="Number of Individuals"
                    type="cc"
                    report={true}
                    id={props.data.id}
                  />
                  <PublicSafetyTable
                    data={props.data.value.ageOffense}
                    keys={publicsafetydata.ageOffenseCode}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    single={true}
                  />
                  <CSVLink
                    data={props.data.value.ageOffense}
                    headers={[{ label: 'Offender Demographics  >  Age', key: 'source'}, {label: 'Value', key: 'value'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {/* {props.data.name ===
                "Demographics  >  Race (by Penal Code Chapters)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.racePenalCode
                          ? props.data.value.racePenalCode
                          : publicsafetymockdata.racePenalCode
                      }
                      keys={publicsafetydata.racePenalCode}
                      xaxis="Race"
                      yaxis="Number of Individuals"
                      report={true}
                      smalltext={true}
                      wrapText={true}
                      id={props.data.id}
                    />
                    <PublicSafetyTable
                      data={props.data.value.racePenalCode}
                      keys={publicsafetydata.racePenalCode}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      penalCode={true}
                    />
                    <CSVLink
                      data={props.data.value.racePenalCode}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null} */}
              {props.data.name === "Offender Demographics  >  Race" ? (
                <div>
                  <SingleBarGraph
                    data={
                      props.data.value.raceOffense
                        ? props.data.value.raceOffense
                        : publicsafetymockdata.raceOffense
                    }
                    keys={publicsafetydata.raceOffenseCode}
                    xaxis="Race"
                    type="cc"
                    yaxis="Number of Individuals"
                    report={true}
                    id={props.data.id}
                  />
                  <PublicSafetyTable
                    data={props.data.value.raceOffense}
                    keys={publicsafetydata.raceOffenseCode}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    single={true}
                  />
                  <CSVLink
                    data={props.data.value.raceOffense}
                    headers={[{ label: 'Offender Demographics  >  Race', key: 'source'}, {label: 'Value', key: 'value'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.name === "Offender Demographics  >  Ethnicity" ? (
                <div>
                  <SingleBarGraph
                    id={props.data.id}
                    report
                    data={props.data.value.offenderEthnicity ?? []}
                    xaxisVar='source'
                    labelOnHover
                    xaxis="Ethnicity"
                    yaxis="Number of Individuals"
                    type="cc"
                  />
                  <TableWithTotal
                    id={`${props.data.id}-table`}
                    data={props.data.value.offenderEthnicity ?? []}
                    headers={['Ethnicity', '']}
                    columnKeys={['source', 'value']}
                  />
                  <CSVLink
                    data={props.data.value.offenderEthnicity}
                    headers={[{ label: 'Ethnicity', key: 'source' }, { label: 'Number of Individuals', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null} 

              {props.data.name === "Offender Demographics  >  Race/Ethnicity" ? (
                <div>
                  <SingleBarGraph
                    id={props.data.id}
                    report
                    data={props.data.value.offenderRaceEthnicity ?? []}
                    xaxisVar='source'
                    xaxis="Race"
                    yaxis="Number of Individuals"
                    type="cc"
                  />
                  <TableWithTotal
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    data={props.data.value.offenderRaceEthnicity ?? []}
                    headers={['Race/Ethnicity', 'Value']}
                    columnKeys={['source', 'value']}
                  />
                  
                  <CSVLink
                    data={props.data.value.offenderRaceEthnicity}
                    headers={[{ label: 'Race/Ethnicity', key: 'source' }, { label: 'Value', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {/* {props.data.name ===
                "Demographics  >  Ethnicity (by Penal Code Chapters)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.ethnicityPenalCode
                          ? props.data.value.ethnicityPenalCode
                          : publicsafetymockdata.ethnicityPenalCode
                      }
                      keys={publicsafetydata.ethnicityPenalCode}
                      xaxis="Ethnicity"
                      yaxis="Number of Individuals"
                      report={true}
                      id={props.data.id}
                    />
                    <PublicSafetyTable
                      data={props.data.value.ethnicityPenalCode}
                      keys={publicsafetydata.ethnicityPenalCode}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      penalCode={true}
                    />
                    <CSVLink
                      data={props.data.value.ethnicityPenalCode}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
                    */}
              
              {/* {props.data.name ===
                "Demographics  >  Gender (by Penal Code Chapters)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.genderPenalCode
                          ? props.data.value.genderPenalCode
                          : publicsafetymockdata.genderPenalCode
                      }
                      keys={publicsafetydata.genderPenalCode}
                      xaxis="Gender"
                      yaxis="Number of Individuals"
                      report={true}
                      id={props.data.id}
                    />
                    <PublicSafetyTable
                      data={props.data.value.genderPenalCode}
                      keys={publicsafetydata.genderPenalCode}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      penalCode={true}
                    />
                    <CSVLink
                      data={props.data.value.genderPenalCode}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null} */}
              {props.data.name === "Offender Demographics  >  Gender" ? (
                <div>
                  <SingleBarGraph
                    data={
                      props.data.value.genderOffense
                        ? props.data.value.genderOffense
                        : publicsafetymockdata.genderOffense
                    }
                    keys={publicsafetydata.genderOffenseCode}
                    xaxis="Gender"
                    yaxis="Number of Individuals"
                    report={true}
                    type="cc"
                    id={props.data.id}
                  />
                  <PublicSafetyTable
                    data={props.data.value.genderOffense}
                    keys={publicsafetydata.genderOffenseCode}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    single={true}
                  />
                  <CSVLink
                    data={props.data.value.genderOffense}
                    headers={[{ label: 'Offender Demographics  >  Gender', key: 'source'}, {label: 'Value', key: 'value'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {/* {props.data.name ===
                "Demographics  >  Citizenship (by Penal Code Chapters)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.citizenshipPenalCode
                          ? props.data.value.citizenshipPenalCode
                          : publicsafetymockdata.citizenshipPenalCode
                      }
                      keys={publicsafetydata.citizenshipPenalCode}
                      xaxis="Citizenship"
                      yaxis="Number of Individuals"
                      report={true}
                      wrapText={true}
                      id={props.data.id}
                    />
                    <PublicSafetyHorizontalTable
                      data={props.data.value.citizenshipPenalCode}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      penalCode={true}
                    />
                    <CSVLink
                      data={props.data.value.citizenshipPenalCode}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demographics  >  Citizenship (by Offense)" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={
                        props.data.value.citizenshipOffense
                          ? props.data.value.citizenshipOffense
                          : publicsafetymockdata.citizenshipOffense
                      }
                      xaxis="Citizenship"
                      keys={publicsafetydata.citizenshipOffenseCode}
                      yaxis="Number of Individuals"
                      report={true}
                      wrapText={true}
                      id={props.data.id}
                    />
                    <PublicSafetyHorizontalTable
                      data={props.data.value.citizenshipOffense}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.citizenshipOffense}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null} */}
              

              {/* Federal Cases */}
              {props.data.name === "Case Information  >  Federal Cases by Year" ? (
                  <div>
                    <StackedBarVariableXAxis
                      smallXaxis={true}
                      report={true}
                      width={864}
                      id={props.data.id}
                      data={props.data.value.casesYear}
                      xaxis="Year"
                      yaxis="Number of Cases"
                      keys={federalcasesdata.traffTypes}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Cases"
                      type="cc"
                    />
                    <CasesByYearTable
                      data={
                        props.data.value.casesYear
                          ? props.data.value.casesYear
                          : federalcasesdata.traffTypes
                      }
                      keys={federalcasesdata.traffTypes}
                      columnHeaders={['Year', 'Minor Sex Trafficking', 'Adult Sex Trafficking', 'Labor Trafficking', 'Both Sex and Labor Trafficking']}
                      columnVars={[['xaxis'], ['minorsextraf', 'adultsextraf', 'labor', 'both']]}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                      differentTotalVars={true}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.casesYear}
                      headers={[{ label: "Year", key: "name" }, { label: "Minor Sex Trafficking", key: "minorsextraf" }, { label: 'Adult Sex Trafficking', key: 'adultsextraf' }, { label: 'Labor Traffickin', key: 'labor' }, { label: 'Both Sex and Labor Trafficking', key: 'both'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
              ) : null}
              
              {props.data.name ===
                "Case Information  >  Trafficking Locations" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.casesLocation.sort((a,b) => b.total - a.total).slice(0,10)}
                      percentOfTotal={props.data.casesLocation}
                      xaxis="Number"
                      value="xaxis"
                      yaxis="Location"
                      report={true}
                      id={props.data.id}
                    />
                    <FederalCasesHorizontalTable
                      data={props.data.value.casesLocation.sort((a,b) => b.total - a.total)}
                      id={`${props.data.id}-table`}
                      name='Location'
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.casesLocation.sort((a,b) => {
                        return b.total - a.total
                      })}
                      headers={[{label: 'Location', key: 'xaxis'}, {label: 'Value', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name ===
                "Case Information  >  Number of Defendants and Victims Over Time" ? (
                  <div>
                    <SingleBarGraph
                      id={props.data.id}
                      report
                      data={props.data.value.defendantsVictimsTotal ?? []}
                      singleColor
                      rotateXaxis
                      noPercent
                      xaxis="Time"
                      yaxis='Number of Defendants/Victims'
                      yaxisRight='Number of Cases'
                      xaxisVar='xaxis'
                      barLegend={[{ name: 'Cases', var: 'value' }]}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Victims"
                      trendLineColor={1}
                      trendLineData1={[...props.data.value.defendantsVictimsTotal].map(item => ({ ...item, value: item.victim }))}
                      trendLineTwo={props.data.chartLineTwo}
                      trendLineLegendTwo="Defendants"
                      trendLineTwoColor={2}
                      trendLineDataTwo={[...props.data.value.defendantsVictimsTotal].map(item => ({ ...item, value: item.defendant }))}
                      type='cc'
                    />

                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      name={props.data.name.split('>  ')[1]}
                      data={props.data.value.defendantsVictimsTotal ?? []}
                      headers={['Year', 'Number of Defendants', 'Number of Victims', 'Number of Cases']}
                      columnKeys={['xaxis', 'defendant', 'victim', 'value']}
                      totalEachColumn
                    />
                    
                    <CSVLink
                      data={props.data.value.defendantsVictimsTotal}
                      headers={[{ label: 'Year', key: 'xaxis' }, { label: 'Number of Defendants', key: 'defendant' }, { label: 'Number of Victims', key: 'victim' }, { label: 'Number of Cases', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name ===
                "Case Information  >  Known Period of Exploitation" ? (
                  <div>
                    <StackedBarVariableXAxis
                      data={props.data.value.periodOfExploitation}
                      xaxis="Time (years)"
                      yaxis="Number of Defendants"
                      keys={federalcasesdata.traffTypes}
                      trendLine={true}
                      trendLineLegend="Total Cases"
                      report={true}
                      type="cc"
                      id={props.data.id}
                    />
                    <PeriodOfExploitationTable
                      data={props.data.value.periodOfExploitation}
                      keys={federalcasesdata.traffTypes}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      headers={['Trafficking Type', 'Known Period of Exploitation', 'Number of Defendants']}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.periodOfExploitation}
                      headers={[{ label: 'Known Period of Exploitation', key: 'xaxis' }, { label: 'Minor Sex Trafficking', key: 'minorsextraf' }, { label: 'Adult Sex Trafficking', key: 'adultsextraf' }, { label: 'Labor Trafficking', key: 'labor' }, { label: 'Both Sex and Labor Trafficking', key: 'both' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Case Information  >  Sex Trafficking Type" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.sexTraffType}
                      percentOfTotal={props.data.sexTraffType}
                      value="xaxis"
                      xaxis="Number of Cases"
                      yaxis="Sex Trafficking Type"
                      report={true}
                      id={props.data.id}
                    />
                    <FederalCasesHorizontalTable
                      data={props.data.value.sexTraffType}
                      id={`${props.data.id}-table`}
                      name='Sex Trafficking Type'
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.sexTraffType.sort((a,b) => b.total - a.total)}
                      headers={[{ label: 'Sex Trafficking Type', key: 'xaxis' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name ===
                "Case Information  >  Labor Trafficking Type" ? (
                  <div>
                    <HorizontalBarChart
                      report
                      id={props.data.id}
                      data={props.data.value.laborTraffType}
                      percentOfTotal={props.data.laborTraffType}
                      value="xaxis"
                      xaxis="Number of Cases"
                      yaxis="Labor Trafficking Type"
                    />
                    <FederalCasesHorizontalTable
                      reverseOrder
                      id={`${props.data.id}-table`}
                      name='Labor Trafficking Type'
                      data={props.data.value.laborTraffType}
                      keys={federalcasesdata.laborTraffType}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.laborTraffType.sort((a,b) => b.total - a.total)}
                      headers={[{ label: 'Labor Trafficking Type', key: 'xaxis' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              
              {props.data.name ===
                "Demographics - Defendants  >  Age" ? (
                  <div>
                    <StackedBarVariableXAxis
                      report
                      wrapText
                      wrapTextWidth={140}
                      id={props.data.id}
                      data={props.data?.activeCategory ? props.data.value?.defendantsAge2 : props.data.value?.defendantsAge1 ?? []}
                      keys={props.data?.activeCategory ? federalcasesdata.traffTypes : federalcasesdata.defendantsAge1}
                      xaxis="Age at Arrest"
                      yaxis="Number of Individuals"
                      trendLine={true}
                      trendLineLegend="Total Individuals"
                      type="cc"
                    />
                    <PeriodOfExploitationTable
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      data={props.data.value?.defendantsAge2 ?? []}
                      keys={federalcasesdata.traffTypes}
                      headers={['Trafficking Type', 'Age at Arrest', 'Number of Individuals']}
                    />
                    <CSVLink
                      data={props.data.value?.defendantsAge1 ?? []}
                      headers={[{ label: 'Trafficking Type', key: 'xaxis' }, { label: '24 and Under', key: '24 and Under' }, { label: '25-29', key: '25-29' }, { label: '30-39', key: '30-39' }, { label: '40-49', key: '40-49' }, { label: '50 and Over', key: '50 and Over' }, { label: 'Total', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demographics - Defendants  >  Race/Ethnicity" ? (
                  <div>
                    <StackedBarVariableXAxis
                      report
                      wrapText
                      wrapTextWidth={140}
                      id={props.data.id}
                      data={props.data?.activeCategory ? props.data.value?.defendantRace2 : props.data.value?.defendantRace1 ?? []}
                      keys={props.data?.activeCategory ? federalcasesdata.traffTypes : federalcasesdata.raceEthnicity}
                      xaxis="Race/Ethnicity"
                      yaxis="Number of Individuals"
                      trendLine={true}
                      trendLineLegend="Total Individuals"
                      type="cc"
                    />
                    <PeriodOfExploitationTable
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      data={props.data.value?.defendantRace2}
                      keys={federalcasesdata.traffTypes}
                      headers={['Trafficking Type', 'Race/Ethnicity', 'Number of Individuals']}
                    />
                    <CSVLink
                      data={props.data.value?.defendantRace1}
                      headers={[{ label: 'Trafficking Type', key: 'xaxis' }, { label: 'Black', key: 'black' }, { label: 'Hispanic', key: 'hispanic' }, { label: 'Other', key: 'other' }, { label: 'Unknown', key: 'unknown' }, { label: 'White', key: 'white' }, {label: 'Total', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demographics - Defendants  >  Gender" ? (
                  <div>
                    <StackedBarVariableXAxis
                      report
                      wrapText
                      wrapTextWidth={140}
                      id={props.data.id}
                      data={props.data?.activeCategory ? props.data.value?.defendantsGender2 : props.data.value?.defendantsGender1 ?? []}
                      keys={props.data?.activeCategory ? federalcasesdata.traffTypes : federalcasesdata.defendantGender}
                      xaxis="Gender"
                      yaxis="Number of Individuals"
                      trendLine={true}
                      trendLineLegend="Total Individuals"
                      type="cc"
                    />
                    <PeriodOfExploitationTable
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      data={props.data.value?.defendantsGender2}
                      keys={federalcasesdata.traffTypes}
                      headers={['Trafficking Type', 'Gender', 'Number of Individuals']}
                    />
                    <CSVLink
                      data={props.data.value.defendantsGender1}
                      headers={[{ label: 'Trafficking Type', key: 'xaxis' }, { label: 'Female', key: 'female' }, { label: 'Male', key: 'male' }, { label: 'Unknown', key: 'unknown' }, { label: 'Total', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demographics - Defendants  >  Country of Origin" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.defendantCountry ?? []}
                      value="xaxis"
                      xaxis="Number of Individuals"
                      yaxis="Country of Origin"
                      report={true}
                      id={props.data.id}
                    />
                    <FederalCasesHorizontalTable
                      data={props.data.value.defendantCountry ?? []}
                      id={`${props.data.id}-table`}
                      name='Country of Origin'
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.defendantCountry}
                      headers={[{ label: 'Country of Origin', key: 'xaxis'}, {label: 'Value', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Demographics - Victims  >  Adult/Minor" ? (
                <div>
                  <StackedBarVariableXAxis
                    report
                    wrapText
                    wrapTextWidth={140}
                    id={props.data.id}
                    data={props.data?.activeCategory ? props.data.value?.victimAdultMinor2 : props.data.value?.victimAdultMinor1 ?? []}
                    keys={props.data?.activeCategory ? federalcasesdata.traffTypes : federalcasesdata.victimAge}
                    xaxis="Adult/Minor"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                    type="cc"
                  />
                  <PeriodOfExploitationTable
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    data={props.data.value?.victimAdultMinor2}
                    keys={federalcasesdata.traffTypes}
                    headers={['Trafficking Type', 'Age Group', 'Number of Individuals']}
                  />
                  <CSVLink
                    data={props.data.value.victimAdultMinor1}
                    headers={[{ label: 'Trafficking Type', key: 'xaxis' }, { label: 'Adult', key: 'adult' }, { label: 'Minor', key: 'minor' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Demographics - Victims  >  Gender" ? (
                <div>
                  <StackedBarVariableXAxis
                    report
                    wrapText
                    wrapTextWidth={140}
                    id={props.data.id}
                    data={props.data?.activeCategory ? props.data.value?.victimGender2 : props.data.value?.victimGender1 ?? []}
                    keys={props.data?.activeCategory ? federalcasesdata.traffTypes : federalcasesdata.victimGender}
                    xaxis="Gender"
                    yaxis="Number of Individuals"
                    trendLine={true}
                    trendLineLegend="Total Individuals"
                    type="cc"
                  />
                  <PeriodOfExploitationTable
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    data={props.data.value?.victimGender2}
                    keys={federalcasesdata.traffTypes}
                    headers={['Trafficking Type', 'Gender', 'Number of Individuals']}
                  />
                  <CSVLink
                    data={props.data.value.victimGender1}
                    headers={[{ label: 'Trafficking Type', key: 'xaxis' }, { label: 'Female', key: 'female' }, { label: 'Male', key: 'male' }, { label: 'Unknown', key: 'unknown' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Demographics - Victims  >  Country of Origin" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.victimCountry}
                      value="xaxis"
                      xaxis="Number of Cases"
                      yaxis="Country of Origin"
                      report={true}
                      id={props.data.id}
                    />
                    <FederalCasesHorizontalTable
                      data={props.data.value.victimCountry}
                      id={`${props.data.id}-table`}
                      name='Country of Origin'
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.victimCountry.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.country < b.country ? -1 : 1;
                        }
                        return b.total - a.total;
                      })}
                      headers={[{ label: 'Country of Origin', key: 'xaxis'}, {label: 'Value', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Methods  >  International Cases" ? (
                  <div>
                    <PieGraph
                      data={
                        props.data.value.methodInternational
                          ? props.data.value.methodInternational
                          : federalcasesmockdata.methodInternational
                      }
                      sourceNameVar='source'
                      report
                      id={props.data.id}
                    />
                    <FederalCasesHorizontalTable
                      data={props.data.value.methodInternational}
                      id={`${props.data.id}-table`}
                      name='International Cases'
                      type="reports"
                      pie={true}
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.methodInternational}
                      headers={[{ label: 'International Cases', key: 'source'}, {label: 'Value', key: 'value'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Methods  >  Interstate Cases" ? (
                <div>
                  <PieGraph
                    data={
                      props.data.value.methodInterstate
                        ? props.data.value.methodInterstate
                        : federalcasesmockdata.methodInterstate
                    }
                    sourceNameVar='source'
                    report={true}
                    id={props.data.id}
                  />
                  <FederalCasesHorizontalTable
                    data={props.data.value.methodInterstate}
                    id={`${props.data.id}-table`}
                    name='Interstate Cases'
                    type="reports"
                    pie={true}
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={props.data.value.methodInterstate}
                    headers={[{ label: 'Interstate Cases', key: 'source'}, {label: 'Total', key: 'value'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.name === `Methods  >  ${props.data?.activeCategory} Cases + Trafficking Type` && props.data.value?.methodSubPies && (
                <div>
                  <PieGraph
                    data={props.data.value?.methodSubPies?.[props.data?.activeCategory?.toLowerCase()]}
                    sourceNameVar='source'
                    report
                    id={props.data.id}
                  />
                  <FederalCasesHorizontalTable
                    data={props.data.value?.methodSubPies?.[props.data?.activeCategory?.toLowerCase()]}
                    id={`${props.data.id}-table`}
                    name={`${props.data?.activeCategory} Cases + Trafficking Type`}
                    type="reports"
                    pie
                    reverseOrder
                  />
                  <CSVLink
                    data={props.data.value?.methodSubPies?.[props.data?.activeCategory?.toLowerCase()]}
                    headers={[{ label: `${props.data?.activeCategory} Cases + Trafficking Type`, key: 'source' }, { label: 'Total', key: 'value' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              )}

              {props.data.name === "Methods  >  Transportation Method" ? (
                <div>
                  <HorizontalBarChart
                    data={props.data.value.transportationMethod}
                    percentFromData
                    xaxis="Number of Cases"
                    value="crime_methods_mode"
                    yaxis="Mode of Transportation"
                    report={true}
                    id={props.data.id}
                  />
                  <FederalCasesHorizontalTable
                    data={props.data.value.transportationMethod}
                    id={`${props.data.id}-table`}
                    name='Transportation Method'
                    type="reports"
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={props.data.value.transportationMethod}
                    headers={[{ label: 'Transportation Method', key: 'crime_methods_mode'}, {label: 'Value', key: 'total'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.type === 'Federal Cases' && props.data.name === "Methods  >  Recruitment Method" ? (
                <div>
                  <HorizontalBarChart
                    data={props.data.value.recruitmentMethods}
                    value="source"
                    xaxis="Number of Cases"
                    yaxis="Recruitment Method"
                    report={true}
                    id={props.data.id}
                  />
                  <FederalCasesHorizontalTable
                    data={props.data.value.recruitmentMethods}
                    name='Recruitment Method'
                    id={`${props.data.id}-table`}
                    type="reports"
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={props.data.value.recruitmentMethods}
                    headers={[{ label: 'Recruitment Method', key: 'source'}, {label: 'Value', key: 'total'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.name === "Methods  >  Crime Type" ? (
                <div>
                  <PieGraph
                    report
                    id={props.data.id}
                    data={props.data.value.crimeMethods}
                    sourceNameVar='source'
                  />
                  <FederalCasesHorizontalTable
                    id={`${props.data.id}-table`}
                    data={props.data.value.crimeMethods}
                    name='Crime Type'
                    pie={true}
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={props.data.value.crimeMethods}
                    headers={[{ label: 'Crime Type', key: 'source'}, {label: 'Value', key: 'value'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.name === "Charging and Sentencing  >  Statutes Charged Over Time" ? (
                <div>
                  <StackedBarVariableXAxis
                    report
                    id={props.data.id}
                    smallXaxis
                    fontSize
                    data={props.data.value.statutesCharged}
                    keys={federalcasesdata.statutesCharged}
                    xaxis="Year"
                    yaxis="Statutes Charged"
                    type="cc"
                  />
                  <TableWithTotal
                    id={`${props.data.id}-table`}
                    data={props.data.value.statutesChargedTable ?? []}
                    headers={['Year', 'Statute', 'Number']}
                    columnKeys={['year', 'statute', 'value']}
                  />     
                  <CSVLink
                    data={props.data.value.statutesChargedTable}
                    headers={[{ label: 'Year', key: 'year' }, { label: 'Statute', key: 'statute' }, {label: 'Number', key: 'value'}]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

              {props.data.name === "Charging and Sentencing  >  Defendant Sentence Lengths" ? (
                <div>
                  <WhiskerPlot
                    id={props.data.id}
                    report
                    data={props.data.value.defendantSentenceLength ?? []}
                    keys={federalcasesdata.defendantSentenceLength}
                    xaxis="Statute"
                    yaxis="Time (in months)"
                    yaxisRight={{ text: 'Number of Defendants', source: 'defendants', legend: 'Defendants' }} // (which appears only when the "Defendants" legend option is selected)
                    activeKeys={['median', 'mean', 'mode', 'outlier', 'defendants']}
                    type="cc"
                  />
                  <TableWithTotal
                    id={`${props.data.id}-table`}
                    data={props.data.value.defendantSentenceLength ?? []}
                    headers={['Statute', 'Median', 'Mean', 'Mode', 'Number of Defendants']}
                    columnKeys={['xaxis', 'median', 'mean', 'mode', 'defendants']}
                    totalEachColumn
                  />
                  <CSVLink
                    data={props.data.value.defendantSentenceLength}
                    headers={[{ label: 'Statute', key: 'xaxis' }, { label: 'Median', key: 'median' }, { label: 'Mean', key: 'mean' }, { label: 'Mode', key: 'mode' }, { label: 'Number of Defendants', key: 'defendants' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}

            {/* State Policies */}
              {props.data.name === "Policies Passed  >  Human Trafficking Policies Passed" ? (
                  <div>
                    <StackedBarVariableXAxis
                      report={true}
                    smallXaxis={true}
                    width={745}
                      id={props.data.id}
                      data={props.data.value.policies}
                      xaxis="Time"
                      yaxis="Total Policies"
                      keys={statepolicydata.policies}
                      type="cc"
                    />
                  <TableWithTotal
                    type="reports"
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    data={props.data.value.policies ?? []}
                    headers={['Year', 'Prevention Policies', 'Protection Policies', 'Prosecution Policies', 'Total Policies Passed']}
                    columnKeys={['xaxis', 'prevention', 'protection', 'prosecution', 'total']}
                    customTotalRowData={props.data.value.policies[props.data.value.policies.length - 1]}
                  />
                    <CSVLink
                      data={props.data.value.policies}
                    headers={[{ label: "Year", key: "xaxis" }, { label: "Prevention Policies", key: "prevention" }, { label: 'Protection Policies', key: 'protection' }, { label: 'Prosecution Policies', key: 'prosecution' }, { label: 'Total Policies Passed', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
              ) : null}

              {props.data.name === "Policies Passed  >  Net Policies Passed by Year" && (
                <div>
                  <StackedBarVariableXAxis
                    report
                    smallXaxis
                    width={745}
                    id={props.data.id}
                    data={props.data.value.netPolicies}
                    xaxis="Year"
                    yaxis="Number of Policies"
                    keys={statepolicydata.netPolicies}
                    type="cc"
                  />
                  <TableWithTotal
                    noTotalRow
                    type="reports"
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    data={props.data.value.netPolicies ?? []}
                    headers={['Year', 'Prevention', 'Protection', 'Prosecution', 'Total']}
                    columnKeys={['xaxis', 'prevention', 'protection', 'prosecution', 'total']}
                  />
                  <CSVLink
                    data={props.data.value.netPolicies}
                    headers={[{ label: "Year", key: "xaxis" }, { label: "Prevention", key: "prevention" }, { label: 'Protection', key: 'protection' }, { label: 'Prosecution', key: 'prosecution' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              )}

              {props.data.name === "Policies Passed  >  Sentencing Requirements" ? (
                  <div>
                  <GroupedBarVariableLegend
                    report
                    id={props.data.id}
                    data={props.data.value.sentencingRequirements}
                    keys={statepolicydata.sentencingRequirements}
                    xaxis="Sentence Category"
                    yaxis="Sentence Length in Years"
                    multipleYMaxValues={['min_sex_minor1', 'min_sex_minor2', 'max_sex_minor1', 'max_sex_minor2', 'min_sex_ad1', 'min_sex_ad2', 'max_sex_ad1', 'max_sex_ad2', 'min_lab_min1', 'min_lab_min2', 'max_lab_min1', 'max_lab_min2', 'min_lab_ad1', 'min_lab_ad2', 'max_lab_ad1', 'max_lab_ad2']}
                    activeCategory={props.data.activeCategory}
                  />
                  <TableWithTotal
                    noTotalRow
                    type="reports"
                    id={`${props.data.id}-table`}
                    data={props.data.value.sentencingAllTableData ?? []}
                    headers={['State', 'Sentencing Category', 'Years']}
                    columnKeys={['state', 'category', 'years']}
                  />
                  <CSVLink
                    data={props.data.value.sentencingAllTableData}
                    headers={[{ label: "State", key: "state" }, { label: "Sentencing Category", key: "category" }, { label: 'Years', key: 'years' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                  </div>
              ) : null}

              {props.data.name === "Policies Passed  >  Policy Prevalence" ? (
                  <div>
                     <HorizontalBarChart
                        data={props.data.value.policyPrevalence} 
                        value="title"
                        id={props.data.id}
                        report={true}
                        xaxis="Number of States"
                        yaxis="Policy"
                        percentFromData
                        legend={true}
                        legendCategories={['prevention', 'protection', 'prosecution']}
                    yLabelsWidth={260}
                      />
                  <TableWithTotal
                        type="reports"
                        id={`${props.data.id}-table`}
                        data={props.data.value.policyPrevalence ?? []}
                        headers={['Policies Present', 'Total']}
                        columnKeys={['title', 'total']}
                        descending={true}
                        totalKey='total'
                        rightAlignTotal={true}
                      />
                    <CSVLink
                      data={props.data.value.policyPrevalence}
                    headers={[{ label: "Policies Present", key: "title" }, { label: "Total", key: "total" }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
              ) : null}

            {props.data.name === "Policies Passed  >  Policy Prevalence and Adoption Time" ? (
                  <div>
                    <ScatterplotChart
                      data={props.data.value.policyAdoption ?? []}
                      value="variable"
                      xaxisValue="yearAverage"
                      yaxisValue="totalStates"
                      id={props.data.id}
                      xaxis="Average Years to Pass After First Policy Passed"
                      yaxis="Number of States"
                      legend={true}
                      report={true}
                      legendCategories={['prevention', 'protection', 'prosecution']}
                    />
                    <TableWithTotal
                      noTotalRow
                      type="reports"
                      id={`${props.data.id}-table`}
                      data={props.data.value.policyAdoption ?? []}
                      headers={['Policy', 'Average Years to Pass Policy', 'Number of States']}
                      columnKeys={['name', 'yearAverage', 'totalStates']}
                    />
                    <CSVLink
                      data={props.data.value.policyAdoption}
                      headers={[{ label: "Policy", key: "name" }, { label: "Average Years to Pass Policy", key: "yearAverage" }, { label: "Number of States", key: "totalStates" }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
              ) : null}

          {props.data.name === "Policies Passed  >  Policies Passed and Average Lag Time" ? (
                  <div>
                  <GridChart
                    report
                    id={props.data.id}
                      data={props.data.value.policyLagTime ?? []}
                    xaxisInfo={{ source: 'averageTime', axisText: 'Average Lag Time to Pass Policy After First State (Years)', tooltip: 'Average Lag Time:' }}
                    yaxisInfo={{ source: 'totalPolicies', axisText: 'Number of Policies', tooltip: 'Number of Policies:' }}
                    quadrantText={{ '1': 'Earlier Adoption, Less Policies', '2': 'Earlier Adoption, More Policies', '3': 'Later Adoption, Less Policies', '4': 'Later Adoption, More Policies' }}
                    medianLines={{ vertical: { name: 'Median Number of Policies', color: 0 }, horizontal: { name: 'Median Average Lag Time', color: 1 } }}
                    />
                    <TableWithTotal
                      noTotalRow
                      type="reports"
                      id={`${props.data.id}-table`}
                      data={props.data.value.policyLagTime ?? []}
                      headers={['State', 'Number of Policies', 'Average Lag Time']}
                      columnKeys={['name', 'totalPolicies', 'averageTime']}
                    />
                    <CSVLink
                      data={props.data.value.policyLagTime}
                      headers={[{ label: "State", key: "name" }, { label: "Number of Policies", key: "totalPolicies" }, { label: "Average Lag Time", key: "averageTime" }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
              ) : null}

              {props.data.name === "Policies Passed  >  Policies Passed and Legislative Sessions" && (
                <div>
                  <GridChart
                    report
                    id={props.data.id}
                    data={props.data.value.policyLegSessions ?? []}
                    xaxisInfo={{ source: 'legSessions', axisText: 'Number of Legislative Sessions to Pass Policies', tooltip: 'Number of Legislative Sessions:' }}
                    yaxisInfo={{ source: 'totalPolicies', axisText: 'Number of Policies', tooltip: 'Number of Policies:' }}
                    quadrantText={{ '1': 'Batched', '3': 'Incremental' }}
                    medianLines={{ vertical: { name: 'Median Number of Legislative Sessions', color: 0 } }}
                  />
                  <TableWithTotal
                    noTotalRow
                    type="reports"
                    id={`${props.data.id}-table`}
                    data={props.data.value.policyLegSessions ?? []}
                    headers={['State', 'Number of Policies', 'Number of Legislative Sessions']}
                    columnKeys={['name', 'totalPolicies', 'legSessions']}
                  />
                  <CSVLink
                    data={props.data.value.policyLegSessions}
                    headers={[{ label: "State", key: "name" }, { label: "Number of Policies", key: "totalPolicies" }, { label: "Number of Legislative Sessions", key: "legSessions" }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              )}

              {props.data.name === "Policies Passed  >  Total Policies Passed" && (
                <div>
                  <SingleBarDottedGraph
                    report
                    id={props.data.id}
                    data={props.data.value.totalPolicies ?? []}
                    keys={statepolicydata.netPolicies}
                    xaxis="Policy Type"
                    yaxis="Number of Policies"
                    type="cc"
                    smalltext
                  />
                  <TableWithTotal
                    type="reports"
                    id={`${props.data.id}-table`}
                    data={props.data.value.totalPolicies ?? []}
                    headers={['Policy Type', 'Total']}
                    columnKeys={['xaxis', 'value']}
                  />
                  <CSVLink
                    data={props.data.value.totalPolicies}
                    headers={[{ label: "Policy Type", key: "xaxis" }, { label: "Total", key: "value" }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              )}

              {/* TDCJ */}
              {props.data.name === "Offense Information  >  Inmates by Year" ? (
                <div>
                  <GroupedBarVariableXGraph
                    data={props.data.value.inmateByYear}
                    keys={tdcjdata.inmateByYear}
                    xaxis="Year"
                    yaxis="Number of Inmates"
                    multipleYMaxValues={['offenseYear', 'sentenceYear']}
                    report={true}
                    id={props.data.id}
                    smalltext={true}
                  />
                  <TDCJTable
                    noTotalRow
                    data={props.data.value.inmateByYear}
                    keys={tdcjdata.inmateByYear}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                  />
                  <CSVLink
                    data={props.data.value.inmateByYear}
                    headers={[{ label: 'Year', key: 'xaxis' }, { label: 'Sentence Year', key: 'sentenceYear' }, { label: 'Offense Year', key: 'offenseYear' }, { label: 'Total', key: 'total' }]}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Offense Information  >  Length of Sentence" ? (
                  <div>
                    <SingleBarGraph
                      data={
                        props.data.value.lengthSentence
                          ? props.data.value.lengthSentence
                          : tdcjmockdata.lengthSentence
                      }
                      keys={tdcjdata.lengthSentence}
                      xaxis="Length of Sentence (Years)"
                      yaxis="Number of Inmates"
                      report={true}
                      type="cc"
                      id={props.data.id}
                    />
                    <TDCJTable
                      data={
                        props.data.value.lengthSentence
                          ? props.data.value.lengthSentence
                          : tdcjmockdata.lengthSentence
                      }
                      keys={tdcjdata.lengthSentence}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.lengthSentence}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Offense Information  >  Time Between Offense and Sentence Date" ? (
                  <div>
                    <SingleBarGraph
                      data={
                        props.data.value.timeBetweenOffenseSentence
                          ? props.data.value.timeBetweenOffenseSentence
                          : []
                      }
                      keys={tdcjdata.timeBetweenOffenseSentence}
                      xaxis="Length of Time (years)"
                      yaxis="Number of Inmates"
                      report={true}
                      type="cc"
                      id={props.data.id}
                    />
                    <TDCJTable
                      data={
                        props.data.value.timeBetweenOffenseSentence
                          ? props.data.value.timeBetweenOffenseSentence
                          : []
                      }
                      keys={tdcjdata.timeBetweenOffenseSentence}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.timeBetweenOffenseSentence}
                      headers={[{ label: 'Length of Time (years)', key: 'source' }, { label: 'Value', key: 'value' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Offense Information  >  Offenses By Chapter Over Time" ? (
                  <div>
                    <StackedBarGraph
                      data={
                        props.data.value.offenseByChapterOverTime
                          ? props.data.value.offenseByChapterOverTime
                          : []
                      }
                      keys={tdcjdata.offenseByChapterOverTime}
                      xaxis="Year"
                      yaxis="Number of Inmates"
                      report={true}
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Inmates"
                      id={props.data.id}
                    />
                    <TDCJTable
                      data={
                        props.data.value.offenseByChapterOverTime
                          ? props.data.value.offenseByChapterOverTime
                          : []
                      }
                      keys={tdcjdata.offenseByChapterOverTime}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                    />
                    <CSVLink
                      data={props.data.value.offenseByChapterOverTime}
                      headers={[{ label: 'Year', key: 'name' }, { label: '20A', key: '20A' }, { label: '20', key: '20' }, { label: '21', key: '21' }, { label: '22', key: '22' }, { label: '25', key: '25' }, { label: '33', key: '33' }, { label: '43', key: '43' }, { label: 'Total Inmates', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name === "Inmate Demographics  >  Age" &&
                props.data.type === "Texas Department of Criminal Justice" &&
                stateLocation === "TX" ? (
                  <div>
                    <SingleBarGraph
                      data={
                        props.data.value.inmatesAge
                          ? props.data.value.inmatesAge
                          : tdcjmockdata.inmatesAge
                      }
                      keys={tdcjdata.inmatesAge}
                      xaxis="Age"
                      yaxis="Number of Individuals"
                      report={true}
                      type="cc"
                      id={props.data.id}
                    />
                    <TDCJTable
                      data={
                        props.data.value.inmatesAge
                          ? props.data.value.inmatesAge
                          : tdcjmockdata.inmatesAge
                      }
                      keys={tdcjdata.inmatesAge}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.inmatesAge}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Inmate Demographics  >  Race/Ethnicity" &&
                props.data.type === "Texas Department of Criminal Justice" &&
                stateLocation === "TX" ? (
                  <div>
                    <SingleBarGraph
                      data={
                        props.data.value.inmatesRace
                          ? props.data.value.inmatesRace
                          : tdcjmockdata.inmatesRace
                      }
                      keys={tdcjdata.inmatesRace}
                      xaxis="Race/Ethnicity"
                      yaxis="Number of Individuals"
                      report={true}
                      type="cc"
                      id={props.data.id}
                    />
                    <TDCJTable
                      data={
                        props.data.value.inmatesRace
                          ? props.data.value.inmatesRace
                          : tdcjmockdata.inmatesRace
                      }
                      keys={tdcjdata.inmatesRace}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.inmatesRace}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name === "Inmate Demographics  >  Gender" &&
                props.data.type === "Texas Department of Criminal Justice" &&
                stateLocation === "TX" ? (
                  <div>
                    <SingleBarGraph
                      data={
                        props.data.value.inmatesGender
                          ? props.data.value.inmatesGender
                          : tdcjmockdata.inmatesGender
                      }
                      keys={tdcjdata.inmatesGender}
                      xaxis="Gender"
                      type="cc"
                      report={true}
                      id={props.data.id}
                    />
                    <TDCJTable
                      data={
                        props.data.value.inmatesGender
                          ? props.data.value.inmatesGender
                          : tdcjmockdata.inmatesGender
                      }
                      keys={tdcjdata.inmatesGender}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.inmatesGender}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* DEMAND */}
              {props.data.name ===
                "Demand Information  >  Ad Posts by Location" ? (
                  <div>
                    <HorizontalBarChart
                      rotateXaxis
                      data={props.data.value.postByLocation.slice(0, 10)}
                      xaxis="Number of Ads"
                      yaxis="Location Name"
                      value="city"
                      report={true}
                      id={props.data.id}
                    />
                    <DemandHorizontalTable
                      data={
                        props.data.value.postByLocation
                          ? props.data.value.postByLocation
                          : demandmockdata.postByLocation
                      }
                      keys={demanddata.postByLocation}
                      variableName='city'
                      name='Ad Posts by Location'
                      id={`${props.data.id}-table`}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.postByLocation}
                      headers={[{ label: 'Location', key: 'city' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Demand Information  >  Ad Posts by Location and Population" ? (
                  <div>
                    <HorizontalBarChart
                      data={props.data.value.postByLocationPop.slice(0, 10)}
                      xaxis="Number of Ads per 100,000 population"
                      value="city"
                      yaxis="Location Name"
                      report={true}
                      id={props.data.id}
                    />
                    <DemandHorizontalTable
                      data={
                        props.data.value.postByLocationPop
                          ? props.data.value.postByLocationPop
                          : demandmockdata.postByLocationPop
                      }
                      variableName='city'
                      id={`${props.data.id}-table`}
                      name='Ad Posts by Location and Population'
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.postByLocationPop}
                      headers={[{label: 'Location', key: 'city'}, {label: 'Value', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* {props.data.name === "Demand Information  >  Provider Age" ? (
                <div>
                  <SingleBarGraph
                    data={
                      props.data.value.providerAge
                        ? props.data.value.providerAge
                        : demandmockdata.providerAge
                    }
                    keys={demanddata.providerAge}
                    xaxis="Age"
                    yaxis="Number of Individuals"
                    report={true}
                    id={props.data.id}
                    type="cc"
                  />
                  <DemandTable
                    data={
                      props.data.value.providerAge
                        ? props.data.value.providerAge
                        : demandmockdata.providerAge
                    }
                    keys={demanddata.providerAge}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    single={true}
                  />
                  <CSVLink
                    data={props.data.value.providerAge}
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null} */}
              {/* News */}
              {props.data.name === "Trafficking Types  >  Trafficking Type" ? (
                <div>
                  <HorizontalBarChart
                    data={
                      props.data.value.traffickingType
                        ? props.data.value.traffickingType
                        : newsmockdata.traffickingType
                    }
                    xaxis="Number of Incidents"
                    report={true}
                    value="type"
                    id={props.data.id}
                  />
                  <NewsHorizontalTable
                    data={
                      props.data.value.traffickingType
                        ? props.data.value.traffickingType
                        : newsmockdata.traffickingType
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={
                      props.data.value.traffickingType
                        ? props.data.value.traffickingType
                        : newsmockdata.traffickingType
                    }
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name ===
                "Trafficking Types  >  Trafficking Sub-Type" ? (
                  <div>
                    <HorizontalBarChart
                      data={
                        props.data.value.traffickingSubType
                          ? props.data.value.traffickingSubType
                          : newsmockdata.traffickingSubType
                      }
                      xaxis="Number of Incidents"
                      report={true}
                      value="type"
                      id={props.data.id}
                    />
                    <NewsHorizontalTable
                      data={
                        props.data.value.traffickingSubType
                          ? props.data.value.traffickingSubType
                          : newsmockdata.traffickingSubType
                      }
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={
                        props.data.value.traffickingSubType
                          ? props.data.value.traffickingSubType
                          : newsmockdata.traffickingSubType
                      }
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.type === 'News' && props.data.name === "Methods  >  Recruitment Method" ? (
                <div>
                  <HorizontalBarChart
                    data={
                      props.data.value.recruitment
                        ? props.data.value.recruitment
                        : newsmockdata.recruitment
                    }
                    xaxis="Number of Incidents"
                    report={true}
                    value="type"
                    id={props.data.id}
                  />
                  <NewsHorizontalTable
                    data={
                      props.data.value.recruitment
                        ? props.data.value.recruitment
                        : newsmockdata.recruitment
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={
                      props.data.value.recruitment
                        ? props.data.value.recruitment
                        : newsmockdata.recruitment
                    }
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Methods  >  Control Method" ? (
                <div>
                  <HorizontalBarChart
                    data={
                      props.data.value.control
                        ? props.data.value.control
                        : newsmockdata.control
                    }
                    xaxis="Number of Incidents"
                    report={true}
                    value="type"
                    id={props.data.id}
                  />
                  <NewsHorizontalTable
                    data={
                      props.data.value.control
                        ? props.data.value.control
                        : newsmockdata.control
                    }
                    keys={newsmockdata.control}
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={
                      props.data.value.control
                        ? props.data.value.control
                        : newsmockdata.control
                    }
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {props.data.name === "Methods  >  Transportation Methods" ? (
                <div>
                  <HorizontalBarChart
                    data={
                      props.data.value.transportation
                        ? props.data.value.transportation
                        : newsmockdata.transportation
                    }
                    xaxis="Number of Incidents"
                    report={true}
                    value="type"
                    id={props.data.id}
                  />
                  <NewsHorizontalTable
                    data={
                      props.data.value.transportation
                        ? props.data.value.transportation
                        : newsmockdata.transportation
                    }
                    id={`${props.data.id}-table`}
                    name={props.data.name}
                    type="reports"
                    reverseOrder={true}
                  />
                  <CSVLink
                    data={
                      props.data.value.transportation
                        ? props.data.value.transportation
                        : newsmockdata.transportation
                    }
                    target="_blank"
                    filename={`${props.data.name}-data.csv`}
                    style={{ marginTop: ".5em", color: "gray" }}
                  >
                    <button>Download Table Data</button>
                  </CSVLink>
                </div>
              ) : null}
              {/* Hotline */}
              {props.data.name ===
                "Hotline Information  >  National Hotline Situations" ? (
                  <div>
                    <GroupedBarGraph
                      hotlineCases
                      data={props.data.value.casesByTime.filter(
                        (obj) => obj.name !== "All quarters"
                      )}
                      keys={hotlineVariables.time} // needs to point to hotlinevariables
                      xaxis="Time (calendar year and quarter)"
                      yaxis="Number of Situations"
                      report={true}
                      id={props.data.id}
                    />
                    <HotlineCasesTable
                      data={
                        props.data.value.casesByTime
                          ? props.data.value.casesByTime
                          : hotlineVariables.time
                      }
                      keys={hotlineVariables.time}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={
                        props.data.value.casesByTime &&
                        props.data.value.casesByTime.map((obj) => ({
                          year: obj.name,
                          quarter: obj.quarter,
                          total: obj.total,
                        }))
                      }
                      headers={[{ label: 'Year', key: 'year' }, { label: 'Quarter', key: 'quarter' }, { label: 'Total', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Hotline Information  >  National Hotline Situations by Location" ? (
                  <div>
                    <HorizontalBarChart
                      percentOfTotal={props.data.value.casesByCounty}
                      data={props.data.value.casesByCounty
                        .sort((a, b) => {
                          return b.total - a.total; // sort biggest number to smallest
                        })
                        .slice(0, 10)} // get top 10 counties for the chart
                      value="county"
                      xaxis="Number of Situations"
                      yaxis="Location"
                      report={true}
                      //value="type"
                      id={props.data.id}
                    />
                    <HotlineCasesTable
                      data={
                        props.data.value.casesByCounty
                          ? props.data.value.casesByCounty.sort((a, b) => {
                            return b.total - a.total; // sort biggest number to smallest
                          })
                          : null
                      }
                      keys={null}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.casesByCounty}
                      headers={[{ label: 'Location', key: 'county' }, { label: 'Total', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Hotline Information  >  National Hotline Situations by Location and Population" ? (
                  <div>
                    <HorizontalBarChart
                      percentOfTotal={props.data.value.casesByCountyAndPopulation}
                      data={props.data.value.casesByCountyAndPopulation
                        .sort((a, b) => {
                          return b.total - a.total; // sort biggest number to smallest
                        })
                        .slice(0, 10)} // get top 10 counties for the chart
                      value="county"
                      xaxis="Number of Situations per 100,000 Population"
                      yaxis="Location"
                      report={true}
                      //value="type"
                      id={props.data.id}
                    />
                    <HotlineCasesTable
                      data={
                        props.data.value.casesByCountyAndPopulation
                          ? props.data.value.casesByCountyAndPopulation.sort(
                            (a, b) => {
                              return b.total - a.total; // sort biggest number to smallest
                            }
                          )
                          : null
                      }
                      keys={null}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.casesByCountyAndPopulation}
                      headers={[{ label: 'Location', key: 'county' }, { label: 'Total', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {/* IMB */}
              {props.data.name ===
                "Business Information  >  Businesses by Year" ? (
                  <div>
                    <SingleBarGraph
                      data={
                        props.data.value.businessesByYear
                          ? props.data.value.businessesByYear
                          : imbmockdata
                      }
                      keys={imbdata.businessYear}
                      xaxis="Year"
                      yaxis="Number of Businesses"
                      trendLine={props.data.chartLine}
                      trendLineLegend="Total Businesses"
                      report={true}
                      type="cc"
                      //value="type"
                      id={props.data.id}
                    />
                    <ImbTable
                      data={
                        props.data.value.businessesByYear
                          ? props.data.value.businessesByYear
                          : imbmockdata
                      }
                      keys={imbdata.businessYear}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      single={true}
                    />
                    <CSVLink
                      data={props.data.value.businessesByYear}
                      headers={[{ label: "year", key: "year" }, { label: "value", key: "value" }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Business Information  >  Businesses by Location" ? (
                  <div>
                    <HorizontalBarChart
                      percentOfTotal={props.data.value.businessByLocationAll}
                      data={props.data.value.businessesByLocation.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city < b.city ? -1 : 1;
                        }
                        return b.total - a.total;
                      })}
                      value="city"
                      xaxis="Number of Cases"
                      yaxis="Location"
                      report={true}
                      //value="type"
                      id={props.data.id}
                    />
                    <ImbHorizontalTable
                      data={props.data.value.businessByLocationAll ?? imbmockdata.businessesByLocation}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.businessByLocationAll.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city < b.city ? -1 : 1;
                        }
                        return b.total - a.total;
                      })}
                      headers={[{ label: 'city', key: 'city' }, { label: 'value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
              {props.data.name ===
                "Business Information  >  Businesses by Location and Population" ? (
                  <div>
                    <HorizontalBarChart
                      percentOfTotal={props.data.value.businessesByLocationPop ?? []}
                      data={props.data.value.businessesByLocationPop?.slice(0, 10) ?? []}
                      value="city"
                      xaxis="Number of Cases"
                      yaxis="Location and Population"
                      report={true}
                      id={props.data.id}
                    />
                    <ImbHorizontalTable
                      decimalPoint
                      noTotalRow
                      data={props.data.value.businessesByLocationPop ?? []}
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      type="reports"
                      reverseOrder={true}
                    />
                    <CSVLink
                      data={props.data.value.businessesByLocationPop}
                      headers={[{ label: 'City', key: 'city' }, { label: 'Value', key: 'total' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}

              {props.data.name ===
                "Business Information  >  Businesses by Region and Year" ? (
                  <div>
                    <ConnectedScatterplot
                      report
                      id={props.data.id}
                      data={props.data.value.byRegionAndYear ?? []}
                      xaxisKey='year'
                      yaxisKey='total'
                      xaxis='Year'
                      yaxis='Number of Businesses'
                      legend
                      legendKey='region'
                      hoverItems={['Businesses:', 'total', 'Growth Rate:', 'growth_rate']}
                    />
                    <TableWithTotal
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                      data={props.data.value.byRegionAndYear ?? []}
                      tableTotal={props.data.value.byRegionAndYearTableTotal ?? 0}
                      headers={['Year', 'Region', 'Growth Rate', 'Number of Businesses']}
                      columnKeys={['year', 'region', 'growth_rate', 'total']}
                    />
                    <CSVLink
                      data={props.data.value.byRegionAndYearCSV ?? []}
                      headers={[{ label: 'Year', key: 'year' }, { label: 'Region', key: 'region' }, {label: 'Growth Rate', key: 'growth_rate'}, {label: 'Number of Businesses', key: 'total'}]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}


              {props.data.name ===
                "Business Information  >  Total Businesses" ? (
                  <div id={props.data.id}>
                    <h3 style={{ margin: "0" }}>Total Locations</h3>
                    <p>
                      {
                        props.data.value.totals.locations
                      }
                    </p>
                    <h3 style={{ margin: "0" }}>Total Businesses</h3>
                    <p>
                      {props.data.value.totals.businesses}
                    </p>
                    <ImbTotalsTable
                      data={props.data.value.orgs}
                      title="Total Businesses"
                      type="Business Information  >  Total Businesses"
                      id={`${props.data.id}-table`}
                      name={props.data.name}
                    />
                    <CSVLink
                      data={props.data.value.orgs}
                      headers={[{ label: 'Business Name', key: 'name' }, { label: 'Address', key: 'address' }, { label: 'City', key: 'city' }, { label: 'Zip', key: 'zip' }, { label: stateLocation === 'TX' ? 'County' : 'Parish', key: 'county' }, { label: 'Region', key: 'region' }]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    ><button>Download Table Data</button></CSVLink>
                  </div>) : null}
              {/* Community Risk */}
              {props.data.name ===
                "Risk Prevalence  >  Counties with Prevalence of Risk" ? (
                  <div>
                    <TableContainer
                      component={Paper}
                      id={props.data.id}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>County</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>Total Population</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>Youth Population</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong># CSE-IT Screenings</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>% Clear Concern</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong># in Care Coordination</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>Region</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong># Serving Organizations</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>Risk Score</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.data.value.topTenCountyData?.map((item, key) => {
                            return (
                              <TableRow
                                key={key}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {item?.countyName}
                                </TableCell>
                                <TableCell align="right">
                                  {item?.population
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </TableCell>
                                <TableCell align="right">
                                  {item?.youthPopulation
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </TableCell>
                                <TableCell align="right">
                                  {item?.screenings}
                                </TableCell>
                                <TableCell align="right">
                                  {item?.clearConcern}
                                </TableCell>
                                <TableCell align="right">
                                  {item?.careCoordination}
                                </TableCell>
                                <TableCell align="right">{item?.region}</TableCell>
                                <TableCell align="right">{item?.orgs}</TableCell>
                                <TableCell align="right">
                                  {item?.riskScore}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <CSVLink
                      data={props.data.value?.allCountyData}
                      headers={[{ label: 'County', key: 'countyName' }, { label: 'Total Population', key: 'population' }, { label: 'Youth Population', key: 'youthPopulation' }, { label: '# CSE-IT Screenings', key: 'screenings' }, { label: '% Clear Concern', key: 'clearConcern' }, { label: '# in Care Coordination', key: 'careCoordination' }, { label: 'Region', key: 'region' }, { label: '# Serving Organizations', key: 'orgs' }, { label: 'Risk Score', key: 'riskScore' },]}
                      target="_blank"
                      filename={`${props.data.name}-data.csv`}
                      style={{ marginTop: ".5em", color: "gray" }}
                    >
                      <button>Download Table Data</button>
                    </CSVLink>
                  </div>
                ) : null}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
          <div className="flexColumnCenter" style={{ marginBottom: "25px" }}>
            <h3>Updating Data...</h3>
            <CircularProgress size={25} />
          </div>
        )}
    </div>
  );
}
