import { mapsNationalData } from '../Data/mapsNationalData'
import { flCountyData } from '../Data/flCountyJson'
import { flRegionCircuitJson } from '../Data/flRegionCircuitJson'
import { flCountyList } from '../Data/flCountyList'
import { flRegionList } from '../Data/flRegionList'
import { flCircuitRegionList } from '../Data/flCircuitRegionList'
import { flIMBRegionList } from '../Data/flIMBRegionList'

export const mapsFLData = {
  mapZoom: 2,
  reportMapZoom: 4,
  mapBoxCenter: [-81.760254, 27.994402],
  mapBoxCenterReport: [-81.760254, 27.994402],
  mapMaxBounds: [[-92.77287069367998, 22.7091], [-76.26493500455376, 32.743288738760796]],
  mapMaxBoundsReport: [[-92.77287069367998, 22.7091], [-76.26493500455376, 32.743288738760796]],
  reportMapWidth: '577px',

  'Services': {
    orgList: [
      { name: 'Case Management', color: 0 },
      { name: 'Crisis', color: 2 },
      { name: 'Daily Needs', color: 3 },
      { name: 'Education', color: 4 },
      { name: 'Employment', color: 5 },
      { name: 'Financial Services', color: 6 },
      { name: 'Healthcare', color: 7 },
      { name: 'Housing', color: 8 },
      { name: 'Legal Services', color: 9 },
      { name: 'Mental Health', color: 10 },
      { name: 'Peer-led Support', color: 11 },
      { name: 'Substance Abuse Services', color: 12 },
      { name: 'Training', color: 13 },
      { name: 'Translation Services', color: 14 },
      { name: 'Transportation', color: 15 },
      { name: 'Other', color: 16 },
    ],

    'Provider Location ': { // info specifically based on filter selection!
      'State': {
        mapJson: null,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'County',
        popUpOrgTypeName: 'Service Provided',
        featureLocProp: 'COUNTY',
      },
      'County': {
        mapJson: flCountyData,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'County',
        popUpOrgTypeName: 'Service Provided',
        featureLocProp: 'COUNTY',
      },
      'Region': {
        mapJson: flRegionCircuitJson,
        dataVar: 'orgList',
        locSourceVar: 'region',
        popUpLocName: 'Region',
        popUpOrgTypeName: 'Service Provided',
        featureLocProp: 'CIRCUIT',
      },
    },

    countyList: flCountyList,
    regionList: flCircuitRegionList,

    mapInfo: <div>
      <p className='margin0'><span className='fontWeight700'>Data Source:</span> TiP Lab, University of South Florida (USF)</p>
      <p className='margin0'><span className='fontWeight700'>Date:</span> 2024 - Present</p>

      <p>
        The Services Data is provided by the <a href='https://www.usf.edu/cbcs/criminology/tip-lab/bright/' rel='noopener noreferrer' target='_blank'>TiP Research Lab's BRIGHT Team</a>. BRIGHT is an online, vetted, and secure resource referral network for individuals seeking or providing services for those affected by trafficking. This map displays the locations and services of organizations across Florida that offer or may offer support to confirmed or potential victims of human trafficking. The organizations visualized either report their services to TiP Lab or participate in BRIGHT. Organizations with multiple locations or services may appear more than once. The map view can be filtered by both the Provider Location filter and the Legend.
      </p>
    </div>
  }, // Services

  'Screenings': {
    mapJson: flCountyData,
    dataVar: 'counties',
    dataTotalVar: 'countiesTotal',
    matchDataLoc: 'homecnty',
    matchJsonLoc: 'COUNTY',
    victimStatusOptions: ['Definitely is victim', 'Likely is victim', 'Not sure', 'Likely not victim', 'Definitely not victim', 'Unknown/No Answer'],
    organizations: ['DCF', 'DJJ'],
    // countyList: ,
    // regionList: ,
    agesOptions: ['0-4', '5-9', '10-14', '15-19', '20-24', '25+'],
    countyList: flCountyList,
    regionList: flCircuitRegionList,
    displayMapData: [
      {
        name: 'Total',
        dataVar: 'total',
        totalsDataVar: 'total',
        featureProp: 'SCREENINGS',
      },
      {
        name: 'Definitely is victim',
        dataVar: 'def_is',
        totalsDataVar: 'def_is',
        featureProp: 'def_is',
        percent: 'def_is_percent'
      },
      {
        name: 'Likely is victim',
        dataVar: 'likely_is',
        totalsDataVar: 'likely_is',
        featureProp: 'likely_is',
        percent: 'likely_is_percent'
      },
      {
        name: 'Not sure',
        dataVar: 'not_sure',
        totalsDataVar: 'not_sure',
        featureProp: 'not_sure',
        percent: 'not_sure_percent'
      },
      {
        name: 'Likely not victim',
        dataVar: 'likely_not',
        totalsDataVar: 'likely_not',
        featureProp: 'likely_not',
        percent: 'likely_not_percent'
      },
      {
        name: 'Definitely not victim',
        dataVar: 'def_not',
        totalsDataVar: 'def_not',
        featureProp: 'def_not',
        percent: 'def_not_percent'
      },
      {
        name: 'Unknown/No Answer',
        dataVar: 'unknown',
        totalsDataVar: 'unknown',
        featureProp: 'unknown',
        percent: 'unknown_percent'
      },
    ],
    mapInfo: <div>
      <p className='margin0'><span className='fontWeight700'>Data Source:</span> Florida Department of Children & Families (DCF)</p>
      <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2024-present</p>

      <p className='margin0'><span className='fontWeight700'>Data Source:</span> Florida Department of Juvenile Justice (DJJ)</p>
      <p className='margin0'><span className='fontWeight700'>Date:</span> 2023-present</p>

      <p>The Florida Screenings Data represents Human Trafficking Screenings conducted by <a href='https://www.myflfamilies.com/' rel='noopener noreferrer' target='_blank'>DCF</a> and <a href='https://www.djj.state.fl.us/' rel='noopener noreferrer' target='_blank'>DJJ</a> using the <a href='https://www.flcourts.gov/content/download/217038/file/DJJ_Human_Trafficking_Screening_Tool.pdf' rel='noopener noreferrer' target='_blank'>Human Trafficking Screening Tool (HTST)</a>.</p>

      <p>Organizations may complete more than one screening for an individual as needed, or the same individual may be screened by more than one organization. Data reflects the location of the youth's residence at the time of the screening, although trafficking may have occurred in a different location. The Florida Screenings Data includes information on demographics, indicators, and screening results.</p>

      <p>Any screening values which are less than six will be shown as zero in the Screenings map and charts.</p>
    </div>,
  },

  'Federal Cases': {
    mapZoom: mapsNationalData.mapZoom,
    reportMapZoom: mapsNationalData.reportMapZoom,
    mapBoxCenter: mapsNationalData.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData.reportMapWidth,
    'Map View': mapsNationalData['Federal Cases']['Map View'],
    mapInfo: mapsNationalData['Federal Cases'].mapInfo,
  },

  'Law Enforcement': {
    countyList: flCountyList,
    regionList: flRegionList,
  },

  'State Policy': {
    mapZoom: mapsNationalData.mapZoom,
    reportMapZoom: mapsNationalData.reportMapZoom,
    mapBoxCenter: mapsNationalData.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData.reportMapWidth,
    'Data': mapsNationalData['State Policy']['Data'],
    'CriminalData': mapsNationalData['State Policy']['CriminalData'],
    mapInfo: mapsNationalData['State Policy'].mapInfo
  },

  'Businesses of Interest': {
    countyList: flCountyList,
    regionList: flIMBRegionList,
  }
}