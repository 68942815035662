import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from '../../../Lib/UserContext';
import { availableDashboards } from '../../../Data/availableDashboards'

import TimeYearFilter from './TimeYearFilter'
import HeadingsClickableFilter from './HeadingsClickableFilter'
import SingleSelectionFilter from './SingleSelectionFilter'
import MultipleSelectionFilter from './MultipleSelectionFilter';
import CRLocationFilter from './CRLocationFilter';

import { offenseFLOptions } from './PublicSafety/offenseFLOptions'

import { allStateNames } from "../../../Data/allStateNames";

import ServicesTimeFilter from './Services/TimeFilter';
import ServicesYearFilter from './Services/YearFilter';
import ServicesLocationTypeFilter from './Services/LocationTypeFilter';
// Lousiana
import ServicesLAAgeFilter from './ServicesLA/AgeFilter';
import ServicesLATrafTypeFilter from './ServicesLA/TrafTypeFilter';
import ServicesLATimeFilter from './ServicesLA/TimeLAFilter';
import VictimStatusTimeFilter from './ServicesLA/VictimStatusFilter';

import CCTimeFilter from './CareCoordination/TimeFilter';
import CCLocationFilter from './CareCoordination/LocationFilter';
import CCLocationLAFilter from './CareCoordination/LocationLAFilter';
import TraffTypeLAFilter from './CareCoordination/TraffTypeLAFilter'
import IndividualsServedLAFilter from './CareCoordination/IndividualsServedLAFilter'

import ScreeningsTimeFilter from './Screenings/TimeFilter';
import ScreeningsScreeningsFilter from './Screenings/ScreeningsFilter';
import ScreeningsIndicatorsFilter from './Screenings/IndicatorsFilter';

import PSAgencyFilter from './PublicSafety/AgencyFilter';
import PSOffensesFilter from './PublicSafety/OffensesFilter';
import PSOffensesLAFilter from './PublicSafety/OffensesLAFilter';
import PSTimeFilter from './PublicSafety/TimeFilter';

import NationalFCLoFilter from './FederalCases/NationalFCLoFilter'
import FederalLocationsTypeFilter from './FederalCases/LocationTypeFilter';
import FederalTypesFilter from './FederalCases/TraffickingType';

import StatePolicyLocationsFilter from './StatePolicy/Location';
import StatePolicyTimeFilter from './StatePolicy/Time';
import StatePolicyPolicyFilter from './StatePolicy/Policy';
import StatePolicyMapFilter from './StatePolicy/MapFilter';
import StatePolicySentencingFilter from './StatePolicy/SentencingRequirement';

import TDCJOffensesFilter from './TDCJ/OffensesFilter';

import DemandAdSitesFilter from './Demand/AdSitesFilter';

import HotlineSituationsFilter from './Hotline/SituationsFilter';
import HotlineTimeFilter from './Hotline/TimeFilter';

import ImbMapLocationFilter from './IMB/ImbMapLocationFilter';
import ImbAnalysisFilter from './IMB/AnalysisFilter';
import ImbYearFilter from './IMB/Year';

import TraffickingLocationTypeFilter from './TraffickingLocations/LocationTypeFilter'
import TraffickingTimeFilter from './TraffickingLocations/TimeFilter';
import TraffickingAgeFilter from './TraffickingLocations/AgeFilter';
import TraffickingOrganizationFilter from './TraffickingLocations/OrganizationFilter';
import TraffickingTrafTypeFilter from './TraffickingLocations/TrafTypeFilter';
import VictimStatusFilter from './TraffickingLocations/VictimStatusFilter';


//import { data } from '../Charts/Screenings/DemographicData';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    }
}));


export default function Filters(props) {
    const classes = useStyles();
    const [filter, setFilter] = React.useState([]);
    const { stateLocation } = useAppContext();
    const [showImbMapYearFilter, setShowImbMapYearFilter] = useState(true)
    let mapsIndex = availableDashboards.filter(d => d.stateLocation === stateLocation)?.[0]?.mapsIndex

    const handleFilterCallback = (item) => {
        props.filterCallback(item)
    }

    useEffect(() => {
        setFilter(props.filter)

        for (let i = 0; i < props.selectedFilters.length; i++) {
            if (props.selectedFilters[i].name === 'Location ' && props.selectedFilters[i].filter === 'Businesses of Interest' && props.selectedFilters[i].value[0] === 'Zip Code') {
                setShowImbMapYearFilter(false)
            }
        }
    }, [props.filter, props.secondaryNav, props.selectedFilters])


    return (
        <div className={`${classes.container} filterDropDown ${props.styles?.container?.className || ''}`}>
            {/* Services Filters */}
            { filter === 'Time' && props.data === 'Services' && stateLocation === 'TX' ?
                <ServicesTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Time ' && props.data === 'Services' && stateLocation === 'TX' ?
                <ServicesYearFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Location' && props.data === 'Services' && stateLocation === 'TX' ?
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={'County'}
                    allCountiesText={'All counties'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            { filter === 'Location ' && props.data === 'Services' && stateLocation === 'TX' ?
                <ServicesLocationTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            {/* LA Filters */}
            { filter === 'Time' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLATimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Location of Origin' && props.data === 'Services' && stateLocation === 'LA' ?
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={'Parish'}
                    allCountiesText={'All parishes'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            { filter === 'Provider Location ' && props.data === 'Services' && (stateLocation === 'FL' || stateLocation === 'LA') ?
                // <ServicesLALocationMapFilter stateLocation={stateLocation} filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                <SingleSelectionFilter
                    page={props.data}
                    filterName={filter}
                    options={Object.keys(mapsIndex[props.data][filter])}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}

            { filter === 'Provider Location' && props.data === 'Services' && stateLocation === 'LA' ?
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={'Parish'}
                    allCountiesText={'All parishes'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            {filter === 'Provider Location' && props.data === 'Services' && stateLocation === 'FL' &&
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName='County'
                    allCountiesText='All counties'
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
            }
            { filter === 'Organizations' && props.data === 'Services' && (stateLocation === 'LA' || stateLocation === 'FL') &&
                <MultipleSelectionFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    allOptionText='All organizations'
                    options={mapsIndex?.[props.data]?.orgList?.map(o => o?.name)}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
            }
            { filter === 'Ages' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLAAgeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { filter === 'Trafficking Types' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLATrafTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Victim Status' && props.data === 'Services' && stateLocation === 'LA' ?
                <VictimStatusTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            {/* Care Coordination Filters */}
            { filter === 'Time' && props.data === 'Care Coordination' ?
                <CCTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            {filter === 'Location' && props.data === 'Care Coordination' && stateLocation === 'TX' ?
                <CCLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { stateLocation === 'LA' && filter === 'Location' && props.data === 'Care Coordination' ?
                <CCLocationLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { stateLocation === 'LA' && filter === 'Trafficking Types' && props.data === 'Care Coordination' ?
                <TraffTypeLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { stateLocation === 'LA' && filter === 'Individuals Served' && props.data === 'Care Coordination' ?
                <IndividualsServedLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            {/* Screenings */}
            { filter === 'Time' && props.data === 'Screenings' ?
                <ScreeningsTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Location' && props.data === 'Screenings' ?
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={stateLocation === 'LA' ? 'Parish' : 'County'}
                    allCountiesText={stateLocation === 'LA' ? 'All parishes' : 'All counties'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            {filter === 'Victim Status' && props.data === 'Screenings' && stateLocation === 'FL' &&
                <MultipleSelectionFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    allOptionText='All screenings'
                    options={mapsIndex?.[props.data]?.victimStatusOptions}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
            }
            { filter === 'Organizations' && props.data === 'Screenings' &&
                <>
                    {stateLocation !== 'FL' ?
                        <MultipleSelectionFilter
                            stateLocation={stateLocation}
                            page={props.data}
                            filterName={filter}
                            allOptionText='All organizations'
                            options={mapsIndex?.[props.data]?.organizations}
                            filterCallback={handleFilterCallback}
                            selectedFilters={props.selectedFilters}
                            handleClose={props.handleClose}
                        />
                        :
                        <SingleSelectionFilter
                            stateLocation={stateLocation}
                            page={props.data}
                            filterName={filter}
                            allOptionText='All organizations'
                            options={mapsIndex?.[props.data]?.organizations}
                            filterCallback={handleFilterCallback}
                            selectedFilters={props.selectedFilters}
                            handleClose={props.handleClose}
                        />
                    }
                </>
            }

            { filter === 'Screenings' && props.data === 'Screenings' ?
                <ScreeningsScreeningsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Ages' && props.data === 'Screenings' ?
                <MultipleSelectionFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    allOptionText='All ages'
                    options={mapsIndex?.[props.data]?.agesOptions}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            { filter === 'Indicators' && props.data === 'Screenings' ?
                <ScreeningsIndicatorsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { filter === 'Location' && props.data === 'Law Enforcement' &&
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={stateLocation === 'LA' ? 'Parish' : 'County'}
                    allCountiesText={stateLocation === 'LA' ? 'All parishes' : 'All counties'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
            }
            { filter === 'Arrest Year' && props.data === 'Law Enforcement' ?
                <PSTimeFilter options={stateLocation === 'TX' ? ['2025', '2024', '2023'] : ['2025', '2024', "2023", "2022", "2021"]} filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} stateLocation={stateLocation} />
                : null}
            { filter === 'Agency Type' && props.data === 'Law Enforcement' ?
                <PSAgencyFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Offenses' && props.data === 'Law Enforcement' && stateLocation === 'TX' ?
                <PSOffensesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Offenses' && props.data === 'Law Enforcement' && stateLocation === 'LA' ?
                <PSOffensesLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Offenses' && props.data === 'Law Enforcement' && stateLocation === 'FL' &&
                <HeadingsClickableFilter filterName='Offenses' page='Law Enforcement' stateLocation={stateLocation} allOptionText='All offenses' options={offenseFLOptions} filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
            }

            {/* Federal Cases */}
            { filter === 'Year Case Filed' && props.data === 'Federal Cases' &&
                <TimeYearFilter
                    page='Federal Cases'
                    filterName={filter}
                    options={['All Years', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000']}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
            }
            { filter === 'Map View' && props.data === 'Federal Cases' ?
                <FederalLocationsTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { filter === 'Location ' && props.data === 'Federal Cases' &&
                <SingleSelectionFilter
                    page={props.data}
                    filterName={filter}
                    allOptionText='All States'
                    options={[...allStateNames].filter(s => s.name !== 'National').map(s => s.name)}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
            }

            { filter === 'Location' && props.data === 'Federal Cases' ?
                <NationalFCLoFilter
                    districtDefaultForStates
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose} />
                : null}

            { filter === 'Location Type' && props.data === 'Federal Cases' ?
                <FederalLocationsTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Trafficking Type' && props.data === 'Federal Cases' ?
                <FederalTypesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            {/* State Policy */}
            { filter === 'Location' && props.data === 'State Policy' ?
                <StatePolicyLocationsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Year' && props.data === 'State Policy' ?
                <StatePolicyTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Policy' && props.data === 'State Policy' ?
                <StatePolicyPolicyFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Map View' && props.data === 'State Policy' ?
                <StatePolicyMapFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { filter === 'Sentencing Requirement' && props.data === 'State Policy' ?
                <StatePolicySentencingFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { filter === 'Location' && props.data === 'Texas Department of Criminal Justice' ?
                // <TDCJLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={'County'}
                    allCountiesText={'All counties'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            { filter === 'Offenses' && props.data === 'Texas Department of Criminal Justice' ?
                <TDCJOffensesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Ad Sites' && props.data === 'Demand' ?
                <DemandAdSitesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
    
            {/* Hotline */}
            { filter === 'Time' && props.data === 'Hotline' ?
                <HotlineTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Situations' && props.data === 'Hotline' ?
                <HotlineSituationsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Location' && props.data === 'Hotline' ?
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={stateLocation === 'LA' ? 'Parish' : 'County'}
                    allCountiesText={stateLocation === 'LA' ? 'All parishes' : 'All counties'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            {/* chart */}
            { filter === 'Location' && props.data === 'Businesses of Interest' ?
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={stateLocation === 'LA' ? 'Parish' : 'County'}
                    allCountiesText={stateLocation === 'LA' ? 'All parishes' : 'All counties'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            {/* map */}
            { filter === 'Location ' && props.data === 'Businesses of Interest' ?
                <ImbMapLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'IMB Analysis' && props.data === 'Businesses of Interest' ?
                <ImbAnalysisFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Year' && props.data === 'Businesses of Interest' && showImbMapYearFilter ?
                <ImbYearFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} state={stateLocation} />
                : null}
            { filter === 'Location' && props.data === 'Locations' ?
                <CRLocationFilter
                    stateLocation={stateLocation}
                    page={props.data}
                    filterName={filter}
                    countyName={stateLocation === 'LA' ? 'Parish' : 'County'}
                    countySelection='Parish'
                    allCountiesText={stateLocation === 'LA' ? 'All parishes' : 'All counties'}
                    countyOptions={mapsIndex[props.data].countyList}
                    regionOptions={mapsIndex[props.data].regionList}
                    filterCallback={handleFilterCallback}
                    selectedFilters={props.selectedFilters}
                    handleClose={props.handleClose}
                />
                : null}
            { filter === 'Location Type' && props.data === 'Locations' ?
                <TraffickingLocationTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Time' && props.data === 'Locations' ?
                <TraffickingTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Ages' && props.data === 'Locations' ?
                <TraffickingAgeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Organizations' && props.data === 'Locations' ?
                <TraffickingOrganizationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Trafficking Types' && props.data === 'Locations' ?
                <TraffickingTrafTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Victim Status' && props.data === 'Locations' ?
                <VictimStatusFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
        </div>
    )
}