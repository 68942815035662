export const laRegionList = [
    "All regions",
    "Alexandria",
    "Baton Rouge",
    "Covington I",
    "Covington II",
    "Lafayette",
    "Lake Charles",
    "Monroe",
    "Orleans",
    "Shreveport",    
    "Thibodaux",
]