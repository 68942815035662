/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import "./Filter.css";
import Chip from "@material-ui/core/Chip";
// import Button from '@material-ui/core/Button';
import { useAppContext } from "../../../Lib/UserContext";
import { availableDashboards } from '../../../Data/availableDashboards'


export default function FilterSummary(props) {
  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = React.useState("");
  const [filters, setFilters] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const { stateLocation } = useAppContext();

  let dashboardData = availableDashboards.filter(d => d.stateLocation === stateLocation)[0].dashboardDataFile

  // const handleDelete = (value, list) => {
  // console.log(value, list, filter)
  // var newArray = [...selectedFilters]
  // for (let i = 0; i < newArray.length; i++){
  //   if (newArray[i].name === list.name && newArray[i].filter === item.filter){
  //     newArray[i].value = list.value.filter(filter => filter !== item);
  //   }
  // }
  // props.handleRemoveFilterItem(value, list, filter)
  // }

  const handleResetDefaults = () => {
    props.handleUpdateSummaryFilterItem([{
      name: 'Time',
      filter: 'Services',
      selection: 'Quarter & Fiscal Year',
      value: ["All quarters", "All years"],
      stateLocation: 'TX'
    },
    {
      name: 'Time ',
      filter: 'Services',
      selection: 'Fiscal Year',
      value: ["All years"],
      stateLocation: 'TX'
    },
    {
      name: 'Location ',
      filter: 'Services',
      selection: 'State',
      value: ["State"],
      stateLocation: 'TX'
    },
    {
      name: 'Location',
      filter: 'Services',
      selection: 'County',
      value: ["All counties"],
      stateLocation: 'TX'
    },
    {
      name: 'Provider Location ',
      filter: 'Services',
      selection: 'State',
      value: ["State"],
      stateLocation: stateLocation
    },
    {
      name: 'Location of Origin',
      filter: 'Services',
      selection: 'Parish',
      value: ["All parishes"],
      stateLocation: 'LA'
      },
      {
        name: 'Provider Location',
        filter: 'Services',
        selection: stateLocation === 'LA' ? 'Parish' : 'County',
        value: stateLocation === 'LA' ? ['All parishes'] : ['All counties'],
        stateLocation: stateLocation,
      },
    {
      name: 'Ages',
      filter: 'Services',
      value: ["All ages"],
      stateLocation: 'LA'
    },
    {
      name: 'Organizations',
      filter: 'Services',
      value: ["All organizations"],
      stateLocation: stateLocation
    },
    {
      name: 'Trafficking Types',
      filter: 'Services',
      value: ["All types"],
      stateLocation: 'LA'
    },
    {
      name: "Victim Status",
      filter: "Services",
      value: ["Confirmed", "Suspected"],
      stateLocation: "LA",
    },
    {
      name: 'Time',
      filter: 'Services',
      value: ["All years"],
      stateLocation: 'LA'
      },
      {
        name: 'Provider Location ',
        filter: 'Services',
        selection: 'State',
        value: ["State"],
        stateLocation: 'FL'
      },
    {
      name: 'Time',
      filter: 'Locations',
      value: ["All years"]
    },
    {
      name: 'Location Type',
      filter: 'Locations',
      value: ["Parish of Trafficking"]
    },
    {
      name: 'Location',
      filter: 'Locations',
      selection: 'Parish',
      value: ["All parishes"],
      stateLocation: stateLocation,
    },
    {
      name: 'Ages',
      filter: 'Locations',
      value: ["All ages"]
    },
    {
      name: 'Organizations',
      filter: 'Locations',
      value: ["All organizations"]
    },
    {
      name: 'Trafficking Types',
      filter: 'Locations',
      value: ["All types"]
    },
    {
      name: "Victim Status",
      filter: "Locations",
      value: ["Confirmed", "Suspected"],
      stateLocation: "LA",
    },
    {
      name: 'Time',
      filter: 'Care Coordination',
      selection: 'Quarter & Fiscal Year',
      value: ["All quarters", "All years"]
    },
      {
        name: "Location",
        filter: "Care Coordination",
        stateLocation: stateLocation,
        selection: stateLocation === "TX" ? "County" : "Region",
        value: stateLocation === "TX" ? ["All counties"] : ['All regions'],
      },
      {
        name: "Trafficking Types",
        filter: "Care Coordination",
        stateLocation: stateLocation,
        value: ["All types"],
      },
      {
        name: "Individuals Served",
        filter: "Care Coordination",
        stateLocation: stateLocation,
        value: ["Trafficking Victims"],
      },
    {
      name: 'Time',
      filter: 'Screenings',
      selection: 'All time',
      value: ["All time"]
    },
    {
      name: 'Location',
      filter: 'Screenings',
      selection: 'County',
      value: stateLocation === 'LA' ? ["All parishes"] : ["All counties"],
      stateLocation: stateLocation,
      },
      {
        name: 'Victim Status',
        filter: "Screenings",
        value: ['All screenings'],
        stateLocation: stateLocation,
      },
    {
      name: 'Organizations',
      filter: 'Screenings',
      value: ["All organizations"],
      stateLocation: stateLocation,
    }, {
      name: 'Screenings',
      filter: 'Screenings',
      value: ["All screenings"]
    },  {
      name: 'Ages',
      filter: 'Screenings',
      value: ["All ages"],
      stateLocation: stateLocation,
    }, {
      name: 'Indicators',
      filter: 'Screenings',
      value: ["Clear Concern"]
    },  {
      name: 'Agency Type',
      filter: 'Law Enforcement',
      value: ["Arresting Agency (ORIA)"]
    }, {
      name: 'Offenses',
      filter: 'Law Enforcement',
      value: ["All offenses"],
      stateLocation: stateLocation,
    }, 
    {
      name: "Arrest Year",
      filter: "Law Enforcement",
      value: ['All years'],
      stateLocation: stateLocation,
    },{
      name: 'Location',
      filter: 'Law Enforcement',
      selection: 'County',
      stateLocation: stateLocation,
      value: stateLocation === 'TX' ? ["All counties"] : stateLocation === 'LA' ? ["All parishes"] : ["All counties"] 
      },
      
      {
        name: 'Map View',
      filter: 'Federal Cases',
      value: ['State Prosecuted'],
        stateLocation: stateLocation,
        stateClickedData: null
      },
      {
        name: 'Year Case Filed',
        filter: 'Federal Cases',
        value: ["All Years"]
      },
      {
        name: 'Location ',
        filter: 'Federal Cases',
        stateLocation: stateLocation,
        value: ['All States'],
      }, 
    {
      name: 'Location',
      filter: 'Federal Cases',
      stateLocation: stateLocation,
      selection: dashboardData.Cases.Federal.defaultChartLoSelection,
      value: dashboardData.Cases.Federal.defaultChartLoValue,
      stateValue: dashboardData.Cases.Federal?.defaultStateValue,
      districtValue: dashboardData.Cases.Federal?.defaultDistrictValue,
      countyValue: dashboardData.Cases.Federal?.defaultCountyValue
      },
      {
      name: 'Trafficking Type',
      filter: 'Federal Cases',
      value: ["All types"]
      }, 
      {
        name: 'Year',
        filter: 'State Policy',
        value: ['2023']
      },
      {
        name: 'Map View',
        filter: 'State Policy',
        value: ['All Other Trafficking Policy']
      },
      {
        name: 'Sentencing Requirement',
        filter: 'State Policy',
        value: ['Minor Sex Trafficking Maximum']
      },
      {
        name: 'Policy',
        filter: 'State Policy',
        value: ['All policies']
      },
    {
      name: 'Location',
      filter: 'State Policy',
      value: ["All States"]
    },
    {
      name: 'Location',
      filter: 'Texas Department of Criminal Justice',
      selection: 'County',
      value: ["All counties"],
      stateLocation: stateLocation,
    },{
      name: 'Offenses',
      filter: 'Texas Department of Criminal Justice',
      value: ["All offenses"]
    },{
      name: 'Ad Sites',
      filter: 'Demand',
      value: ["All Sites"]
    },{
      name: 'Location',
      filter: 'Businesses of Interest',
      stateLocation: stateLocation,
     selection: 'County',
     value: stateLocation === "LA" ? ["All parishes"] : ["All counties"],
    },
    {
      name: 'IMB Analysis',
      filter: 'Businesses of Interest',
      value: ["All Businesses"]
    },
    {
      name: 'Year',
      filter: 'Businesses of Interest',
      value: ["2024"]
      },
      {
        name: 'Location ',
        filter: 'Businesses of Interest',
        value: stateLocation === "LA" ? ["Parish"] : ["County"],
      },
      {
      name: 'Location',
      filter: 'Hotline',
      selection: 'County',
        value: stateLocation === "LA" ? ["All parishes"] : ["All counties"],
        stateLocation: stateLocation
    },{
      name: 'Cases',
      filter: 'Hotline',
      selection: 'All Cases',
      value: ["Involves Minors", "Involves Adults"]
    },{
      name: 'Time',
      filter: 'Hotline',
      selection: 'Quarter & Fiscal Year', // Fiscal on the backend, Calendar year on the frontend
      value: ["All years"]
    }
      // },{
      //   name: 'Location',
      //   filter: 'News',
      //   value: ["State"]
      // }
    ]);
  };

  useEffect(() => {
    setFilters(props.filters);
    setSelectedFilters(props.selectedFilters);
    setFilter(props.data);
  }, [props.selectedFilters, props.filters, props.data]);

  return (
    <div className='filterSummary container'>
      <div className='headerWrapper'>
        <p className='fontWeight700 margin0'>Filters</p>
        <p 
        className='pointer fontWeight700 margin0'
        onClick={handleResetDefaults}
        >Reset to defaults</p>
      </div>
      <div className='filterWrapper'>
        {filters.map((filter, key) => {
          return (
            <div key={key} className='filterSection'>
              <p>{filter.name ?? filter}</p>

              {selectedFilters.map((list, key) => {
                if (list.name === (filter?.name ?? filter) && props.data === list.filter) {
                  if (list.filter === "Services") {
                    if (stateLocation === list.stateLocation) {
                      return (
                        <div key={key} className='chipsContainer'>
                          {list.value.map((value, key) => (
                            <Chip
                              key={key}
                              label={value}
                              className='chip'
                              style={{ marginBottom: list.value.length  - 1 !== key ? '4px' : '0px'}}
                            />
                          ))}
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div key={key} className='chipsContainer'>
                        {list.value.map((value, key) => (
                          <Chip
                            key={key}
                            label={value}
                            className='chip'
                            style={{ marginBottom: list.value.length - 1 !== key ? '4px' : '0px' }}
                          />
                        ))}
                      </div>
                    );
                  }
                }
              })}
            </div>
          );
        })}
      </div>
      {/* <div className={classes.saveButton}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Save
              </Button>
            </div> */}
    </div>
  );
}
