import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { useAppContext } from '../../../../Lib/UserContext';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '20px',
    fontWeight: '300'
  },
  filterWrapper: {
    display: 'flex'
  },
  active: {
    cursor: 'pointer',
    fontWeight: "700 !important",
  },
  inactive: {
    fontWeight: '400 !important',
    cursor: 'pointer'
  },
  checkboxLabel: {
    padding: '0px'
  },
  checkboxes: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  }
}));

export default function CCTimeFilter(props) {
  const classes = useStyles();
  const { stateLocation } = useAppContext()
  //const [filter, setFilter] = React.useState([]);
  const [time, setTime] = React.useState(true);
  const [qFSelections, setQFSelections] = React.useState(["All quarters", "All years"]);
  const [yearSelections, setYearSelections] = React.useState(["All years"]);


  const filterData = {
    TX: {
      'quarters': ['Q1: Oct 1 - Dec 31', 'Q2: Jan 1 - Mar 31', 'Q3: Apr 1 - Jun 30', 'Q4: Jul 1 - Sep 30'],
      'years': ['FY19 (Oct 2018 - Sep 2019)', 'FY20 (Oct 2019 - Sep 2020)', 'FY21 (Oct 2020 - Sep 2021)', 'FY22 (Oct 2021 - Sep 2022)', 'FY23 (Oct 2022 - Sep 2023)', 'FY24 (Oct 2023 - Sep 2024)']
    },

    LA: {
      'quarters': ['Q1: Jan 1 - Mar 31', 'Q2: Apr 1 - Jun 30', 'Q3: Jul 1 - Sep 30', 'Q4: Oct 1 - Dec 31'],
      'years': ['2022', '2023', '2024']
    }
  }

  const handleQFCheckboxChange = (e, item) => {
    if (qFSelections.includes(item)) {
      let newArray = qFSelections.filter(x => x !== item);
      setQFSelections(newArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All quarters");
      filteredArray.push(item);
      setQFSelections(filteredArray);
    }
  }

  const handleQFYearCheckboxChange = (e, item) => {
    if (qFSelections.includes(item)) {
      let newArray = qFSelections.filter(x => x !== item);
      setQFSelections(newArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      filteredArray.push(item);
      setQFSelections(filteredArray);
    }
  }

  const handleQFAllQuarterCheckboxChange = (e) => {
    if (e.target.checked) {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => !filterData[stateLocation]['quarters'].includes(x))
      filteredArray.push("All quarters");
      setQFSelections(filteredArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All quarters");
      setQFSelections(filteredArray);
    }
  }

  const handleQFAllYearCheckboxChange = (e) => {
    if (e.target.checked) {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => !filterData[stateLocation]['years'].includes(x))
      filteredArray.push("All years");
      setQFSelections(filteredArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      setQFSelections(filteredArray);
    }
  }

  const handleYearCheckboxChange = (e, item) => {
    if (yearSelections.includes(item)) {
      let newArray = yearSelections.filter(x => x !== item);
      setYearSelections(newArray);
    } else {
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      filteredArray.push(item);
      setYearSelections(filteredArray);
    }
  }

  const handleAllYearCheckboxChange = (e) => {
    if (e.target.checked) {
      setYearSelections(["All years"]);
    } else {
      setYearSelections([]);
    }
  }

  const handleTimeChange = () => {
    setTime(!time)
  }

  const handleSubmit = () => {
    var timeCategory;
    if (stateLocation === 'TX') {
      timeCategory = time ? "Quarter & Fiscal Year" : "Fiscal Year";
    } else {
      timeCategory = time ? "Quarter & Calendar Year" : "Calendar Year";
    }
    props.filterCallback({
      name: 'Time',
      filter: 'Care Coordination',
      selection: timeCategory,
      value: time ? qFSelections : yearSelections,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++) {
      //Sort filters to get time filters
      if (props.selectedFilters[i].name === 'Time' && props.selectedFilters[i].filter === 'Care Coordination') {
        //Filter based on Quarter/Fiscal Year and Fiscal Year
        if (props.selectedFilters[i].selection === 'Quarter & Fiscal Year' || props.selectedFilters[i].selection === 'Quarter & Calendar Year') {
          setTime(true)
          setQFSelections(props.selectedFilters[i].value)
        } else {
          setTime(false)
          setYearSelections(props.selectedFilters[i].value)
        }
      }
    }
  }, [props.selectedFilters])

  return (
    <div className={classes.container}>
      {time ? (
        <div>
          <div className={classes.filterWrapper}>
            <div>
              <p className={time ? classes.active : classes.inactive} onClick={handleTimeChange}>{stateLocation === 'TX' ? `Quarter & Fiscal Year` : `Quarter & Year`}</p>
              <p className={!time ? classes.active : classes.inactive} onClick={handleTimeChange}>{stateLocation === 'TX' ? 'Fiscal Year' : 'Year'}</p>
            </div>
            <div className={classes.checkboxes}>
              <FormControlLabel className="checkboxLabel"
                control={
                  <BlackCheckbox
                    checked={qFSelections.includes("All quarters")}
                    onChange={handleQFAllQuarterCheckboxChange} name="checkedA"
                  />
                }
                label="All quarters"
              />

              {filterData[stateLocation]?.['quarters']?.map(quarter => (
                <FormControlLabel key={quarter} className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={qFSelections.includes(quarter)}
                      onChange={e => handleQFCheckboxChange(e, quarter)} name="checkedA"
                    />
                  }
                  label={quarter}
                />
              ))}
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.checkboxes}>
              <FormControlLabel className="checkboxLabel"
                control={
                  <BlackCheckbox
                    checked={qFSelections.includes("All years")}
                    onChange={e => handleQFAllYearCheckboxChange(e, "All years")}
                    name="checkedA"
                  />
                }
                label="All years"
              />
              {filterData[stateLocation]?.['years']?.map(year => (
                <FormControlLabel key={year} className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={qFSelections.includes(year)}
                      onChange={e => handleQFYearCheckboxChange(e, year)}
                      name="checkedA"
                    />
                  }
                  label={year}
                />
              ))}
            </div>

          </div>
          <div className={classes.saveButton}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save
                  </Button>
          </div>
        </div>
      ) : (
          <div>
            <div className={classes.filterWrapper}>
              <div>
                <p className={time ? classes.active : classes.inactive} onClick={handleTimeChange}>{stateLocation === 'TX' ? `Quarter & Fiscal Year` : `Quarter & Year`}</p>
                <p className={!time ? classes.active : classes.inactive} onClick={handleTimeChange}>{stateLocation === 'TX' ? 'Fiscal Year' : 'Year'}</p>
              </div>
              <div className={classes.checkboxes}>
                <FormControlLabel className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={yearSelections.includes("All years")}
                      onChange={handleAllYearCheckboxChange} name="checkedA"
                    />
                  }
                  label="All years"
                />
                {filterData[stateLocation]?.['years']?.map(year => (
                  <FormControlLabel key={year} className="checkboxLabel"
                    control={
                      <BlackCheckbox
                        checked={yearSelections.includes(year)}
                        onChange={e => handleYearCheckboxChange(e, year)}
                        name="checkedA"
                      />
                    }
                    label={year}
                  />
                ))}
              </div>
            </div>
            <div className={classes.saveButton}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Save
                  </Button>
            </div>
          </div>
        )}
    </div>
  )
}