import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { only50StatesNames } from "../../../../Data/only50StatesNames";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';


const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      padding: '20px',
      // width: '400px',
      fontWeight: '300',
      height: '300px',
      overflowY: 'scroll',
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer',
      fontWeight: "700 !important",
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      position: 'fixed',
      bottom: '10px',
      right: '10px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function StatePolicyLocationsFilter(props) {
  const classes = useStyles();
  const [stateSelections, setStateSelections] = React.useState([]);
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)


  const handleStateChange = (event) => {
      if (event[event.length-1] === 'All States'){
        setStateSelections(['All States']);
        setAutocompleteOpen(false)
      } else {
        const index = event.indexOf('All States');
        if (index > -1) {
          event.splice(index, 1); // 2nd parameter means remove one item only
        }
        setStateSelections(event);
      }
  };


  const handleSubmit = () => {

    props.filterCallback({
      name: 'Location',
      filter: 'State Policy',
      value: stateSelections,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Location' && props.selectedFilters[i].filter === 'State Policy'){
        setStateSelections(props.selectedFilters[i].value)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
        <div className={classes.container}>
           <div className={classes.filterWrapper}>
              <div>
            <p className={classes.active}>State</p>
                </div>
                <div>
                    <div className={classes.checkboxes}> 
                    <Autocomplete
                      multiple
                id="checkboxes-tags-demo"
                disableCloseOnSelect
                open={autocompleteOpen}
                onOpen={(e) => setAutocompleteOpen(true)}
                onClose={(e) => setAutocompleteOpen(false)}
                options={only50StatesNames.map(s => s.name)}
                      onChange={(event, value) => handleStateChange(value)}
                      value={stateSelections}
                      getOptionLabel={(option) => option}
                      renderOption={(option) => (
                        <React.Fragment>
                          <BlackCheckbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={stateSelections.includes(option)}
                          />
                          {option}
                        </React.Fragment>
                      )}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  
                  </div>
                    
               
                <div className={classes.saveButton}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                    </Button>
                </div>
              </div> 


            </div>
        </div>
    )
}