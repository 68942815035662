import React, { Fragment } from "react";

import Services from "../../../Pages/Dashboard/Charts/Services/Services";
import CareCoordination from "../../../Pages/Dashboard/Charts/CareCoordination/TXCareCoordination";
import Screenings from "../../../Pages/Dashboard/Charts/Screenings/TXLAScreenings";
import TrafLocations from "../../../Pages/Dashboard/Charts/TraffickingLocations/TraffickingLocations";
import PublicSafety from "../../../Pages/Dashboard/Charts/PublicSafety/PublicSafety";
import FederalCases from "../../../Pages/Dashboard/Charts/FederalCases/FederalCases";
import TDCJ from "../../../Pages/Dashboard/Charts/TDCJ/TDCJ";
import Demand from "../../../Pages/Dashboard/Charts/Demand/Demand";
import News from "../../../Pages/Dashboard/Charts/News/News";
import Hotline from "../../../Pages/Dashboard/Charts/Hotline/Hotline";
import BusinessesOfInterest from "../../../Pages/Dashboard/Charts/IMB/IMB";
import CommunityRisk from "../../../Pages/Dashboard/Charts/Risk/CommunityRisk";
import StatePolicy from "../../../Pages/Dashboard/Charts/StatePolicy/StatePolicy";

import "./ChartWrapper.scss";

export default function ChartWrapper(props) {
    const getChartKlass = (chartCategory) => {
        switch (chartCategory) {
            case "Services":
                return Services;
            case "Coordination":
                return CareCoordination;
            case "Screenings":
                return Screenings;
            case "TraffickingLocations":
                return TrafLocations;
            case "Federal":
                return FederalCases;
            case "PublicSafety":
                return PublicSafety;
            case "TDCJ":
                return TDCJ;
            case "Demand":
                return Demand;
            case "News":
                return News;
            case "Hotline":
                return Hotline;
            case "IMB":
                return BusinessesOfInterest;
            case "CommunityRisk":
                return CommunityRisk;
            case "StatePolicy":
                return StatePolicy;
            default:
                return "";
        }
    };

    const renderChart = () => {
        if (!props.chartCategory || !props.chartData || !props.chartId) {
            return <Fragment></Fragment>;
        }

        const ChartKlass = getChartKlass(props.chartCategory);
        if (!ChartKlass) {
            return <Fragment></Fragment>;
        }

        return <ChartKlass
            data={props.chartData[props.chartCategory]}
            chartId={props.chartId}
            chartTitle={props.chartTitle}
            chartInsight={props.chartInsight}
            subchartInsight={props.subchartInsight}
            handleStateClick={props.handleStateClick}
            statePolicyStateSelection={props.statePolicyStateSelection}
        />
    };

    const getSourceElement = () => {
        if (!props.chartSource) {
          return "";
        }

        const sourceLink = /\(([^)]+)\)/g.exec(props.chartSource)?.[1] || "";
        return props.chartSource
          .replace("[", `<a href="${sourceLink || ""}" target="_blank">`)
          .replace("]", "</a>")
          .replace(/\((.*)\)/g, "");
    };

    return (
        <div className="public-site-chart-wrapper">
            {renderChart(props.chartData, props.chartCategory, props.chartId)}
            <div
              className="public-site-chart-footer"
              dangerouslySetInnerHTML={{ __html: getSourceElement() }}
            >
            </div>
        </div>
    );
}