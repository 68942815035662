import uniq from "lodash/uniq";
import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";

import "./CustomDropdown.scss";

export default function CustomDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);

    const handleChange = (value) => {
        props.setSelectedValue(value);
    };

    const handleOnOpen = () => {
        setIsOpen(true);
    };

    const handleOnClose = () => {
        setIsOpen(false);
    }

    const getItemsByCategory = (category, menuItems) => {
        return menuItems.filter((item) => item.category === category);
    }

    useEffect(() => {
        const uniqueCategories = uniq(props.menuItems.map((item) => item.category));
        setCategories(uniqueCategories);
    }, [props.menuItems]);

    return (
        props.menuItems.length
            ? <div className={isOpen ? "custom-select-dropdown open" : "custom-select-dropdown"}>
                <FormControl>
                    <Select
                        defaultValue={props.menuItems[0].value}
                        value={props.selectedValue}
                        onOpen={handleOnOpen}
                        onClose={handleOnClose}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{
                            className: "custom-select-dropdown-menu"
                        }}
                    >
                    {
                        categories.map((category, index) => [
                            <ListSubheader key={`menu-header-${index}`}>
                                {category}
                            </ListSubheader>,
                            ...getItemsByCategory(category, props.menuItems)
                                .map((item, key) => (
                                    <MenuItem
                                        onClick={() => handleChange(item.value)}
                                        value={item.value}
                                        key={`menu-option-${key}`}
                                    >
                                        <h3 className='margin0'>{item.text}</h3>
                                    </MenuItem>
                                ))
                        ])
                    }
                    </Select>
                </FormControl>
            </div>
            : <Fragment></Fragment>
    );
};