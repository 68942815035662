import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import './Filter.css';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';


const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    // width: '400px',
    fontWeight: '300',
    height: '400px',
    overflowY: 'scroll',
  },
  filterWrapper: {
    display: 'flex',
    flex: 1,
  },
  active: {
    cursor: 'pointer',
    fontWeight: '700 !important',

  },
  inactive: {
    fontWeight: '400 !important',
    cursor: 'pointer'
  },
  checkboxLabel: {
    padding: '0px'
  },
  checkboxes: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  saveButton: {
    // position: 'fixed',
    // bottom: '10px',
    // right: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    // marginTop: '20px'
    padding: '20px 0px 10px 0px'
  }
}));

export default function CRLocationFilter(props) {
  const classes = useStyles();
  const [location, setLocation] = React.useState(true)
  let newRegionList = props.regionOptions?.filter(r => r !== 'All regions')
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const [countySelections, setCountySelections] = React.useState([]);
  const [regionSelections, setRegionSelections] = React.useState([]);

  const handleCountyLocationChange = () => {
    setLocation(true)
    setRegionSelections(["All regions"]);
  }
  const handleRegionLocationChange = () => {
    setLocation(false)
    setCountySelections([props.allCountiesText]);
  }

  const handleCountyChange = (event) => {
    if (event[event.length - 1] === props.allCountiesText) {
      setCountySelections([props.allCountiesText]);
      setAutocompleteOpen(false)
    } else {
      let newEvent = [...event].filter(x => x !== props.allCountiesText)
      setCountySelections(newEvent);
    }
  };

  const handleRegionChange = (event) => {
    var newRegionList = [...regionSelections];
    newRegionList = newRegionList.filter(x => x !== 'All regions')

    if (newRegionList.includes(event.target.value)) {
      newRegionList = newRegionList.filter(x => x !== event.target.value)
      setRegionSelections(newRegionList);
    } else {
      newRegionList.push(event.target.value)
      setRegionSelections(newRegionList);
    }
  };

  const handleAllRegionChange = (e) => {
    if (e.target.checked) {
      setRegionSelections(["All regions"]);
    } else {
      setRegionSelections([]);
    }
  }

  const handleSubmit = () => {
    var locationCategory = location ? (props.countySelection ? props.countySelection : "County") : "Region";
    props.filterCallback({
      name: props.filterName,
      filter: props.page,
      stateLocation: props.stateLocation,
      selection: locationCategory,
      value: location ? countySelections : regionSelections,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++) {
      if (props.selectedFilters[i].name === props.filterName && props.selectedFilters[i].filter === props.page && props.selectedFilters[i].stateLocation === props.stateLocation) {
        if (props.selectedFilters[i].selection === (props.countySelection ?? 'County')) {
          setCountySelections(props.selectedFilters[i].value)
          setRegionSelections(["All regions"])
        } else {
          setCountySelections([props.allCountiesText])
          setRegionSelections(props.selectedFilters[i].value)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.container}>
      {location ? (
        // <div>
        <div className={classes.filterWrapper}>
          <div>
            <p className={location ? classes.active : classes.inactive} onClick={handleCountyLocationChange}>{props.countyName}</p>
            <p className={!location ? classes.active : classes.inactive} onClick={handleRegionLocationChange}>Region</p>
          </div>
          <div className={classes.checkboxes}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              disableCloseOnSelect
              open={autocompleteOpen}
              onOpen={(e) => setAutocompleteOpen(true)}
              onClose={(e) => setAutocompleteOpen(false)}
              options={props.countyOptions ?? []}
              onChange={(event, value) => handleCountyChange(value)}
              value={countySelections}
              getOptionLabel={(option) => option}
              renderOption={(option) => (
                <React.Fragment>
                  <BlackCheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={countySelections.includes(option)}
                  />
                  {option}
                </React.Fragment>
              )}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />

          </div>
        </div>
      ) :
        (
          <div className={classes.filterWrapper}>
            <div>
              <p className={location ? classes.active : classes.inactive} onClick={handleCountyLocationChange}>{props.countyName}</p>
              <p className={!location ? classes.active : classes.inactive} onClick={handleRegionLocationChange}>Region</p>
            </div>
            <div className={classes.checkboxes}>
              <FormControlLabel className="checkboxLabel"
                control={
                  <BlackCheckbox
                    checked={regionSelections.includes("All regions")}
                    onChange={handleAllRegionChange}
                    name="checkedA" />
                }
                label="All regions"
              />
              {newRegionList?.map((region, key) => {
                return (
                  <FormControlLabel key={key} className="checkboxLabel"
                    control={
                      <BlackCheckbox
                        checked={regionSelections.includes(region)}
                        onChange={e => handleRegionChange(e, region)}
                        name="checkedA"
                        value={region} />
                    }
                    label={region}
                  />
                )
              })}
            </div>
          </div>
        )}
      <div className={classes.saveButton}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
                </Button>
      </div>
    </div>
  )
}