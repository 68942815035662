export const imbdata = {
  //Business by Year
  businessYear: [
    {
      variable: "2016",
      name: "2016",
      
    },
    {
      variable: "2017",
      name: "2017",
      
    },
    {
      variable: "2018",
      name: "2018",
      
    },
    {
      variable: "2019",
      name: "2019",
      
    },
    {
      variable: "2020",
      name: "2020",
      
    },
    {
      variable: "2021",
      name: "2021",
      
    },
    {
      variable: "2022",
      name: "2022",
      
    },
    {
      variable: "2023",
      name: "2023",
      
    },
    {
      variable: "2024",
      name: "2024",
      
    },
    {
      variable: "2025",
      name: "2025",

    },
  ],
  businessYearFL: [
    {
      variable: "2019",
      name: "2019",
      color: "#4E79A7",
    },
    {
      variable: "2020",
      name: "2020",
      color: "#4E79A7",
    },
    {
      variable: "2021",
      name: "2021",
      color: "#4E79A7",
    },
    {
      variable: "2022",
      name: "2022",
      color: "#4E79A7",
    },
    {
      variable: "2023",
      name: "2023",
      color: "#4E79A7",
    },
    {
      variable: "2024",
      name: "2024",
      color: "#4E79A7",
    },
    {
      variable: "2025",
      name: "2025",
      color: "#4E79A7",
    },
  ],
  //Business by Location
  businessLocation: [
    {
      variable: "Houston",
      name: "Houston",
      
    },
    {
      variable: "San Antonio",
      name: "San Antonio",
      color: "#F2BE2B",
    },
    {
      variable: "Austin",
      name: "Austin",
      
    },
    {
      variable: "Plano",
      name: "Plano",
      
    },
    {
      variable: "Dallas",
      name: "Dallas",
      
    },
    {
      variable: "Fort Worth",
      name: "Fort Worth",
      
    },
    {
      variable: "Midland",
      name: "Midland",
      
    },
    {
      variable: "Odessa",
      name: "Odessa",
      
    },
    // {
    //   variable: "Spring",
    //   name: "Spring",
    //   
    // },
    {
      variable: "Odessa",
      name: "Odessa",
      
    },
    {
      variable: "Arlington",
      name: "Arlington",
      
    },
  ],
  //Business by location and population
  businessByLocationPop: [
    {
      variable: "Arlington",
      name: "Arlington",
      // 
    },
    {
      variable: "Austin",
      name: "Austin",
      color: "#F2BE2B",
    },
    {
      variable: "Dallas",
      name: "Dallas",
      
    },
    {
      variable: "Fort Worth",
      name: "Fort Worth",
      
    },
    {
      variable: "Houston",
      name: "Houston",
      
    },
    {
      variable: "Midland",
      name: "Midland",
      
    },
    {
      variable: "Odessa",
      name: "Odessa",
      
    },
    {
      variable: "Plano",
      name: "Plano",
      
    },
  ],
};
