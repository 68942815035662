// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

export function ScreeningsLayer(map, filters, data, stateLocation, mapsIndex) {
  let mapDataIndex = mapsIndex['Screenings']
  // var locationSelection = "County"
  // for (let i = 0; i < filters.length; i++){
  //     if (filters[i].name === 'Location' && filters[i].filter === 'Screenings'){
  //         locationSelection = filters[i].selection;
  //     }
  // }
  var screeningSelection = filters.filter(f => f.name === 'Screenings' && f.filter === 'Screenings')?.[0]?.value?.[0] ?? 'All screenings'
  // let victimStatusSelection = filters.filter(f => f.name === 'Victim Status' && f.filter === 'Screenings')?.[0]?.value ?? ['All screenings']

  var mapJson = JSON.parse(JSON.stringify(mapDataIndex?.mapJson))

  if (data[mapDataIndex.dataVar]) {
    mapJson.features.map(f => {
      mapDataIndex.displayMapData.forEach(item => {

        f.properties[item.featureProp] = 0
        f.properties[item?.percent] = 0
      })

      data[mapDataIndex.dataVar].forEach(d => {
        if (
          f.properties[mapDataIndex.matchJsonLoc] ===
          d[mapDataIndex.matchDataLoc]
        ) {
          mapDataIndex.displayMapData.forEach(item => {
            f.properties[item.featureProp] = Number(d[item.dataVar])
            f.properties[item.percent] = Number(d[item.percent])
          })
        }

      })
      return f
    })

    map.on("load", function () {
      map.addSource("county", {
        type: "geojson",
        data: mapJson,
      });
      map.addLayer({
        id: "county",
        type: "line",
        source: "county",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#28ADE3",
          "line-width": 1,
        },
      });

      map.addLayer({
        id: "county-fills",
        type: "fill",
        source: "county",
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": {
            property: "SCREENINGS",
            stops: [
              [1, "#e9f6fc"],
              [100, "#93d6f1"],
              [500, "#28ade3"],
              [1000, "#186788"],
              [2000, "#0c3344"],
              [3000, "#000000"],
            ],
          },
          "fill-outline-color": "#28ADE3",
          "fill-opacity": 0.9,
        },
      });
    });

    let hoveredStateId = null;
    let popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on("mousemove", "county-fills", function (e) {
      if (hoveredStateId !== e.features[0].id) {
        if (e.features.length > 0) {
          if (hoveredStateId) {
            map.setFeatureState(
              { source: "county", id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = e.features[0].id;
          map.setFeatureState(
            { source: "county", id: hoveredStateId },
            { hover: true }
          );
        }
        // console.log(e.features[0].properties)
        let description;
        if (stateLocation !== 'FL') {
          if (screeningSelection === 'Clear Concern') {
            description = `
            <h3><strong>${e.features[0].properties.COUNTY}</strong></h3> 
              <p>Clear Concern: 
              <span style="font-weight: 300 !important">
              ${e.features[0].properties.CLEARCONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
              </p>`
          } else if (screeningSelection === 'Possible Concern') {
            description = `
            <h3><strong>${e.features[0].properties.COUNTY}</strong></h3> 
              <p>Possible Concern: <span style="font-weight: 300 !important">${e.features[0].properties.POSSIBLECONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`
          } else if (screeningSelection === 'No Concern') {
            description = `
            <h3><strong>${e.features[0].properties.COUNTY}</strong></h3> <p>No Concern: <span style="font-weight: 300 !important">${e.features[0].properties.NOCONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`
          } else {
            description = `
              <h3>${e.features[0].properties.COUNTY}</h3> 
              <p>Total Screenings: <span style="font-weight: 300 !important">${e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
              <p>Clear Concern: <span style="font-weight: 300 !important">${e.features[0].properties.CLEARCONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${(e.features[0].properties.CLEARCONCERN && e.features[0].properties.SCREENINGS) ? ((e.features[0].properties.CLEARCONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) : 0}%)</span></p>
              <p>Possible Concern: <span style="font-weight: 300 !important">${e.features[0].properties.POSSIBLECONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${(e.features[0].properties.POSSIBLECONCERN && e.features[0].properties.SCREENINGS) ? ((e.features[0].properties.POSSIBLECONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) : 0}%)</span></p>
              <p>No Concern: <span style="font-weight: 300 !important">${e.features[0].properties.NOCONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${(e.features[0].properties.NOCONCERN && e.features[0].properties.SCREENINGS) ? ((e.features[0].properties.NOCONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) : 0}%)</span></p>
              `;
          }
        } else { // FLORIDA
          let text = `<h3>${e.features[0].properties.COUNTY}</h3> `

          mapDataIndex.displayMapData.forEach(item => {
            if (item.name === 'Total') {
              text += `
              <p>Total Screenings: <span style="font-weight: 300 !important">${e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
              `
            } else {
              text += `
              <p>${item.name}: <span style="font-weight: 300 !important">${e.features[0].properties[item.featureProp].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${e.features[0].properties[item.percent] ?? 0}%)</span></p>
              `
            }
          })
          description = text
        }
        popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
      }
    });


    map.on("mouseleave", "county-fills", function () {
      if (hoveredStateId) {
        map.setFeatureState(
          { source: "county", id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
      popup.remove();
    });

  }
  // else if (locationSelection === "Region" && data.regions) {
  //     for (let i = 0; i < screeningsRegionData.features.length; i++) {
  //         for (let j = 0; j < data.regions.length; j++) {
  //           if (
  //             screeningsRegionData.features[i].properties.region ===
  //             data.regions[j].region
  //           ) {
  //             screeningsRegionData.features[i].properties.SCREENINGS = Number(
  //                 data.regions[j].total
  //             );
  //             screeningsRegionData.features[i].properties.CLEARCONCERN = Number(
  //                 data.regions[j].clearconcern
  //             );
  //             screeningsRegionData.features[i].properties.POSSIBLECONCERN = Number(
  //                 data.regions[j].possibleconcern
  //             );
  //             screeningsRegionData.features[i].properties.NOCONCERN = Number(
  //                 data.regions[j].noconcern
  //             );
  //           }
  //         }
  //       }
  //       let calculatedData = screeningsRegionData;
  //         map.on("load", function () {
  //             map.addSource("region", {
  //             type: "geojson",
  //             data: calculatedData,
  //             });
  //             map.addLayer({
  //             id: "region",
  //             type: "line",
  //             source: "region",
  //             layout: {
  //                 "line-join": "round",
  //                 "line-cap": "round",
  //             },
  //             paint: {
  //                 "line-color": "#28ADE3",
  //                 "line-width": 1,
  //             },
  //             });

  //             map.addLayer({
  //             id: "region-fills",
  //             type: "fill",
  //             source: "region",
  //             layout: {
  //                 visibility: "visible",
  //             },
  //             paint: {
  //                 "fill-color": {
  //                 property: "SCREENINGS",
  //                 stops: [
  //                     [1, "#e9f6fc"],
  //                     [1000, "#93d6f1"],
  //                     [5000, "#28ade3"],
  //                     [10000, "#186788"],
  //                     [15000, "#0c3344"],
  //                     [20000, "#000000"],
  //                 ],
  //                 },
  //                 "fill-outline-color": "#28ADE3",
  //                 "fill-opacity": 0.9,
  //             },
  //             });
  //         });

  //         let hoveredStateId = null;
  //         let popup = new mapboxgl.Popup({
  //             closeButton: false,
  //             closeOnClick: false,
  //             });

  //             map.on("mousemove", "region-fills", function (e) {
  //             if (hoveredStateId !== e.features[0].id) {
  //                 if (e.features.length > 0) {
  //                 if (hoveredStateId) {
  //                     map.setFeatureState(
  //                     { source: "region", id: hoveredStateId },
  //                     { hover: false }
  //                     );
  //                 }
  //                 hoveredStateId = e.features[0].id;
  //                 map.setFeatureState(
  //                     { source: "region", id: hoveredStateId },
  //                     { hover: true }
  //                 );
  //                 }

  //                 let description = `
  //                 <h3>${e.features[0].properties.region}</h3> 
  //                 <p>Total Screenings: <span style="font-weight: 300 !important">${
  //                     Number(e.features[0].properties.SCREENINGS) > 5
  //                       ? e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //                       : 0
  //                   }</span></p>
  //                 <p>Clear Concern: <span style="font-weight: 300 !important">${
  //                     Number(e.features[0].properties.SCREENINGS) > 5
  //                       ? e.features[0].properties.CLEARCONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //                       : 0
  //                   } (${((e.features[0].properties.CLEARCONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) ? ((e.features[0].properties.CLEARCONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) : 0}%)</span></p>
  //                 <p>Possible Concern: <span style="font-weight: 300 !important">${
  //                     Number(e.features[0].properties.SCREENINGS) > 5
  //                       ? e.features[0].properties.POSSIBLECONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //                       : 0
  //                   } (${((e.features[0].properties.POSSIBLECONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) ? ((e.features[0].properties.POSSIBLECONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1): 0}%)</span></p>
  //                 <p>No Concern: <span style="font-weight: 300 !important">${
  //                     Number(e.features[0].properties.SCREENINGS) > 5
  //                       ? e.features[0].properties.NOCONCERN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //                       : 0
  //                   } (${((e.features[0].properties.NOCONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) ? ((e.features[0].properties.NOCONCERN / e.features[0].properties.SCREENINGS) * 100).toFixed(1) : 0}%)</span></p>`;
  //                 popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
  //             }
  //             });

  //             map.on("mouseleave", "region-fills", function () {
  //             if (hoveredStateId) {
  //                 map.setFeatureState(
  //                 { source: "region", id: hoveredStateId },
  //                 { hover: false }
  //                 );
  //             }
  //             hoveredStateId = null;
  //             popup.remove();
  //             });

  // } 
}