import { countyData as countyJson } from "../Data/countyJson";
// import { txDistrictJson } from "../Data/txDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'
import { screeningsOrgList } from '../Data/orgList'
import { txCountyList } from "../Data/txCountyList";
import { txRegionList } from "../Data/txRegionList";

export const mapsTXData = {
  mapZoom: 5,
  reportMapZoom: 4,
  mapBoxCenter: [-99.901, 37.0586],
  mapBoxCenterReport: [-114.77287069367998, 31.0586],
  mapMaxBounds: [[-113.77287069367998, 16.7091], [-86.26493500455376, 37.743288738760796]],
  mapMaxBoundsReport: [[-114.77287069367998, 16.7091], [-86.26493500455376, 37.743288738760796]],
  reportMapWidth: '577px',

  'Services': {
    orgList: [
      { name: "CSEY Advocacy", color: 0 },
      { name: "Drop-In Center", color: 1 },
      { name: "Emergency Shelter", color: 2 },
      { name: "Long Term Residential", color: 3 },
      { name: "National Referral Directory", color: 4 },
    ],
    countyList: txCountyList,
    regionList: txRegionList,
  },

  'Screenings': {
    mapJson: countyJson,
    dataVar: 'counties',
    dataTotalVar: 'countiesTotal',
    matchDataLoc: 'county',
    matchJsonLoc: 'COUNTY',
    displayMapData: [
      {
        name: 'Total',
        dataVar: 'total',
        totalsDataVar: 'total',
        featureProp: 'SCREENINGS'
      },
      {
        name: 'Clear Concern',
        dataVar: 'clearconcern',
        totalsDataVar: 'clearConcern',
        featureProp: 'CLEARCONCERN',
        percent: 'clearConcernPercent'
      },
      {
        name: 'Possible Concern',
        dataVar: 'possibleconcern',
        totalsDataVar: 'possibleConcern',
        featureProp: 'POSSIBLECONCERN',
        percent: 'possibleConcernPercent'
      },
      {
        name: 'No Concern',
        dataVar: 'noconcern',
        totalsDataVar: 'noConcern',
        featureProp: 'NOCONCERN',
        percent: 'noConcernPercent'
      },
    ],
    organizations: screeningsOrgList,
    countyList: txCountyList,
    regionList: txRegionList,
    agesOptions: ['0-4', '5-9', '10-14', '15-19', '20-24', '25-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80+'],

    mapInfo: <div>
      <p className='margin0'><span className='fontWeight700'>Data Source:</span> WestCoast & TJJD CSE-IT screenings</p>
      <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2016-present</p>

      <p className='margin0'><span className='fontWeight700'>Data Source:</span> DFPS CSE-IT screenings</p>
      <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2019-present</p>

      <p className='margin0'><span className='fontWeight700'>Data Source:</span> Lighthouse CSE-IT Screenings</p>
      <p className='margin0'><span className='fontWeight700'>Date:</span> 2020-present</p>

      <p>
        This data source represents CSE-IT screenings, including
        historic data submitted by Texas agencies to WestCoast
        Children’s Clinic, historic and current screenings from the
        Texas Juvenile Justice Department (TJJD) and the Texas
        Department of Family and Protective Services (DFPS), and
        screenings submitted directly in Lighthouse starting in
        2020.
                  </p>
      <p>
        Organizations may complete more than one CSE-IT screening for an individual as needed, or the same individual may be screened by more than one organization. Screening data should not be interpreted as prevalence data. Data represents the location where the screening occurred or the location of the agency that performed the screening. Some screenings may have occurred in locations that are different from the location of the agency that performed the screening.
                  </p>
      <p>
        TJJD CSE-IT screening data is submitted outside Lighthouse,
        and is refreshed monthly. There may be a delay at the start
        of each month before new data is visible.
                  </p>
      <p>
        DFPS CSE-IT screening data is submitted outside Lighthouse,
        and may be delayed by several quarters before new data is
        visible. Screenings are conducted in multiple Texas counties
        (Bexar, Dallas, Harris, Tarrant, Travis), but are all
        associated with Travis county within Lighthouse. DFPS data
        reflects the number and trends for CSE-ITs completed by DFPS
        and not the number of unique individual children's results.
        DFPS may complete more than one CSE-IT for a child/youth as
        needed, therefore children may be duplicated in this data
        set. Once a child/youth has a CSE-IT clear concern score or
        is an identified victim, DFPS no longer completes a CSE-IT.
        A clear concern score does not equate to victimization but
        is considered a presumed victim. DFPS partners who may also
        complete CSE-ITs for children and youth involved with DFPS
        are not included in the DFPS data set.
                  </p>
      <p>
        Historic CSE-IT screenings did not include certain data for
        gender, identity and sexual orientation; these demographics
        can be submitted in Lighthouse. Lighthouse screenings are
        updated dynamically.
                  </p>
      <p>Any screening values which are less than six will be shown as zero in the Screenings map and charts.</p>
    </div>,
  },

  'Federal Cases': {
    mapZoom: mapsNationalData?.mapZoom,
    reportMapZoom: mapsNationalData?.reportMapZoom,
    mapBoxCenter: mapsNationalData?.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData?.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData?.reportMapWidth,
    'Map View': mapsNationalData['Federal Cases']['Map View'],
    mapInfo: mapsNationalData['Federal Cases'].mapInfo,
  },

  'Law Enforcement': {
    countyList: txCountyList,
    regionList: txRegionList,
  },

  'State Policy': {
    mapZoom: mapsNationalData?.mapZoom,
    reportMapZoom: mapsNationalData?.reportMapZoom,
    mapBoxCenter: mapsNationalData?.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData?.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData?.reportMapWidth,
    'Data': mapsNationalData['State Policy']['Data'],
    'CriminalData': mapsNationalData['State Policy']['CriminalData'],
    mapInfo: mapsNationalData['State Policy'].mapInfo
  },

  'Texas Department of Criminal Justice': {
    countyList: txCountyList,
    regionList: txRegionList,
  },

  'Hotline': {
    countyList: txCountyList,
    regionList: txRegionList,
  },

  'Businesses of Interest': {
    countyList: txCountyList,
    regionList: txRegionList,
  },
}