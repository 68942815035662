import React, { useState, useCallback, useEffect } from "react";
import throttle from "lodash/throttle";
import { API } from "aws-amplify";
import Markdown from "react-markdown";

import "./MapWrapper.scss";

import { useAppContext } from "../../../Lib/UserContext";
import Map from "../../../Pages/Dashboard/Map.js";
import ChartMapControls from "../ChartMapControls/ChartMapControls";
import ButtonLink from "../ButtonLink/ButtonLink";
import Modal from "../Modal/Modal";

import { getMapOrChartData, getAPIMethodByMapChartName } from "../../../Lib/Helpers/MapChartHelpers";

export default function MapWrapper(props) {
    const { stateLocation } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState({});
    const [mapData, setMapData] = useState({});
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [mapNameToMapInfo, setMapNameToMapInfo] = useState({});
    const [statePolicyYear, setStatePolicyYear] = useState(2023);
    const [shouldShowGuidelinesModal, setOpenGuidelinesModal] = useState(false);
    const [shouldShowDisclaimerModal, setOpenDisclaimerModal] = useState(false);
    const [citationModalContent, setCitationModalContent] = useState({});
    const [disclaimerModalContent, setDisclaimerModalContent] = useState({});

    const openCitationGuidelinesModal = () => {
      setOpenGuidelinesModal(true);
    }

    const openDisclaimerModal = () => {
      setOpenDisclaimerModal(true);
    };

    function LinkRenderer(props) {
      return (
        <a href={props.href} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      );
    }

    const getMapNameToMapInfo = useCallback(() => {
        const nameToFiltersMap = {};

        for (const key of Object.keys(dashboardData)) {
          for (const mapName of Object.keys(dashboardData[key])) {
            nameToFiltersMap[mapName] = dashboardData[key][mapName];
          }
        }

        return nameToFiltersMap;
    }, [dashboardData]);

    const callAPI = (method) => {
      API.post("lighthouse-data-initiative", method, {
        body: {
          filters: selectedFilters,
          stateLocation
        }
      }).then((response) => {
        setMapData(response);
        setIsLoading(false);
      }).catch((error) => {
        console.log("Error on api call to get map data", method, error);
      });
    };

    const throttleCallAPI = throttle(callAPI, 1000);

    useEffect(() => {
        if (!props.mapName) {
            return;
        }

        const { dashboardData, defaultFilterArray } = getMapOrChartData(stateLocation);

        setDashboardData(dashboardData);
        setSelectedFilters(defaultFilterArray);
        setMapNameToMapInfo(getMapNameToMapInfo());
    }, [props.mapName, stateLocation, getMapNameToMapInfo]);

    useEffect(() => {
      if (!props.modals?.length) {
        return;
      }

      setCitationModalContent(props.modals?.filter((modal) => modal.modalId === "citationGuidelines")[0]);
      setDisclaimerModalContent(props.modals?.filter((modal) => modal.modalId === "disclaimer")[0]);
    }, [props.modals]);

    useEffect(() => {
        if (!props.mapName) {
            return;
        }

        const apiMethodName = getAPIMethodByMapChartName(props.mapName, true);
        if (!apiMethodName) {
            return;
        }

        throttleCallAPI(apiMethodName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);

  const stateFilterCallback = (item) => {
    var newArray = [...selectedFilters];
    for (let i = 0; i < newArray.length; i++) {
      if (
        newArray[i].name === item.name &&
        newArray[i].filter === item.filter
      ) {
        newArray[i] = item
      }
    }
    setSelectedFilters(newArray);
  };

    return (
        <div className="public-site-map">
            <div className="public-site-map-wrapper">
                <ChartMapControls
                  isMap={true}
                  mapName={props.mapName}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  mapNameToMapInfo={mapNameToMapInfo}
                  mapData={mapData}
                  stateFilterCallback={stateFilterCallback}
                  statePolicyYear={statePolicyYear}
                  setStatePolicyYear={setStatePolicyYear}
                />
                <div className="public-site-map-container">
                    {
                        isLoading
                            ? <div></div>
                            : <Map
                                mapZoom={3.6}
                                charts={mapNameToMapInfo[props.mapName].secondary}
                                data={mapData}
                                filters={selectedFilters}
                                shouldUseNewStyles={true}
                                filterCallback={stateFilterCallback}
                              />
                    }
                </div>
                <div className="public-site-map-description">
                    <div className="map-header">
                        {props.mapTitle}
                    </div>
                    <div className="map-description">
              <h3>
                      <Markdown>
                {props.mapInsight}
                </Markdown>
              </h3>
                    </div>
                </div>
            </div>
        <div className="public-site-map-footer">
          <h3 className='margin0'>
              <Markdown components={{ a: LinkRenderer }}>
                {props.mapSource}
            </Markdown>
          </h3>
              <div className="cta-button-group">
                <ButtonLink
                  className="btn-black"
                  onClickFn={openCitationGuidelinesModal}
                >
                  {props.citationGuidelinesCta}
                </ButtonLink>
                <ButtonLink
                  className="btn-black"
                  onClickFn={openDisclaimerModal}
                >
                  {props.disclaimerCta}
                </ButtonLink>
              </div>
            </div>
            <Modal
              open={shouldShowGuidelinesModal}
              handleClose={() => setOpenGuidelinesModal(false)}
              modalHeader={citationModalContent.modalHeader}
              modalContent={citationModalContent.modalContent}
              modalFooterConfirmLabel={citationModalContent.modalFooterConfirmLabel}
            />
            <Modal
              open={shouldShowDisclaimerModal}
              handleClose={() => setOpenDisclaimerModal(false)}
              modalHeader={disclaimerModalContent.modalHeader}
              modalContent={disclaimerModalContent.modalContent}
              modalFooterConfirmLabel={disclaimerModalContent.modalFooterConfirmLabel}
            />
        </div>
    )
}
