import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: '20px',
        fontWeight: '300'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer',
      fontWeight: "700 !important",
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function TimeFilter(props) {
  const classes = useStyles();
  //const [filter, setFilter] = React.useState([]);
  const [time, setTime] = React.useState(true);
  const [qFSelections, setQFSelections] = React.useState(["All quarters", "All years"]);
  const [yearSelections, setYearSelections] = React.useState(["All years"]);

  const handleQFCheckboxChange = (e, item) => {
    if (qFSelections.includes(item)){
      let newArray = qFSelections.filter(x => x !== item); 
      setQFSelections(newArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All quarters");
      filteredArray.push(item);
      setQFSelections(filteredArray);
    }
  }

  const handleQFYearCheckboxChange = (e, item) => {
    if (qFSelections.includes(item)){
      let newArray = qFSelections.filter(x => x !== item); 
      setQFSelections(newArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      filteredArray.push(item);
      setQFSelections(filteredArray);
    }
  }

  const handleQFAllQuarterCheckboxChange = (e) => {
    if (e.target.checked){
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "Q1: Oct 1 - Dec 31" && x !== "Q2: Jan 1 - Mar 31" && x !== "Q3: Apr 1 - Jun 30"  && x !== "Q4: Jul 1 - Sep 30");
      filteredArray.push("All quarters");
      setQFSelections(filteredArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All quarters");
      setQFSelections(filteredArray);
    }
  }

  const handleQFAllYearCheckboxChange = (e) => {
    if (e.target.checked){
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "FY18 (Oct 2017 - Sep 2018)" && x !== "FY19 (Oct 2018 - Sep 2019)" && x !== "FY20 (Oct 2019 - Sep 2020)" && x !== "FY21 (Oct 2020 - Sep 2021)" && x !== "FY22 (Oct 2021 - Sep 2022)" && x !== "FY23 (Oct 2022 - Sep 2023)");
      filteredArray.push("All years");
      setQFSelections(filteredArray);
    } else {
      let newArray = [...qFSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      setQFSelections(filteredArray);
    }
  }

  const handleYearCheckboxChange = (e, item) => {
    if (yearSelections.includes(item)){
      let newArray = yearSelections.filter(x => x !== item); 
      setYearSelections(newArray);
    } else {
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      filteredArray.push(item);
      setYearSelections(filteredArray);
    }
  }

  const handleAllYearCheckboxChange = (e) => {
    if (e.target.checked){
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "FY18 (Oct 2017 - Sep 2018)" && x !== "FY19 (Oct 2018 - Sep 2019)" && x !== "FY20 (Oct 2019 - Sep 2020)" && x !== "FY21 (Oct 2020 - Sep 2021)" && x !== "FY22 (Oct 2021 - Sep 2022)" && x !== "FY23 (Oct 2022 - Sep 2023)");
      filteredArray.push("All years");
      setYearSelections(["All years"]);
    } else {
      let newArray = [...yearSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      setYearSelections(filteredArray);
    }
  }

  const handleTimeChange = () => {
    setTime(!time)
  }

  const handleSubmit = () => {
    var timeCategory = time ? "Quarter & Fiscal Year" : "Fiscal Year";
    props.filterCallback({
      name: 'Time',
      filter: 'Services',
      selection: timeCategory,
      value: time ?  qFSelections: yearSelections,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      //Sort filters to get time filters
      if (props.selectedFilters[i].name === 'Time' && props.selectedFilters[i].filter === 'Services' && props.selectedFilters[i].stateLocation === 'TX'){
        //Filter based on Quarter/Fiscal Year and Fiscal Year
        if (props.selectedFilters[i].selection === 'Quarter & Fiscal Year') {
          setTime(true)
          setQFSelections(props.selectedFilters[i].value)
        } else {
          setTime(false)
          setYearSelections(props.selectedFilters[i].value)
        }
      }
    }
  }, [props.selectedFilters])

    return (
        <div className={classes.container}>
            {time ? (
              <div>
              <div className={classes.filterWrapper}>
                <div>
                  <p className={time ? classes.active : classes.inactive} onClick={handleTimeChange}>{`Quarter & Fiscal Year`}</p>
                  <p className={!time ? classes.active : classes.inactive} onClick={handleTimeChange}>Fiscal Year</p>
                </div>
                <div className={classes.checkboxes}>
                  <FormControlLabel className="checkboxLabel"
                    control={ 
                      <BlackCheckbox 
                        checked={qFSelections.includes("All quarters")} 
                        onChange={handleQFAllQuarterCheckboxChange} name="checkedA" 
                        />
                      }
                    label="All quarters"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={qFSelections.includes("Q1: Oct 1 - Dec 31")} 
                        onChange={e => handleQFCheckboxChange(e, "Q1: Oct 1 - Dec 31")} name="checkedA" 
                        />
                      }
                    label="Q1: Oct 1 - Dec 31"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={qFSelections.includes("Q2: Jan 1 - Mar 31")}
                        onChange={e => handleQFCheckboxChange(e, "Q2: Jan 1 - Mar 31")} name="checkedA" 
                        />
                      }
                    label="Q2: Jan 1 - Mar 31"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={qFSelections.includes("Q3: Apr 1 - Jun 30")}
                        onChange={e => handleQFCheckboxChange(e, "Q3: Apr 1 - Jun 30")} name="checkedA" 
                        />
                      }
                    label="Q3: Apr 1 - Jun 30"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={qFSelections.includes("Q4: Jul 1 - Sep 30")}
                        onChange={e => handleQFCheckboxChange(e, "Q4: Jul 1 - Sep 30")} name="checkedA" 
                        />
                      }
                    label="Q4: Jul 1 - Sep 30"
                  />
              </div>
              <Divider orientation="vertical" flexItem />
              <div className={classes.checkboxes}>
                  <FormControlLabel className="checkboxLabel"
                      control={ 
                        <BlackCheckbox 
                          checked={qFSelections.includes("All years")} 
                          onChange={e => handleQFAllYearCheckboxChange(e, "All years")}
                          name="checkedA" 
                          />
                        }
                      label="All years"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={qFSelections.includes("FY18 (Oct 2017 - Sep 2018)")} 
                          onChange={e => handleQFYearCheckboxChange(e, "FY18 (Oct 2017 - Sep 2018)")}
                          name="checkedA" 
                          />
                        }
                      label="FY18 (Oct 2017 - Sep 2018)"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={qFSelections.includes("FY19 (Oct 2018 - Sep 2019)")} 
                          onChange={e => handleQFYearCheckboxChange(e, "FY19 (Oct 2018 - Sep 2019)")}
                          name="checkedA" 
                          />
                        }
                      label="FY19 (Oct 2018 - Sep 2019)"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={qFSelections.includes("FY20 (Oct 2019 - Sep 2020)")} 
                          onChange={e => handleQFYearCheckboxChange(e, "FY20 (Oct 2019 - Sep 2020)")} 
                          name="checkedA" 
                          />
                        }
                      label="FY20 (Oct 2019 - Sep 2020)"
                    />
                    <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={qFSelections.includes("FY21 (Oct 2020 - Sep 2021)")} 
                          onChange={e => handleQFYearCheckboxChange(e, "FY21 (Oct 2020 - Sep 2021)")}
                          name="checkedA" 
                          />
                        }
                      label="FY21 (Oct 2020 - Sep 2021)"
                    />
                     <FormControlLabel className="checkboxLabel"

                      control={
                        <BlackCheckbox 
                          checked={qFSelections.includes("FY22 (Oct 2021 - Sep 2022)")} 
                          onChange={e => handleQFYearCheckboxChange(e, "FY22 (Oct 2021 - Sep 2022)")}
                          name="checkedA" 
                          />
                        }
                      label="FY22 (Oct 2021 - Sep 2022)"
                />
                <FormControlLabel className="checkboxLabel"

                  control={
                    <BlackCheckbox
                      checked={qFSelections.includes("FY23 (Oct 2022 - Sep 2023)")}
                      onChange={e => handleQFYearCheckboxChange(e, "FY23 (Oct 2022 - Sep 2023)")}
                      name="checkedA"
                    />
                  }
                  label="FY23 (Oct 2022 - Sep 2023)"
                />
                </div>
                
              </div>
                <div className={classes.saveButton}>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
              </div> 
            ) : (
              <div>
              <div className={classes.filterWrapper}>
                <div>
                  <p className={time ? classes.active : classes.inactive} onClick={handleTimeChange}>{`Quarter & Fiscal Year`}</p>
                  <p className={!time ? classes.active : classes.inactive} onClick={handleTimeChange}>Fiscal Year</p>
                </div>                
                <div className={classes.checkboxes}>
                  <FormControlLabel className="checkboxLabel"
                    control={ 
                      <BlackCheckbox 
                        checked={yearSelections.includes("All years")} 
                        onChange={handleAllYearCheckboxChange} name="checkedA" 
                        />
                      }
                    label="All years"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={yearSelections.includes("FY18 (Oct 2017 - Sep 2018)")} 
                        onChange={e => handleYearCheckboxChange(e, "FY18 (Oct 2017 - Sep 2018)")} name="checkedA" 
                        />
                      }
                    label="FY18 (Oct 2017 - Sep 2018)"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={yearSelections.includes("FY19 (Oct 2018 - Sep 2019)")} 
                        onChange={e => handleYearCheckboxChange(e, "FY19 (Oct 2018 - Sep 2019)")} name="checkedA" 
                        />
                      }
                    label="FY19 (Oct 2018 - Sep 2019)"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={yearSelections.includes("FY20 (Oct 2019 - Sep 2020)")}
                        onChange={e => handleYearCheckboxChange(e, "FY20 (Oct 2019 - Sep 2020)")}  name="checkedA" 
                        />
                      }
                    label="FY20 (Oct 2019 - Sep 2020)"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox 
                        checked={yearSelections.includes("FY21 (Oct 2020 - Sep 2021)")}
                        onChange={e => handleYearCheckboxChange(e, "FY21 (Oct 2020 - Sep 2021)")} name="checkedA" 
                        />
                      }
                    label="FY21 (Oct 2020 - Sep 2021)"
                  />
                  <FormControlLabel className="checkboxLabel"
                      control={
                        <BlackCheckbox 
                          checked={yearSelections.includes("FY22 (Oct 2021 - Sep 2022)")} 
                          onChange={e => handleYearCheckboxChange(e, "FY22 (Oct 2021 - Sep 2022)")}
                          name="checkedA" 
                          />
                        }
                      label="FY22 (Oct 2021 - Sep 2022)"
                  />
                  <FormControlLabel className="checkboxLabel"
                    control={
                      <BlackCheckbox
                        checked={yearSelections.includes("FY23 (Oct 2022 - Sep 2023)")}
                        onChange={e => handleYearCheckboxChange(e, "FY23 (Oct 2022 - Sep 2023)")}
                        name="checkedA"
                      />
                    }
                    label="FY23 (Oct 2022 - Sep 2023)"
                  />
              </div>
              </div>
                <div className={classes.saveButton}>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
              </div> 
            )}
        </div>
    )
}