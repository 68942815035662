import React, { useEffect, useState } from "react";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

import "./IconWrapper.scss";

export default function IconWrapper(props) {
    const [MaterialIcon, setMaterialIcon] = useState("");
    const iconKlass = {
        "PlaceOutlined": PlaceOutlinedIcon,
        "SchoolOutlined": SchoolOutlinedIcon,
        "GavelOutlined": GavelOutlinedIcon,
        "QueryStatsOutlined": QueryStatsOutlinedIcon
    };

    useEffect(() => {
        if (!props.iconName || !iconKlass[props.iconName]) {
            return;
        }

        setMaterialIcon(iconKlass[props.iconName]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.iconName]);

    return (
        <div className="icon-wrapper">
            <div className="icon">
                {MaterialIcon && <MaterialIcon fontSize="large" />}
            </div>
            <div className="icon-caption">
                <h3>{props.caption}</h3>
            </div>
        </div>
    );
}