import React from "react";
// import Cookies from "js-cookie";
// import { useForm } from "react-hook-form";
// import axios from "axios";
// import debounce from "lodash/debounce";

import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import TextField from "@mui/material/TextField";

// import ButtonLink from "../ButtonLink/ButtonLink.js";
// import Button from "../Button/Button.js";

// import { ENUMS } from "../../../Lib/Enums.js";

import "./NewsletterSubscribeModal.scss";

import HubspotForm from 'react-hubspot-form'
import closeImg from '../../../Assets/Images/close.png'


export default function NewsletterSubscribeModal(props) {
    // const { register, handleSubmit, formState: { errors }, reset } = useForm({
    //     defaultValues: {
    //         email: "",
    //         firstName: "",
    //         lastName: ""
    //     }
    // });
    // const textInputProps = {
    //     className: "text-input"
    // };

    // const isEmailValid = (email) => {
    //     // Regex copied from https://www.geeksforgeeks.org/how-to-validate-email-address-using-regexp-in-javascript/
    //     // eslint-disable-next-line no-useless-escape
    //     const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return emailRegex.test(email.toLowerCase())
    //         ? true
    //         : "Email must be formatted correctly";
    // };

    // const getForm = () => {
    //     return (
    //         <form
    //             className="modal-subscribe-form"
    //             onSubmit={handleSubmit(debounceSubmitForm)}
    //         >
    //             <TextField
    //                 error={!!errors.email}
    //                 InputProps={textInputProps}
    //                 label="Email*"
    //                 helperText={errors.email ? errors.email.message : ""}
    //                 {...register("email", {
    //                     required: "Please complete this required field",
    //                     validate: isEmailValid
    //                 })} />
    //             <div className="form-input-group">
    //                 <TextField
    //                     error={!!errors.firstName}
    //                     InputProps={textInputProps}
    //                     label="First Name*"
    //                     helperText={errors.firstName ? errors.firstName.message : ""}
    //                     {...register("firstName", {
    //                         required: "Please complete this required field"
    //                     })} />
    //                 <TextField
    //                     error={!!errors.lastName}
    //                     InputProps={textInputProps}
    //                     label="Last Name*"
    //                     helperText={errors.lastName ? errors.lastName.message : ""}
    //                     {...register("lastName", {
    //                         required: "Please complete this required field"
    //                     })} />
    //             </div>
    //             <Button className="modal-btn btn-black" type="submit">
    //                 Submit
    //             </Button>
    //         </form>
    //     );
    // };

    // const generateHubspotEndpoint = () => {
    //     const { SUBMIT_FORM_ENDPOINT } = ENUMS.HUBSPOT;
    //     const pathParamKeyToValueMap = {
    //         portalId: process.env.REACT_APP_PUBLIC_SITE_HUBSPOT_PORTAL_ID,
    //         formGuid: process.env.REACT_APP_PUBLIC_SITE_HUBSPOT_FORM_ID
    //     };

    //     let endpoint = SUBMIT_FORM_ENDPOINT;
    //     for (const key of Object.keys(pathParamKeyToValueMap)) {
    //         endpoint = endpoint.replace(`:${key}`, pathParamKeyToValueMap[key]);
    //     }

    //     return endpoint;
    // };

    // const getIpAddress = async () => {
    //     const res = await axios.get("https://ipinfo.io/json");
    //     return res?.data?.ip;
    // };

    // const submitForm = async (data) => {
    //     const ipAddress = await getIpAddress();
    //     const endpoint = generateHubspotEndpoint();
    //     const formBody = {
    //         fields: [
    //             {
    //                 name: "email",
    //                 value: data.email
    //             },
    //             {
    //                 name: "firstname",
    //                 value: data.firstName
    //             },
    //             {
    //                 name: "lastname",
    //                 value: data.lastName
    //             },
    //         ],
    //         context: {
    //             hutk: Cookies.get("hubspotutk"),
    //             ipAddress,
    //             pageUri: window.location.href,
    //             pageName: window.location.pathname.substring(1)
    //         },
    //         legalConsentOptions: {
    //             consent: {
    //                 consentToProcess: true, // This is always kept to true
    //                 text: "Allies needs the contact information you provide to us to send you information about human trafficking, Lighthouse, and our work to protect freedom and dignity. You may unsubscribe from these communications at any time."
    //             }
    //         }
    //     };

    //     try {
    //         await axios.post(endpoint, formBody, {
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         });
    //         reset();

    //         props.handleClose();
    //         props.showSnackbar();
    //     } catch (error) {
    //         console.log("Error on submitting hubspot form", error);
    //     }
    // };

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // const debounceSubmitForm = useCallback(debounce(submitForm, 1000), []);

    return (
        <div>
            <Dialog
                className="newsletter-subscribe-modal"
                open={props.open}
                onClose={props.handleClose}
            >
                <div className='padding40'>
                    <button className='btnNoStyle newsletterSubscribeClose' onClick={props.handleClose}>
                        <img src={closeImg} width={24} height={24} alt='close' />
                    </button>

                    <h2 className='fontLora fontSize32 lineHeight41 marginT0 marginB16'>Get inspiration in your inbox.</h2>

                    <p className='fontSize20 lineHeight25 margin0 marginB40'>
                        Our newsletter shares stories of hope,
                        the latest news, and successes you help achieve
                        in the fight against human trafficking.
                    </p>
                    {/* {getForm()} */}
                    <HubspotForm
                        portalId='4121080'
                        formId='9bafa647-87f2-4b1b-b6be-f43328ff3328'
                        region='na1'
                        onSubmit={() => console.log('Submit!')}
                        // onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
                    
                    {/* <DialogActions>
                    <div className="modal-form-group modal-login-footer">
                        <ButtonLink onClickFn={props.handleClose}>
                            Cancel
                        </ButtonLink>
                    </div>
                </DialogActions> */}
                </div>
            </Dialog>
        </div>
    );
}