import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
       // height: '400px',
        overflowY: 'scroll',
       // width: '500px'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer'
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function TimeFilter(props) {
  const classes = useStyles();
  const [filterSelections, setFilterSelections] = React.useState([]);

  const handleCheckboxChange = (e, item) => {
    if (filterSelections.includes(item)){
      let newArray = filterSelections.filter(x => x !== item); 
      setFilterSelections(newArray);
    } else {
      let newArray = [...filterSelections]
      let filteredArray = newArray.filter(x => x !== "All years");
      filteredArray.push(item);
      setFilterSelections(filteredArray);
    }
  }


  const handleAllCheckboxChange = (e) => {
    if (e.target.checked){
      setFilterSelections(["All years"]);
    } else {
      setFilterSelections([]);
    }
  }

  const handleSubmit = () => {
    props.filterCallback({
      name: 'Time',
      filter: 'Services',
      selection: filterSelections,
      value: filterSelections,
      stateLocation: 'LA'
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Time' && props.selectedFilters[i].filter === 'Services'){
        setFilterSelections(props.selectedFilters[i].value)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
        <div className={classes.container}>
          <div className={classes.checkboxes}>
                <FormControlLabel className="checkboxLabel"
                  control={ 
                    <BlackCheckbox 
                      checked={filterSelections.includes("All years")} 
                      onChange={e => handleAllCheckboxChange(e, "All years")} name="checkedA" />
                    }
                  label={"All years"}
                />
                <FormControlLabel className="checkboxLabel"
                    control={ 
                    <BlackCheckbox 
                        checked={filterSelections.includes("2017")} 
                        onChange={e => handleCheckboxChange(e, "2017")} name="checkedA" />
                    }
                    label={"2017"}
                />
                <FormControlLabel className="checkboxLabel"
                    control={ 
                    <BlackCheckbox 
                        checked={filterSelections.includes("2018")} 
                        onChange={e => handleCheckboxChange(e, "2018")} name="checkedA" />
                    }
                    label={"2018"}
                />
                <FormControlLabel className="checkboxLabel"
                    control={ 
                    <BlackCheckbox 
                        checked={filterSelections.includes("2019")} 
                        onChange={e => handleCheckboxChange(e, "2019")} name="checkedA" />
                    }
                    label={"2019"}
                />
                <FormControlLabel className="checkboxLabel"
                    control={ 
                    <BlackCheckbox 
                        checked={filterSelections.includes("2020")} 
                        onChange={e => handleCheckboxChange(e, "2020")} name="checkedA" />
                    }
                    label={"2020"}
                />
                <FormControlLabel className="checkboxLabel"
                    control={ 
                    <BlackCheckbox 
                        checked={filterSelections.includes("2021")} 
                        onChange={e => handleCheckboxChange(e, "2021")} name="checkedA" />
                    }
                    label={"2021"}
                />
                <FormControlLabel className="checkboxLabel"
                    control={ 
                    <BlackCheckbox 
                        checked={filterSelections.includes("2022")} 
                        onChange={e => handleCheckboxChange(e, "2022")} name="checkedA" />
                    }
                    label={"2022"}
                />
                <FormControlLabel className="checkboxLabel"
                    control={ 
                    <BlackCheckbox 
                        checked={filterSelections.includes("2023")} 
                        onChange={e => handleCheckboxChange(e, "2023")} name="checkedA" />
                    }
                    label={"2023"}
          />
          <FormControlLabel className="checkboxLabel"
            control={
              <BlackCheckbox
                checked={filterSelections.includes("2024")}
                onChange={e => handleCheckboxChange(e, "2024")} name="checkedA" />
            }
            label={"2024"}
          />
                  
        </div> 
       
        <div className={classes.saveButton}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    )
}