import { createClient } from "contentful";
import pick from "lodash/pick";

import { ContentfulFields } from "../ContentfulFields";

let client;
if (process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_API_KEY) {
    client = createClient({
        space: process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_SPACE_ID,
        environment: process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_ENV_ID,
        accessToken: process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_API_KEY
    });
}

const _getFormattedMedia = (media) => {
    const item = media[0]?.fields || media.fields;
    const { title, file } = item;
    return {
        url: file?.url || "",
        contentType: file?.contentType,
        title
    };
}

const _getEntries = async (contentType) => {
    const response = await client.getEntries({
        content_type: contentType,
        select: "fields"
    });

    return response.items.map((item) => {
        const entryItem = pick(item.fields, Object.keys(ContentfulFields[contentType]))
        entryItem.contentType = contentType;
        return entryItem;
    });
};

const getFooterContent = async () => {
    try {
        return (await _getEntries("footer"))[0];
    } catch (error) {
        console.log("getFooterContent error", error);
    }
};

// const getBannerContent = async () => {
//     try {
//         return (await _getEntries("bannerBar"))[0];
//     } catch (error) {
//         console.log("getBannerContent error", error);
//     }
// }

const getChartContent = async (pageName) => {
    try {
        return (await _getEntries("chart")).filter(({ chartPageAssociated }) => {
            const associatedPages = chartPageAssociated?.split(",");
            return associatedPages?.includes?.(pageName);
        });
    } catch (error) {
        console.log("getChartsContent error", error);
    }
};

const getMapsContent = async (pageName) => {
    try {
        return (await _getEntries("map"))
            .filter(({ mapPageAssociated }) => mapPageAssociated === pageName);
    } catch (error) {
        console.log("getMapsContent error", error);
    }
};

const getLogoContent = async () => {
    try {
        const logos = await _getEntries("logoComponent");
        logos.sort((a, b) => a.order - b.order);

        return logos.map((logoItem) => {
            logoItem.logo = _getFormattedMedia(logoItem.logo);
            return logoItem;
        });
    } catch (error) {
        console.log("getLogoContent error", error);
    }
}

const getTableContent = async (pageName) => {
    try {
        const entries = await _getEntries("tableItem");
        const filteredEntries = entries.filter(({ pageAssociated }) => pageAssociated === pageName);
        filteredEntries.sort((a, b) => a.order - b.order);

        return filteredEntries.map((entry) => {
            entry.icon = _getFormattedMedia(entry.icon)
            return entry;
        });
    } catch (error) {
        console.log("getTableContent error", error);
    }
};

const getTimelineContent = async (pageName) => {
    try {
        const entries = await _getEntries("timelineItem");

        entries.sort((a, b) => a.year - b.year);
        return entries.filter(({ pageAssociated }) => pageAssociated === pageName);
    } catch (error) {
        console.log("getTimelineContent error", error);
    }
};

const getDropdownMenuOptions = async (pageName) => {
    try {
        const entries = await _getEntries("dropdownOption");
        return entries.filter(({ pageAssociated }) => pageAssociated === pageName);
    } catch (error) {
        console.log("getDropdownMenuOptions error", error);
    }
};

const getModalContent = async (pageName) => {
    try {
        const entries = await _getEntries("modal");
        return entries.filter(({ pageAssociated }) => pageAssociated === pageName);
    } catch (error) {
        console.log("getModalContent error", error);
    }
};

const getIconContent = async (pageName, sectionName) => {
    try {
        const entries = await _getEntries("icon");
        const filtered = entries.filter(({ pageAssociated, pageSection }) => pageAssociated === pageName
            && pageSection === sectionName);
        filtered.sort((a, b) => a.sortOrder - b.sortOrder);
        return filtered;
    } catch (error) {
        console.log("getModalContent error", error);
    }
};

const getHighlightContent = async (pageName, sectionName) => {
    try {
        const entries = await _getEntries("highlight");
        // eslint-disable-next-line array-callback-return
        entries.map((entry) => {
            if (entry.image) {
                entry.image = _getFormattedMedia(entry.image);
            }
        });

        const filtered = entries.filter(({ pageAssociated, pageSection }) => pageAssociated === pageName
            && pageSection === sectionName);
        filtered.sort((a, b) => a.sortOrder - b.sortOrder);
        return filtered;
    } catch (error) {
        console.log("getModalContent error", error);
    }
};

const getSiteContent = async (contentType) => {
    try {
        const siteContent = (await _getEntries(contentType))[0];
        if (siteContent.headerMedia) {
            siteContent.headerMedia = _getFormattedMedia(siteContent.headerMedia);
        }

        return siteContent;
    } catch (error) {
        console.log("getSiteContent error", error);
    }
};

const useContentful = () => {
    return {
        getFooterContent,
        // getBannerContent,
        getSiteContent,
        getMapsContent,
        getLogoContent,
        getChartContent,
        getTableContent,
        getTimelineContent,
        getDropdownMenuOptions,
        getModalContent,
        getIconContent,
        getHighlightContent
    };
};

export default useContentful;