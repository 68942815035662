import { countyData } from "../../../Data/countyJson";
import { parishData } from "../../../Data/parishJson";
import { flCountyData } from "../../../Data/flCountyJson";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
var demandTXData = JSON.parse(JSON.stringify(countyData));
var demandLAData = JSON.parse(JSON.stringify(parishData));
var demandFLData = JSON.parse(JSON.stringify(flCountyData));



export function DemandLayer(map, location, data, stateLocation) {
    console.log(data)
    if (data.counties) {
        if (data.counties.length > 0) {
            let stateData;
            if (stateLocation === 'TX') {
                stateData = demandTXData;
            } else if (stateLocation === 'LA') {
                stateData = demandLAData;
            } else {
                stateData = demandFLData;
            }
            if (Object.keys(data.counties[0])[0] === "county"){
                for (let i = 0; i < stateData.features.length; i++) {
                    var counter = 0;
                    for (let j = 0; j < data.counties.length; j++) {
                        if (
                            stateData.features[i].properties.COUNTY.toLowerCase() ===
                (data.counties[j].county.toLowerCase())
                ) {
                    stateData.features[i].properties.SCREENINGS = Number(
                    data.counties[j].total
                );
                counter += 1;
                }
            }
            if (counter === 0){
                stateData.features[i].properties.SCREENINGS = 0;
            }
        }
        let calculatedData = stateData;
        map.on("load", function () {
            map.addSource("county", {
            type: "geojson",
            data: calculatedData,
            });
            map.addLayer({
            id: "county",
            type: "line",
            source: "county",
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: {
                "line-color": "#28ADE3",
                "line-width": 1,
            },
            });

            map.addLayer({
            id: "county-fills",
            type: "fill",
            source: "county",
            layout: {
                visibility: "visible",
            },
            paint: {
                "fill-color": {
                property: "SCREENINGS",
                    stops: stateLocation === 'FL' ? [
                        [1, "#e9f6fc"],
                        [1000, "#93d6f1"],
                        [3000, "#28ade3"],
                        [5000, "#186788"],
                        [10000, "#0c3344"],
                        [20000, "#000000"],
                    ] : [

                    [1, "#e9f6fc"],
                    [100, "#93d6f1"],
                    [500, "#28ade3"],
                    [1000, "#186788"],
                    [2000, "#0c3344"],
                    [3000, "#000000"],
                ],
                },
                "fill-outline-color": "#28ADE3",
                "fill-opacity": 0.9,
            },
            });
        });

        let hoveredStateId = null;
        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            });

            map.on("mousemove", "county-fills", function (e) {
            if (hoveredStateId !== e.features[0].id) {
                if (e.features.length > 0) {
                if (hoveredStateId) {
                    map.setFeatureState(
                    { source: "county", id: hoveredStateId },
                    { hover: false }
                    );
                }
                hoveredStateId = e.features[0].id;
                map.setFeatureState(
                    { source: "county", id: hoveredStateId },
                    { hover: true }
                );
                }
                let description = `
                <h3>${e.features[0].properties.COUNTY}</h3> 
                <p>Ads: <span style="font-weight: 300 !important">${
                    e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                    <p>Percent: <span style="font-weight: 300 !important">${
                        Number((((e.features[0].properties.SCREENINGS / data.countiesTotal[0].count)*100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}%</span></p>`
            
                popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
            }
            });

            map.on("mouseleave", "county-fills", function () {
            if (hoveredStateId) {
                map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: false }
                );
            }
            hoveredStateId = null;
            popup.remove();
            });
        }
    }
    } else {
        return;
    }
}